import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@akeela/auth';
import { environment } from '../../environments/environment';
@Injectable()
export class ValidateSession implements CanActivate {
  constructor(private router: Router, private _auth: AuthService, private _router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this._auth.isAuthenticated()) {
      return true;
    } else {
      // this._auth.logout();
      this.router.navigate([environment.defaultInitRouteAuthenticated], { queryParams: {} });
      return false;
    }
  }
}
