import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpService } from '@akeela/properties';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  phoneSelected = false;
  phone;

  form;

  verified;


  constructor(private fb: FormBuilder, private _http: HttpService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      phone: ['', Validators.required]
    });
  }

  clickEvent(first, last) {
    if (first.value.length) {
      document.getElementById(last).focus();
    }
  }

  openOTP() {
    this.phone = this.form.get('phone').value;
    const params = {
      name: 'otp$sendsms$post',
      params: {
        request: {
          phone: this.phone,
        },
        path: {},
        body: {}
      }
    };
    this._http.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      if (!isNullOrUndefined(response.send)) {
        this.phoneSelected = true;
      } else {
        alert('no se puedo enviar sms');

      }
    }, error => {
      alert('no se puedo enviar sms');
    });
  }

  responseOTP(event) {
    if (event.response === 'success') {
      this.verified = true;
      setTimeout(() => {
        this.phone = null;
        this.phoneSelected = false;
      }, 50);

    } else if (event.response === 'close') {
      setTimeout(() => {
        this.phone = null;
        this.phoneSelected = false;
      }, 50);

    }
  }

}
