import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit {
  public balancerequirements;

  @Input() set balanceRequirements(value) {
    this.balancerequirements = value;
  }

  constructor() { }

  ngOnInit() {
  }

}
