import { Injectable } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { reject } from 'q';
import { environment } from '../../environments/facebank/environment';

@Injectable({
    providedIn: 'root'
})

export class MiddlewareService {
    constructor(
        private _httpService: HttpService,
    ) { }

    isModuleRequirementsActive() {
        return new Promise((resolve, reject) => {
            if (environment.middleware) {
                const params: any = {
                    endpoint: {
                        name: 'middleware$isactive$get',
                        params: {
                            path: {
                                environment: environment.middleware.environment,
                                module: environment.middleware.module
                            },
                            request: {},
                            body: {}
                        }
                    }
                };
                this._httpService.executeRequest(params.endpoint.name, params.endpoint.params).subscribe((response: any) => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
            } else {
                resolve(false);
            }
        });
    }
}
