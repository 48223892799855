<app-template>
    <ng-template appContent componentName="mainContentModalBottonMeet">
        <div class="capa-menu-2" *ngIf="buttomModal" (click)="changes()"> </div>

        <div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom ak-modal-botoom2  p-2 ">
            <div class="body pb-4 pt-2 " style="overflow:auto;">
                <div class="container text-center  ">
                    <div class="mt-4">


                        <ng-container *ngIf="loading">
                            <div class="text-left w-100">
                                <img src="./assets/public/images/loading.gif " alt=" ">
                            </div>
                        </ng-container>
                        <div class="row text-center " *ngIf="!loading">

                            <div class="col-12 col-md-6 offset-md-3">
                                <h5 class="pt-2 font-weight-bold"> Reuniones</h5>
                            </div>
                        </div>



                        <ng-container>
                            <div class="row animate__animated animate__fadeIn">
                                <div class="col-12 col-md-6 offset-md-3">
                                    <div class=" text-left">
                                        <div class="p-2 fz-14 d-flex bd-highlight  list-flex flex-wrap text-left size-caja-dopd">
                                            <ng-container *ngIf="!back && !loadingTransaction">
                                                <div class="w-100 pt-5  text-center animate__animated animate__fadeIn" *ngIf="interviews?.length < 1">
                                                    <i class=" pt-4 pb-2 opacity-card fas fa-people-arrows fa-5x  text-center text-primary"></i>
                                                    <div>No se encontraron reuniones</div>
                                                </div>

                                                <div *ngIf="interviews?.length > 0" class="mt-4 w-100">

                                                    <!--  interviewsTime : <pre>{{ interviewsTime | json }}</pre>-->

                                                    <ng-container *ngFor="let row of interviews ; let i = index">

                                                        <div class="d-flex bd-highlight  list-flex mt-2">

                                                            <div>
                                                                <i class="fas mr-3 icon-list-meet text-secondary" [ngClass]="{
                                                                    'fa-video': interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option3',
                                                                    'fa-phone-alt':interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option2',
                                                                    'fa-user':interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option1'}"></i>
                                                            </div>

                                                            <div class="mb-3">

                                                                <div class="d-flex  flex-wrap text-left ">
                                                                    <div class=" mb-1 w-100 font-weight-bold"> {{interviewType[row?.type].label | i18n}} <span class="font-weight-normal"> - {{row?.transaction}}</span></div>
                                                                    <div class="mb-1 w-100  text-secondary"> {{toDateString(row?.interviewDate)}} </div>
                                                                    <div class="mb-2 p-2 fz-12 text-secondary bg-light " *ngIf="interviewsTime[row.idTransaction]['valid']">
                                                                        <span class="pr-2 ">Faltan</span>
                                                                        <span class="pr-2"><span *ngIf="interviewsTime[row.idTransaction]['day'] > 0" >{{ interviewsTime[row.idTransaction]['day']}}</span>D</span>
                                                                        <span class="pr-2"><span *ngIf="interviewsTime[row.idTransaction]['hours'] > 0"  >{{ interviewsTime[row.idTransaction]['hours'] }}</span>Hor</span>
                                                                        <span class="pr-2"><span *ngIf="interviewsTime[row.idTransaction]['minutes'] > 0"  >{{interviewsTime[row.idTransaction]['minutes'] }}</span>Min</span>
                                                                        <span class="pr-2"><span  *ngIf="interviewsTime[row.idTransaction]['seconds'] > 0" >{{interviewsTime[row.idTransaction]['seconds'] }}</span>Seg</span>
                                                                        <span class="pl-2 far fa-clock blink-me rounded "></span>
                                                                    </div>
                                                                    <div class="mb-2 p-2 fz-12 text-secondary bg-light " *ngIf="!interviewsTime[row.idTransaction]['valid']">
                                                                        <span class="pr-2 text-danger">Fecha expirada</span>
                                                                        <span class="pl-2 far fa-clock blink-me rounded "></span>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>



                                                        <div class="d-flex  flex-wrap text-left ">
                                                            <div *ngIf="row?.description " class="font-weight-bold mb-2 ">Descripción </div>
                                                            <div class=" mb-2 w-100 "> {{row?.description}} </div>


                                                            <div class="text-left w-100 pb-4">

                                                                <div *ngIf=" interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option3'" class="mt-1 mb-2  text-primary active-cursor " (click)="redirectMeet('https://meet.jit.si/meet-'+env?.theme?.images+'-'+clientDetail?.identifier+'-'+row?.transaction) ">
                                                                    {{'https://meet.jit.si/meet-'+env?.theme?.images+'-'+clientDetail?.identifier+'-'+row?.transaction}}
                                                                </div>

                                                                <div class="w-100">
                                                                    <span *ngIf=" interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option3'" class="text-secondary active-cursor pb-3 pr-3" (click)="setCopy( 'https://meet.jit.si/meet-'+env?.theme?.images+ '-'+clientDetail?.identifier+'-'+row?.transaction) ">
                                                                    <button class="btn btn-outline-secondary ">
                                                                        <i class="fas fa-copy pr-2 "></i>Copiar
                                                                   </button>
                                                                   </span>
                                                                    <span class="  d-md-none  text-secondary pb-3 text-left" (click)="setShare(interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option3'?'https://meet.jit.si/meet-'+env?.theme?.images+ '-'+clientDetail?.identifier+'-'+row?.transaction: false ,{fecha:row?.interviewDate,tipo:interviewType[row?.type].label}) ">

                                                                    <button class="btn btn-outline-secondary ">
                                                                        <i class="fas fa-share-alt pr-2 "></i>Compartir
                                                                   </button>
                                                                 </span>

                                                                </div>


                                                            </div>
                                                        </div>

                                                    </ng-container>
                                                </div>

                                                <ng-container *ngIf="loadingTransaction ">
                                                    <div>
                                                        <img src="./assets/public/images/loading.gif " alt=" ">
                                                    </div>
                                                </ng-container>
                                            </ng-container>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>

    </ng-template>
</app-template>