import { Injectable, TemplateRef } from '@angular/core';

@Injectable()
export class TemplateService {
    private templates: any = {};

    constructor() {
    }

    addTemplate(componentName: string, template: TemplateRef<any>) {
        this.templates[componentName] = template;
    }

    getTemplates() {
        return this.templates;
    }

    getTemplate(componentName) {
        return this.templates[componentName];
    }
}
