import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '@akeela/properties';
import { AlertService } from '@akeela/alert';
import { AuthService } from '@akeela/auth';

@Component({
  selector: 'app-change-user-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  username;
  token;
  config;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _httpService: HttpService,
    private akAlert: AlertService,
    public _auth:AuthService
  ) { }

  ngOnInit() {    

    this.route.params.subscribe(queryParams => {
      this.username = queryParams['username'];
      this.token = queryParams['token'];
      this.config = {
        save: {
          url: 'person$create_password$put',
          params: {
            path: {},
            request: {},
            body: {}
          }
        },
        reset: {
          url: 'person$reset_password_client$put',
          params: {
            path: {},
            request: {},
            body: {}
          }
        }
      };


      
    });
  }

  getResponse(response) {    
    if (response.success) {

      this._auth.setShowChangePassword(false);
      this.akAlert.show({
        title: '',
        html: 'Cambio de contraseña exitosa.'
      });



    }
  }
}
