import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { AuthService } from '@akeela/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { isNullOrUndefined } from 'util';
import { TransitionService } from '../../../../services/transition.service';
import { environment } from '../../../../../environments/environment';
import { I18nPipe } from '@akeela/i18n';
import swal from 'sweetalert2';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { Subscription } from 'rxjs';
import { debug } from 'console';
import { WorkflowService } from 'src/app/services/workflow.service';

declare var Swal: any;
@Component({
  selector: 'app-paperwork',
  templateUrl: './paperwork.component.html',
  styleUrls: ['./paperwork.component.css']
})
export class PaperworkComponent implements OnInit, OnDestroy, AfterViewInit {
  workflow;
  stage;
  step;
  role;
  formalities = null;
  totalFormalities = null;

  sentFormalities = null;
  draftFormalities = null;
  inboxFormalities = null;

  formalitieI18n = '';

  queryParams;
  active_redirect = false;
  breadcrumdKey: any;
  redirect = true;
  cargando = '';
  url_transition = 'workflow$transitionssteps$get';
  trans_subscriber: Subscription;
  route_subscriber: Subscription;
  wf_events_subscriber: Subscription;
  trans3_subscriber: Subscription;
  trans2_subscriber: Subscription;
  trans1_subscriber: Subscription;
  cout_subscriber: Subscription;
  temp_subscriber: Subscription;
  indicatorsProgres:any = {};
  indicatorsLoading;


  code = '';

  usuario_session = '';
  transactionData;
  usuario;

  paginator: any = null;
  loading_table = true;
  env = environment;
  active;
  paramsSubscription: Subscription;
  workflowCodeActive;
  listWf = {};
  newTransaction;
  constructor(
    public _httpService: HttpService,
    private akWorkflowService: AkeelaWorkflowService,
    private _wfService: WorkflowService,

    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private akeelaSectionService: AkeelaSectionService,
    private akeelaBreadcrumbService: AkeelaBreadcrumbService,
    private _transition: TransitionService,
    private activatedRoute: ActivatedRoute,
    private _i18n: I18nPipe,
    private localStorage: LocalService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,


  ) {


  }



  ngOnInit() {
    this.localStorage.removeItem('histotyBak');

    this.akeelaBreadcrumbService.setbreadcrumbOptions([]);
    this.workflowCodeActive = this.akWorkflowService.currentWorkflowActive$.getValue();
    this.akStepService.setModeCurrentStep(false);
    this.akWorkflowService.worflowEvents$.next(null);
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akStageService.currentStage$.getValue();
    this.akStageService.setshowHeaderStage(false);
    this.akStepService.setshowHeaderStep(false);
    this.akStepService.setshowSubHeaderStep(false);
    this.step = this.akStepService.currentStep$.getValue();
    this.akeelaSectionService.addModalSection({ name: 'PROD_PLAN_SELECTOR_MODAL' });
    this.akWorkflowService.setTransactionNumber(null);
    this.akWorkflowService.transactionData$.next(null);

    

    this.role = this.localStorage.getItem('role');
    this._akeelaSidebarMenu.setshowSidebarLeft(false);

    this.akWorkflowService.currentWorkflow$.subscribe((response) => {
      if (!isNullOrUndefined(response)) {
        this.workflow = response;
      }
    });

    this.usuario_session = this._auth.getUsername();

    this.usuario = this._auth.getSession();

    this.temp_subscriber = this.activatedRoute.queryParams.subscribe(params => {
      let code: string;

      let tmp = params.transactionNumber;
      this.code = code = params.code;
      this.paginator = null;

          this.active = true;
          this.formalities = null;
          this.formalitieI18n = '';
          this.inbox();

    });



    this.route_subscriber = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });




    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }
    this.wf_events_subscriber = this.akWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (response) {
        if (response.action === 'account$accountopening$post' && response.response.transactionNumber && !this.active_redirect) {
          this.wait();
          this.active_redirect = true;
          
          this.getAccountproductinformation(response, response.response.transactionNumber);
          
        }
      }
    });

    this._wfService.listWF$.subscribe((listWf: any) => {

      if(listWf){
        this.listWf = listWf;
      }
             
    });

    
    if(this.localStorage.getItem('newProduct')){

      this.new();
      this.localStorage.removeItem('newProduct');

    }


}
getListWf(wf){
return   this.listWf[wf];


}

  getAccountproductinformation(responseIn, transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };



    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.transactionData = response;
      const queryParams2 = {
        role: this.localStorage.getItem('role'),
        profile: this.localStorage.getItem('profile'),
        audience: this.localStorage.getItem('audience'),
        transactionNumber: transactionNumber,
      };

      this.localStorage.setItem('sectionRedirect',true);

     let url = ['transaction-dashboard'];

      this.router.navigateByUrl(
        this.router.createUrlTree(url,
          {
            queryParams: queryParams2
          }
        )
      ).then(() => {
      });
      this.akWorkflowService.setTransactionNumber(transactionNumber);

      setTimeout(() => {
        this.akeelaSectionService.hideModalSection('PROD_PLAN_SELECTOR_MODAL');
        swal.close();

      }, 500);

      this.destroySubscribers();

    });
  }


  ngAfterViewInit() {


  }


  inbox() {
    let request = {};
    this.loading_table = true;

    request = {
      page: 0,
      size: 60

    };

    const param = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: request,
        path: {
          username: this._auth.getUsername()
        },
        body: {}
      }
    };

    this.trans_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {

      this.inboxFormalities = response.totalElements;
        this.paginator = response;
        this.formalitieI18n = '@i18n-menu-label-client-inbox';
        this.formalities = response.content;
        this.getIndicatorsProgres(this.formalities);


      this.loading_table = false;

    }, error => {
      this.loading_table = false;
      this.inboxFormalities = 0;
        this.formalities = [];
    });

  }

  openFormalities(transactionNumber, requirements = false) {


    this.deleteEditTransaction();
    this.localStorage.removeItem('transactionModeEditShowFlag');

    this.getTransactionData(transactionNumber).subscribe((response: any) => {
      this.transactionData = response;
      const queryParams = {
        role: this.localStorage.getItem('role'),
        profile: this.localStorage.getItem('profile'),
        audience: this.localStorage.getItem('audience'),
        transactionNumber: transactionNumber,
      };

      this.redirecTransaction();

    

      this.akWorkflowService.setTransactionNumber(transactionNumber);
    });

  }

  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }


  new() {

    this.localStorage.setItem('clientaudience', this.localStorage.getItem('audience'));
    this.newTransaction = true;
  }

  destroySubscribers() {

    if (this.temp_subscriber) {
      this.temp_subscriber.unsubscribe();
    }

    if (this.trans_subscriber) {
      this.trans_subscriber.unsubscribe();
    }
    if (this.trans1_subscriber) {
      this.trans1_subscriber.unsubscribe();
    }
    if (this.trans2_subscriber) {
      this.trans2_subscriber.unsubscribe();
    }
    if (this.trans3_subscriber) {
      this.trans3_subscriber.unsubscribe();
    }
    if (this.route_subscriber) {
      this.route_subscriber.unsubscribe();
    }
    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }
  }

  confirmDelete(row) {
    swal.fire({
      title: this._i18n.transform('@i18n-popup-text-delete-question'),
      text: this._i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this._i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this._i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (result) {
        this.trash(row);
      }
    });
  }

  trash(row) {
    const request = {};
    const param = {
      name: 'account_opening$account$delete',
      params: {
        request: {
          requestUser: this._auth.getUsername(),
          reason: 'SOMETEXT'
        },
        path: {
          transactionNumber: row.transactionNumber,
        },
        body: {}
      }
    };

    this.trans_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {
    
           this.inbox();
    });

  }

  ngOnDestroy() {

    document.body.classList.remove('gray');

    this.redirect = false;
    this.akeelaBreadcrumbService.setbreadcrumbOptions([]);


    if (this.route_subscriber) {
      this.route_subscriber.unsubscribe();
    }

    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }

    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }

    this.destroySubscribers();
  }

  deleteEditTransaction() {
    if (!isNullOrUndefined(this.localStorage.getItem('transactionModeEdit'))) {
      //this.breakfreeTransactionEdit(); // Mario: este caso era de liberación individual, pero debido a que ahora se va a liberar por usuario, se comenta para ver el comportamiento
      this.breakfreeTransactionsByUser();
    } else {
      this.breakfreeTransactionsByUser();
    }
  }

  breakfreeTransactionsByUser() {
    // alert('voy a ejecuctar 2');

    const configuration = {
      name: 'account$finish_editing_role_by_user$put',
      params: {
        path: {},
        request: {
          role: 'CLIENT',
          holderId: this.localStorage.getItem('audience') === 'LEGAL' ? this._auth.getAdminOrganizationIdentifier() : this.localStorage.getItem('clientaudience')

        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
    });
  }

  breakfreeTransactionEdit() {
    // alert('voy a ejecuctar 111111');

    const configuration = {
      name: 'account_opening$finish_editing_role$put',
      params: {
        path: {
          transactionNumber: this.localStorage.getItem('transactionModeEdit')
        },
        request: {
          role: 'CLIENT',
          holderId: this.localStorage.getItem('audience') === 'LEGAL' ? this._auth.getAdminOrganizationIdentifier() : this.localStorage.getItem('clientaudience')

        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');

    });
  }


  wait() {
    swal.fire({
      title: '',
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      html: '<b>' + this._i18n.transform('@i18n-text-please-wait') + '...<b><br><i class="fa fa-spin fa-spinner"></i>',
    });
  }


  getIndicatorsProgres(datas) {
  
    let transactions = [];
    let data :any;
    let ẗempTransaction = {};

      for (const row of   datas ) {
  
 

        ẗempTransaction[row?.transactionNumber]  = row?.plan?.url ;
     
        if(row?.accountStatus[0].additionalType =='PI_IN_PROCESS'){
          transactions = [...transactions, {transactionNumber:row?.transactionNumber}]
        }
              
  
      }

   data = {
      "workflow":this.workflowCodeActive,
      bankAccountTransactions :transactions
    } ;


  const params = {

    name: 'resume$workflow_progress$post',
    params: {
      request: {
        role: this.localStorage.getItem('role'),
        audience: this.localStorage.getItem('audience'),

      },
      path: {},
      body: data

    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if (response) {
      for (const row of   response ) {
  
      let transactionNumber =    row?.bankAccountTransaction?.transactionNumber;
         
       let progress :any;
      let preData =row.modules;

      let  progress2 = preData;

    
       if(progress2?.length > 0) {


          
             progress  = progress2[1]?.progress;

        let  maxValue = progress?.maxValue;
        let  minValue = progress?.minValue;

        let indicatorsProgres = {};
        indicatorsProgres['name'] = progress2[1]?.name;
      
        indicatorsProgres['maxValue'] = maxValue;
        indicatorsProgres['minValue'] = minValue;
        indicatorsProgres['percent'] = minValue > 0 ?   this.round(minValue*100/maxValue):0;
          
        this.indicatorsProgres[transactionNumber]  = indicatorsProgres;

      }
      }
      ;

      }


  }, error => {

    this.indicatorsLoading = false;

  });

}


getDataProgress(transactionNumber){

  let resp = this.indicatorsProgres[transactionNumber]?this.indicatorsProgres[transactionNumber]:null;
  return resp;
  
};

round(value) {
  return Math.round(value);
}


redirecTransaction(){

  let transactionNumber = this.transactionData?.transactionNumber

  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    transactionNumber: transactionNumber

  };

  let url = ['/transaction-dashboard'];

  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
    
  });
}

setNewTransaction(e) {

  this.newTransaction = false;  
}


}
