<div (click)="select(_obj)" class=" animate__animated animate__fadeIn rectangle  block " [class.active]="_selected==_obj?.identifier  " [class.disabled]="false">

    <div class="d-flex">
        <div class="icon pr-3 w-10 text-secondary" [class.active]="    _selected===_obj.identifier  " [innerHTML]="_obj.icon"></div>
        <div class="font-weight-bold section-title w-90 d-inline-flex text-secondary ">

            <span class="pl-1" [innerHTML]="_obj.name | i18n"></span>



        </div>
    </div>

    <div class="d-flex  mb-1">
        <div class="w-15">
            <span class="text-description text-secondary pr-1" [innerHTML]="(_obj.progress.completed+'/'+_obj.progress.total)"></span>
        </div>

        <div class="w-85" style="padding-top: 12px">
            <akeela-graphics [percent]="round(_obj.progress.completed*100/_obj.progress.total)" [type]="'line'" [shownumber]="true">
            </akeela-graphics>
        </div>
    </div>

    <!-- 
  <div class="d-flex ">
    <div class="w-80">
      <ng-container *ngIf="_obj.progress.completed<1" >
        <span class="text-secondary text-description "> Complete
          <span [innerHTML]="_obj.name+'-ant' | i18n"></span>
          <span [innerHTML]="_obj.name | i18n"></span>
      </span>

      </ng-container>

      comentado el progreso en los modulos 
      <ng-container *ngIf="_obj.progress.completed>0">
      </ng-container>



      <span *ngIf="_obj.progress.completed>0">


        <span class="text-description pr-2 text-secondary">
          <a (click)="select(_obj.link.url)"> {{(_obj.progress.completed>0? _obj.link.name : _obj.link.name+'-2') | i18n }}</a>
        </span>

        <span *ngIf="_obj.progress.percent" [innerHTML]="_obj.progress.percent+'%'"></span>
        <span class="text-description text-secondary" *ngIf="_obj.progress.total>0 && _obj.progress.completed>0" [innerHTML]="_obj.type!=='ITEMS'?(_obj.progress.completed+'/'+_obj.progress.total): _obj.progress.completed"></span>

      </span>
    </div>


    <div class="icon w-20">

      <div>
        <akeela-graphics 
          [percent]="_obj.progress.percent? _obj.progress.percent :(_obj.progress.completed===0 && _obj.type!=='EXISTENCE'? 1: round(_obj.progress.completed*100/_obj.progress.total))"
          [type]="(_obj.type==='EXISTENCE' || round(_obj.progress.completed*100/_obj.progress.total)===100)?'icon':'circle'"
          [shownumber]="true"
        >
        </akeela-graphics>

      </div>

    </div>

  </div>
  -->
</div>