import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'workflow-embed-components',
  templateUrl: './embed-components.component.html',
  styleUrls: ['./embed-components.component.css']
})
export class EmbedComponentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
