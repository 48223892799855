import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteUtilsService, AkeelaWorkflowService } from '@akeela/workflow';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { TransitionService } from '../../services/transition.service';
import { LocalService } from '@akeela/local-storage';
import { debug } from 'console';


@Component({

  selector: 'app-menu-module',
  templateUrl: './menu-module.component.html',
  styleUrls: ['./menu-module.component.css']
})
export class MenuModuleComponent implements OnInit {
  items = [];
  _selected ;
  _transactionData = null;
  audience;
  role;
  showModalResponses;

  showCommentsBoolean = [];
  showCommentsBooleanButton = [];
  incomplete;
  subscriptions: Subscription[] = [];
  subscriptionsModule:Subscription;
  requestSubscription :Subscription;
  showLoading = true;
  paramsSubscription;
  links ={};

  @Output() response = new EventEmitter<any>();

  @Input() set selected(value) {
    this._selected = value;
  }

  @Input() set transactionData(value) {
    this._transactionData = value;
  }

  constructor(
    private _httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private routerUtilService: RouteUtilsService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private i18n: I18nPipe,
    private _transition: TransitionService,
    public localStorage: LocalService,


  ) { }

  ngOnInit() {

    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.role = this.routerUtilService.getRole(queryParams);
      this.audience = this.routerUtilService.getAudience(queryParams);
      this.getShowCommentsBooleanButton();

    });

    this.subscriptions.push(this.akeelaWorkflowService.worflowEvents$.subscribe((response) => {
      if (!isNullOrUndefined(response)) {
        if (response.response === 'UPDATE_REQUIREMENT_FINISH') {
          this.getModulesInformation();
        }
      }
    }));

    this.subscriptions.push(this.akeelaWorkflowService.resetEstatus$.subscribe((response) => {
      if (!isNullOrUndefined(response)) {
        this.getModulesInformation();
      }
    }));

  }

  ngOnDestroy() {
    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach(s => s.unsubscribe());
  }
  if (this.requestSubscription) {
    this.requestSubscription.unsubscribe();
  }

    if (this.subscriptionsModule) {
      this.subscriptionsModule.unsubscribe();
    }

    if (this.subscriptionsModule) {
      this.paramsSubscription.unsubscribe();
    }

    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }


  }



  getShowCommentsBooleanButton() {


    if (this._transactionData.accountStatus[0].additionalType === 'AC_INCOMPLETE') {

      this.showCommentsBooleanButton['ACCOUNT_OPENING'] = true;
      this.showCommentsBooleanButton['REQUIREMENT'] = true;

    } else {
      this.getHistoryStatusTransaction().then((history: any) => {
        if (history.length >= 2) {
          if (history[0].name === 'PI_IN_PROCESS' && history[1].name === 'AC_INCOMPLETE') {

            this.showCommentsBooleanButton['ACCOUNT_OPENING'] = true;
            this.showCommentsBooleanButton['REQUIREMENT'] = true;

          }
        }
      });
    }


  }
  getHistoryStatusTransaction() {
    return new Promise((resolve, reject) => {
      const params = {
        name: 'accounts$accountstates$get',
        params: {
          request: {},
          path: {
            transactionNumber: this._transactionData.transactionNumber
          },
          body: {}
        }
      };

      if (this.requestSubscription) {
        this.requestSubscription.unsubscribe();
      }
    this.requestSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        resolve(response);
      }, error => {
        reject(false);
      });
    });
  }

  getModulesInformation() {

  //  ;
    if (this.subscriptionsModule) {
      this.subscriptionsModule.unsubscribe();
    }
    const params = {
      name: 'resume$transaction_modules_complete$get',
      params: {
        request: {
          idPlan: this._transactionData.plan.identifier,
          role: this.role,
          audience: this.audience
        },
        path: {
          idTransaction: this._transactionData.identifier,
          idPerson: this._transactionData.holder ? this._transactionData.holder.identifier : null
        },
        body: {}
      }
    };

    this.subscriptionsModule = this._httpService.executeRequest(params.name, params.params,true).subscribe((response: any) => {
      let items = [];

      response = response.sort(function (a, b) {
        return a.identifier - b.identifier;
      });



      for (const resp of response) {

        if(resp?.alternateName == 'ACCOUNT_OPENING'){

          this._transactionData;
          const configuration = {
            name: 'account_opening$current_step$get',
            params: {
              path: {
                workflowCode: resp?.workflowCode,
                transactionNumber: this._transactionData.transactionNumber,
                idPerson:  this._transactionData.holder ? this._transactionData.holder.identifier : null
              },
              request: {
                idPlan: this._transactionData.plan.identifier,
                role: this.localStorage.getItem('role'),
                audience: this.localStorage.getItem('audience'),
                depend: false
              }
            }
          };

          this._httpService.executeRequest(configuration.name, configuration.params, true).subscribe((resp2: any) => {

            if (!isNullOrUndefined(resp)) {

              let stage = resp2.stageParentCode;
              let step = resp2.alternateName;
              let urlWf = resp2.url;

              this.links[resp?.workflowCode] = urlWf + '/stages/' + stage + '/steps/' + step;

            } 

          }, error => {

          });
            


        }else{

          
          this.links[resp?.workflowCode] =  resp.url ? JSON.parse(resp.url)?.url : null;

        }

        this.items = [...this.items,{
          identifier: resp.workflowCode,
          name: resp.approvalUser,
          icon: resp.modifyUser,
          active: true,
          type: resp.additionalType,
          progress: {
            percent: null,
            total: resp.progress.maxValue ? resp.progress.maxValue : 0,
            completed: resp.progress.minValue ? resp.progress.minValue : 0
          }}];
      }

      let compleat = true;
      for(let item of  this.items ){
        item.progress.completed != item.progress.total

        if(item){
            
          if( item.progress.completed != item.progress.total){
            compleat = false;
          }
        }

      }

      this._transition.setCompleatTransaction(compleat);
      this.showLoading = false;
      

    }, error => {
      this.items = null;
      this.showLoading = false;
    });
  }

  event_response(response) {
   this._selected =  response?.identifier;

    this.response.emit(this.links[response?.identifier]);
  }

  event_showComments(data) {
    this.showCommentsBoolean = [];

    this.showCommentsBoolean[data] = true;
    this.showModalResponses = true;

  }


send() {
  //  if (this.form.get('check').value) {

  swal.fire({
    title: this.i18n.transform('@i18n-confirmation-are-you-shure'),
    text: this.i18n.transform('@i18n-confirmation-are-you-shure-send-analisys'),
    showCancelButton: true,
    confirmButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-true'),
    cancelButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-false'),
    reverseButtons: true
  }).then((result) => {


    if (!isNullOrUndefined(result.value) && result.value) {

   
    } else {

    }


  });

}

  //  }
}
