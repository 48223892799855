
<div  *ngFor="let param of params ; let first = first; let i = index">

  <div class="w-100 ak-font-roboto">
    <div class="">

      <i *ngIf="param?.status != 'COMPLETED'" class="far fa-circle text-primary mr-2"> </i>

      <i *ngIf="param?.status == 'COMPLETED'" class="fas fa-check-circle text-primary mr-2"> </i>
      <span [innerHTML]="param.name | i18n:lang "></span>


    </div>
<!--
  
<div class="font-gray" ><em [innerHTML]="param.description | i18n:lang "></em></div>

-->
    <hr>
  </div>
</div>


<ng-container *ngIf="loadingGuide" >
  <div>
      <img  src="./assets/public/images/loading.gif" alt="">
  </div>
</ng-container>