import { Route } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { WorkflowRoutes } from '@akeela/workflow';
import { WorkflowComponent } from '../components/workflow/workflow.component';
import { AuthGuard } from '../../../guards/auth.guard';

import { ClientInformationComponent } from '../components/client-information/client-information.component';
import { OrganizationInformationComponent } from '../components/organization-information/organization-information.component';
import { InvestorProfileBriefcaseComponent } from '../components/investor-profile-briefcase/investor-profile-briefcase.component';
import { BriefcaseRequirementsComponent } from '../components/briefcase-requirements/briefcase-requirements.component';


export const PRIVATE_EXTERNAL_ROUTES: Route = {
    path: '',
    children: [
        {
            path: 'client-information',
            component: ClientInformationComponent
        },
        {
            path: 'organization-information',
            component: OrganizationInformationComponent
        },
        {
            path: 'investor-profile',
            component: InvestorProfileBriefcaseComponent
        },
        
        {
            path: 'requirements-briefcase',
            component: BriefcaseRequirementsComponent
        }
    ],
    canActivate: [AuthGuard]
};


export const PRIVATE_ROUTES: Route = {
    path: 'private',
    component: WorkflowComponent,
    children: WorkflowRoutes,
    canActivate: [AuthGuard]
};
