<div class="row">
    <div class="col-12">
        <br>
        <div class="rectangle">
            <div class="row" style="height: 100%">
                <div class="col-1 padre" style="align-items: baseline;height: 100%!important;">
                    <div class="hijo" style="align-items: baseline;height: 100%!important;">
                        <br>
                        <i class="far fa-lightbulb" style="font-size: 40px;color: #ccc"></i>

                    </div>
                </div>
                <div class="col-11 f-1" style="color: #000000">
                    <br> Su solicitud fue recibida y esta siendo revisada. Por favor descargue la planilla. Debe imprimirla, firmarla y consignarla en la agencia seleccionada junto con los recaudos para completar el proceso de verificación de datos y
                    activación de su cuenta,
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <br>
    </div>
</div>