<div class="container  px-0">
    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2" *ngIf="!checkDoNotHavePersonalReference">




            <div class="w-100 ak-table-0-comment" *ngIf="!showLoading ">
                <div class="text-dark  mb-4 text-left">

                    <div class="text-form-description mb-2">Por favor, es necesario que identifique a personas que lo conocen. Le solicitaremos su nombre completo, documento de identidad y medio de contacto</div>

                </div>
            </div>


            <ng-container *ngIf=" !showLoading ">
                <div class=" mb-4">
                    <button (click)="openItem(null)" class="btn btn-outline-dark">
                    <span class="fas fa-plus mr-1" ></span>

                    Agregar
                    <span [innerHTML]="'@i18n-quote-PersonalReferences-section.title' | i18n:lang "></span>
                </button>
                </div>
            </ng-container>


            <ng-container *ngIf="itemList  && !showLoading && !checkDoNotHavePersonalReference">

                <div *ngIf="!((itemList && itemList.length===0) && !showLoading && !checkDoNotHavePersonalReference)" class="mt-4">


                    <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">
                    </akeela-table>

                </div>

            </ng-container>


            <ng-container *ngIf="showLoading && !checkDoNotHavePersonalReference">
                <div class="w-50 ">
                    <img class="" src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>
</div>