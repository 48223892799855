import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '@akeela/properties';
import { AuthService, ErrorService } from '@akeela/auth';
import { LocalService } from '@akeela/local-storage';

@Component({
  selector: 'app-briefcase-requirements',
  templateUrl: './briefcase-requirements.component.html',
  styleUrls: ['./briefcase-requirements.component.css']
})
export class BriefcaseRequirementsComponent implements OnInit {
  env = environment;
  dataInformation = null;

  constructor(
    private _httpService: HttpService,
    public _auth: AuthService,
    public localStorage: LocalService
  ) { }

  ngOnInit(): void {
    this.getDataInformation();
  }

  getDataInformation = async () => {
    const params = {
      name:  this._auth.getAdminOrganizationIdentifier()? 'briefcase$business_person$get' : 'briefcase$person$get',
      params: {
        request: {
          'id': this.localStorage.getItem('clientIdentifier')
        },
        path: {},
        body: {}
      }
    };
    this.dataInformation = await this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

}
