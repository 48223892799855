<div class="capa-menu-2" *ngIf="buttomModal" (click)="changes()"> </div>
<div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom ak-modal-botoom2  p-2 ">

    <div class="body pb-4 pt-2 " style="overflow:auto;">
        <div class="container text-center">
            <div class=" w-100 text-center ">
                <div class="col-12 col-md-6 offset-md-3">
                    <div class="done_text">
                        <div class="alert alert-success" role="alert" *ngIf="checked==='success'">
                            <i class="fa fa-check" style="color:green"></i>

                            OTP validado correctamente
                        </div>
                        <div class="alert alert-danger" role="alert" *ngIf="checked==='danger'">
                            Código inválido, intente nuevamente
                        </div>
                        <h6>Un código secreto de 6 digitos ha sido enviado a su télefono.
                            <br>
                            <div class="font-weight-bold">{{ '+'+_phone }}</div>
                            <br>Porfavor, ingreselo al recibirlo.
                        </h6>
                    </div>
                </div>
                <!-- ACA VA EL HEADER-->

            </div>



            <div class="w-100">
                <section class="multi_step_form">
                    <form [formGroup]="form" id="msform">
                        <fieldset>
                            <div class="container">
                                <div class="userInput">
                                    <input type="text" (keyup)="clickEvent(this,'digit2')" id='digit1' data-next="digit2" formControlName="digit1" maxlength="1" class="form-control" placeholder="0">
                                    <input type="text" (keyup)="clickEvent(this,'digit3')" id='digit2' data-next="digit3" data-previous="digit1" formControlName="digit2" maxlength="1" class="form-control"
                                        placeholder="0">
                                    <input type="text" (keyup)="clickEvent(this,'digit4')" id='digit3' data-next="digit4" data-previous="digit2" formControlName="digit3" maxlength="1" class="form-control"
                                        placeholder="0">
                                    <input type="text" (keyup)="clickEvent(this,'digit5')" id='digit4' data-next="digit3" data-previous="digit5" formControlName="digit4" maxlength="1" class="form-control"
                                        placeholder="0">
                                    <input type="text" (keyup)="clickEvent(this,'digit6')" id='digit5' data-next="digit4" data-previous="digit6" formControlName="digit5" maxlength="1" class="form-control"
                                        placeholder="0">
                                    <input type="text" id='digit6' data-previous="digit5" formControlName="digit6" maxlength="1" class="form-control" placeholder="0">
                                </div>
                            </div>
                            <div class="row pt-3">

                                <div class="col-12">
                                    <button type="button" class="btn btn-primary" (click)="verificate()" [disabled]="!form.valid">
                                        <i class="fas fa-spinner-third" *ngIf="loading"></i> Verificar</button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </section>
            </div>
        </div>
    </div>
</div>