import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[akeela-content]'
})
export class AkeelaContentDirective {
    @Input() componentName: string;

    constructor() {
    }
}
