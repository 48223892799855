<div class="text-center text-md-left" *ngIf="config">


                <div class="row" *ngIf="step1">
                    <div class="col-12  text-center" *ngIf="header">
                        <div class=" ">
                            <div [innerHTML]="'@i18n-faceapi-title' | i18n"></div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="effectiveType==='slow-2g' || effectiveType==='2g'">
                        <div class="alert alert-warning alert-dismissible fade show" role="alert">
                            <strong>¡Conexión lenta!</strong> Se ha detectado que su conexión a internet es lenta, para un desempeño óptimo se requiere una mejor conexión.
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                    </div>
                    
                    <!-- [class.section-box-green]="_detected===true" [class.section-box-red]="_detected===false"-->
                    <div  class="col-12 min-200 pl-3 pr-3 text-left "   [class.section-box]="_detected===null">
                        <lib-facial-recognition (current_view)="set_current_view($event)" [config]="config" (detected)="detected($event)" (finish)="response_finish($event)" (event)="catch_event_live_video($event)"></lib-facial-recognition>
                    </div>
                   
                </div>
                <div class="w-100" *ngIf="step2">
                    <akeela-requirements-camera 
                        [config]="config" 
                        (finish)="finish($event)" 
                        (showFile)="show_file($event)" 
                        (response)="response_requirements($event)"
                        (event)="catch_event($event)">
                    </akeela-requirements-camera>
                </div>


</div>