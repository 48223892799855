import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@akeela/auth';
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private _auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this._auth.isAuthenticated()) {
            return true;

        } else {
            this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}
