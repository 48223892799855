import { Renderer2, ElementRef, Component, OnInit, DoCheck, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { SubheaderService } from './services/subheader.service';
import { PropertiesService } from './services/properties/properties.service';
import { AuthService } from '@akeela/auth';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { PathService, HttpService } from '@akeela/properties';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService } from '@akeela/workflow';
import { isNullOrUndefined } from 'util';
import { UploadService, ErrorService } from '@akeela/common';
import { Favicons } from './services/favicons.service';
import { environment } from '../environments/environment';
import { TemplateService } from './services/template.service';
import { AkeelaTemplateService } from '@akeela/requirements';
import { Subscription, Subject } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
import { AlertService } from '@akeela/alert';
import { I18nPipe } from '@akeela/i18n';
import { WorkflowService } from './services/workflow.service';
import { log } from 'console';
// import { logWarnings } from 'protractor/built/driverProviders';
// import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
// import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'

import { ConnectionService } from 'ng-connection-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {
    title = 'app';
    showChangePass= false;
    subheaderShow = false;
    showSidebarLeft: boolean;
    role: any;
    profile: any;
    audience: any;
    tempStep;
    transactionData;
    dinamicCssUrl;
    isAuth;
    templates: any = {};
    templateRequirements: any = {};
    public env = environment;

    timeinactivity = null;
    reloadkeepalive = false;
    subscriptions: Subscription[] = [];
    loadingAutoSave;
    loadingAutoSaveOut;
    encryptSecretKey = "ejemplo de encriptado"
    w8;
    pos;
    elemento;

    showIdentityVerification;
    workflowCodeActive;
    workflowStatusMenu = [];
    optionActive = 'PRODUCT_INFORMATION';
    queryParams;

    activeValidateTlf = false;
    dataValidateTlf;


    activeValidateEmail = false;
    dataValidateEmail;


    public removeEventListener: () => void;
    activeRedirects = false;
    isNatural;
    status;
    isConnected;
    config;

    activeSuggestion = false;
    detailSuggestion;


    constructor(private _httpService: HttpService,

        private route: ActivatedRoute,
        private router: Router,
        private _subHeaderService: SubheaderService,
        private _properties: PropertiesService,
        public _auth: AuthService,
        public _akeelaSidebarMenu: AkeelaSidebarMenuService,
        private _pathService: PathService,
        private akWorkflowService: AkeelaWorkflowService,
        public upload: UploadService,
        private workflowService: AkeelaWorkflowService,
        private akStepService: AkeelaStepService,
        private akStageService: AkeelaStageService,
        private favicons: Favicons,
        private titleService: Title,
        private sanitizer: DomSanitizer,
        private error: ErrorService,
        private templateService: TemplateService,
        private _templateRequirements: AkeelaTemplateService,
        public localStorage: LocalService,
        private alert: AlertService,
        private i18n: I18nPipe,
        private _wfService: WorkflowService,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private httpClient: HttpClient,
        private connectionService: ConnectionService

    ) {

        this.config = require('./../assets/config.json'); 
        console.log('Akeela Versión: '+this.config.version);

        const headers = new HttpHeaders()
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache'); 
        
        this.httpClient
        .get<{ version: string }>("/assets/config.json", {headers})
        .subscribe(config => {
            if (config.version !== this.config.version) {
                console.log("*************************************************");
                console.log("*"+this.config.version+", new Version: "+config.version+". Will be reload*");      
                console.log("*************************************************");
                this.forceReload();
            }
        });


    
      




        if(navigator.onLine) {
            this.isConnected = true;
            this.status = "ONLINE";

        } else {
            this.isConnected = false;
            this.status = "OFFLINE";

        }

        

        this.connectionService.monitor().subscribe(isConnected => {

            if (isConnected) {

                this.status = "ONLINE";

                setTimeout(() => {
                    this.isConnected = isConnected;
                }, 7000);


            }
            else {
                this.status = "OFFLINE";
                this.isConnected = isConnected;
            }


        });



        if (environment.production) {
            if (window) {
                window.console.log = function () { };
            }
        }


        this.favicons.reset();
        this.favicons.activate(environment.theme.images);


        this.getDinamicCssUrl();

        this.localStorage.setItem('role', 'CLIENT');
        this.role = this.localStorage.getItem('role');
        this.titleService.setTitle(environment.theme.site.title);

        this.subscriptions.push(this._auth.isAuth$.subscribe((isAuth) => {
            if (!isAuth) {
                this.akWorkflowService.transactionData$.next(null);
                this.akStepService.setModeCurrentStep(false);
            }
        }));

        this._pathService.getPathService(false, environment.appEndPontDefaultConfig.transactionCode).subscribe(pathResponse => {
            this.workflowService.setendpointTransactioData(pathResponse);
        });


        this._pathService.getPathService(false, environment.appEndPontDefaultConfig.transitions).subscribe(pathResponse => {
            this.akStepService.setendpointTransitions(pathResponse);
        });

        this._pathService.getPathService(false, environment.appEndPontDefaultConfig.workflowState).subscribe(pathResponse => {
            this.workflowService.setEndpointWorkflowState(pathResponse);
        });

        this._pathService.getPathService(false, environment.appEndPontDefaultConfig.stageStatus).subscribe(pathResponse => {
            this.akStageService.setendpointStageState(pathResponse);
        });

        this._pathService.getPathService(false, environment.appEndPontDefaultConfig.stepState).subscribe(pathResponse => {
            this.akStepService.setendpointStepState(pathResponse);
        });

        this._pathService.getPathService(false, environment.appEndPontDefaultConfig.sectionsState).subscribe(pathResponse => {
            this.akStepService.setendpointSectionsState(pathResponse);
        });


        this._pathService.getPathService(false, environment.appEndPontDefaultConfig.sectionsState).subscribe(pathResponse => {
            this.akStepService.setendpointSectionsState(pathResponse);
        });

        let dataTemp: any = environment?.appEndPontDefaultConfig;
        this.workflowService.setHiddeFieldOptional(dataTemp?.hiddeFieldOptional);


        this.akWorkflowService.currentWorkflowActive$.subscribe(resp => {

            this.workflowCodeActive = resp;

        });


    }


    forceReload() {
        //if(environment.production) {
        if(true){
            const form = document.createElement('form');
            form.method = "POST";
            form.action = location.href;
            document.body.appendChild(form);
            form.submit();
        } else {
            window.location.reload();
        }
    }


    ngOnInit() {

        this._auth.showChangePassword$.subscribe((data: boolean) => {
            this.showChangePass = data;
        });

        this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
            if (event.target instanceof HTMLAnchorElement) {
                // Your custom anchor click event handler
                this.handleAnchorClick(event);
            }
        });


        this.route.queryParams.subscribe(queryParams => {


            this.queryParams = queryParams;


        });


        this.akWorkflowService.currentWorkflow$.subscribe((response) => {
            let respActual = this.akWorkflowService.currentWorkflowActive$.getValue();
            if (response && response != respActual) {

                this.workflowCodeActive = response;

                this.getDataWorkflows();
            }
        });

        this.akWorkflowService.showSuggestion$.subscribe((response) => {
            if (response) {
                this.activeSuggestion = true;
                this.detailSuggestion = response;
            }
          });

        this.localStorage.setItem('modeDebug', 'true');

        this.localStorage.removeItem('modeDebug');
        this.localStorage.removeItem('transactionModeEditShow');
        this.localStorage.removeItem('transactionModeEditShowFlag');
        this._akeelaSidebarMenu.setshowSidebarLeft(false);
        this._wfService.showIdentityVerification$.subscribe((data: any) => {

            this.showIdentityVerification = data;

        });

        if (this._auth.isAuthenticated()) {
            this.getUserVerification();


        }





        this.workflowService.worflowEvents$.subscribe((worflowEvents: any) => {
            if (worflowEvents?.action == 'verificationIdentity') {
                if (this._auth.isAuthenticated()) {
                    this.getUserVerification();

                }
            } else if (worflowEvents?.action == "person$save_data_verify_identity$post" && worflowEvents?.response) {

                this._wfService.setshowIdentityVerification(false);

                this.router.navigate(['/dashboard'], {
                    queryParams: {
                        role: this.localStorage.getItem('role'),
                        profile: this.localStorage.getItem('profile'),
                        audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL'
                    }
                });

            }
            //  console.log("worflowEvents: ",worflowEvents);

        });


        this.workflowService.saveSectios$.subscribe((response: any) => {

            this.loadingAutoSave = response;
            this.loadingAutoSaveOut = false;

            if (response === false) {

                this.loadingAutoSaveOut = true;

                setTimeout(() => {

                    this.loadingAutoSaveOut = false;

                }, 500);
            }
        });

        /*
        this._akeelaSidebarMenu.sidebarLeftActive$.subscribe(rep => {
           // this._akeelaSidebarMenu.setshowSidebarLeft(true);

            if (screen.width < 768) {
                this._akeelaSidebarMenu.setshowSidebarLeft(false);
            }
        });*/



        /*
               
        */
        // this.workflowService.addWfFormField({"name":"organization.address.addressCountry.identifier","value":"AO","disabled":true,"temp":true});
        // this.workflowService.addWfFormField({"name":"organization.legalName","value":"HOLA","disabled":true,"temp":true});

        /*
                let sidebarLeftActive;
                sidebarLeftActive = this.localStorage.getItem('sidebarLeftActive');
        
                if (!isNullOrUndefined(sidebarLeftActive) && sidebarLeftActive != "null") {
                  //  this._akeelaSidebarMenu.setSidebarLeftActive(sidebarLeftActive);
                } else {
        
                   // this._akeelaSidebarMenu.setSidebarLeftActive(environment.appEndPontDefaultConfig.sidebarLeftActive);
        
                }
        */
        // Juan soto
        // metodo que se utiliza para actualizar estatus cuando la transaccion pase de incompleta a en proceso 
        this.UpdateTransactionStatus('AC_INCOMPLETE');
        this._auth.isAuth$.subscribe((isAuth: boolean) => {
            if (isAuth !== this.isAuth) {
                if (!isAuth) {
                    if (this.isAuth) {
                        this.isAuth = isAuth;
                        this.router.navigate(['/'], {});
                        // control de cache por versión
                        // this.config = require('../../package.json'); 
                        // console.log("************************************************");
                        // console.log("Akeela System Version: "+this.config.version);      
                        // console.log("************************************************");
                        this._akeelaSidebarMenu.setshowSidebarLeft(false);

                    } else {
                        this.isAuth = isAuth;
                    }
                } else {
                    this.isAuth = isAuth;
                }
            }
        });

        this.akWorkflowService.setFieldsNotRequired(environment.appEndPontDefaultConfig.isFieldNotRequired ? environment.appEndPontDefaultConfig.isFieldNotRequired : false);

        this.akWorkflowService.transactionData$.subscribe((response: any) => {
            if (!isNullOrUndefined(response) && response !== undefined) {
                this.transactionData = response;

                const temp = response.accountStatus[0].additionalType;

                if (temp === 'AC_PENDING' || temp === 'AC_RECEIVED' || temp === 'AC_REJECTED') {
                    this.akStepService.setModeCurrentStep(true);
                } else {
                    if (!isNullOrUndefined(response.takenByRol)) {
                        // alert(this.localStorage.getItem('transactionModeEdit') + response.takenByRol);
                        if (response.takenByRol !== this.role && response.takenByRol !== '' || response.takenByRol === '@i18n-error-message-acc-cant-taken-role') {
                            this.akStepService.setModeCurrentStep(true);
                            if (this.localStorage.getItem('transactionModeEditShow') !== response.transactionNumber && this.localStorage.getItem('transactionModeEditShowFlag') !== response.transactionNumber) {
                                const error = {
                                    error: {
                                        status: 500,
                                        code: 'AKC-014',
                                        titleLabel: '',
                                        name: ''
                                    }
                                };
                                this.error.show(error, 'Mensaje!', this.i18n.transform(response.takenByRol));
                                this.localStorage.setItem('transactionModeEditShow', response.transactionNumber);
                                this.localStorage.setItem('transactionModeEditShowFlag', response.transactionNumber);

                            }
                        } else {
                            if (!isNullOrUndefined(this.localStorage.getItem('transactionNumber'))) {
                                this.akStepService.setModeCurrentStep(false);
                                this.localStorage.setItem('transactionModeEdit', response.transactionNumber);
                            }
                            // const error = {
                            //     error: {
                            //         status: 500,
                            //         code: 'AKC-014',
                            //         titleLabel: '',
                            //         name: ''
                            //     }
                            // };
                            // this.error.show(error, 'Mensaje!', this.i18n.transform(response.takenByRol));
                        }
                    } else {
                        this.akStepService.setModeCurrentStep(false);
                    }
                }
            } else {
                this.transactionData = null;
                this.akStepService.setModeCurrentStep(false);

            }
        });



        if (this._auth.isAuthenticated()) {

            this.localStorage.setItem('clientName', this._auth.getSession()?.firstName + ' ' + this._auth.getSession()?.lastName);
            this.localStorage.setItem('clientUsername', this._auth.getUsername());
            this.localStorage.setItem('clientIdentifier', this._auth.getAdminOrganizationIdentifier() ? this._auth.getAdminOrganizationIdentifier() : this._auth.getPersonIdentifier());
            this.localStorage.setItem('clientaudience', this.localStorage.getItem('audience'));

            this.localStorage.setItem('businessName', this._auth.getSession()?.businessName);
            /*
                        const opt = {
                            name: 'LEFT_MENU_CLIENT'
                        };
            
                        this._akeelaSidebarMenu.setsidebarLeftOptions(opt);
                        if (this.localStorage.getItem('showSidebarLeft') === null) {
                            if (screen.width > 768) {
            
                                this._akeelaSidebarMenu.setshowSidebarLeft(true);
                            }
                        } else {
            
                            if (this.localStorage.getItem('showSidebarLeft') === 'true') {
                               this._akeelaSidebarMenu.setshowSidebarLeft(true);
            
            
                            } else {
                              //  this._akeelaSidebarMenu.setshowSidebarLeft(false);
                            }
            
                        }*/
        }

        this._pathService.getPathService(false, environment.appEndPontDefaultConfig.menu).subscribe(pathResponse => {
            this._akeelaSidebarMenu.setsidebarLeftKey(pathResponse);
        });

        this._akeelaSidebarMenu.showSidebarLeft.subscribe((data: boolean) => {
            this.showSidebarLeft = data;
        });

        this._subHeaderService.subHeader$.subscribe((show) => {
            this.subheaderShow = show;
        });

        this._auth.isAuth$.subscribe((respAuth) => {
            this.timeinactivity = null;
            this.reloadkeepalive = false;
            if (respAuth) {
                if (environment.auth.inactivity.active) {
                    this.subscriptions.push(this._properties.getProperty({
                        endpointKey: 'app_properties$getProperty$get',
                        propertyKey: 'app.inactivity.time'
                    }).subscribe((response: any) => {
                        this.timeinactivity = response;
                        this.reloadkeepalive = true;
                    }, error => {
                        this.timeinactivity = 3;
                        this.reloadkeepalive = true;
                    }));
                }
            }
        });

        this.getAllDataWorkflows();

        this.akWorkflowService.showValidateTel$.subscribe((response) => {
            if (response) {

                this.activeValidateTlf = true;
                this.dataValidateTlf = response;

            }

        });

        this.akWorkflowService.showValidateEmail$.subscribe((response) => {
            if (response) {
            
                this.activeValidateEmail = true;
                this.dataValidateEmail = response;

            }

        });






        //  this.getShowValidateTel();

    }

    setShowChangePassword(param) {
        this.showChangePass = false;
        this._auth.setShowChangePassword(param);
      }

    closeSuggestion = (e) => {

        this.activeSuggestion = false;
    
    }
    /*
        getShowValidateTel = async () => {
    
            let ValidateTel = await this.akWorkflowService.getShowValidateTel();
    
            let value = ValidateTel.getValue();
            
        }
    */



    closeValidateTlf = (e) => {

        this.activeValidateTlf = false;

    }

    setValidateTlf = (e) => {


        let datas = this.akWorkflowService.tlfValidates$.getValue();

        datas[e] = true;

        this.akWorkflowService.setTlfValidates(datas);

        this.activeValidateTlf = false;

    }

    closeValidateEmail = (e) => {

        this.activeValidateEmail = false;

    }


    setValidateEmail = (e) => {


        let datas = this.akWorkflowService.emailValidates$.getValue();

        datas[e] = true;

        this.akWorkflowService.setEmailValidates(datas);

        this.activeValidateEmail = false;

    }




    UpdateTransactionStatus(status) {
        // Juan soto
        this._httpService.HttpUpdate$.subscribe(type => {
            if (type && type !== 'get') {
                let transactionData: any = this.workflowService.transactionData$.getValue();
                if (transactionData) {
                    let temp = transactionData.accountStatus[0].additionalType;
                    if (temp === status) {
                        this.workflowService.setTransactionData(true).subscribe();
                    }
                }
            }
        });

    }



    getAllDataWorkflows() {
        const params = {
            name: 'workflow$workflows$get',
            params: {
                request: {
                    content: 'none'
                },
                path: {
                },
                body: {}
            }
        };

        let sectionBack = null;

        this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
            if (result) {

                let results = {}

                for (let item of result) {

                    results[item.alternateName] = item;

                }
                this._wfService.setListWF(results);
            }


        });
    }

    getDataWorkflows() {
        const params = {
            name: 'workflow$workflow$get',
            params: {
                request: {
                    content: 'none'
                },
                path: {
                    "workflow": this.workflowCodeActive,
                },
                body: {}
            }
        };

        let sectionBack = null;

        this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
            if (result) {
                this.akWorkflowService.setCurrentWorkflowCodeActive(this.workflowCodeActive);

                if (result?.endpointStatus && !this.queryParams?.section) {

                    this.localStorage.setItem('sectionRedirect', true);
                } else {
                    this.localStorage.removeItem('sectionRedirect');

                }

            }


        });
    }

    getDinamicCssUrl() {
        if (environment.theme.images === 'FACEBANK') {
            this.dinamicCssUrl = './assets/facebank/public/css';
        } else if (environment.theme.images === 'MIBANCO') {
            this.dinamicCssUrl = './assets/mibanco/public/css';
        } else if (environment.theme.images === 'DEMO1') {
            this.dinamicCssUrl = './assets/demo1/public/css';
        } else if (environment.theme.images === 'DEMO2') {
            this.dinamicCssUrl = './assets/demo2/public/css';
        } else if (environment.theme.images === 'CARONI') {
            this.dinamicCssUrl = './assets/caroni/public/css';
        } else if (environment.theme.images === 'RENDIVALORES') {
            this.dinamicCssUrl = './assets/rendivalores/public/css';
        } else if (environment.theme.images === 'MC') {
            this.dinamicCssUrl = './assets/mc/public/css';
        } else if (environment.theme.images === 'KOI') {
            this.dinamicCssUrl = './assets/koi/public/css';
        }else if (environment.theme.images === 'PERCAPITA') {
            this.dinamicCssUrl = './assets/percapita/public/css';
        }  else {
            this.dinamicCssUrl = './assets/public/css';
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.templates = this.templateService.getTemplates();
            // console.log(this.templates);
        }, 10);


        setTimeout(() => {
            this.templateRequirements = this._templateRequirements.getTemplates();
        }, 10);
    }

    public ngDoCheck() {

        if (this.showSidebarLeft && this._auth.isAuthenticated()) {

            // document.body.classList.add('p-body');
            // document.body.classList.add('size-menu-left');

            /* let html =  document?.getElementById("id-pleft");
              html?.classList?.add('p-body'); */

        } else {

            /*  let html =  document?.getElementById("id-pleft");
              html?.classList?.remove('p-body');*/

            // document.body.classList.remove('p-body');
            // document.body.classList.add('size-menu-left');

        }
    }


    toggleMenu() {
        this._akeelaSidebarMenu.setshowSidebarLeft(!this.showSidebarLeft);
    }

    window_close() {
        this.upload.visor = false;
    }

    ngOnDestroy() {
        this.removeEventListener();

        if (this.subscriptions) {
            // prevent memory leak when component destroyed
            this.subscriptions.forEach(s => s.unsubscribe());
        }
    }

    eventMenu(response) {
        if (response.action === 'navigate') {
            if (!isNullOrUndefined(this.localStorage.getItem('transactionModeEdit'))) {
                //this.breakfreeTransactionEdit(); // Mario: este caso era de liberación individual, pero debido a que ahora se va a liberar por usuario, se comenta para ver el comportamiento
                this.breakfreeTransactionsByUser();
            } else {
                this.breakfreeTransactionsByUser();
            }
        }
    }

    breakfreeTransactionsByUser() {
        //alert('voy a ejecuctar 1');
        const configuration = {
            name: 'account$finish_editing_role_by_user$put',
            params: {
                path: {},
                request: {
                    role: 'CLIENT',
                    holderId: this.localStorage.getItem('audience') === 'LEGAL' ? this._auth.getAdminOrganizationIdentifier() : this.localStorage.getItem('clientIdentifier')

                }
            }
        };
        this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
            this.localStorage.removeItem('transactionModeEdit');
            this.localStorage.removeItem('transactionModeEditShow');
        }, error => {
            this.localStorage.removeItem('transactionModeEdit');
            this.localStorage.removeItem('transactionModeEditShow');
        });
    }

    breakfreeTransactionEdit() {
        //alert('voy a ejecuctar 111111');

        const configuration = {
            name: 'account_opening$finish_editing_role$put',
            params: {
                path: {
                    transactionNumber: this.localStorage.getItem('transactionModeEdit')
                },
                request: {
                    role: 'USER',
                    holderId: this.localStorage.getItem('audience') === 'LEGAL' ? this._auth.getAdminOrganizationIdentifier() : this.localStorage.getItem('clientIdentifier')
                }
            }
        };
        this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
            this.localStorage.removeItem('transactionModeEdit');
            this.localStorage.removeItem('transactionModeEditShow');
        }, error => {
            this.localStorage.removeItem('transactionModeEdit');
            this.localStorage.removeItem('transactionModeEditShow');
        });
    }

    action(response) {
        if (response) {
            if (response.action) {
                if (response.action === 'download') {
                    if (response.status === 'success') {
                        if (response.deleteAfterShow) {
                            this._httpService.executeRequest(response.deleteAfterShow.deleteRequirement.name, response.deleteAfterShow.deleteRequirement.params, true).subscribe((del: any) => {
                            }, error => {
                            });
                        }
                    }
                }
            }
        }
    }

    redirectHome() {
        this.router.navigate(['/'], {});
    }


    deleteToken() {
        this.localStorage.removeItem('objToken');
        this.localStorage.removeItem('objTokenPlan');



        this.akWorkflowService.addWfFormField(
            {
                "name": "applicantType",
                "value": '',
                "disabled": false,
                "temp": true
            });

    }


    getClient = async () => {
        try {
            const params = {
                name: 'person$enduser$get',
                params: {
                    request: {
                        username: this._auth.getUsername()
                    },
                    path: {},
                    body: {}
                }
            };
            return await this._httpService.executeRequest(params.name, params.params, true).toPromise();

        } catch (error) {
            console.error(error);
            return null

        }

    }


    getUserVerification = async () => {
        this._wfService.setshowIdentityVerification(false);

        if (this.localStorage.getItem('clientaudience') == 'NATURAL') {


            try {


                this.activeRedirects = true;
                let client: any = await this.getClient();

                if (client) {
                    this.activeRedirects = false;
                    if (!client?.person?.identity && !client?.person?.passport) {
                        this._wfService.setshowIdentityVerification(true);

                        this.router.navigate(['private/ACCOUNT_OPENING/stages/INTER_IDENTITY_VERIF/steps/INTER_IDENTITY_VERIF_INFO'], {
                            queryParams: {
                                role: this.localStorage.getItem('role'),
                                profile: this.localStorage.getItem('profile'),
                                audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL'
                            }
                        });
                    } else {

                        this._wfService.setshowIdentityVerification(false);

                    }

                } else {
                    this.activeRedirects = false;


                }


            } catch (error) {
                console.error(error);
                this.activeRedirects = false;

            }

        }



    };




    public handleAnchorClick(event: Event) {
        // Prevent opening anchors the default way
        const target: any = event.target;

        let obj;
        let value = target?.classList;


        if (value[0]) {
            try {

                obj = JSON.parse(value[0]);
                event.preventDefault();
                if (obj?.rxOption) {
                    switch (obj?.rxOption) {
                        case 'showTermAndoConditions':
                            this._properties.setShowTermAndoConditions(true);

                            break;

                    }

                }

            } catch (e) {

            }
        }

    }


    orders = () => {
        this.router.navigate(['/orders'], {
            queryParams: {}
        });
    }
}
