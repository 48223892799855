<div class=" px-0">
    <div class="col-12 w-100 border table-title border-top-0 border-left-0 border-right-0 pl-0 pb-2" *ngIf="itemList?.length > 0">
        <div class="word-wrap: break-word; font-weight-bold">Personas relacionadas Políticamente Expuestas</div>
    </div>
    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2">
            <ng-container *ngIf="itemList">
                <div class="w-100 fz-body" *ngFor="let row of itemList; let first = first; let i_stf = index">
                    <hr *ngIf="!first" class="m-0 ">
                    <div class="d-flex bd-highlight list-flex flex-wrap pb-3 pt-3 pb-md-2 pt-md-2 w-100">

                        <div (click)="openItem(row)" class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-sm-80 w-80 order-1   ">
                            <div class="font-weight-bold text-capitalize">
                                {{row?.person?.givenName}} {{row?.person?.familyName}}
                            </div>
                        </div>

                        <div (click)="openItem(row)" class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-10  order-2 order-4 d-block d-md-none">
                            <div class="text-capitalize text-primary">
                                Ver
                            </div>
                        </div>


                        <div class=" mb-1 mb-md-0 text-md-right w-10 pl-md-2 pr-md-2  order-3">
                            <a *ngIf="!readonlyMode" href="javascript:void(0)" (click)="confirmDelete(row)" class="font-weight-bold">
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>
                        <div class=" mb-1 mb-md-0 text-md-right w-10 pl-md-2 pr-md-2  order-4 d-none d-md-block ">
                            <a href="javascript:void(0)" (click)="openItem(row)" class="font-weight-bold">
                                <i class="fas fa-angle-right text-primary"></i>
                            </a>
                        </div>

                    </div>
                </div>
            </ng-container>

            <div class="w-100 ak-table-0-comment " *ngIf="(modalFather?.person?.isRelatedToPep && itemList?.length === 0)">
                <div class="text-dark mt-2 mb-4">
                    <i class="text-secondary far fa-comment mr-3"></i>
                    <span [innerHTML]="'@i18n-register-text-standard' | i18n:lang "></span>
                    <span [innerHTML]="'@i18n-quote-legalRepresentative-pep-modal-section-title' | i18n:lang "></span>
                </div>
            </div>

        </div>
    </div>
</div>