<plan-requirements-export [show]="false">
    </plan-requirements-export>
    <div class="box-white pb-4 animate__animated animate__fadeIn" *ngIf="!planHidden  && optionsProducts?.length > 0">
            <div class=' mt-3 text-dark' >
                    <div class='list d-flex bd-highlight justify-content-center text-center  list-flex flex-wrap'>
                        <div class='bd-highlight  w-100 order-1  '>
                            <h4 class='font-weight-normal '> Realiza tu solicitud en línea </h4>
                        </div>
                    </div>
     </div>
        <div >
            <form class="animate__animated animate__fadeIn" novalidate [formGroup]="productform" *ngIf="optionsProducts?.length > 0">
                <div class="row  mt-3">
                    <label class="col-12 offset-md-4 col-md-4 font-weight-bold">Productos</label>
                    <div class=" col-12 offset-md-4 col-md-4">
                            <select (change)="setIdentifierProduct(identifierProduct)" class="border-primary form-control"
                            formControlName="idproduct" [value]="identifierProduct">
                            <ng-container *ngFor="let option of optionsProducts">
                                <option [value]="option?.identifier" [innerHTML]="option?.name | i18n" [selected]="identifierProduct"></option>
                            </ng-container>
                        </select>
                    </div>
                    
                </div>
    
    
                <div class="row mt-3">

                    <div class="col-12 mt-3">
                            <div class='list d-flex bd-highlight justify-content-center text-center  list-flex flex-wrap'>
                                    <div class='bd-highlight  w-100 order-1  '>
                                        <h5 class='font-weight-normal '> Selecciona el tipo de solicitud</h5>
                                    </div>
                                </div>
                    </div>
        
                    <div class='col-12 offset-md-2 col-md-4 mt-3'>

                     
                
                    <div  class="base-style-option-button p-2 pt-3 pb-3" [ngClass]="{'active':audience  == 'NATURAL'}" (click)="audience = 'NATURAL'" >
                        <div class='list d-flex bd-highlight list-flex '>
                        <div class='bd-highlight'>
                            <div class="img" >
                                    <i class="fa fa-user"></i>
                            </div>
                        </div>
    
                        <div class='bd-highlight pl-3'>
                            <div class="title font-weight-normal">
                                 Personas     
                            </div >
                            <div class="mt-2" >

                                Selecciona el tipo de cuenta para Personas Naturales

                                </div>
                        </div>
                    </div>
               </div> 
            </div>
    
            <div class='col-12 col-md-4 mt-3'>
                   
                <div  class="base-style-option-button p-2 pt-3 pb-3" [ngClass]="{'active':audience == 'LEGAL'}"  (click)="audience = 'LEGAL'"  >
                        <div class='list d-flex bd-highlight list-flex '>
                        <div class='bd-highlight'>
                            <div class="img" >
                                    <i class="fas fa-building"></i>
                            </div>
                        </div>
    
                        <div class='bd-highlight pl-3'>
                            <div class="title font-weight-normal">
                                 Empresas     
                            </div >
                            <div class="mt-2" >
                                Selecciona el tipo de cuenta para Personas Jurídicas                            </div>
                        </div>
                    </div>
               </div>
            </div>
        </div>
    
    
            </form>
        </div>
    </div>
    
    




    <products-akeela-template>
                <ng-template products-akeela-content componentName="sectionRequirementsPDFImageHeader" let-persontype>
                    <div>
                        <app-logo-img [theme]="env?.theme?.logoConf?.themeColorPdf" [type]="env?.theme?.logoConf?.typePdf"></app-logo-img>
                    </div>
                    <br>
                    <div>
                        <b>RECAUDOS PARA APERTURA DE CUENTA</b>
                        <br>
                        <br> El solicitante debe consignar dos copias de los documentos y el original para la validación de las copias entregadas
                        <br>
                        <br>
                    </div>
                </ng-template>
   
                <ng-template products-akeela-content componentName="imgLogo" let-plan>
                    <div class="mt-2">
                            <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>

                    </div>
                </ng-template>
            
                <ng-template products-akeela-content componentName="detailForm" let-plan>
                    <div class="text-dark mb-3" *ngIf="!planHidden">
            
                            <div class='row box-plan mb-2'>
                                    <div class='col-12 ' >
                                            <div class="float-right ">
                                                    <div >
                                                            <i class="fas fa-share-alt"></i>
                                                    </div>
                                             </div>

                                        <div class="img" >
                                                <i class="fas fa-credit-card"></i>
                                        </div>
                                    </div>
                                    <div class='col-12 mt-2' >
                                          <span [innerHTML]="'PERSONAS' | i18n" *ngIf="audience  == 'NATURAL'" ></span>
                                          <span [innerHTML]="'EMPRESAS' | i18n" *ngIf="audience  == 'LEGAL'"></span>
                                    </div>
                             </div>
            
                        <h5 class="font-weight-bold mb-3" [innerHTML]="plan?.shortDescription | i18n"></h5>
                        <div [innerHTML]="plan.subtitle | i18n" class="font-weight-normal mb-2 "></div>
                       <!-- <div *ngIf="plan.images">
                            <div class="img-plan d-none d-lg-block ">
                            </div>
                        </div>-->
                        <div class="text-secondary" [innerHTML]="plan.description | i18n"></div>
            
                    </div>
                </ng-template>
            
            
            
                <ng-template products-akeela-content componentName="sectionForm" let-plan>
                
                    <div *ngIf="!planHidden">
                        <form novalidate [formGroup]="forms[plan.identifier]" >
                            <!--  [(ngModel)]="plan.identifier" -->
                            <input [type]="'hidden'" formControlName="identifierPlan">
                            <div class="row">
                                    <div class=" col-12 box-plan">

                                            <div class='list d-flex bd-highlight list-flex '>
                                                    <div class='bd-highlight'>
                                                            <div class="img  border-0" >
                                                                    <i class="fas fa-list-ol "></i>
                                                            </div>
                                                    </div>

                                                        <div class='bd-highlight'>
                                                                <div *ngIf="plan?.termsOfService" class="mt-2">
                                                                        <button (click)="setDocsupport(plan.identifier,true)" type="button" class="p-0 btn btn-link">
                                                                            <span [innerHTML]="'@i18n-summary-account-opening-step-upload-file-plan' | i18n:lang"></span>

                                                                        </button>
                                                                </div>      
                                                         </div>
                                             </div>
                                   </div>



                                   <div class=" col-12 mt-3">

                                        <div [innerHTML]="'@i18n-text-title-apply-login' | i18n:lang" class="font-weight-bold mb-2"></div>

                                        <button (click)="submit(plan.identifier,plan?.shortDescription,false)" type="button" [disabled]="!forms[plan.identifier].valid" class="btn btn-outline-primary btn-block ">
                                            <span [innerHTML]="'@i18n-text-button-apply-login' | i18n:lang"></span>
                                        </button>
                                    </div>

                                    
                                        <div class=" col-12 mt-4">

                                        <div [innerHTML]="'@i18n-text-title-apply-register' | i18n:lang" class="font-weight-bold mb-2"></div>

                                            <button  (click)="submit(plan.identifier,plan?.shortDescription,true)"  type="button" [disabled]="!forms[plan.identifier].valid" class="btn btn-primary btn-block ">
                                                <span [innerHTML]="'@i18n-text-button-apply-register' | i18n:lang"></span>
                                            </button>
                                        </div>
                              
                            </div>
                        </form>
                        <div class="mt-4" [innerHTML]="plan?.notes | i18n"></div>
                    </div>
                </ng-template>
            </products-akeela-template>

<div class="animate__animated animate__fadeIn" [ngClass]="{'container':showContainerPlan }" *ngIf="audience">

        <div class=' mt-4 list d-flex bd-highlight justify-content-center text-center  list-flex flex-wrap'>
                <div class='bd-highlight  w-100 order-1  '>
                    <h3 class='font-weight-bold '> Planes para 
                        <span [innerHTML]="'Personas' | i18n" *ngIf="audience  == 'NATURAL'" ></span>
                        <span [innerHTML]="'Empresas' | i18n" *ngIf="audience  == 'LEGAL'"></span>
                    </h3>
                    <h4 class='font-weight-normal mt-4'> Selecciona tu plan de preferencia </h4>

                </div>
        </div>

    <div  [ngClass]="{'row':showContainerPlan }">
        <div  [ngClass]="{'col-md-6  col-12 p-0':showContainerPlan }" class="" *ngFor="let plan of plans; let i = index" [attr.data-index]="i">
            
                <div class="animate__animated animate__fadeIn" [ngClass]="{'mt-md-3 mt-3  ml-md-4 mr-md-4':showContainerPlan }" class="">
                       
                    <plans  [showNoteCategory]="showNoteCategory" [showDescriptionReq]="showDescriptionReq" [product]="product" [plan]="plan"></plans>
                </div>
</div>
</div>
</div>
          

<div class="container animate__animated animate__fadeIn">
    <div class="box" *ngIf="!plans">
            <div>
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
    </div>
</div>



<div *ngIf=" plans &&  plans?.length < 1 && audience" class="animate__animated animate__fadeIn container box  bg-white rounded animate__animated animate__fadeIn mb-3 mt-5 pl-3 pr-3 pt-4 pb-4 text-center ">
    <h5>No se encontraron Planes Activos.</h5>
</div>