import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ProductsService, PlansService } from '@akeela/products-plans';
import { HttpService } from '@akeela/properties';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SubheaderService } from '../../../../services/subheader.service';
import { TransitionService } from '../../../../services/transition.service';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { AuthService } from '@akeela/auth';
import { AlertService } from '@akeela/alert';
import { ErrorService } from '@akeela/common';
import { environment } from '../../../../../environments/environment';
const BASE_URL = './';

declare var $: any;
import { LocalService } from '@akeela/local-storage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'public-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit, OnDestroy, AfterViewInit {
  config;

  params_plans;
  plans;
  productform: FormGroup;
  product;
  states;
  forms: any = {};
  submit_disabled;
  loading_form: Boolean = true;
  identifierProduct = '';
  docsupport: Array<any> = [];
  optionsProducts = []
  workflow;
  stage;
  step;
  event = 'ACCOUNT_SELECTED_AND_USER_NOT_LOGGED';
  url_transition = 'workflow$transitionssteps$get';
  url_desktop: string;
  env = environment;
  options = {
    orientation: 'landscape',
    margins: {
      top: '100'
    }
  };
  planHidden = false;
  


showDescriptionReq;
showNoteCategory;
audience = null;
showContainerPlan = true;
paramsSubscription: Subscription;


  constructor(
    private formBuilder: FormBuilder,
    public _httpService: HttpService,
    private _productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private sh: SubheaderService,
    private transitionService: TransitionService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService,
    private _auth: AuthService,
    private _alert: AlertService,
    private _error: ErrorService,
    private _planService: PlansService,
    private localStorage: LocalService,
  ) {

   

  }

  ngAfterViewInit() {

    // this.ejemplo.nativeElement.dispatchEvent(new MouseEvent('click'));
  }

  ngOnInit() {

    this.showDescriptionReq = this.env.configDefauld.showDescriptionReq;
    this.showNoteCategory = this.env.configDefauld.showNoteCategory;

    this.getImages();
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    // this.sh.subHeader$.next(true);
    document.body.classList.add('teme-gray');
    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {

      this.identifierProduct = queryParams.identifierProduct;

      this.setIdentifierProduct(this.identifierProduct)

    });



    this._planService.showContainerPlan$.subscribe((showContainerPlan) => {
      
        this.showContainerPlan = showContainerPlan;

    });

    const params_states: any = {
      endpoint: {
        products: {
          name: 'app_properties$states$get',
          params: {
            path: {},
            request: { countryCode: 'VE' },
            body: {}
          }
        }
      }
    };

    this._httpService.executeRequest(params_states.endpoint.products.name, params_states.endpoint.products.params).subscribe((response: any) => {
      this.states = response;
    });


    const params_product: any = {
      endpoint: {
        products: {
          name: 'product_plan$products_sheets$get',
          params: {
            path: {},
            request: {},
            body: {}
          }
        }
      }
    };

    this._httpService.executeRequest(params_product.endpoint.products.name, params_product.endpoint.products.params).subscribe((response: any) => {

      this.optionsProducts = response;
    });

    this._planService.docsupport$.subscribe((docsupport) => {
      if (docsupport) {
          this.docsupport = docsupport;

          let tmp = false;
          for (let val of docsupport) {
            if(val){
              tmp = true; 
            }
          }

          this.planHidden = tmp;
      }
    });

    this.productform = this.formBuilder.group({
      idproduct: ['', Validators.required]
    });

    this._productsService.currentProduct$.subscribe((identifierProduct) => {
      if (identifierProduct) {
        // this.identifierProduct=identifierProduct;
      }
    });

  }

  setIdentifierProduct(identifierProduct) {

    this.plans = null
    this.forms = {};
    this._planService.resetDocsupport();

    const params_plans: any = {
      endpoint: {
        products: {
          name: 'product_plan$plans$get',
          params: {
            path: {},
            request: {
              identifierProduct: identifierProduct

            },
            body: {}
          }
        }
      }
    };

    this._httpService.executeRequest(params_plans.endpoint.products.name, params_plans.endpoint.products.params, true).subscribe((response: any) => {
      if (response) {
        this.plans = response;
        const group: any = {};
        for (const plan of this.plans) {
          this.forms[plan.identifier] = this.formBuilder.group({
            identifierPlan: ['', Validators.required]
          });
        }
        this.loading_form = false;
        if (response.length > 0) {
          this.product = response[0].financialProduct;
        }
      }
    }, error => {
      this.plans = null;
    });
  }


  setDocsupport(id,param){

    this.planHidden = true;
    this._planService.setDocsupport(id,param);

  }

  getImages() {
    if (environment.theme.images === 'MIBANCO') {
      this.url_desktop = BASE_URL + 'assets/mibanco/public/images/mibanco_color.png';
    } else if (environment.theme.images === 'FACEBANK') {
      this.url_desktop = BASE_URL + 'assets/facebank/public/images/logo_claro.jpeg';
    } else {
      this.url_desktop = BASE_URL + 'assets/public/images/akeela_logo_claro.svg';
    }
  }

  getRequirementsByIdPlan(identifierPlan) {
    const params_requirements: any = {
      endpoint: {
        products: {
          name: 'requeriments$plan_requirements$get',
          params: {
            path: {},
            request: {},
            body: {
              identifierPlan: identifierPlan
            }
          }
        }
      }
    };

    this._httpService.executeRequest(params_requirements.endpoint.products.name, params_requirements.endpoint.products.params).subscribe((response: any) => {

    });
    
  }

  submit(identifier,plan,register = false) {


    const form = this.forms[identifier];
    if (form.valid) {
      const params_submit = {
        name: 'account$accountopening$post',
        params: {
          request: {
            role: 'CLIENT',
            audience: 'ALL'
          },
          path: {
            workflow: this.workflow,
            stage: this.stage,
            step: this.step,
            event: 'ACCOUNT_SELECTED_AND_USER_NOT_LOGGED'
          },
          body: {}
        }
      };
      if (this._auth.isAuthenticated()) {
        params_submit.params.request.audience = this._auth.getUserAudience();
        params_submit.params.body = {
          plan: {
            identifier: form.get('identifierPlan').value
          },
          requestUser: {
            identifier: this._auth.getUsername(),
            adminOrganization: {
              identifier: this.localStorage.getItem('clientIdentifier')
            }
          }
        };


      } else {
        params_submit.params.body = {
          plan: {
            identifier: form.get('identifierPlan').value
          }
        };
      }


      this._httpService.executeRequest(params_submit.name, params_submit.params).subscribe((response: any) => {
        if (!this._auth.isAuthenticated()) {
          const obj = {
            token: response.token,
            transactionNumber: response.transactionNumber,
          
          };
          this.localStorage.setItem('objToken', JSON.stringify(obj));
          this.localStorage.setItem('objTokenPlan', plan);
/*
          this._alert.show({
            title: 'Trámite almacenado temporalmente',
            text: 'Por favor, ingrese con su usuario y contraseña para víncular su trámite.'
          });
*/
          if(register){


          this.akWorkflowService.addWfFormField(
            {"name":"applicantType",
             "value":this.audience,
             "disabled":true,
             "temp":false
            }
            );

            this.router.navigate([environment.staticLinks.register], { queryParams: {} });

          }else{
/*
            const params = {
              name: this.url_transition,
              params: {
                request: {
                  role: 'CLIENT',
                  audience: 'ALL',
                  event_code: this.event
                },
                path: {
                  prefixWorkflow: 'workflow_manager',
                  workflow: this.workflow,
                  stage: this.stage,
                  step: this.step
                }
              },
              o: {}
            };
        
            this.transitionService.next('public', params);
  */

        this.router.navigate([environment.staticLinks.login], { queryParams: {} });

          }


        } else {


          this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
            //alert(response.transactionNumber);
            this.localStorage.setItem('transactionNumber', response.transactionNumber);
            const aux = Object.assign({ transactionNumber: response.transactionNumber }, queryParams);
        
        
            if(register){
              
              this.akWorkflowService.addWfFormField(
                {"name":"applicantType",
                 "value":this.audience,
                 "disabled":true,
                 "temp":false}
                );
    
                this.router.navigate([environment.staticLinks.register], { queryParams: {} });
    
              }else{

                const parameters = {
                  name: this.url_transition,
                  params: {
                    request: {
                      role: 'CLIENT',
                      profile: 'CLIENT',
                      audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL',
                      event_code: 'ACCOUNT_PLAN_SELECTED_AND_USER_LOGGED'
                    },
                    path: {
                      prefixWorkflow: 'workflow_manager',
                      workflow: this.workflow,
                      stage: this.stage,
                      step: this.step
                    }
                  },
                  o: {}
                };

                this.transitionService.next('private', parameters, aux);
              }
          });
        }
      }, (error) => {
        this._error.show(error);
      });
    }

  }

  ngOnDestroy() {
    this.sh.subHeader$.next(false);
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
    document.body.classList.remove('teme-gray');
  }

  export() {

    let tmp = {
      type: "pdf",
      plan: 3,
      audience: "LEGAL"
    };

    this._planService.setRequirementsCategory(tmp);

  }


}
