import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalService } from '@akeela/local-storage';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit, OnDestroy {

  constructor(
    private localStorage: LocalService

  ) { }

  ngOnInit() {


    document.body.classList.add('teme-gray');
  }

  ngOnDestroy() {
    document.body.classList.remove('teme-gray');
  }
}
