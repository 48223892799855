<akeela-meet-detail [selected]="init_meet" [username]="user_meet" (change)="setMeetData($event)">
</akeela-meet-detail>
<!--  
<pre>{{this.localDirectionFull | json}}</pre>

 -->

<app-new-transaction *ngIf="newTransaction" [selected]="newTransaction" (change)="setNewTransaction($event)"></app-new-transaction>

<div *ngIf="localDirection" class=" mb-2 ak-direction-bar active-cursor" (click)="redirectDirection()">
    <div style="min-height: 40px;" class=" pt-2 text-md-center w-100">

        <div class="d-flex w-100 pl-2 fz-14 justify-content-center">
            <div>
                <i style="color: #ccc;" class="blink_me_circle fas fa-map-marker-alt mr-2"></i>
            </div>
            <div>
                <span>
                    <!--     {{this.localDirection?.formatted_address}}-->
            
                    {{this.localDirectionFull?.administrative_area_level_2}},
                    {{this.localDirectionFull?.locality}} 
                    {{this.localDirectionFull?.postal_code}},
                    {{this.localDirectionFull?.administrative_area_level_1}},
                    {{this.localDirectionFull?.country}}
                    </span>
            </div>
            <div>

                <span>
                    <i class=" ml-2 fas fa-chevron-down  text-secondary"></i>
                </span>

            </div>
        </div>



    </div>
</div>

<div class="container mb-4 mt-2">
    <div class="row">
        <div class="col-12 col-md-10 offset-md-1">

            <div class="w-100">
                <!-- <ak-requirements-briefcase *ngIf="env?.requirements?.briefcase===true" [dataInformation]="dataInformation"></ak-requirements-briefcase> -->

                <div class=" pt-3 pb-4 text-center">

                    <div class="mb-2 ">
                        <h5 class="font-weight-normal ">

                            <div class="ml-auto mr-auto text-secondary mt-3">
                                <b>Hola</b>,

                                <span>
                                <span  *ngIf="_auth.getSession()?.firstName "> {{_auth.getSession()?.firstName  | titlecase}}</span>
                                <span *ngIf="_auth.getSession()?.lastName "> {{_auth.getSession()?.lastName  | titlecase}}</span>
                                </span>


                            </div>
                        </h5>

                        <div [innerHTML]="'@i18n-dashboard-title' | i18n"></div>


                        <div class="text-dark font-weight-bold" *ngIf="_auth.getSession()?.businessName ">
                            <span>
                         {{_auth.getSession()?.businessName}} 
                       </span>
                        </div>
                    </div>


                </div>

                <div (click)="getMeet() " *ngIf="interviews?.length > 0 && validMeet" class="alert alert-secondary  active-cursor p-2  mt-4 mb-4 animate__animated animate__fadeIn">

                    <div class="d-flex  fz-14">

                        <div class="pr-1 ">
                            <i class="text-secondary fas fa-video pt-2 pr-2 fa-2x "></i>

                        </div>

                        <div class="text-left w-100">

                            <div class="text-secondary">

                                <div class="font-weight-bold mt-2"> Tienes reuniones pendientes</div>
                            </div>
                            <div class="ml-auto text-right ">


                                <a class="font-weight-bold" href="javascript:void(0)">Ver</a>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="d-flex justify-content-center flex-wrap mt-2 mb-4 text-secondary">

                    
                    <div (click)="setIdentity()" *ngIf="showVerification && !identityCompleat && isNatural" class="option-car-selected-ak">

                        <div class="card  text-center">
                            <div class="card-body pt-0 pb-0 pl-2 pr-2 fz-14">

                                <div class="circle-bg ml-auto mr-auto text-center mt-3">

                                    <i class="fas fa-video text-secondary"></i>
                                </div>

                                <div class="mt-3 mb-2 font-weight-bold text-dark">Tómate una selfie
                                </div>
                                <div class="text-secondary fz-12 text-left">
                                    Agiliza tu proceso tomandote una selfie y una foto a tu documento de identidad
                                </div>

                            </div>

                            <div class="card-footer text-right  text-primary fz-14">

                                
                                <a (click)="setShowVerification()" class="font-weight-normal mr-3" href="javascript:void(0)">Más tarde</a>

                                <a (click)="setIdentity()" class="font-weight-bold" href="javascript:void(0)">Completar</a>



                            </div>
                        </div>

                    </div>
                    <div *ngIf="transactions?.length == 0  " (click)="newTransaction = true " class="option-car-selected-ak">

                        <div class="card  text-center">
                            <div class="card-body pt-0 pb-0 pl-2 pr-2 fz-14">

                                <div class="circle-bg bg-2 ml-auto mr-auto text-center mt-3">

                                    <i class="fas fa-chart-line text-secondary"></i>
                                </div>

                                <div [innerHTML]="'@i18n-new-product-body' | i18n">

                                </div>

                            </div>
                            <div class="card-footer text-right  text-primary">
                                <button class="btn btn-primary btn-block btn-primary small float-right">Continuar <span class="fas fa-arrow-right ml-1"></span></button>

                                <!-- <span class="fz-14 "> Continuar</span>
                                <span class="fas fa-arrow-right ml-1"></span> -->


                            </div>
                        </div>

                    </div>
                    <div *ngIf="transaction " (click)="redirectTransactions2(transaction) " class="option-car-selected-ak">
                        <div class="card  text-center">
                            <div class="card-body pt-0 pb-0 pl-2 pr-2 fz-14">

                                <div class="circle-bg bg-2 ml-auto mr-auto text-center mt-3 ">

                                    <i class="fas fa-chart-line text-secondary"></i>
                                </div>

                                <div class="mt-1 ml-1 ">

                                    <div class="mt-3 mb-2 font-weight-bold text-dark">
                                        <span class="font-weight-bold text-dark "> {{transaction?.plan?.name}}</span>

                                    </div>
                                    <div class="text-secondary">
                                        <span class="fz-12 "><span  [innerHTML]="transaction?.plan?.shortDescription| i18n"></span></span>
                                    </div>
                                    <div class="mt-2">
                                        <span class="fz-12 text-dark"> Sol# {{transaction?.transactionNumber}}</span>

                                    </div>
                                </div>
                            </div>
                            <div class="card-footer text-center ">

                                <button class="btn btn-primary btn-block btn-primary small float-right">Reanudar <span class="fas fa-arrow-right ml-1"></span></button>


                            </div>
                        </div>

                    </div>



                </div>

                <!-- *ngIf="!transaction" -->
                <div class="w-100 animate__animated animate__fadeIn  mt-3">
                    <ul class="scrollmenu pl-0 mt-5 mb-5">


                        <li *ngIf="transactions?.length > 0 && env.theme.images != 'MC'" class="d-inline mt-2 active-cursor " (click)="redirectTransactions() ">
                            <div class="item-rd p-3 mb-2 ">
                                <div><i class="text-primary icon fas fa-history pb-3 fa-3x "></i></div>
                                <div>Mis <br>Solicitudes
                                    <span *ngIf="transactions" class="font-weight-bold"> ( {{transactions?.length}} )</span>
                                </div>

                            </div>
                        </li>


                        <li *ngIf="transactions?.length > 0 && env.theme.images == 'MC'" class="d-inline mt-2 active-cursor " (click)="redirectTransactions() ">
                            <div class="item-rd p-3 mb-2 ">
                                <div><i class="text-primary icon fas fa-id-card pb-3 fa-3x "></i></div>
                                <div>Mis Datos <br>Personales
                                </div>
                            </div>
                        </li>



                         <!-- *ngIf="env.theme.images == 'MC' -->
                        <li *ngIf="false " class="d-inline mt-2 active-cursor " (click)="extranet()">

                            <!-- <li *ngIf="env.theme.images == 'MC' " class="d-inline mt-2 active-cursor " (click)="extranet()"> -->
                            <div class="item-rd p-3 mb-2 ">
                                <div><i class="text-primary icon fas fa-store pb-3 fa-3x "></i></div>
                                <div>

                                    Cuenta de <br>Corretaje Bursatil
                                </div>
                            </div>
                        </li>

                        <li class="d-inline mt-2 active-cursor " (click)="gotoWhatsapp(agencyEjecutive?.person?.telephone)">

                            <!-- <li *ngIf="env.theme.images == 'MC' " class="d-inline mt-2 active-cursor " (click)="extranet()"> -->
                            <div class="item-rd p-3 mb-2 ">
                                <div><i class="text-primary icon fas fa-user-tie pb-3 fa-3x "></i></div>
                                <div>

                                    <span class=" ">Tu Ejecutivo</span>
                                    <br>
                                    <span class="mr-2 font-weight-bold">{{agencyEjecutive?.person?.givenName}} {{agencyEjecutive?.person?.familyName}}</span>
                                    <a *ngIf="agencyEjecutive?.person?.telephone" href="javascript:void(0) " class="text-primary ">
                                        {{agencyEjecutive?.person?.telephone}}  <i class="fab fa-whatsapp "></i> </a>
                                </div>
                            </div>
                        </li>


                    </ul>
                </div>



                <div *ngIf="!contactar && env.theme.images == 'MC'" class="alert alert-secondary mb-2 animate__animated animate__fadeIn mt-3 small">
                    Si desea actualizar sus datos y/o perfil de inversión haga click <span (click)="setNoteNew()" class="text-primary text-color-blue font-weight-bold active-cursor">Aquí</span> para ser contactado por su ejecutivo.
                </div>

                <div class="row pb-2">
                    <div class="col-12 col-md-12 mt-4 ">
                        <div class="w-100 min-200 section-box  p-3 text-left ">
                            <div>
                                <!-- <div class="d-flex w-100 ">

                                    <div>
                                        <i class="text-secondary fas fas fa-user icon mr-1 "></i>
                                    </div>
                                    <div class="mt-1 ml-1 ">
                                        <span class="font-weight-bold ">Tu Ejecutivo</span>
                                    </div>
                                </div> -->

                                <div class="w-100 ">

                                    <div class="fz-14 ">
                                        <!-- <div class="mb-2 mt-2 ">Si necesitas ayuda puedes contactar con <br>
                                            <div class="mt-2 "> <b>{{agencyEjecutive?.person?.givenName}} {{agencyEjecutive?.person?.familyName}} </b><br>

                                                <div *ngIf="agencyEjecutive?.person?.telephone">({{agencyEjecutive?.person?.telephone}})

                                                    <ng-container *ngIf="agencyEjecutive?.person?.telephone">
                                                        <a href="tel:{{agencyEjecutive?.person?.telephone}}" class="text-primary  ml-2"><i class="fas fa-phone-alt mr-2"></i>  </a>

                                                        <a (click)="gotoWhatsapp(agencyEjecutive?.person?.telephone)" href="javascript:void(0) " class="text-primary ">
                                                            <i class="fab fa-whatsapp "></i> </a>

                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-100 d-flex fz-14 mt-3 ">




                                        </div> -->

                                        <div *ngIf="agencyEjecutive?.localBusiness" class="text-secondary mt-2" (click)="gotoGoogle()">
                                            <div class="mb-1">
                                                <div class="text-dark mb-2 font-weight-bold"> Oficina</div>
                                                <a href="javascript:void(0) " class="text-primary " (click)="gotoGoogle()"> <i class="fas fa-map-marker-alt mr-2"></i> </a>

                                                <b> {{agencyEjecutive?.localBusiness?.name}}</b>

                                            </div>
                                            <div>{{agencyEjecutive?.localBusiness?.address?.description}}</div>

                                        </div>
                                    </div>
                                </div>

                                <div class="w-100 d-flex fz-14 mt-3 ">


                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </div>

        </div>
    </div>
</div>