import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener, Input, AfterViewInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '@akeela/auth';
import {  AkeelaWorkflowService } from '@akeela/workflow';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit, AfterViewInit {
  @Input('parent') parent: string;
  @Input('line') line: string;

  lang: string;
  @ViewChild('footer', {static: false}) footer: ElementRef;

  height: number;
  environment;
  isAuth;
  workflowCodeActive;
  leftF = false;

  constructor(
    private renderer: Renderer2, 
     public _auth: AuthService,
     private workflowService: AkeelaWorkflowService
    ) { 

  }
  ngOnInit() {


    this.workflowService.currentWorkflowActive$.subscribe(resp => {

      this.workflowCodeActive = resp;

    if(this.workflowCodeActive && this.workflowCodeActive != 'ACCOUNT_OPENING' && this.workflowCodeActive != 'INTER_IDENTITY_VERIF' ){
 
      this.leftF = true;  

    }else{
      
      this.leftF = false;        

    }
    
    });
  

    this.environment = environment;


    this._auth.isAuth$.subscribe((isAuth: boolean) => {
      if (isAuth !== this.isAuth) {
          if (!isAuth) {
              if (this.isAuth) {
                  this.isAuth = isAuth;
              } else {
                  this.isAuth = isAuth;
              }
          } else {
              this.isAuth = isAuth;
          }
      }
  });
  }

  ngAfterViewInit() {
    
    setTimeout(() => {
      document.body.style.marginBottom =
      this.footer.nativeElement.offsetHeight + 'px';
    this.height = this.footer.nativeElement.offsetHeight;
  
  }, 0);
  
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    document.body.style.marginBottom =
      this.footer.nativeElement.offsetHeight + 'px';
    this.height = this.footer.nativeElement.offsetHeight;
  }


}
