import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { promise } from 'protractor';
import { PathService } from '@akeela/properties';

@Injectable()
export class SidebarService {

  constructor(private _http: HttpClient, private _path: PathService) { }

  private sidebar: any;
  public inbox: any = 'inbox';

  my_credits_inbox_number = 0;
  my_credits_sent_number = 0;
  my_credits_draft_number = 0;


  getSidebar(): Observable<any> {
    if (this.sidebar) {
      return new Observable(resolve => {
        resolve.next(this.sidebar);
        resolve.complete();
      });
    } else {
      return new Observable(resolve => {
        this.getSidebarSearch('LEFT_MENU_CLIENT').subscribe(result => {
          resolve.next(result);
          resolve.complete();
        }, (error) => {
          resolve.error(error);
          resolve.complete();
        });
      });
    }
  }


  getSidebarUsername(username?): Observable<any> {
    if (this.sidebar) {

      return new Observable(resolve => {
        resolve.next(this.sidebar);
        resolve.complete();
      });
    } else {
      return new Observable(resolve => {
        this.getSidebarUsernameSearch(username).subscribe(result => {
          resolve.next(result);
          resolve.complete();
        });
      });
    }
  }

  getSidebarSearch(opt) {
    let path = 'app_properties$menu$get';
    return new Observable(resolve => {
      this._path.getPathService(path).subscribe((resp: any) => {
        let url_path = resp.url;
        let url = url_path + '?name=' + opt;
        let headers = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
          })
        };
        return this._http.get(url).subscribe(resultado => {
          this.sidebar = resultado;
          resolve.next(resultado);
          resolve.complete();
        });
      });
    });
  }

  getSidebarUsernameSearch(opt) {
    let path = 'person$user_menu$get';
    return new Observable(resolve => {
      this._path.getPathService(path).subscribe((resp: any) => {
        let url_path = resp.url;
        let url = url_path + '?username=' + opt;
        let headers = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
          })
        };

        return this._http.get(url).subscribe(resultado => {
          this.sidebar = resultado;
          resolve.next(resultado);
          resolve.complete();
        });
      });
    });
  }

}
