import { AuthService } from '@akeela/auth';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { async, Subscription } from 'rxjs';
import { NotesService } from 'src/app/services/notes.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { ErrorService  } from '@akeela/common'

@Component({
  selector: 'app-new-transaction',
  templateUrl: './new-transaction.component.html',
  styleUrls: ['./new-transaction.component.css']
})
export class NewTransactionComponent implements OnInit ,OnDestroy{

  indicatorsProgres;
  indicatorsProgresTransactionData;
  productsSub :Subscription;
  plansSub:Subscription;
  products:any = [];
  plans:any ;
  productSelected;
  planSelected;
  transaction;
  transactions;
  requirements;
  rows;
  listWf = {};
  existsTransaction;
  centerModal;
  listWF : Subscription;
  @Output() change = new EventEmitter();
  showLoading;

  @Input() set selected(centerModal) {
    if (centerModal) {
      this.centerModal = true;

      this.getTransactions();
      this.getproducts();

    }
    if(this.listWF){
      this.listWF.unsubscribe;
    }
  }
  constructor(
    private _wfService: WorkflowService,
    private router: Router,
    private _httpService: HttpService,
    public _auth: AuthService,
    private localStorage: LocalService,
    private akWorkflowService: AkeelaWorkflowService,
    private i18n: I18nPipe,
    public  _notes: NotesService,
    public _error: ErrorService
  ) { }

  ngOnInit(): void {


    this.listWF =  this._wfService.listWF$.subscribe((listWf: any) => {

      if(listWf){

        this.listWf = listWf;

      }
             
    });

  
  }
  


  setProductPlan = (product,plan) => {
    let data :any;
    this.showLoading = true;
  //this.transactions?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS'
  if(!this.getExistsTransactionForPlan(plan)){

    const params = {

      name: 'account$accountopening$post',
      params: {
        request: {
          audience: this.localStorage.getItem('audience'),
          role: this.localStorage.getItem('role'),
        },
        path: {
          workflow: 'ACCOUNT_OPENING',
          stage:'LIST_ACCOUNT_OPENING',
          step:'LIST_ACCOUNT_OPENING',
          event:'PRODUCT_PLAN_SELECTED',
        },
        body: {
          "plan": {
            "financialProduct": {
              "identifier": product
            },
            "identifier": plan
          },
          "requestUser": {
            "identifier": this._auth.getUsername(),
            "adminOrganization":
            this._auth.getAdminOrganizationIdentifier()?
            {
              "identifier": this.localStorage.getItem('clientIdentifier')
            }
            :{} ,
            "person": this._auth.getPersonIdentifier()?
            {
            "identifier": this.localStorage.getItem('clientIdentifier')
          }
            :{}
          }
        
      }
      }
    };

    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

        if (response) {

          this.setNoteNewTransaction(response?.transactionNumber);
          this.redirectTransactions2(response);
        this.showLoading = false;

        }
        
    }, error => {
      console.log(error);
      this.showLoading = false;
      this._error.show(error)

    });

    
}else{
  this.showLoading = false;

  swal.fire({
    html: this.i18n.transform('Ya hay una solicitud con este número de plan'),
  });


  /*
  
      if( response.lengh > 1){

        this.plans = response;

        
      }else if (response.lengh == 1 ){

        this.setProductPlan(response[0]?.plan?.financialProduct?.identifier,response?.plan?.identifier);

      }
      
 */

}


}

 getproducts = () => {

  this.transaction = null;

  this.productSelected = null;
  this.planSelected = null;
  this.requirements = null;

  const params = {
    name: 'product_plan$products_sheets$get',
    params: {
      request: {
        audience: this.localStorage.getItem('audience'),
        activePlans:true
      },
      path: {
       
      },
      body: {}
    }
  };

  this.productsSub =   this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
       
    if (response) {

      this.products = response;
    }


  });

}



getPlans = async  (idProduct)  => {

  this.planSelected = null;
  this.transaction = null;
  this.requirements = null;
  const params = {
    name: 'product_plan$plans$get',
    params: {
      request: {
        audience: this.localStorage.getItem('audience'),
        identifierProduct:idProduct,
        listName:idProduct
      },
      path: {
      
      },
      body: {}
    }
  };

  try {

    let   response:any = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();

    if(response){
      if( response?.length > 1){

        this.plans = response;
        
      }else if (response.length == 1 ){

        this.planSelected = response[0]?.identifier;
        this.setRq(response,this.planSelected);
       // this.setProductPlan(response[0]?.plan?.financialProduct?.identifier,response?.plan?.identifier);

      }

    }
    

    
  } catch (error) {
    
  }
    

}


redirectTransactions(valor = false){

  const queryParams = {
    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
  };

  const prefixed = 'private';
  const url = 'ACCOUNT_OPENING/stages/LIST_ACCOUNT_OPENING/steps/LIST_ACCOUNT_OPENING';

  if(valor){
    this.localStorage.setItem('newProduct',true)

  }

  this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });



}



ngOnDestroy = ():void => {
  if(this.listWF){
    this.listWF.unsubscribe;
  }

  if(this.productsSub){
   this.productsSub.unsubscribe;
  }

  if(this.plansSub){
    this.plansSub.unsubscribe;
   }

  }



  getTransactions() {
    const params = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: {
          page: 0,
          size: 60
        },
        path: {
          username: this._auth.getUsername()
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      this.transactions = response.content;
       this.getExistsTransaction();
    }, error => {
    });
  }



getExistsTransaction = () => {

  this.existsTransaction  = null;

   if(this.transactions?.length > 0 ){
     this.transactions?.map(row =>{

      if(row?.accountStatus[0]?.additionalType != 'AC_RECEIVED' && row?.accountStatus[0]?.additionalType != 'AC_REJECTED'){
        this.existsTransaction  = true;
  
          }
     });
     
     

   }
}


  getExistsTransactionForPlan = (plan) => {

    let result = null;
     if(this.transactions?.length > 0 ){

        
       let row =   this.transactions?.find(resp => resp?.plan?.identifier == plan);

    

       if(row?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS'){
           result = true;
           if(row?.transactionNumber !=  this.transaction?.transactionNumber ){

            this.transaction = null;
            this.transaction = row;
            this.getIndicatorsProgres(row?.plan?.url,row);
  
           }
          
      }

     }
    return result;

  }


redirectTransactions2(transaction){

  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
  };

  let url = ['/transaction-dashboard'];
  this.akWorkflowService.setTransactionNumber(transaction?.transactionNumber);


  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
    
  });
    this.changes();

}



getListWf(wf){
    return   this.listWf[wf];


}


changes = () => {

  if (!this.showLoading) {
    this.change.emit(null);
    this.centerModal = false;
 
  }

}


setRq = (plans,idPlan) =>{

  let plan =  plans?.find((resp) => resp?.identifier == idPlan);


  let requirements = plan?.termsOfService;

  this.requirements =  requirements?.filter((resp) =>{

    let a = resp?.audience?.audienceType;
    let b = this.localStorage.getItem('audience');    
    return ((a==b || a=='ALL') && b!=='LEGAL');    
    
  });

  this.requirements ;

}
 


getIndicatorsProgres(workflowCode,transactionData:any = false) {

  let data :any;

  if(workflowCode && transactionData){

    let transactions = [];
        transactions = [...transactions, {transactionNumber: transactionData?.transactionNumber}]
    data = {
      "workflow":workflowCode,
      bankAccountTransactions :transactions
    } ;

  }   if(workflowCode && !transactionData){



    data = {
      "workflow":workflowCode,
      "personId": this.localStorage.getItem('clientIdentifier')
  } ;


  }
 
    
 
  const params = {

    name: 'resume$workflow_progress$post',
    params: {
      request: {
        role: this.localStorage.getItem('role'),
        audience: this.localStorage.getItem('audience'),

      },
      path: {},
      body: data

    }
  };

  let indicatorsProgres = {};
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if (response) {
      let progress;
      let preData =response[0]?.modules;

      if(transactionData){
      let  progress2 = preData?.filter((resp) => {
          return resp?.alternateName == workflowCode;
        });
       progress  = progress2[0].progress;

      }else{
       progress = response[0]?.modules[0]?.progress;

      }
    

       let  maxValue = progress?.maxValue;
       let  minValue = progress?.minValue;

       indicatorsProgres['maxValue'] = maxValue;
       indicatorsProgres['minValue'] = minValue;
       indicatorsProgres['percent'] = minValue > 0 ?   this.round(minValue*100/maxValue):0;
        ;


        if(transactionData){

          this.indicatorsProgresTransactionData = indicatorsProgres;

        }else{

          this.indicatorsProgres = indicatorsProgres;


        }


       
      }


  }, error => {


  });

}


round(value) {
  return Math.round(value);
}

setNoteNewTransaction = async (transacton) =>{
  const env: any = environment;
 
   const data =  {
    "environment_code": env?.code || env?.theme?.images,
     "type": "TRANSACTON",
     "show_note": "TRUE",
     "note_extra_data_json": "",
     "client": this._auth.getUsername(),
     "full_name":  this._auth.getSession()?.businessName ||  (this._auth.getSession()?.firstName + ' '+ this._auth.getSession()?.lastName) ,
     "ejecutive":'',
     "ejecutive_update": "",
     "title": '',
     "subtitle": 'Nueva solicitud creada',
     "status_view": "NEW_TRANSACTON",
     "extra_data": "",
     "note": this.i18n.transform(`Nueva solicitud creada #${transacton}`),
   };
 
    await this._notes.saveNote(data);
 
 };
}
