<!-- Multi step form -->
<section class="multi_step_form">
    <form id="msform">
        <!-- Tittle -->
        <div class="tittle">
            <h2>Verificación OTP</h2>
            <p>Prueba de Concepto</p>
        </div>

        <!-- fieldsets -->
        <fieldset>
            <form [formGroup]="form">
                <div class="row">

                    <div class="col-12 text-left"> 
                        <label for="first-name"><span class="font-weight-bold" *ngIf="verified"><i class="fas fa-check-double" style="color:green"></i></span> Teléfono: </label>
                        <input type="text" class="form-control" id="phone" formControlName="phone" placeholder="+8801123456789" [readonly]="verified">
                    </div>
                </div>
                <div class="row pt-4">

                    <div class="col-12">
                        <button type="button" class="btn btn-primary btn-block" (click)="openOTP()" [disabled]="!form.valid">Verificar</button>
                    </div>
                </div>
            </form>


        </fieldset>


    </form>
</section>


<app-otp-verification [phone]="phone" [selected]="phoneSelected" (response)="responseOTP($event)"></app-otp-verification>