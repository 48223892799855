import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ucwords'
})

export class UcwordsPipe {
  /*
    Takes a value and makes it lowercase.
   */
  transform(val:string) {
    val = val.toLowerCase();//|\s+([a-z])
    return (val + '').replace(/^([a-z])/g, ($1) => {
        return $1.toUpperCase();
    });
  }

}