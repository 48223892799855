import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareholdersService {

  private shareholders: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() { }

  setShareholders (object) {
    this.shareholders.next(object);
  }

  getShareholders() {
    return this.shareholders;
  }
}
