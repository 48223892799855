  <ng-container *ngIf="item!==null">
        <div  class="base-style-option-button p-2" >
            <div class='list d-flex bd-highlight list-flex '>
        
               <div class='bd-highlight pl-3'>
                   <div class="font-weight-bold">
                      <span class=" text-capitalize"> {{ item.organization? item.organization.legalName: item.person.givenName+' '+item.person.familyName}}</span>

                          <span class="pl-1 fas fa-check-circle"></span>
                   </div>
                   <div  class="text-2" >
                      <span [innerHTML]="title" ></span>
                    </div>
               </div>
           </div>
        </div>

  </ng-container>
  <ng-container *ngIf="item==null">
        <div  class="base-style-option-button p-2 active-cursor" (click)="event_redirect('add')" >
                <div class='list d-flex bd-highlight list-flex m-auto '>
        
                   <div class='bd-highlight m-auto  m-auto' style=" min-height: 44px;padding-top: 11px">
                       <div class="font-weight-bold  m-auto">
                          <span class=" text-capitalize  m-auto ">
                                <i class="text-primary fas fa-plus mr-2"></i> 
  
                            Agregar </span>
                       </div>
                       
                   </div>
               </div>
            </div>

</ng-container>


  