import { Component, OnInit, Input, AfterContentInit, AfterViewInit } from '@angular/core';
import { TemplateService } from '../../services/template.service';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.css']
})
export class SubheaderTemplateComponent implements OnInit, AfterViewInit {
  templates: any = {};

  constructor(
    private templateService: TemplateService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.templates = this.templateService.getTemplates();
    }, 1);
  }

}
