import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { isNullOrUndefined } from 'util';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.css']
})
export class OtpVerificationComponent implements OnInit {

  _phone;

  /* Flags */
  buttomModal = false;
  animate_init_bottom = false;
  loading = false;
  checked = '';

  form;


  @Input() set phone(phone) {
    this._phone = phone;
  }

  @Input() set selected(buttomModal) {
    this.checked = '';
    if (buttomModal) {
      this.buttomModal = buttomModal;
    } else {
      this.buttomModal = buttomModal;

    }
  }

  @Output() response = new EventEmitter();


  constructor(private fb: FormBuilder, private _http: HttpService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      digit1: ['', Validators.required],
      digit2: ['', Validators.required],
      digit3: ['', Validators.required],
      digit4: ['', Validators.required],
      digit5: ['', Validators.required],
      digit6: ['', Validators.required]
    });
  }

  changes() {
    this.buttomModal = false;
    this.response.emit(false);
  }

  clickEvent(first, last) {
    document.getElementById(last).focus();
  }



  responseOTP(event) {

  }

  verificate() {
    if (this.form.valid) {
      this.checked = '';
      this.loading = true;
      const params = {
        name: 'otp$verificate$get',
        params: {
          request: {
            phone: this._phone,
            code: this.form.get('digit1').value + '' + this.form.get('digit2').value + '' + this.form.get('digit3').value + '' + this.form.get('digit4').value + '' + this.form.get('digit5').value + '' + this.form.get('digit6').value
          },
          path: {},
          body: {}
        }
      };
      this._http.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        if (!isNullOrUndefined(response.valid)) {
          if (response.valid) {
            this.checked = 'success';
            this.response.emit({
              response: 'success'
            });
          } else {
            this.form.reset();
            document.getElementById('digit1').focus();
            this.checked = 'danger';
          }
        }
        this.loading = false;
      }, error => {
        this.form.reset();
        document.getElementById('digit1').focus();
        this.loading = false;
        this.checked = 'danger';
      });
    }
  }

}
