import { Component, OnInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { 
  AkeelaWorkflowService, 
  AkeelaStageService, 
  AkeelaStepService, 
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';




@Component({
  selector: 'app-section-pep-boardofdirectors',
  templateUrl: './section-pep-boardofdirectors.component.html',
  styleUrls: ['./section-pep-boardofdirectors.component.css']
})
export class SectionPepBoardOfDirectorsComponent implements OnInit {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  modalFather;
  readonlyMode = false;
  paramsSubscription: Subscription;


  
  private workflowEventsSubscription: Subscription;

  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe
  ) { }

  ngOnInit() {
    //[dfutrille] Esta ejecucion de evento es para prevenir la suscripcion al ultimo evento ejecutado.
    //Esto se debe evaluar, ya que es algo que molesta en muchas ejecuciones.
    this.akeelaWorkflowService.worflowEvents$.next(null);


    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
     });

    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();
    this.modalFather = this.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(this.modalFather) && !isNullOrUndefined(this.modalFather.person)){
      this.renderItemList(this.modalFather.person.relatedPeps);
    }

    this.akeelaWorkflowService.transactionNumber.subscribe(transactionNumber => {
      if (transactionNumber) {
        this.transactionNumber = transactionNumber;
      }
    });

    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    this.workflowEventsSubscription = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
      
      console.log(response);

      if (!isNullOrUndefined(response) && response.action === 'update_board_of_directors_pep' && this.akeelaSectionService.getModalFather()) {
        if (response.response.person.identifier === this.akeelaSectionService.getModalFather().person.identifier){
          this.modalFather = response.response;
          this.akeelaSectionService.setModalFather(response.response);
          this.renderItemList(response.response.person.relatedPeps);
        }
      } else if (response && response.action === 'new_pep_board_of_directors'){
        if (isNullOrUndefined(this.modalFather)){
          this.itemList = this.akeelaSectionService.getModalChildList(response.action);
          if(this.itemList.length === 0){
            this.akeelaWorkflowService.setResetEstatus(true);
          }
        }
      } else if (response && response.action === 'account_opening$related_person_all_pep_bod$delete'){
        if (!isNullOrUndefined(this.modalFather)){
          if (this.modalFather.person.relatedPeps.length > 0){
            this.deleteChildren(this.modalFather);
          } else {
            this.renderItemList(null);
            this.akeelaSectionService.resetModalChildList('new_pep_board_of_directors');
          }
        } else {
          this.renderItemList(null);
          this.akeelaSectionService.resetModalChildList('new_pep_board_of_directors');
        }
      }
    });
  }

  isTheSameFather(child:any, father:any){
    return child.person.relatedTo.identifier === father.person.identifier;
  }


  ngOnDestroy() {
    if (this.workflowEventsSubscription) {
        this.workflowEventsSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  renderItemList(newList){
    this.itemList = newList;
    if(this.itemList.length === 0){
      this.akeelaWorkflowService.setResetEstatus(true);
    }
  }

  openItem(row) {
    this.akeelaSectionService.addModalSection({ name: 'BOARD_DIRECTORS_PEP_MODAL' });
    this.akeelaSectionService.showModalSection('BOARD_DIRECTORS_PEP_MODAL');
    this.akeelaSectionService.setModalFormValue('BOARD_DIRECTORS_PEP_MODAL', row);
  }

  confirmDelete(row: any){
    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }

  deleteItem(row: any) {
    if (isNullOrUndefined(row.person.identifier)){
      const itemToDelete = this.itemList.findIndex(item => item.tempIdentifier === row.tempIdentifier );
      if (!isNullOrUndefined(itemToDelete)){
        this.itemList.splice(itemToDelete, 1);
      }
    } else {
      const relatedPersonId = row.person.identifier;
      const configuration = {
        name: 'account$related_persons$delete',
        params: {
          path: {
            relatedPersonId: relatedPersonId,
            transactionNumber: this.transactionNumber,
            workflow: this.workflow,
            stage: this.stage,
            step: this.step
          },
          request: {
            audience: this.queryParams.audience,
            role: this.queryParams.role
          }
        }
      };
  
      this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
        swal.fire({
          title: this.i18n.transform('Éxito'),
          html: this.i18n.transform('@i18n-text-information-deleted')
        });
        this.updateList('account$related_person_bod$post');
        this.akeelaWorkflowService.setResetEstatus(true);

      }, error => {
        //dfutrille esto debe borrarse.
        this.updateList('account$related_person_bod$post');
      });
    }
  }

  updateList(action: string){
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }

  deleteChildren(row: any) {
    if (!isNullOrUndefined(row.person.identifier)){
      const relatedPersonId = row.person.identifier;
      const configuration = {
        name: 'account_opening$related_person_all_pep_bod$delete',
        params: {
          path: {
            relatedPersonId: relatedPersonId,
            transactionNumber: this.transactionNumber,
            workflow: this.workflow,
            stage: this.stage,
            step: this.step
          },
          request: {
            audience: this.queryParams.audience,
            role: this.queryParams.role
          }
        }
      };
  
      this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
        swal.fire({
          title: this.i18n.transform('Éxito'),
          html: this.i18n.transform('@i18n-text-information-deleted')
        });
        this.updateList('account$related_person_bod$post');
      }, error => {
        console.log("ERROR ELIMINANDO PEP JUNTA DIRECTIVA:", error);
      });
    }
  }

}
