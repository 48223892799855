import { AuthService, ErrorService } from '@akeela/auth';
import { UploadService } from '@akeela/common';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { HttpService } from '@akeela/properties';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import swal from 'sweetalert2';
import { timer } from 'rxjs';


@Component({
  selector: 'akeela-meet-detail',
  templateUrl: './akeela-meet-detail.component.html',
  styleUrls: ['./akeela-meet-detail.component.css']
})
export class AkeelaMeetDetailComponent implements OnInit {

  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;
  end: any;
  now: any;
  day: any;
  hours: any;
  minutes: any;
  seconds: any;
  source = timer(0, 1000);
  clock: any;

  env = environment;
  animate_init = true;
  animate_init_bottom = true;
  buttomModal;
  clientDetail;
  init_meet;
  back = false;
  menuSelect = false;
  shared =  false;
  newInterview = false
  dataAndSelfi;
  filePreview;
  client;
  dataIdentity;
  compleatVerifi;
  loading = true;
  loadingTransaction = true;

  verifiedIdentification;
  copy;
  interviews = [];
  interviewsObj= {};
  rows = [];
  transactions;
  interviewTypeIcon = [];
  interviewType = [];
  formsDataResponse = {};
  interviewsTime={};
  
  @Output() change = new EventEmitter();

  @Input() set username(client) {
    this.loading = true;
    this.loadingTransaction = true;
  
    this.clientDetail = [];
          if (client) {
        this.dataAndSelfi = null;
        this.filePreview = null;
        this.init_meet = false;

        this.client = client;
        this.getUser();
        this.getTransactions();
        }
    }
  

  @Input() set selected(buttomModal) {
    if (buttomModal) {
      this.buttomModal = true;
      this.animate_init_bottom = false;
    }
  }

  constructor(
    private _httpService: HttpService,
    private i18n: I18nPipe,
    private _upload: UploadService,
    private datePipe: DatePipe,
    private _auth: AuthService,
    private sanitizer: DomSanitizer,
    private localStorage: LocalService,
    private error: ErrorService,

  ) { }

  ngOnInit(): void {


    this.formsDataResponse = {
      interviewDate: '',
      description: '',
      idTransaction:'',
      modifyUser:this._auth?.getUsername(),
      type:'3'
    }
  }

  showDate(idTransaction){
    let distance = this.interviewsTime[idTransaction]['end'] - this.interviewsTime[idTransaction]['now'];
    this.interviewsTime[idTransaction]['day'] = Math.floor(distance / this._day);
    this.interviewsTime[idTransaction]['hours']  = Math.floor((distance % this._day) / this._hour);
    this.interviewsTime[idTransaction]['minutes']  = Math.floor((distance % this._hour) / this._minute);
    this.interviewsTime[idTransaction]['seconds']  = Math.floor((distance % this._minute) / this._second);
  }

   epoch (value) {

    let date =  this.parseDateString(value);
    return +date;

  }

  private parseDateString(date:string): Date {
    date = date.replace('T','-');
    let parts:any = date.split('-');
    let timeParts = parts[3].split(':');
    
   // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1]-1, parts[2], timeParts[0], timeParts[1]); // Note: months are 0-based

 }

  get_data_identity() {

    const params = {
      name: 'person$get_data_identity$get',
      params: {
        request: {
          'username': this.client
        },
        path: {},
        body: {}
      }
    };



    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      this.dataIdentity = response;



    });

  }


  get_dataAndSelfi() {

    const params = {
      name: 'person$selfie$get',
      params: {
        request: {
          'username': this.client
        },
        path: {},
        body: {}
      }
    };



    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      this.dataAndSelfi = response;
      this.filePreview = 'data:image/jpg' + ';base64,' + response?.description;

    });


  }

  redirectMeet(url:any = false){

    let data  = 'https://meet.jit.si/meet-'+this.env.theme.images+'-'+this.clientDetail?.identifier;
   
    if(url){
      data = url;
    }
   
    // Abrir nuevo tab
    var win = window.open(data, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    this.resetMenu();
    win.focus();

}

setClientDetail(e) {


}


changes = () => {
  this.buttomModal = false;
  this.change.emit(null);
}


setUserDetail() {
  this.buttomModal = false;
  this.change.emit(null);
}



setMenuSelect(){
  this.resetMenu();
  this.menuSelect = true;
  this.back = true;
}

setShared(){
  this.resetMenu();
  this.shared = true;
  this.back = true;
}

setNewInterview(idTransaction:any = ''){


  if(idTransaction){
    this.setformTransaction(idTransaction);

  }else{

    this.formsDataResponse = {
      interviewDate: '',
      description: '',
      idTransaction:'',
      modifyUser:this._auth?.getUsername(),
      type:'3'
    }
  
  }



  this.resetMenu();
  this.newInterview = true;
  this.back = true;
}


setBack(){
  this.resetMenu();
  this.back = false;

}

resetMenu(){
  this.menuSelect = false;
  this.shared = false;
  this.newInterview = false;
  this.back = false;
  this.copy = false;


}


getUser() {
  const params = {
    name: 'person$enduser$get',
    params: {
      request: {
        'username': this.client
      },
      path: {},
      body: {}
    }
  };


  this.loading = true;
 // ;
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    this.clientDetail = response;
    this.loading = false;

    this.compleatVerifi = response ?.person ?.completeIdentification ;
    this.verifiedIdentification = response ?.person ?.verifiedIdentification ;
  });

}

getTransactions() {
  this.loadingTransaction = true;
  const params = {
    name: 'account$myformalities_paginated$get',
    params: {
      request: {
        page: 0,
        size: 60
      },
      path: {
        username: this.client
      },
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {


    this.transactions = response.content;
    this.rows = [];

    for (const transaction of this.transactions) {

      let row = transaction;

      let jsonTmp =
        {
          "identifier": row.identifier,
          "solicitud": row.transactionNumber,
          "fecha": (row.accountStatus) ? (this.datePipe.transform(row.accountStatus[0].dateOfThing.startDate, 'dd/MM/yyyy')) : '-',
        }

      this.rows = [...this.rows, jsonTmp];

      this.getInterview(row?.identifier, row?.transactionNumber);
    }

    this.interviews= [];
    this.interviewsObj = {};
    this.getInterviewType();
    this.loadingTransaction = false;

  }, error => {
    this.loadingTransaction = false;

  });
}



setCopy(text){
  navigator.clipboard.writeText(text);
  this.copy =  true;
}

setShare(url,row:any =false){

  if (navigator.share) {

    let text = this.env?.theme?.images +" Video LLamada.";

    if(row){
      text = this.env?.theme?.images +". Fue programada una reunion de tipo ( "+ this.i18n.transform(row?.tipo)+") en fecha "+ this.datePipe.transform(row?.fecha, 'd MMM, y h:mm a');
    }

    if(url){
      navigator.share({  text: text , url: url });

    }else{
      navigator.share({text: text});

    }

  }


}


convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
  
}

private toDateString(fecha): string {
  let temp = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);
  let date:Date = this.parseDateString(temp);

  return (("0" + (date.getDate())).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' +date.getFullYear().toString())
   + ' ' + date.toTimeString().slice(0,5);
}


private toDateStringTime(fecha): string {
  let temp = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);
  let date:Date = this.parseDateString(temp);

  return (  ("0" + (date.getMonth() + 1)).slice(-2)  + '/' + ("0" + (date.getDate())).slice(-2)  + '/' +date.getFullYear().toString())
   + ' ' + date.toTimeString().slice(0,5);
}


setformTransaction(idTransaction){

  let temp = this.interviewsObj[idTransaction];

  //let fecha =this.datePipe.transform(temp?.interviewDate, 'd-M-yyTh:mm');

  let fecha = new Date(temp?.interviewDate).toISOString().slice(0, 16); 

    this.formsDataResponse['interviewDate'] =  fecha;
    this.formsDataResponse['type'] =  temp?.type;
    this.formsDataResponse['description'] =  temp?.description;
    this.formsDataResponse['idTransaction'] =  idTransaction;
    

}

  getInterview(transaction,transactionNumber) {
      
    const params = {
      name: 'interview$interviews_transaction$get',
      params: {
        request: {
          idTransaction: transaction
        },
        path: { 

        },
        body: {}
      }
    };

       this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      if(response){
        response['transaction'] = transactionNumber; 
        response['idTransaction'] = transaction; 


        this.interviews = [...this.interviews, response];

        this.interviewsObj[transaction] = response;
        this.setTimeInterview(response);

      }

    }, error => {
    });

  }

  setTimeInterview(response){
    this.interviewsTime[response?.idTransaction]= {
      now:'',
      end:'',
      day:'',
      hours:'',
      minutes:'',
      seconds:'',
      valid:''
    }
  
    this.clock = this.source.subscribe(t => {
      this.interviewsTime[response?.idTransaction]['now'] = new Date();
    //  this.end = new Date('01/01/' + (this.now.getFullYear() + 1) +' 00:00');

    let temp = this.toDateStringTime(response?.interviewDate);
   // let temp2 = '01/01/' + (this.interviewsTime[response?.idTransaction]['now'].getFullYear() + 1) +' 00:00';

    this.interviewsTime[response?.idTransaction]['end'] = new Date(temp);
    this.interviewsTime[response?.idTransaction]['valid'] = this.interviewsTime[response?.idTransaction]['end'] > this.interviewsTime[response?.idTransaction]['now'];
     // this.interviewsTime[response?.idTransaction]['end'] = this.toDateString(response?.interviewDate);
    if(this.interviewsTime[response?.idTransaction]['valid']){
      this.showDate(response?.idTransaction);
    }

    });
  }



  getInterviewType() {    
    const params = {
      name: 'workflow_manager$attributes_options$get',
      params: {
        request: {},
        path: { 
          code: 'interviewType'
        },
        body: {}
      }
    };
    
  
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      let nameItem = '';

      if(response.length){


        this.interviewType = [];
        for (let value of response) {
          nameItem = value.item.alternateName;
        if(nameItem){

          this.interviewType[nameItem] =   {value:nameItem, label: value.name} ;

        }
                  


        }}
    
      });

  }



  submitFormsDataResponse(){
    let formsDataResponse:any = this.formsDataResponse

    formsDataResponse['interviewDate'] =   this.epoch(this.formsDataResponse['interviewDate']);
    const params = {

      name: "interview$interviewuser$post",
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience')
        },
        path: {},
        body: formsDataResponse
      }
    };

            this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
  
             // 
              swal.fire({
                reverseButtons: true,
                confirmButtonText: 'Ok',
                html: 'Reunión agendada',
              });

              this.getTransactions();
              this.setBack();
  
            }, error => {
             
              this.error.show(error, this.i18n.transform('Alerta!'), this.i18n.transform('No se pudo dar respuesta a esta Solicitud  <br><b>'));
            });
            

    
  }

  

}
