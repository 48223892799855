
<div class="row ">
    <div class="ak-section bg-white col-12">
      <div class="row">
        <div class="ak-section col-12 col-md-8">
            
            <div class="pl-3 pr-2">
                <div class="row pt-4">
                    <div class="col-12">
                        <h4 class="w-100 flex-fill title-section mb-3">{{ '@i18n-product-information-account-opening-step-name' | i18n }}</h4>
                    </div>
                </div>
               <app-investor-profile [briefcase]="true"></app-investor-profile>
            </div>

        </div>

        <div class="d-none d-md-block col-md-4 ak-border-left-box-right">
            <div class="p-1 pl-2  mt-2 mb-2 ">
                <!-- <div>
                    <div class="base-description-section-top mb-2 font-weight-bold">
                    Datos Básicos
                    </div>
            
                    <div class="text-form-description ">
                    Se refiere a su información personal, como: nombres, apellidos, estado civil, edad, género, profesión y ocupación.<br><br>Esta información estará visible a su ejecutivo asignado. De este modo, le resultará más fácil ponerse en contacto con usted.
                    </div>
                 </div> -->
            </div>
          </div>
      </div>
    </div>
  </div>
  
  