import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AkeelaSectionService, AkeelaStepService, AkeelaWorkflowService, AkeelaStageService, RouteUtilsService } from '@akeela/workflow';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@akeela/auth';
import { HttpService } from '@akeela/properties';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { RequirementsService } from '@akeela/requirements';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { LocalService } from '@akeela/local-storage';
import * as moment from 'moment-mini-ts';
import { isNullOrUndefined } from 'util';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'private-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.css']
})
export class ResumenComponent implements OnInit, OnDestroy {
  @Input() short?= false;
  transactionNumber;
  transactionData;
  breadcrumdKey: any;
  transactionStatus: any;
  workflowCode;
  stageCode;
  audience;
  holder;
  sectionIncompleats = [];
  sectionRequirementsIncompleats: any = [];
  sectionIncompleatsDropDown = {};
  sectionRequirementsIncompleatsDropDown = {};
  guide = [];
  role;
  loadingGuide;

  subscriptions: Subscription[] = [];

  wfSubscription: Subscription;
  public isCollapsed = false;

  showModalResponses = false;

  env;
  showModalResume = false;

  percent = null;
  completed = 9;
  total = 14;

  view_more = false;

  items;

  legal_representative = [];
  autorizado = [];
  cuentadante = [];

  incomplete = false;
  showCommentsBoolean = [];
  showGuia = false;
  dataEjecutive = null;
  interview = null;
  interviewType = [];
  interviewTypeIcon = []

  constructor(
    private akeelaSectionService: AkeelaSectionService,
    private route: ActivatedRoute,
    private router: Router,
    public _auth: AuthService,
    private _httpService: HttpService,
    private akeelaBreadcrumbService: AkeelaBreadcrumbService,
    private akStepService: AkeelaStepService,
    private workflowService: AkeelaWorkflowService,
    private stageService: AkeelaStageService,
    private _akeelaRequirementsService: RequirementsService,
    private localStorage: LocalService,
    private routerUtilService: RouteUtilsService,
    public config: NgbDropdownConfig,
    public datePipe: DatePipe



  ) {


    config.placement = 'top-left';
    config.autoClose = true
      ;

  }

  async ngOnInit() {

    this.showCommentsBoolean['ACCOUNT_OPENING'] = true;
    this.showCommentsBoolean['REQUIREMENT'] = true;

    this.env = environment;
    this.transactionNumber = this.localStorage.getItem('transactionNumber');
    // this.route.params.subscribe(params => {
    // });
    this.role = await this.routerUtilService.getParamAsync(this.route, 'role');
    this.audience = await this.routerUtilService.getParamAsync(this.route, 'audience');

    this.getAccountproductinformation();



    this.wfSubscription = this.workflowService.currentWorkflow$.subscribe(workflowCode => {
      this.workflowCode = workflowCode;
      this.stageService.currentStage$.subscribe(stageCode => {
        this.stageCode = stageCode;
      });
    });
  }

  getItemListLegalRepresentative() {
    if (!isNullOrUndefined(this.transactionData.person) || !isNullOrUndefined(this.transactionData.organization)) {
      const params = {
        name: 'account$related_persons$get',
        params: {
          request: {},
          path: {
            typeRelatedPerson: 'LEGAL_REPRESENTATIVE',
            personId: this.transactionData.organization ? this.transactionData.organization.identifier : this.transactionData.person.identifier
          },
          body: {}
        }
      };

      this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

        if (response.length) {
          this.autorizado = [];
          this.legal_representative = [];

          for (let value of response) {

            if (value.person.condition.identifier == "1") {

              this.legal_representative.push(value);

            } else if (value.person.condition.identifier == "2") {

              this.autorizado.push(value);

            } else if (value.person.condition.identifier == "3") {

              this.cuentadante.push(value);
            }

          }
        };

      }));
    } else {
      this.legal_representative = [];
    }

  }

  getModulesInformation() {
    const params = {
      name: 'resume$transaction_modules_complete$get',
      params: {
        request: {
          idPlan: this.transactionData.plan.identifier,
          role: this.role,
          audience: this.audience
        },
        path: {
          idTransaction: this.transactionData.identifier,
          idPerson: this.transactionData.holder ? this.transactionData.holder.identifier : null
        },
        body: {}
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      const items = [];
      response = response.sort(function (a, b) {
        return a.identifier - b.identifier;
      });

      this.items = response;

    }, error => {
      this.items = null;
    }));
  }

  getRequirementsIncompleted() {
    const config = {
      statusResponseRequirement: {
        verified: 'VERIFIED',
        inconsistent: 'INCONSISTENT',
        incomplete: 'INCOMPLETED',
        rejected: 'REJECTED'

      },
      endpoints: {
        getRequirements: {
          identifier: '',
          name: 'requirements$transaction_requirements$get',
          params: {
            request: {
              audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
            },
            path: {
              idPlan: this.transactionData.plan.identifier,
              transactionNumber: this.transactionData.transactionNumber,
              idPerson: this.transactionData.holder ? this.transactionData.holder.identifier : null
            },
            body: {}
          }
        },
        saveRequirement: {
          params: {
            request: {},
            path: {},
            body: {
              'transaction': {
                'identifier': this.transactionData.identifier,
                'name': this.transactionData.transactionNumber
              },
              'requirement': {
                'identifier': null
              },
              'requirementArchives': [
                {
                  'creator': {
                    'identifier': null
                  }
                }
              ],
              'person': {
                'identifier': this.transactionData.holder.identifier,
                'identity': this.transactionData.holder.alternateName,
              }

            }
          }
        },
        deleteRequirement: {
          name: 'requirements$requirement$delete',
          params: {
            request: {},
            path: {},
            body: {
              alternateName: 'ACCREDITED',
              transaction: {
                'identifier': this.transactionData.identifier,
                'name': this.transactionData.transactionNumber
              },
              requirement: {
                'identifier': null
              },
              person: {
                'identifier': this.transactionData.holder.identifier,
              },
              requirementArchives: [{
                identifier: null
              }]
            }
          }
        }
      }
    };
    return new Promise((resolve, reject) => {
      this._akeelaRequirementsService.getRequirementsWithMultipleStatus(config, ['INCOMPLETED', 'REJECTED']).then((resp: any) => {
        resolve(resp);
      }, error => {
        reject(error);
      });
    });
  }

  getAccountproductinformation() {
    this.transactionData = null;
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role
        },
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };

    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.transactionData = response;
      this.getDataEjecutive();
      this.getInterview();
      this.getInterviewType();

      this.getGuiaSolicitud();


      if (this.env.showTransactionsRelatedResumen) {
        this.getItemListLegalRepresentative();
      }



      if (this.transactionData.accountStatus[0].additionalType === 'AC_INCOMPLETE') {
        this.getStatusIncompleat();
        this.incomplete = true;
        this.getRequirementsIncompleted().then((resp) => {
          this.sectionRequirementsIncompleats = resp;
        });
      } else {

        this.getHistoryStatusTransaction().then((history: any) => {
          if (history.length >= 2) {
            if (history[0].name === 'PI_IN_PROCESS' && history[1].name === 'AC_INCOMPLETE') {
              this.incomplete = true;
              this.getRequirementsIncompleted().then((resp) => {
                if (resp) {
                  this.getStatusIncompleat();
                }
                this.sectionRequirementsIncompleats = resp;
              });
            }
          }
        });
      }

      // this.getHolderByTransactionNumber(response.transactionNumber).subscribe((holder) => {
      //   this.holder = holder;
      // });
    }));

  }

  getHistoryStatusTransaction() {
    return new Promise((resolve, reject) => {
      const params = {
        name: 'accounts$accountstates$get',
        params: {
          request: {},
          path: {
            transactionNumber: this.transactionNumber
          },
          body: {}
        }
      };

      this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        resolve(response);
      }, error => {
        reject(false);
      }));
    });
  }

  getHolderByTransactionNumber(transactionNumber) {
    const params = {
      name: 'account$client_identification_business$get',
      params: {
        request: {},
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  getStatusIncompleat() {

    const params = {
      name: 'account_opening$stage_sections_incompleted$get',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
        },
        path: {
          workflow: this.workflowCode,
          stage: "ACCOUNT_CONTRACT_ANALYSIS",
          transactionNumber: this.transactionNumber,
          idPerson: this.transactionData.holder.identifier

        }
      }
    };

    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params).subscribe((response: any) => {

      this.sectionIncompleats = response;

    }, error => {

      this.sectionIncompleats = [];

    }));

  }

  ngOnDestroy() {
    this.akeelaBreadcrumbService.setbreadcrumbOptions([]);
    if (this.wfSubscription) {
      this.wfSubscription.unsubscribe();
    }
  }

  gotoBoxUrl(type) {

    if (this.localStorage.getItem('clientaudience') == 'NATURAL') {

      this.gotoNexUrl('CLIENT_IDENTIFICATION', 'PERSONAL_INFORMATION');


    } else {

      this.gotoNexUrl('CLIENT_IDENTIFICATION', 'BUSINESS_IDENTITY');

    }

    //this.localStorage.setItem('tempOpenLegalRepresentative',true);

  }


  gotoNexUrl(stage, step) {
    const prefixed = "private";
    const queryParams: any = {
      role: this.localStorage.getItem('role'),
      audience: this.localStorage.getItem('audience'),
      profile: this.localStorage.getItem('profile'),
    };

    this.router.navigate([prefixed, this.workflowCode, 'stages', stage, 'steps', step], { queryParams });
  }


  firstStep() {
    this.showModalResponses = false;
    const queryParams: any = {};


    this.router.navigateByUrl(this.router.createUrlTree
      ([environment.staticLinks.firstStep],
      {
        queryParams: queryParams
      }
      )
    );

  }
  redirectRequirementsUpload() {
    this.showModalResponses = false;
    const queryParams: any = {};

    this.router.navigateByUrl(this.router.createUrlTree
      ([environment.staticLinks.requirementsUpload],
      {
        queryParams: queryParams
      }
      )
    );
  }

  open_modal() {
    this.showModalResume = true;
    this.getModulesInformation();
  }

  response() {
    this.showModalResponses = true;
  }

  round(value) {
    return Math.round(value);
  }

  view_mode_active() {
    this.view_more = !this.view_more;
  }

  diffDates(date) {
    const nowMoment: moment.Moment = moment(Date());
    const resta = moment.duration(nowMoment.diff(moment(date)));
    return resta.months() + 'm ' + resta.days() + 'd ' + resta.hours() + 'h ' + resta.minutes() + 'm ';
  }

  duration(date) {

    const nowMoment: moment.Moment = moment(Date());
    const resta = moment.duration(nowMoment.diff(moment(date)));
    let response = '';
    console.log(resta);
    if (resta.years() > 0) {
      response = 'Más de ' + resta.years() + ' año(s)';
    } else if (resta.months() > 0) {
      response = 'Más de ' + resta.months() + ' mes(es)';
    } else if (resta.weeks() > 0) {
      response = 'Más de ' + resta.weeks() + ' semana(s)';
    } else if (resta.days() > 0) {
      response = 'Más de ' + resta.days() + ' dia(s)';
    } else if (resta.hours() > 0) {
      response = 'Más de ' + resta.hours() + ' hora(s)';
    } else if (resta.minutes() > 0) {
      response = 'Más de ' + resta.minutes() + ' minuto(s)';
    } else {
      response = 'Hace pocos segundos';

    }
    return response;

  }


  getGuiaSolicitud() {


    const params = {
      name: 'account_opening$guia_resumen$get',
      params: {
        request: {
          role: this.role,
          audience: this.audience,
          idPlan: this.transactionData.plan.identifier,
          depend: false

        },
        path: {

          idPerson: !isNullOrUndefined(this.transactionData.holder) ? this.transactionData.holder.identifier : '',
          transactionNumber: this.transactionData.transactionNumber

        },
        body: {}
      }
    };




    this.loadingGuide = true;
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      this.guide = [...response]
      this.loadingGuide = false;

    }, error => {
      console.error("error: ", error);
      this.loadingGuide = false;


    }));
  }


  getDataEjecutive() {

    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          role: this.role,
          audience: this.audience,
          username: this.transactionData.salesManagerUser ? this.transactionData.salesManagerUser.modifyUser : ''

        },
        path: {},
        body: {}
      }
    };

    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {


      this.dataEjecutive = response;

    }, error => {
    }));

  }


  getInterview() {

    const params = {
      name: 'interview$interviews_transaction$get',
      params: {
        request: {
          idTransaction: this.transactionData.identifier
        },
        path: {

        },
        body: {}
      }
    };

    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      this.interview = response;

    }, error => {
    }));

  }



  getInterviewType() {
    const params = {
      name: 'workflow_manager$attributes_options$get',
      params: {
        request: {},
        path: {
          code: 'interviewType'
        },
        body: {}
      }
    };


    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      let nameItem = '';
      if (response.length) {

        this.interviewTypeIcon = [];

        this.interviewType = [];
        for (let value of response) {
          nameItem = value.item.alternateName;
          this.interviewType[nameItem] = value.name;

          if (value.image && value.image.contentUrl) {
            const data = JSON.parse(value.image.contentUrl);

            this.interviewTypeIcon[nameItem] = data.cssClassIcon;

          }



        }
      }

    }));





  }


}
