


import { AuthService } from '@akeela/auth';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { WorkflowService } from 'src/app/services/workflow.service';
import swal from 'sweetalert2';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-validate-phone',
  templateUrl: './validate-phone.component.html',
  styleUrls: ['./validate-phone.component.css']
})
export class ValidatePhoneComponent implements OnInit,OnDestroy {


  errorMsgValidatTel;
  sentValidateTlf = false;
  activeModalValidateTlf= false;
  centerModal;
  code;
  data;
  loadingSentValidateTlf;
  loadingValidateCode;
  sentValidateErrorTlf;
  showValidateTel : Subscription;
  @Output() change = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() set selected(data) {
    if (data) {
      
      this.centerModal = true;

    }
   
  }


  @Input() set config(data) {
    if (data) {
      
      this.data = data;
      this.sendMsgValidateTel(data);

    }
   
  }
  constructor(
 
    private _httpService: HttpService,
    public _auth: AuthService,
    private localStorage: LocalService,
    private akWorkflowService: AkeelaWorkflowService,
    private i18n: I18nPipe,

  ) { }

      ngOnInit(): void {

        this.akWorkflowService.showValidateTel$.subscribe((response) => {
        if (response) {
            console.clear();
            console.log(response);
            
           
        }
         });
      }


      
    sendMsgValidateTel(tel){
 /*   setTimeout(() => {
        this.loadingSentValidateTlf = false;
        this.sentValidateTlf = true;


    }, 1000);*/
    this.sentValidateErrorTlf = false;

      this.loadingSentValidateTlf = true;
      
        const params = {
            pathResponse:{
                url:'https://otp.akeela.co/sendsms',
                httpMethod:'POST'
            },
            params: {
                responseType:'json',
                request:{
                    'phone':tel,
                    'client':environment?.theme?.site?.name
                }
               
            }


        }

        this._httpService.executeRequestExternalUrl(params).subscribe((result: any) => {
          

    
            if (!(result?.error)) {
    
              this.sentValidateTlf = true;


            }else{

              this.sentValidateErrorTlf = true;

              this.loadingSentValidateTlf = false;

            }
      
            this.loadingSentValidateTlf = false;

        },error => {
                    this.loadingSentValidateTlf = false;

          this.sentValidateTlf = false;


        });
        
        
    }


    getCodeValidateTel(tel,code){

this.loadingValidateCode = true;
        const params ={
            pathResponse:{
                url:'https://otp.akeela.co/verificate',
                httpMethod:'GET'
            },
            params: {
                responseType:'json',
                request:{
                    'phone':tel,
                     code
                }
            
            }


        }

        this._httpService.executeRequestExternalUrl(params).subscribe((result: any) => {

                if(result?.valid){
                    

                  swal.fire({
                    html: this.i18n.transform('Validación exitosa')
                  });

                  this.setChange(this.data);

                }else{
                    this.errorMsgValidatTel = 'Codigo Invalido'
                    this.code = null;
                }

                ;
                this.loadingValidateCode = false;

        });

        


    }

      

      ngOnDestroy = ():void => {

      }


      setClose(){
        this.close.emit(null);
        this.centerModal = false;
      }


      setChange(e){
        this.change.emit(e);
        this.centerModal = false;
      }


}
