import { Component, OnInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined, isUndefined } from 'util';
import { Subscription } from 'rxjs';
import { ShareholdersService } from '../../services/shareholders.service';
import { LocalService } from '@akeela/local-storage';
import { Table, Row, Column } from '@akeela/ak-table';


@Component({
  selector: 'app-section-shareholders-relateds',
  templateUrl: './section-shareholders-relateds.component.html',
  styleUrls: ['./section-shareholders-relateds.component.css']
})
export class SectionShareholdersRelatedsComponent implements OnInit {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  itemListFather: any;
  itemListFull: any;
  queryParams;
  sumSharePercentage;
  loading;

  readonlyMode = false;
  shareholders = null;
  public shareholders_related = [];
  shareholders_related_selected;

  idPerson = null;

  private transactionDataSubscription: Subscription;
  private workflowEventsSubscription: Subscription;
  private requestSubscription: Subscription;

  disable_save_exceed = false;
  rules = {
    maxShareholders: 20,
    maxMainShareholders: 10
  };

  loading_table = false;
  configuration_table: Table;
  configuration_tables = [];

  paramsSubscription: Subscription;



  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private shareholderService: ShareholdersService,
    private localStorage: LocalService

  ) { }

  ngOnInit() {
    // [dfutrille] Esta ejecucion de evento es para prevenir la suscripcion al ultimo evento ejecutado.
    // Esto se debe evaluar, ya que es algo que molesta en muchas ejecuciones.
    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
    });

    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.akeelaWorkflowService.transactionData$.subscribe((transactionData) => {
      if (!isNullOrUndefined(transactionData)) {
        this.transactionData = transactionData;
        this.shareholderService.getShareholders().subscribe((response) => {
          if (!isNullOrUndefined(response)) {
            this.shareholders = response;
            const idPerson = this.transactionData.organization ? this.transactionData.organization.identifier : this.transactionData.person.identifier;
            this.idPerson = idPerson;
            this.getItemList(idPerson);


          }
        });
      }
    });

    // this.getItemList(1);


    this.akeelaWorkflowService.transactionNumber.subscribe(transactionNumber => {
      if (transactionNumber) {
        this.transactionNumber = transactionNumber;
      }
    });

    this.transactionDataSubscription = this.akeelaWorkflowService.transactionData.subscribe(transactionData => {
      this.transactionData = transactionData;
      // if (!isNullOrUndefined(this.transactionData) &&
      //   (!isNullOrUndefined(this.transactionData.organization) || !isNullOrUndefined(this.transactionData.person))) {
      //   this.getItemList();
      // }
    });

    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    this.workflowEventsSubscription = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
     
     
      if (response && (response.action === 'account$related_person_shareholder$post' || response.action === 'account$related_person_pep_shareholder$post')) {
        this.getItemList(this.shareholders_related_selected);
      }

      /*
            if (response && (response.action === 'account$related_person_shareholder$post' || response.action === 'account$related_person_pep_shareholder$post')) {
              this.getItemList();
            } else if (!isNullOrUndefined(response) && response.action === 'send_formValidationEvent_shareholders') {
              //Si marco SI, entonces valida sino o si tiene al menos un PEP, entonces continua
              const markYes = response.response.forms.controls.person.controls.isRelatedToPep ? response.response.forms.controls.person.controls.isRelatedToPep.value:null;
              if (markYes && !this.haveAtLeastOnePep()) {
                swal.fire({
                  type: "warning",
                  text: this.i18n.transform('@i18n-validation-have-to-add-pep'),
                });
              } else {
                this.sendEventToContinue(response.response);
              }
            } else if (!isNullOrUndefined(response) && response.action === 'send_formValidationEvent_delete_all_shareholders_pep') {
              //Si marco SI, entonces valida sino o si tiene al menos un PEP, entonces continua
              const radioButtonNo = response.response.forms.controls.person.controls.isRelatedToPep ? response.response.forms.controls.person.controls.isRelatedToPep.value:null;
              if (radioButtonNo && this.haveAtLeastOnePep()) {
                //Ha marcado NO y tiene PEP, Preguntar si quiere eliminar todos los PEP de REP LEG
                swal.fire({
                  title: this.i18n.transform('@i18n-confirmation-are-you-shure'),
                  text: this.i18n.transform('@i18n-confirmation-are-you-shure-delete-pep'),
                  showCancelButton: true,
                  confirmButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-true'),
                  cancelButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-false'),
                  reverseButtons: true
                }).then((result) => {
                  if (!isNullOrUndefined(result.value) && result.value) {
                    //Si el usuario responde que si a la pregunta... Elimina sus PEP
                    this.akeelaWorkflowService.worflowEvents$.next({
                      response: 'SHAREHOLDERS',
                      action: 'account_opening$related_person_all_pep_shareholders$delete'
                    });
                  } else {
                    //Asigno nuevo valor cuando el usuario CANCELE la operacion
                    response.response.forms.controls.person.controls.isRelatedToPep.patchValue(true);
                  }
                });
              }
            }
      
            */
    });

  }

  capitalize(word) {
    if(word && word[0]){
      return word[0].toUpperCase() + word.slice(1);

    }
    return '';
  }



  ngOnDestroy() {
    if (this.transactionDataSubscription) {
      this.transactionDataSubscription.unsubscribe();
    }
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
    this.localStorage.removeItem('idShareholderFather');
  }

  getItemList(idPerson) {
    idPerson = this.transactionData.organization ? this.transactionData.organization.identifier : this.transactionData.person.identifier;

    this.loading = true;

    const params = {
      name: 'accounts-related$related-shareholder$get',
      params: {
        request: {},
        path: {
          idPerson: idPerson
        },
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, true).subscribe((resp: any) => {
      this.itemListFull = resp;
      this.itemList = resp.relatedShareholders;
      this.itemListFather = resp.principalShareholders;

      this.refresh_information();
      // if (resp.totalShareholders < this.rules.maxShareholders) {
      //   this.disable_save_exceed = false;
      // } else {
      //   this.disable_save_exceed = true;
      // }


      // const aux = [];
      // let i = 0;
      // const base: Column[] = this.setHeadTable();

      // for (const sh in resp.relatedShareholders) {
      //   if (sh) {
      //     if (this.validateTypeRelatedShareholder(resp.principalShareholders, sh.toString().trim()) === 'LEGAL') {
      //       aux[i] = [];
      //       aux[i]['name'] = sh.toString().trim();
      //       // aux[i]['idFather'] = this.getPersonFatherByChild(resp.relatedShareholders[sh.toString().trim()][0]);
      //       aux[i]['value'] = resp.relatedShareholders[sh.toString().trim()];

      //       aux[i]['totalAccionistas'] = aux[i]['value'].length;

      //       let p = 0;

      //       for (const row in aux[i]['value']) {

      //         let value: any = aux[i]['value'][row];

      //         if (value.applicantType == 'NATURAL') {

      //           p = p + value.person.sharePercentage;
      //         } else {

      //           p = p + value.organization.sharePercentage;

      //         }

      //       }

      //       aux[i]['totalAccionistasPorcentage'] = p;

      //       // Mario: separo el contador de los accionistas principales
      //       this.akeelaSectionService.setSumSharePercentage(p);


      //       if (aux[i]['totalAccionistas'] > 0) {

      //         this.configuration_table = {
      //           cssCustom: '',
      //           width: '100%',
      //           header: {
      //             cssCustom: 'class-color-title-table-section',
      //             rows: [{
      //               backgroundColor: '#fff',
      //               columns: base
      //             }],
      //           },
      //           body: {
      //             cssCustom: '',
      //             rows: this.setContentTable(base, aux[i]['value'])
      //           },
      //           footer: {
      //             cssCustom: '',
      //             rows: []
      //           }
      //         };

      //         this.configuration_tables[aux[i]['name']] = this.configuration_table;


      //       }
      //       i++;

      //     }





      //     console.log("resp resp resp aux", this.configuration_tables);

      //     this.shareholders_related = aux;
      //     this.loading = false;
      //   }
      // }
      this.loading = false;

    }, error => {
      this.loading = false;

    });




  }

  refresh_information() {

    if (this.itemListFull.totalShareholders < this.rules.maxShareholders) {
      this.disable_save_exceed = false;
    } else {
      this.disable_save_exceed = true;
    }


    const aux = [];
    let i = 0;
    const base: Column[] = this.setHeadTable();
    
    this.shareholders_related = [];
    this.configuration_tables = [];


    for (const sh in this.itemListFull.relatedShareholders) {
      if (sh) {
        if (this.validateTypeRelatedShareholder(this.itemListFull.principalShareholders, sh.toString().trim()) === 'LEGAL') {
          aux[i] = [];
          aux[i]['name'] = sh.toString().trim();
          // aux[i]['idFather'] = this.getPersonFatherByChild(resp.relatedShareholders[sh.toString().trim()][0]);
          aux[i]['value'] = this.itemListFull.relatedShareholders[sh.toString().trim()];

          aux[i]['totalAccionistas'] = aux[i]['value'].length;

          let p = 0;

          for (const row in aux[i]['value']) {
            if (true) {
              const value: any = aux[i]['value'][row];

              if (value.applicantType === 'NATURAL') {

                p = p + value.person.sharePercentage;
              } else {

                p = p + value.organization.sharePercentage;

              }
            }
          }

          aux[i]['totalAccionistasPorcentage'] = p;



          if (aux[i]['totalAccionistas'] > 0) {

            this.configuration_table = {
              cssCustom: '',
              width: '100%',
              header: {
                cssCustom: 'class-color-title-table-section',
                rows: [{
                  backgroundColor: '#fff',
                  columns: base
                }],
              },
              body: {
                cssCustom: '',
                rows: this.setContentTable(base, aux[i]['value'])
              },
              footer: {
                cssCustom: '',
                rows: []
              }
            };

            this.configuration_tables[aux[i]['name']] = this.configuration_table;


          }
          i++;

        }


        this.shareholders_related = aux;
        this.loading = false;
      }
    }

    // Mario: separo el contador de los accionistas principales.
    for (const _sh of this.shareholders_related) {
      if (this.shareholders_related_selected === _sh.name) {
        this.akeelaSectionService.setSumSharePercentage(_sh.totalAccionistasPorcentage);

      }
    }

  }


  validateTypeRelatedShareholder(shareholders, identifier) {
    const sh = shareholders.filter((resp) => {
      const i_sh = resp.applicantType === 'NATURAL' ? resp.person.identifier : resp.organization.identifier;
      return i_sh === identifier;
    });

    return sh[0].applicantType;
  }

  getPersonFatherByChild(child) {
    console.log('CHILD');
    console.log(child);
    return child.applicantType === 'NATURAL' ? child.person.relatedType.alternateName : child.organization.relatedType.alternateName;
  }

  updateFather() {
    const self = this;
    const modalFather = self.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather) && !isNullOrUndefined(modalFather.person)) {
      const fatherToUpdate = this.itemList.find(
        (_father) => (!isNullOrUndefined(_father.person)) && (_father.person.identifier === modalFather.person.identifier)
      );
      if (!isNullOrUndefined(fatherToUpdate)) {
        self.akeelaWorkflowService.worflowEvents$.next({
          response: fatherToUpdate,
          action: 'update_shareholders_pep'
        });
      }
    }
  }

  openItem(row, sh) {

    const identifier = sh.person ? sh.person.identifier : sh.organization.identifier;
    this.localStorage.setItem('idShareholderFather', identifier);
    this.localStorage.setItem('typeShareholder', 'RELATED_SHAREHOLDER');

    //this.akeelaSectionService.sharePercentageField(nuevo);

    this.akeelaSectionService.addModalSection({ name: 'SHAREHOLDERS_MODAL' });
    this.akeelaSectionService.showModalSection('SHAREHOLDERS_MODAL', true);
    row.disableWhenUpdate = 'applicantType';

    this.akeelaSectionService.setModalFormValue('SHAREHOLDERS_MODAL', row);
    let tmp = 0;


    if (row.person) {
      tmp = row.person.sharePercentage;
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_LEGAL_FIELDS', 'hidden': true });
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_NATURAL_FIELDS', 'hidden': false });
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_PEP', 'hidden': false });

    } else if (row.organization) {
      tmp = row.organization.sharePercentage;
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_LEGAL_FIELDS', 'hidden': false });
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_NATURAL_FIELDS', 'hidden': true });
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_PEP', 'hidden': true });

    }


    // if (row.person) {
    //   tmp = row.person.sharePercentage;

    // } else if (row.organization) {
    //   tmp = row.organization.sharePercentage;
    // }

    if (isNullOrUndefined(tmp)) {
      tmp = 0;
    }

    this.akeelaSectionService.setSharePercentageField(tmp);



  }

  getShareholderFather(identifier) {
    return this.itemListFather.find((item) => {
      if (item.person) {
        return item.person.identifier.toString() === identifier;
      } else {
        return item.organization.identifier.toString() === identifier;
      }
    });
  }

  select_related(row) {

    if (row.totalAccionistas < 1) {

      this.addRelated(this.getShareholderByIdentifier(row.name));

    }


    this.shareholders_related_selected = row.name;
    this.refresh_information();
  }

  table_response(response) {
    const itemList = this.itemList[this.shareholders_related_selected];
    const sh = this.getShareholderFather(this.shareholders_related_selected.toString());
    if (response.action === 'open') {
      let row = null;

      row = itemList.find((item: any) => {
        if (item.person) {
          return item.person.identifier === response.object.identifier;
        } else {
          return item.organization.identifier === response.object.identifier;
        }
      });


      this.openItem(row, sh);

    } else if (response.action === 'action') {
      if (response.object.action === 'delete_item' && !this.readonlyMode) {

        let row = null;

        row = itemList.find((item: any) => {
          if (item.person) {
            return item.person.identifier === response.object.obj.identifier;
          } else {
            return item.organization.identifier === response.object.obj.identifier;
          }
        });


        this.confirmDelete(row);
      }
    }

  }

  confirmDelete(row: any) {
    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }

  deleteItem(row: any) {
    const relatedPersonId = !isNullOrUndefined(row.organization) ? row.organization.identifier : row.person.identifier;
    const configuration = {
      name: 'account$related_persons$delete',
      params: {
        path: {
          relatedPersonId: relatedPersonId,
          transactionNumber: this.transactionNumber,
          workflow: this.workflow,
          stage: this.stage,
          step: this.step
        },
        request: {
          audience: this.queryParams.audience,
          role: this.queryParams.role
        }
      }
    };

    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
      this.akeelaWorkflowService.setResetEstatus(true);


      swal.fire({
        title: this.i18n.transform('Éxito'),
        html: this.i18n.transform('@i18n-text-information-deleted')
      });
      this.updateList('account$related_person_shareholder$post');
      this.akeelaWorkflowService.setResetEstatus(true);

    }, error => {
      // dfutrille esto debe borrarse.
      this.updateList('account$related_person_shareholder$post');
    });
  }

  addRelated(shareholder) {
    console.log(shareholder);
    this.getItemList(this.idPerson);
    // const identifier = 147; // CABLE SOLO DE PREUUEBA
    const identifier = shareholder.person ? shareholder.person.identifier : shareholder.organization.identifier;
    this.localStorage.setItem('idShareholderFather', identifier);
    this.localStorage.setItem('typeShareholder', 'RELATED_SHAREHOLDER');





    this.akeelaSectionService.addModalSection({ name: 'SHAREHOLDERS_MODAL' });
    this.akeelaSectionService.showModalSection('SHAREHOLDERS_MODAL', true);
    this.akeelaSectionService.setModalFormValue('SHAREHOLDERS_MODAL', []);

    // siempre se asume que se agrega una PN
    this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_LEGAL_FIELDS', 'hidden': true });
    this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_NATURAL_FIELDS', 'hidden': false });
    this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_PEP', 'hidden': false });

  }




  updateList(action: string) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }

  getShareholderByIdentifier(id) {
    return this.shareholders.find((sh) => {
      const identifier = sh.applicantType === 'NATURAL' ? sh.person.identifier : sh.organization.identifier;
      return identifier === id;
    });
  }

  getNameShareholder(id) {
    const shareholder = this.getShareholderByIdentifier(id);
    if (!isNullOrUndefined(shareholder)) {
      return shareholder.applicantType === 'NATURAL' ? (this.capitalize(shareholder.person.givenName) + ' ' + this.capitalize(shareholder.person.familyName)) : this.capitalize(shareholder.organization.legalName);
    }
  }






  setHeadTable() {

    const base: Column[] = [
      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '100',
        html: ' Nombre',
        cssCustom: '',
        enableRightBorder: true

      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Tipo',
        cssCustom: '',

      }
      ,
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Participación',
        cssCustom: ''
      },
      {
        alignContent: 'center',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      },
      {
        alignContent: 'left',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      }
    ]
    return base;
  }

  setContentTable(configuration, itemList): Row[] {
    const content: Row[] = [];
    let i = 0;
    for (const row of itemList) {
      i++;

      let temp = '-';
      if (row.applicantType == 'NATURAL') {

        temp = '@i18n-quote-option-list-applicantType-option-natural';

      } else if (row.applicantType == 'LEGAL') {
        temp = '@i18n-quote-option-list-applicantType-option-legal';


      }
      content.push(
        {
          identifier: row.person ? row.person.identifier : row.organization.identifier,
          cssCustom: '',
          enabled: true,
          columns: [
            this.setRowContentTable({

              configuration,
              number: 0,
              html: (row.person) ? this.capitalize(row.person.givenName) + " " + this.capitalize(row.person.familyName) : this.capitalize(row.organization.legalName),
              cssCustom: ' text-capitalize'

            }),

            this.setRowContentTable({
              configuration,
              number: 1,
              html: this.i18n.transform(temp),
            }),

            this.setRowContentTable({
              configuration,
              number: 2,
              html: row.person ? (row.person.sharePercentage + "%") : (row.organization.sharePercentage + "%"),
            }),

            this.setRowContentTable({

              configuration,
              number: 3,
              html: 'Ver',
              cssCustom: 'ak-tabled-row-active text-capitalize'

            }),

            this.setRowContentTable({
              configuration,
              number: 4,
              html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
              click: 'delete_item',
            })
          ]
        }
      );
    }

    return content;
  }



  setRowContentTable(row) {

    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click ? row.click : null;
    let dropdown = row.dropdown ? row.dropdown : null;
    let cssCustom = row.cssCustom ? row.cssCustom : configuration[number].cssCustom;



    let result = {

      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click: click,
      dropdown: dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }

    return result;

  }



}
