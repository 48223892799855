// import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// MODULES...
import { AkeelaPropertiesModule } from '@akeela/properties';
import { AkeelaAuthModule as AuthModule } from '@akeela/auth';
import { ProductsPlansModule } from '@akeela/products-plans';
import { AlertModule as AkeelaAlertModule } from '@akeela/alert';
import { AkeelaBreadcrumbModule, AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { AkeelaRequirementsModule } from '@akeela/requirements';
import { FacialRecognitionModule } from '@akeela/facial-recognition';


import { Routes, RouterModule } from '@angular/router';
import { I18nModule, I18nService, I18nPipe } from '@akeela/i18n';
import { CommonModule, APP_BASE_HREF, DatePipe } from '@angular/common';
import { AkeelaCommonModule, I18nService as I18nServiceAkeela } from '@akeela/common';
import { AkeelaWorkflowModule, AkeelaStepService, AkeelaDatamodelService } from '@akeela/workflow';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup


// SERVICES...
import { SidebarService } from '../services/sidebar/sidebar.service';
import { PropertiesService } from '../services/properties/properties.service';
import { TransitionService } from '../services/transition.service';
import { TemplateService } from '../services/template.service';
import { EmailService } from '../services/email.service';
import { Favicons, BrowserFavicons, BROWSER_FAVICONS_CONFIG } from '../services/favicons.service';
import { WorkflowService } from '../services/workflow.service';
import { MiddlewareService } from '../services/middleware.service';

import { AkeelaMenuModule, AkeelaSidebarMenuService } from '@akeela/menu';


// COMPONENTS...
import { HeaderComponent } from '../components/header/header.component';
import {
    LogoImgComponent, SubHeaderComponent, StageComponent, StepComponent, FooterComponent, NavbarComponent, NavbarImgComponent, NavbarauthComponent
} from '../components/index';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";


// OTROS...
import { UcwordsPipe } from '../helpers/pipes/ucwords.pipe';
import { environment } from 'src/environments/environment';
import { SubheaderService } from '../services/subheader.service';
import { TemplateComponent } from '../components/template/template.component';
import { ContentDirective } from '../directives/content.directive';
import { SafePipe } from '../helpers/pipes/safe.pipe';

import { AuthGuard } from '../guards/auth.guard';
import { ValidateSession } from '../guards/validatesession.guard';
import { Utf8RegexService } from '../services/utf8-regex.service';
import { LocalStorageModule } from '@akeela/local-storage';
import { ProgressComponent } from '../components/progress/progress.component';
import { AkeelaContentDirective } from '../helpers/directives/content.directive';

import { ResponsesComponent } from '../components/responses/responses.component';
import { MenuModuleComponent } from '../components/menu-module/menu-module.component';
import { BoxComponent } from '../components/menu-module/box/box.component';
import { GraphModule } from '@akeela/graph';
import { BoxComponent as ResumenBoxComponent } from '../modules/private/components/resumen/box/box.component';
import { AkTableModule } from '@akeela/ak-table';
import { NotesService } from '../services/notes.service';





import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgxFaceApiJsModule } from '@akeela/ngx-face-api-js';

import { NgxDocViewerModule } from 'ngx-doc-viewer2';
import { ImageViewerModule } from 'ngx-image-viewer';

import { CurrencyMaskModule } from 'ng2-currency-mask';




@NgModule({
    imports: [
        FacialRecognitionModule.forRoot({
            modelsUrl: './assets/public/models/weights'
        }),
        NgxFaceApiJsModule.forRoot({
            modelsUrl: './assets/public/models/weights'
        }),
        NgIdleKeepaliveModule.forRoot(),
        CommonModule,
        I18nModule,
        RouterModule,
        AkeelaPropertiesModule.config(environment.appPropertiesConfig),
        AkeelaWorkflowModule.config(environment.apiConfig),
        AkeelaCommonModule,
        FormsModule,
        ReactiveFormsModule,
        AuthModule.config(environment.auth),
        ProductsPlansModule,
        AkeelaAlertModule,
        AkeelaRequirementsModule,
        AkeelaBreadcrumbModule,
        NgbModule,
        LocalStorageModule.config(environment.localStorageConfig),
        SweetAlert2Module.forRoot(),
        GraphModule,
        AkTableModule,
        NgxDocViewerModule,
        ImageViewerModule.forRoot(),
        CurrencyMaskModule

    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        NavbarComponent,
        NavbarImgComponent,
        LogoImgComponent,
        NavbarauthComponent,
        StageComponent,
        StepComponent,
        SubHeaderComponent,
        UcwordsPipe,
        SafePipe,
        TemplateComponent,
        ContentDirective,
        ProgressComponent,
        AkeelaContentDirective,
        MenuModuleComponent,
        BoxComponent,
        ResumenBoxComponent,
        ResponsesComponent,

    ],
    providers: [
        NotesService,
        DatePipe,
        EmailService,
        TemplateService,
        SubheaderService,
        TransitionService,
        SidebarService,
        PropertiesService,
        I18nServiceAkeela,
        AkeelaStepService,
        AkeelaDatamodelService,
        AkeelaSidebarMenuService,
        AkeelaBreadcrumbService,
        AuthGuard,
        ValidateSession,
        MiddlewareService,
        // The Favicons is an abstract class that represents the dependency-injection
        // token and the API contract. THe BrowserFavicon is the browser-oriented
        // implementation of the service.
        {
            provide: Favicons,
            useClass: BrowserFavicons
        },
        // The BROWSER_FAVICONS_CONFIG sets up the favicon definitions for the browser-
        // based implementation. This way, the rest of the application only needs to know
        // the identifiers (ie, 'happy', 'default') - it doesn't need to know the paths
        // or the types. This allows the favicons to be modified independently without
        // coupling too tightly to the rest of the code.
        {
            provide: BROWSER_FAVICONS_CONFIG,
            useValue: {
                icons: {
                    'MIBANCO': {
                        type: 'image/x-icon',
                        href: './assets/mibanco/public/images/favicon.ico'
                    },
                    'FACEBANK': {
                        type: 'image/x-icon',
                        href: './assets/facebank/public/images/favicon.ico'
                    },
                    'AKEELA': {
                        type: 'image/x-icon',
                        href: './assets/public/images/favicon.ico',
                        isDefault: true
                    },
                    'DEMO1': {
                        type: 'image/x-icon',
                        href: './assets/demo1/public/images/favicon.ico',
                        isDefault: true
                    },
                    'DEMO2': {
                        type: 'image/x-icon',
                        href: './assets/demo2/public/images/favicon.ico',
                        isDefault: true
                    },
                    'CARONI': {
                        type: 'image/x-icon',
                        href: './assets/caroni/public/images/favicon.ico',
                        isDefault: true
                    },
                    'RENDIVALORES': {
                        type: 'image/x-icon',
                        href: './assets/rendivalores/public/images/favicon.png',
                        isDefault: true
                    },
                    'MC': {
                        type: 'image/x-icon',
                        href: './assets/mc/public/images/favicon.ico',
                        isDefault: true
                    },
                    'KOI': {
                        type: 'image/x-icon',
                        href: './assets/koi/public/images/favicon.ico',
                        isDefault: true
                    },
                    'PERCAPITA': {
                        type: 'image/x-icon',
                        href: './assets/percapita/public/images/favicon.ico',
                        isDefault: true
                    }
                },

                // I determine whether or not a random token is auto-appended to the HREF
                // values whenever an icon is injected into the document.
                cacheBusting: true
            }
        },
        WorkflowService,
        Utf8RegexService
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        UcwordsPipe,
        SafePipe,
        CommonModule,
        HeaderComponent,
        FooterComponent,
        NavbarComponent,
        NavbarImgComponent,
        LogoImgComponent,
        NavbarauthComponent,
        AkeelaWorkflowModule,
        StageComponent,
        StepComponent,
        SubHeaderComponent,
        AuthModule,
        ProductsPlansModule,
        TemplateComponent,
        ContentDirective,
        AkeelaAlertModule,
        AkeelaMenuModule,
        AkeelaBreadcrumbModule,
        AkeelaCommonModule,
        NgIdleKeepaliveModule,
        AkeelaRequirementsModule,
        NgbModule,
        ProgressComponent,
        AkeelaContentDirective,
        MenuModuleComponent,
        BoxComponent,
        SweetAlert2Module,
        GraphModule,
        ResumenBoxComponent,
        AkTableModule,
        ResponsesComponent,
        NgxDocViewerModule,
        ImageViewerModule,
        FacialRecognitionModule,
        CurrencyMaskModule
    ],
    entryComponents: []
})
export class SharedModule { }

