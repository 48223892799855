import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UploadService, ErrorService } from '@akeela/common';
import { AuthService } from '@akeela/auth';
import { HttpService } from '@akeela/properties';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertiesService } from '../../../../services/properties/properties.service';
import { take } from 'rxjs/operators';

import { WorkflowService } from '../../../../services/workflow.service';
import { TransitionService } from '../../../../services/transition.service';
import { I18nPipe } from '@akeela/i18n';
import swal from 'sweetalert2';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService, RouteUtilsService } from '@akeela/workflow';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
import { LocalService } from '@akeela/local-storage';
import { RequirementsService } from '@akeela/requirements';



@Component({
  selector: 'app-private-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.css']
})
export class RequirementsComponent implements OnInit, OnDestroy {
  @Input() isActiveResume?= true;
  @Input() viewOptional?;

  @Input() set dataInformation(dataInformation) {
    console.log(dataInformation);
    this._dataInformation = dataInformation;
  } 

  _dataInformation = null;
  config_requirements: any = null;
  balance_requirements = null;

  transactionData;
  idTransaction;
  transactionNumber;
  audience;
  idPerson;
  idPersonMarketType;
  user;
  submit_disabled;
  activeRequirements = false;

  workflow;
  stage;
  step;

  queryParams;

  mb_max_filesize: any = 1;

  role;
  filter_selected = '';
  filter_category_selected = '';
  categories;

  extensions;
  env: any;
  folder;

  isBeforeTransactionIncomplete = false;
  paramsSubscription: Subscription;
  start_requirements = false;



  constructor(
    private _upload: UploadService,
    public _auth: AuthService,
    private _httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private _wfService: WorkflowService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private _error: ErrorService,
    private _transition: TransitionService,
    private i18n: I18nPipe,
    private _properties: PropertiesService,
    private localStorage: LocalService,
    private routerUtilService: RouteUtilsService,
    private _requirementsService: RequirementsService

  ) { }

  async ngOnInit() {
    this.env = environment;
    this.audience = await this.routerUtilService.getParamAsync(this.route, 'audience');

    this.folder = '';

    if (environment.theme.images === 'FACEBANK') {

      this.folder = 'facebank/';

    } else if (environment.theme.images === 'MIBANCO') {

      this.folder = 'mibanco/';

    }

    // this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
    //   this.queryParams = queryParams;
    // });
    await this.route.queryParams.pipe(take(1)).toPromise().then(res => {
      this.queryParams = res;
    });

    this.role = await this.routerUtilService.getParamAsync(this.route, 'role');

    // this.role = this.routerUtilService.getRole(queryParams);
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    this.transactionNumber = this.localStorage.getItem('transactionNumber');
    this.validateIfBeforeTransactionIsIncompleted();

    this._properties.getProperty({
      endpointKey: 'app_properties$getProperty$get',
      propertyKey: 'akarchive.valid.extention'
    }).subscribe(resp => {
      this.extensions = resp;
    });
    this.startConfigurationRequirements();
    

  }

  openDocument(documentName) {
    this.getPreview();
  }

  getPreview() {
    this._properties.getProperty({
      endpointKey: 'app_properties$getProperty$get',
      propertyKey: 'app.display.template'
    }).subscribe(resp => {
      if (resp === true || resp === 'true') { // Mario: se condiciona para mostrar o no el preview
        const params = {
          name: 'account_opening$preview-document$get',
          params: {
            request: {
              role: this.role,
              audience: this.audience
            },
            body: {},
            path: {
              transactionNumber: this.transactionNumber
            }
          }
        };
        return this._httpService.executeRequest(params.name, params.params, true).subscribe((resp) => {
          this.show_filePreview(resp[0]);
        }, error => {
         
        });
      } 
    }, (error) => {
      
    });

  }


  show_filePreview(data) {
    this.show_file(data);

  }

  startConfigurationRequirements = async () => {
    let transaction: any = await this.getAccountproductinformation();
    if (transaction) {
      this.categories = await this.getRequirementsCategory(transaction.plan.identifier, transaction.holder.additionalType, true).toPromise();
      
      if (transaction.accountStatus[0].additionalType === 'PI_IN_PROCESS') {
        this.getHistoryStatusTransaction().then((history: any) => {
          this._properties.getProperty({
            endpointKey: 'app_properties$getProperty$get',
            propertyKey: 'app.max_upload_size'
          }).subscribe(resp => {
            if (resp === 'UNLIMITED') {
              this.mb_max_filesize = null;
            } else {
              this.mb_max_filesize = (!isNullOrUndefined(resp) ? resp : (!isNullOrUndefined(environment.requirements.file.mb_max_filesize) ? environment.requirements.file.mb_max_filesize : 1));
            }
            this.setConfigurationRequirements();
            if (history.length >= 2) {
              if (history[1].name === 'AC_INCOMPLETE') {
                this.setConditions('8');
              } else {
                this.setConditions();
              }
            } else {
              this.setConditions();
            }
            this.activeRequirements = true;
          }, error => {
            this.mb_max_filesize = (!isNullOrUndefined(environment.requirements.file.mb_max_filesize) ? environment.requirements.file.mb_max_filesize : 1);
            this.setConfigurationRequirements();
            if (history.length >= 2) {
              if (history[1].name === 'AC_INCOMPLETE') {
                this.setConditions('8');
              } else {
                this.setConditions();
              }
            } else {
              this.setConditions();
            }
            this.activeRequirements = true;
          });
        });
      } else {
        this._properties.getProperty({
          endpointKey: 'app_properties$getProperty$get',
          propertyKey: 'app.max_upload_size'
        }).subscribe(resp => {
          if (resp === 'UNLIMITED') {
            this.mb_max_filesize = null;
          } else {
            this.mb_max_filesize = (!isNullOrUndefined(resp) ? resp : (!isNullOrUndefined(environment.requirements.file.mb_max_filesize) ? environment.requirements.file.mb_max_filesize : 1));
          }
          // this.mb_max_filesize = (!isNullOrUndefined(resp) ? resp : (!isNullOrUndefined(environment.requirements.file.mb_max_filesize) ? environment.requirements.file.mb_max_filesize : 1));

          this.setConfigurationRequirements();
          this.setConditions();
          this.activeRequirements = true;
        }, error => {
          this.mb_max_filesize = (!isNullOrUndefined(environment.requirements.file.mb_max_filesize) ? environment.requirements.file.mb_max_filesize : 1);
          this.setConfigurationRequirements();
          this.setConditions();
          this.activeRequirements = true;
        });

      }
    }
  }

  getHistoryStatusTransaction = async () => {
    const params = {
      name: 'accounts$accountstates$get',
      params: {
        request: {},
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };

    return await this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  setConditions = async (status?)  => {
    
    if (!isNullOrUndefined(this.env.requirements.isFilterRequirementsByCategory)) { // se condiciona este caso, debido a un requerimiento de FACEBANK
      this.config_requirements.isFilterRequirementsByCategory = this.env.requirements.isFilterRequirementsByCategory;
    }

    let status_filter = null;
    if (status) {
      status_filter = status;
    } else {
      status_filter = this.transactionData.accountStatus[0].identifier;
    }

    if (status_filter === '8' || await this.validateIfBeforeTransactionIsIncompleted()) { // Si el trámite está en estatus INCOMPLETO
      this.config_requirements.isDigitalUploadActive = false;

      if (!isNullOrUndefined(this.env.requirements.isFilterRequirementsByResponse)) { // se condiciona este caso, debido a un requerimiento de FACEBANK
        this.config_requirements.isFilterRequirementsByResponse = this.env.requirements.isFilterRequirementsByResponse;
      } else {
        this.config_requirements.isFilterRequirementsByResponse = true;
      }
      

      this.config_requirements.isShowStatusResponse = true;
      if (this.isBeforeTransactionIncomplete) {
        this.config_requirements.isReadonly = false;
      } else {
        this.config_requirements.isReadonly = true;

      }
      this.config_requirements.isActiveVerifyChangeTransaction = true;
      // condición de inhabilitar el trámite si lo posee tomado otro usuario
      if (!this.localStorage.getItem('transactionModeEdit') && (this.localStorage.getItem('transactionModeEdit') === this.localStorage.getItem('transactionNumber') || isNullOrUndefined(this.localStorage.getItem('transactionModeEdit')))) {
        this.config_requirements.isReadonly = true;
      }
      this.config_requirements.enableEspecialUpload = {
        active: true,
        status: ['INCOMPLETED', 'REJECTED']
      };
      this.config_requirements.isEnableLogicDelete = true;
    } else if (status_filter === '9') { // Se el trámite fue rechazado
      if (!isNullOrUndefined(this.env.requirements.isFilterRequirementsByResponse)) { // se condiciona este caso, debido a un requerimiento de FACEBANK
        this.config_requirements.isFilterRequirementsByResponse = this.env.requirements.isFilterRequirementsByResponse;
      } else {
        this.config_requirements.isFilterRequirementsByResponse = true;
      }
      this.config_requirements.isReadonly = true;
    } else if (status_filter === '7') { // Se el trámite fue aprobado
      if (!isNullOrUndefined(this.env.requirements.isFilterRequirementsByResponse)) { // se condiciona este caso, debido a un requerimiento de FACEBANK
        this.config_requirements.isFilterRequirementsByResponse = this.env.requirements.isFilterRequirementsByResponse;
      } else {
        this.config_requirements.isFilterRequirementsByResponse = true;
      }
      this.config_requirements.isReadonly = true;
    } else if (status_filter === '6') { // Se el trámite está en proceso de análisis
      this.config_requirements.isReadonly = true;
      this.config_requirements.isHistoryResponsesShow = false;
      this.config_requirements.isEnabledIconColors = false; // los iconos indicadores de respuesta, no se mostrarán hasta que el trámite pase a un estatus de no evaluación
    } else if (status_filter === '3' || status_filter === '4' || status_filter === '5') { // Si el trámite está en proceso de carga por el cliente
      this.config_requirements.isFilterRequirementsByResponse = false;
      this.config_requirements.isReadonly = false;
      this.config_requirements.isActiveProgressBarUpload = true;

      // MARIO: PRUEBA
      // condición de inhabilitar el trámite si lo posee tomado otro usuario
      // if (!this.localStorage.getItem('transactionModeEdit') && (this.localStorage.getItem('transactionModeEdit') === this.localStorage.getItem('transactionNumber') || isNullOrUndefined(this.localStorage.getItem('transactionModeEdit')))) {
      //   this.config_requirements.isReadonly = true;
      //   this.config_requirements.isActiveProgressBarUpload = false;
      // }
      if (!this.localStorage.getItem('transactionModeEdit') && (this.localStorage.getItem('transactionModeEdit') === this.localStorage.getItem('transactionNumber') || isNullOrUndefined(this.localStorage.getItem('transactionModeEdit')))) {
        this.config_requirements.isReadonly = true;
        this.config_requirements.isActiveProgressBarUpload = false;
      }
    }

    this.config_requirements.isActiveProgressBarUpload = true;

  }

  validateIfBeforeTransactionIsIncompleted = async () => {
    const history: any = await this.getHistoryStatusTransaction();
    let response = false;
    if (history.length >= 2) {
      if (history[0].name === 'PI_IN_PROCESS' && history[1].name === 'AC_INCOMPLETE') {
        response = true;
      }
    }
    this.isBeforeTransactionIncomplete = response;
    return response;
  }



  setConfigurationRequirements() {
    this.start_requirements = false;
    let getRequirementRequest = null;
    if(!isNullOrUndefined(this._dataInformation?.typeOfCompany) && this._auth.getUserAudience()==='LEGAL') {
      getRequirementRequest = {
        audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL',
        withRequirements: true,
        others: false,
        typeOfPerson: this._dataInformation?.typeOfCompany?this._dataInformation?.typeOfCompany:null
      };
    } else {
      getRequirementRequest = {
        audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL',
        withRequirements: true
      };
    }
    setTimeout(()=>{
      let preview = true;
      if(!isNullOrUndefined(environment?.requirements['preview'])) {
        preview = environment?.requirements['preview'];
      }
      this.config_requirements = {
        preview,
        downloadAllUploadFilesLink: true,
        downloadAllUploadFilesByRequirementLink: true,
        showHeaderContent: true,
        showFooterContent: false,
        showDetailHeaderContent: true,
        showDetailFooterContent: false,
        isReadonly: false,
        isPhysicalCheckActive: true,
        isDigitalUploadActive: true,
        isCameraActive: false,
        isResponseActive: false,
        isHistoryResponsesShow: true,
        isFilterRequirementsByResponse: false,
        isFilterRequirementsByCategory: true,
        isShowStatusResponse: true,
        isInputDateExpiredActive: true,
        isActiveProgressBarUpload: false,
        isActiveVerifyChangeTransaction: true,
        idCurrentTransaction: this.transactionData.identifier,
        isEnabledIconColors: true,
        isDetailEnable: false,
        isModeUserActive: false,
        internalComment: false,
        isEnableConsiderationsByCategory: true,
        isEnableConsiderationsByRequirements: true,
        isEnableFilterInconsistent: false,
        isEnableLogicDelete: false,
        enableEspecialUpload: {
          active: false,
          status: null
        },
        viewName: 'UPLOAD_FILES_EXAMPLE',
        filterRequirementsByResponse: {
          state: this.filter_selected
        },
        filterRequirementsByCategory: {
          name: this.filter_category_selected
        },
        statusResponseRequirement: {
          verified: 'VERIFIED',
          inconsistent: 'INCONSISTENT',
          incomplete: 'INCOMPLETED',
          rejected: 'REJECTED'
        },
        transactionData: {
          transactionNumber: this.transactionData.transactionNumber,
          identifier: this.transactionData.identifier,
          idPlan: this.transactionData.plan.identifier,
          audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
        },
        file: {
          file_type_accepts: this._requirementsService.getPermitedExtensions(this.extensions),
          file_type_accepts_description: this._requirementsService.getPermitedExtensionsDescription(this.extensions),
          mb_max_filesize: this.mb_max_filesize
        },
        lists: {
          responsesRequirements: [
            {
              identifier: 'VERIFIED',
              name: 'VERIFIED',
              requireObservation: false,
              isFinalResponse: true
            },
            {
              identifier: 'INCOMPLETED',
              name: 'INCOMPLETED',
              requireObservation: true,
              isFinalResponse: true
            },
            {
              identifier: 'REJECTED',
              name: 'REJECTED',
              requireObservation: true,
              isFinalResponse: true
            },
            {
              identifier: 'INCONSISTENT',
              name: 'INCONSISTENT',
              requireObservation: true,
              isFinalResponse: false
            }]
        },
        endpoints: {
          downloadRequirementFilesZip: {
            name: 'requirements$zipfile_requirement$get',
            params: {
              request: {
                systemUser: this._auth.getUsername(),
                idTransactionRequirement: null
              },
              path: {
                idTransaction: this.transactionData.identifier,
                idPerson: this.transactionData.holder.identifier
              },
              body: {}
            }
          },
          downloadAllFilesZip: {
            name: 'requirements$zipfile_transaction$get',
            params: {
              request: {
                systemUser: this._auth.getUsername()
              },
              path: {
                idTransaction: this.transactionData.identifier,
                idPerson: this.transactionData.holder.identifier
              },
              body: {}
            }
          },
          getRequirements: {
            identifier: '',
            name: 'requirements$transaction_requirements$get',
            params: {
              request: getRequirementRequest,
              path: {
                idPlan: this.transactionData.plan.identifier,
                transactionNumber: this.transactionData.transactionNumber,
                idPerson: this.transactionData.holder.identifier
              },
              body: {}
            }
          },
          getRequirement: {
            identifier: '',
            name: 'requirements$transaction_requirement$get',
            params: {
              request: {},
              path: {
                idTransaction: this.transactionData.identifier,
                idRequirement: null,
                idPerson: this.transactionData.holder.identifier
              },
              body: {}
            }
          },
          getStatusResponseRequirements: {
            name: 'credit$credit_status_responses$get',
            params: {
              request: {},
              body: {},
              path: {}
            }
          },
          getCategoriesByPlan: {
            name: 'requirements$category$get',
            params: {
              request: {
                audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL',
                
              },
              body: {},
              path: {
                idPlan: this.transactionData.plan.identifier,
              },
            }
          },
          getPlansByProduct: {
            name: 'product_plan$plans$get',
            params: {
              path: {},
              request: {
                identifierProduct: this.transactionData.plan.financialProduct.identifier
              },
              body: {}
            }
          },
          getHistoricRequirement: {
            name: 'requirements$historic$get',
            params: {
              path: {
                idTransaction: this.transactionData.identifier,
                idRequirement: null,
                idPerson: this.transactionData.holder.identifier
              },
              request: {
                getLast: true
              },
              body: {}
            }
          },
          getHistoryResponseRequirement: {
            name: 'requirements$transaction_requirements_status$get',
            params: {
              request: {},
              body: {},
              path: {
                idRequirement: null,
                idTransaction: this.transactionData.identifier,
                idPerson: this.transactionData.holder.identifier
              }
            }
          },
          saveResponseRequirement: {
            name: 'transaction$req_response$post"',
            params: {
              request: {},
              body: {
                identifier: null,
                response: null,
                responseDescription: null,
                modifyUser: this._auth.getUsername()
              },
              path: {}
            }
          },
          saveLogicArchiveDelete: {
            name: 'requirements$logical_delete$put',
            params: {
              request: {},
              body: {},
              path: {
                transactionNumber: this.transactionData.transactionNumber,
                idPerson: this.transactionData.holder.identifier,
                idRequirement: null,
                idArchive: null,
                stage: 'DOC_SUPPORT'
              }
            }
          },
          saveRequirement: {
            params: {
              request: {},
              path: {},
              body: {
                'transaction': {
                  'identifier': this.transactionData.identifier,
                  'name': this.transactionData.transactionNumber
                },
                'requirement': {
                  'identifier': null
                },
                'requirementArchives': [
                  {
                    'creator': {
                      'identifier': this._auth.getUsername()
                    }
                  }
                ],
                'person': {
                  'identifier': this.transactionData.holder.identifier,
                  'identity': this.transactionData.holder.alternateName,
                }
  
              }
            }
          },
          deleteArchive: {
            name: 'archive$archive$delete',
            params: {
              request: {},
              path: {},
              body: {
                identifier: null
              }
            }
          },
          deleteRequirement: {
            name: 'requirements$requirement$delete',
            params: {
              request: {},
              path: {},
              body: {
                alternateName: 'ACCREDITED',
                transaction: {
                  'identifier': this.transactionData.identifier,
                  'name': this.transactionData.transactionNumber
                },
                requirement: {
                  'identifier': null
                },
                person: {
                  'identifier': this.transactionData.holder.identifier,
                },
                requirementArchives: [{
                  identifier: null
                }]
              }
            }
          },
          saveRequirementPhysical: {
            name: 'requirements$consigned_requirements$put',
            format: {
              identifier: null,
              transaction: {
                identifier: this.transactionData.identifier
              },
              requirement: {
                identifier: null
              },
              person: {
                identifier: this.transactionData.holder.identifier
              },
              consigned: false,
              consignedUsername: this._auth.getUsername()
            },
            params: {
              request: {},
              path: {},
              body: {
                transactionRequirements: null
              }
            }
          },
          updateDate: {
            name: 'requirements$emission_expiration_dates$put',
            dateFormat: 'dd-mm-yyyy',
            params: {
              request: {},
              path: {},
              body: {
                'transaction': {
                  'identifier': this.transactionData.identifier
                },
                'person': {
                  'identifier': this.transactionData.holder.identifier
                },
                'requirement': {
                  'identifier': null
                },
                'requirementArchives': null
              }
            }
  
          }
        }
      };
      this.start_requirements = true;
    }, 500);
    
    
  }

  response_transaction_change(newidentifier) {
    //this.transactionNumber = newidentifier;
    //this.activeRequirements = false;
    //this._wfService.showContainer$.next(true);
    this.startConfigurationRequirements();
  }


  getAccountproductinformation() {
    return new Promise((resolve, reject) => {
      this.transactionData = null;
      const params = {
        name: 'account$accountproductinformation$get',
        params: {
          request: {
            role: this.role
          },
          path: {
            transactionNumber: this.transactionNumber
          },
          body: {}
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        this.transactionData = response;
        resolve(response);
      }, error => {
        reject(false);
      });
    });
  }


  show_file(file) {
    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: null
      }
    };

    if (file.dateCreated) {
      archive['dateCreated']['orderDate'] = file.dateCreated.modifiedDate;
    } else {
      archive['dateCreated']['orderDate'] = null;
    }

    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor_params.deleteAfterShow = '';
      if (file['action'] === 'download') {
        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } if (file['action'] === 'downloadzip') {

        if (this.env.theme.images === 'FACEBANK') {
          this._upload.visor_params.endpoint = 'archive$downloadZipFile$get';
        }

        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } else {
        this._upload.visor_params.action = '';
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      }
    }
  }

  // show_file(file) {
  //   const archive = {
  //     identifier: file.identifier,
  //     name: file.name,
  //     dateCreated: {
  //       orderDate: null
  //     }
  //   };

  //   if (file.dateCreated) {
  //     archive['dateCreated']['orderDate'] = file.dateCreated.modifiedDate;
  //   } else {
  //     archive['dateCreated']['orderDate'] = null;
  //   }
  //   if (archive.identifier !== null) {
  //     this._upload.visor_params.name = '';
  //     this._upload.visor_params.date = '';
  //     this._upload.visor_params.endpoint = 'archive$downloadFile$get';
  //     this._upload.visor_params.archive = archive;
  //     this._upload.visor = true;
  //   }
  // }

  response_requirements(response) {
    this.balance_requirements = response;
    this.akWorkflowService.worflowEvents$.next({
      response: 'UPDTATE REQUIREMENT',
      action: null,
      forms: null
    });
  }

  finish_upload(response) {
    this.akWorkflowService.worflowEvents$.next({
      response: 'UPDATE_REQUIREMENT_FINISH',
      action: null,
      forms: null
    });
  }

  round(value) {
    return Math.round(value);
  }



  getNameHolder() {
    return this.transactionData.holder.name;
  }

  getInitialsHolder() {
    return this.transactionData.holder.name.charAt(0).toUpperCase();
  }

  goToPaperwork() {
    this.router.navigateByUrl(
      this.router.createUrlTree(['private/' + environment.workflow + '/stages/LIST_ACCOUNT_OPENING/steps/LIST_ACCOUNT_OPENING'],
        {
          queryParams: {}
        }
      )
    );
  }

  openWindowRequirement(open) {
    this._wfService.showContainer$.next(!open);
  }

  continuar() {
    this.router.navigateByUrl(
      this.router.createUrlTree(['private/' + environment.workflow + '/stages/ACCOUNT_CONTRACT/steps/ACCOUNT_CONTRACT'],
        {
          queryParams: this.queryParams
        }
      )
    );
  }

  send() {
    const params = {
      name: 'transaction$analysisdocsupports$put',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
        },
        path: {
          transactionNumber: this.transactionNumber,
          workflow: this.workflow,
          stage: this.stage,
          step: this.step,
          event: 'SEND_TRANSACTION_ANALYSIS_DOC_SUPPORT'
        },
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this._transition.nextStep('private', response.nextStep);
    }, (error: any) => {
      this._error.show(error);
    });

  }

  response_transaction(transaction) {
    this._transition.nextStep('private', transaction.nextStep);
  }

  detailClient() {
    swal.fire({
      title: '',
      html: '<b>#' + this.transactionData.transactionNumber + '</b><br>' + this.getNameHolder()
    }).then((result) => {
      // if (!isNullOrUndefined(result.value) && result.value) {
      //   this.deleteItem(row);
      // }
    });
  }

  ngOnDestroy() {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  showDetail() {
    this.config_requirements.isDetailEnable = !this.config_requirements.isDetailEnable;
  }

  filter_category(event) {
    if (this.filter_category_selected !== event.target.value) {
      this.activeRequirements = false;
      this.filter_category_selected = event.target.value;
      setTimeout(() => {
        if (event.target.value !== '') {
          this.config_requirements.filterRequirementsByCategory = {
            name: event.target.value
          };
        } else {
          this.config_requirements.filterRequirementsByCategory = {
            name: ''
          };
        }
        this.activeRequirements = true;
      }, 30);
    }
  }

  filter(event) {

    if (this.filter_selected !== event.target.value) {
      this.activeRequirements = false;
      this.filter_selected = event.target.value;
      setTimeout(() => {
        if (event.target.value !== '') {
          this.config_requirements.filterRequirementsByResponse = {
            state: event.target.value
          };
        } else {
          this.config_requirements.filterRequirementsByResponse = {
            state: ''
          };
        }
        this.activeRequirements = true;
      }, 30);
    }
  }


  getRequirementsCategory(idPlan, audience, withRequirements?) {
    return new Observable(resolve => {
      let request;
      if (withRequirements) {
        request = {
          'audience': audience,
          'withRequirements': withRequirements
        };
      } else {
        request = {
          'audience': audience
        };

      }

      const params: any = {
        endpoint: {
          name: 'requirements$category$get',
          params: {
            path: {
              'idPlan': idPlan
            },
            request: request,
            body: {}
          }
        }
      };
      this._httpService.executeRequest(params.endpoint.name, params.endpoint.params, true).subscribe((response: any) => {
        resolve.next(response);
        resolve.complete();
      }, (error: any) => {
        resolve.next(null);
        resolve.complete();
      });
    });
  }

}
