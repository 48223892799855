import { Component, OnInit} from '@angular/core';
import { AuthService } from '@akeela/auth';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})

export class LogoutComponent implements OnInit {
 
  constructor(
    private _auth: AuthService,


  ) { }

  ngOnInit() {
      console.log("entro");
      //this._auth.logoutDelete();
    }
  }