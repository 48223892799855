<akeela-requirements-template>

    <ng-template akeelaRequirementsContent componentName="imgLogo">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsDetailHeaderContent">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsDetailFooterContent">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsHeaderContent">
        <!--<div class="w-100" *ngIf="balance_requirements && config_requirements.isActiveProgressBarUpload">

            <div class="row">
                <div class="col-12 col-lg-10" style="font-size: 14px">
                    <akeela-requirements-progress [balanceRequirements]="balance_requirements" [configuration]="config_requirements"></akeela-requirements-progress>
                </div>
            </div>
        </div> -->

    </ng-template>
</akeela-requirements-template>
<ng-container *ngIf="activeRequirements">
    <div class="row">
        <div class="col-12">
            
            <akeela-requirements [viewOptional]="viewOptional" [folder]="folder" [config]="config_requirements" (showFile)="show_file($event)" (openWindowRequirement)="openWindowRequirement($event)" (response)="response_requirements($event)" (finishupload)="finish_upload($event)"
                (responseTransactionChange)="response_transaction_change($event)" (responsetransaction)="response_transaction($event)" *ngIf="config_requirements" (open)="openDocument($event)"></akeela-requirements> <!--start_requirements-->

        </div>
    </div>

   
    <!--
    <ng-container *ngIf="balance_requirements && (!config_requirements.isReadonly  || config_requirements.enableEspecialUpload.active)">
        <ng-container *ngIf="balance_requirements.total_required_requirements_completed >= balance_requirements.total_required_requirements">
            <div class="box  m-b-2 p-b-1 p-t-1">
                <div class="w-100 ">
                    <div class="">
                        <div class="col-12 col-md-4 offset-md-8 text-right">
                            <button type="button" [disabled]="submit_disabled" class="btn btn-block btn-primary btn-lg" (click)="continuar()">
                                <span [innerHTML]="'Continuar' | i18n:lang"></span>&nbsp;
                                <span *ngIf="submit_disabled">
                                <i class="fa fa-refresh fa-spin"></i>
                                </span>
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    -->
</ng-container>

<ng-container *ngIf="!config_requirements">
    <div class="container animate__animated animate__fadeIn ">
        <div>
            <img src="./assets/public/images/loading.gif" alt="">
        </div>
    </div>
</ng-container>