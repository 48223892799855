import { HttpService } from '@akeela/properties';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(
    private _httpService: HttpService
    ) {}


  saveNote = async (body, url = 'https://utilities.akeela.co/note') => {

   const params = {
     pathResponse: {
       url,
       httpMethod: 'POST'
     },
     params: {
       request: {},
       body

     }
   }

   return this._httpService.executeRequestExternalUrl(params).toPromise();
   
 }
}
