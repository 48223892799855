<a class="navbar-brand" [routerLink]="['/']">
    <img class="animate__animated animate__bounce" [ngClass]="{'img-destop':!imgmovil}" [style.height.rem]="height?height:env.theme.sizeImageLogoNavbar" [src]="url_desktop">
    <img *ngIf="!imgmovil" class="img-movil " [style.height.rem]="height?height:env.theme.sizeImageLogoNavbarMovil" [src]="url_movil ">
</a>

<div class=" text-dark">
    <div class="list d-flex bd-highlight justify-content-center text-center  list-flex flex-wrap">
        <div class="bd-highlight  mt-2  w-100 order-1  mb-3 font-weight-light">
            <a href="security/login" class="card-link ">Accede a una solicitud ya en progreso</a>
        </div>
        <div class="bd-highlight  w-100  mt-3 order-2">


            Las solicitudes recibidas después de las 2:00 pm serán analizadas y tendrán <br class="d-none d-md-block"> respuesta al día siguiente. </div>
    </div>
</div>