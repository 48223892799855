import { LocalService } from '@akeela/local-storage';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  showContainer$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  showIdentityVerification$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  showBackDashboard$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  getIdentityVerification$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  getIdentityCompleat$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  listWF$: BehaviorSubject<any> = new BehaviorSubject(null);
  login$: BehaviorSubject<any> = new BehaviorSubject(null);


  constructor(
    public localStorage: LocalService

  ) { }
  
  setLogin(login){
    this.login$.next(login);

  }


  setListWF(listWF){
    this.listWF$.next(listWF);

  }

  setGetIdentityVerification(getIdentityVerification){
    this.getIdentityVerification$.next(getIdentityVerification);

  }


  setGetIdentityCompleat(getIdentityCompleat){
    this.getIdentityCompleat$.next(getIdentityCompleat);

  }
  setShowBackDashboard(showBackDashboard){
    this.showBackDashboard$.next(showBackDashboard);

  }
  
  setshowIdentityVerification(showIdentityVerification) {
    
    let temp =  String(showIdentityVerification).toLowerCase() == "true"? true : false ;
    this.showIdentityVerification$.next(temp);
    this.localStorage.setItem('showIdentityVerification',temp);
  }

  

}
