import { Component, OnInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { Table, Row, Column } from '@akeela/ak-table';
import { AuthService } from '@akeela/auth';
import { LocalService } from '@akeela/local-storage';


@Component({
  selector: 'app-section-pep-pn',
  templateUrl: './section-pep-pn.component.html',
  styleUrls: ['./section-pep-pn.component.css']
})
export class SectionPepPNComponent implements OnInit {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  readonlyMode = false;
  modalFather;
  checkDoNotHaveRelatedPEP = false;
  private requestSubscription: Subscription;
  showLoading = true;
  identitys = [];

  loading_table = false;
  configuration_table: Table;

  private workflowEventsSubscription: Subscription;
  paramsSubscription: Subscription;


  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    public _auth:AuthService,
    private localStorage: LocalService,


  ) { }

  ngOnInit() {


    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
    });

    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();
  

    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });
    this.getIdentity();
    this.getItemList();
    this.getpersonData();

   

    this.workflowEventsSubscription = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
      ;

       if (response && response.action === 'showLoadingPep') {

        let values = response.value? true:false;

        this.setCheckDoNotHaveRelatedPEP(values);

        if (values) {
          this.getItemList();
        }

        this.setShowLoading(false);

      }
      else if (!isNullOrUndefined(response) && (response.action === 'delete_pep')) {

        this.getItemList();


      } else if (!isNullOrUndefined(response) && (response.action === 'briefcase$save-related$post' || response.action === 'update_pep')) {


          this.getItemList();

      }

    })
  }


  getIdentity() {
    const params = {
      name: 'workflow_manager$attributes_options$get',
      params: {
        request: {
          audience: this.queryParams.audience
        },
        path: {
          code: 'typeIdentity'
        },
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, false).subscribe((response: any) => {
      let nameItem = '';
      if (response.length) {
        this.identitys = [];
        for (const value of response) {
          nameItem = value.item.alternateName;
          this.identitys[nameItem] = value.name;

        }


      }});
    
  }

  valueRadio(tmp) {
    if (tmp == 'true' || tmp == true) {
      return true;

    } else if (tmp == 'false' || tmp == false) {
      return false;

    } else {

      return tmp

    }

  }

  setCheckDoNotHaveRelatedPEP(value: boolean) {
    this.checkDoNotHaveRelatedPEP = value;
  }

  haveAtLeastOnePep() {
    const modalFather = this.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather) && !isNullOrUndefined(modalFather.person)) {
      //Esto es cuando la entidad Padre ya esta guardada en BD y se verifican sus PEP asociados en el JSON
      if (isNullOrUndefined(modalFather.person.relatedPeps) || (!isNullOrUndefined(modalFather.person.relatedPeps) && modalFather.person.relatedPeps.length === 0)) {
        return false;
      }
    } else if (isNullOrUndefined(modalFather)) {
      //Esto es cuando la entidad Padre no esta guardada en BD y se verifica si tiene PEP en memoria.
      if (this.akeelaSectionService.countModalChildList('new_pep_legal_representative') === 0) {
        return false;
      }
    }
    return true;
  }

  isTheSameFather(child: any, father: any) {
    return child.person.relatedTo.identifier === father.person.identifier;
  }



  ngOnDestroy() {
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  renderItemList(newList) {
    this.itemList = newList;
    if (this.itemList.length === 0) {
      this.akeelaWorkflowService.setResetEstatus(true);
    }
  }

  


  getpersonData() {
  
        const params = {
          name: 'briefcase$person$get',
          params: {
            request: {
              'id': this.localStorage.getItem('clientIdentifier')
            },
            path: {
            },
            body: {}
          }
        };
           

        this.requestSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        
        ;
          let value = response?.isRelatedToPep?true:false;
          this.setCheckDoNotHaveRelatedPEP(value);

          
        }, error => {
        });
      }
    
  


  getItemList() {
    this.setShowLoading(true);
  
        if (this.requestSubscription) {
          this.requestSubscription.unsubscribe();
        }


        const params = {
          name: 'briefcase$get-relateds$get',
          params: {
            request: {
              personId: this.localStorage.getItem('clientIdentifier'),
              relatedType:'PEP',
              pepType:'RELATIONSHIP'
            },
            path: {
            },
            body: {}
          }
        };
           
        

        this.requestSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
          this.setShowLoading(false);
            this.itemList = response;
            this.setConfigurationTable();

          
        }, error => {
          this.setShowLoading(false);
        });
      }
    
  




  setShowLoading(value: boolean) {
    this.showLoading = value;
  }

  openItem(row) {

    this.akeelaSectionService.addModalSection({ name: 'RELATED_PEP_PN_MODAL' });
    this.akeelaSectionService.showModalSection('RELATED_PEP_PN_MODAL');
    this.akeelaSectionService.setModalFormValue('RELATED_PEP_PN_MODAL', row);
  }

  confirmDelete(row: any) {
    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }

  deleteItem(row: any) {
   
      const relatedPersonId = row.person.identifier;
      const configuration = {
        name: 'briefcase$delete-related$delete',
        params: {
          path: {
          },
          request: {
            relatedId: relatedPersonId,
            stepCode: 'PEP_PN',
            role: this.localStorage.getItem('role'),
            audience: this.localStorage.getItem('audience')             
          }
        }
      };
  

      this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
        swal.fire({
          title: this.i18n.transform('Éxito'),
          html: this.i18n.transform('@i18n-text-information-deleted')
        });

        this.updateList('delete_pep');
        this.akeelaWorkflowService.setResetEstatus(true);


      }, error => {

      });
    
  }

  updateList(action: string) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }

  deletePeps() {
    if (!isNullOrUndefined(this.transactionData.person.identifier)) {
      const relatedPersonId = this.transactionData.person.identifier;
      const configuration = {
        name: 'account_opening$related_person_all_pep$delete',
        params: {
          path: {
            relatedPersonId: relatedPersonId,
            transactionNumber: this.transactionNumber,
            workflow: this.workflow,
            stage: this.stage,
            step: this.step
          },
          request: {
            audience: this.queryParams.audience,
            role: this.queryParams.role
          }
        }
      };

      this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
        swal.fire({
          title: this.i18n.transform('Éxito'),
          html: this.i18n.transform('@i18n-text-information-deleted')
        });
        this.updateList('update_pep');
      }, error => {
        console.log("ERROR ELIMINANDO PEP rep-leg:", error);
      });
    }
  }


  sendEventToContinue(response) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {
        fn: response.fn,
        event: event,
        sectionIdentifier: response.sectionIdentifier,
        field: response.field
      },
      action: 'receive_formValidationEvent'
    });
  }






  /**tabla */

  setConfigurationTable() {
    const base: Column[] = this.setHeadTable();
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'class-color-title-table-section',
        rows: [{
          backgroundColor: '#fff',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };

  }

  setHeadTable() {

    const base: Column[] = [
      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '100',
        html: 'Nombre y Apellido',
        cssCustom: '',
        enableRightBorder: true

      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Documento de Identidad',
        cssCustom: '',

      }
      ,
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Número de Documento',
        cssCustom: ''
      },
      {
        alignContent: 'right',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      },
      {
        alignContent: 'right',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      }
    ]
    return base;
  }

  setContentTable(configuration): Row[] {
    const content: Row[] = [];
    let i = 0;
    for (const row of this.itemList) {
      i++;
      content.push(
        {
          identifier: row.person.identifier,
          cssCustom: '',
          enabled: true,
          columns: [
            this.setRowContentTable({

              configuration,
              number: 0,
              html: row.person.givenName + " " + row.person.familyName,
              cssCustom: 'ak-tabled-row-active text-capitalize'

            }),

            this.setRowContentTable({
              configuration,
              
              number: 1,
              html: this.i18n.transform(this.identitys[row.person.additionalType]),
            }),

            this.setRowContentTable({
              configuration,
              number: 2,
              html: row.person.identity,
            }),
            this.setRowContentTable({

              configuration,
              number: 1,
              html: 'Ver',
              cssCustom: 'ak-tabled-row-active text-capitalize'
  
            }),

            this.setRowContentTable({
              configuration,
              number: 3,
              html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
              click: 'delete_item',
            })
          ]
        }
      );
    }
    return content;
  }



  setRowContentTable(row){
    
    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click ? row.click : null;
    let dropdown = row.dropdown ? row.dropdown : null;
    let cssCustom = row.cssCustom ? row.cssCustom : configuration[number].cssCustom;


    let result = {

      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click: click,
      dropdown: dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }

    return result;

  }

  table_response(response) {
    if (response.action === 'open') {

      let row = this.itemList.find((item: any) => {
        return item.person.identifier === response.object.identifier;
      });
      this.openItem(row);

    } else if (response.action === 'action') {
      if (response.object.action === 'delete_item' && !this.readonlyMode) {

        let row = this.itemList.find((item: any) => {
          return item.person.identifier === response.object.obj.identifier;
        });
        this.confirmDelete(row);

      }
    }
  }

}


