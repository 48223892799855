<auth-akeela-template>
    <ng-template auth-akeela-content componentName="login-links-recovery-password">
        <!--<div class=" text-center ">
      <a [routerLink]=" '/register' " class="font-weight-bold link">Crear cuenta de usuario</a>
    </div>
    <br>
    <div class=" text-center font-weight-light ls-o">
      <span class="ls">---------------</span> ó
      <span class="ls">---------------</span>
    </div>
    <br>
    <div class=" text-center font-weight-light">
      <div class="font-weight-light">Tiene código de afiliación ?</div>
      <a [routerLink]=" '/membership' " class="font-weight-bold link">Afíliese</a>
    </div>-->
    </ng-template>
</auth-akeela-template>
<akeela-auth-recovery-password [config]="config" (response)="getResponse($event)"></akeela-auth-recovery-password>