// import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// MODULES...
import { ProductsPlansModule as ProductsModule } from '@akeela/products-plans';
import { SharedModule } from './shared/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutingModule } from './routes/app-routing.module';

import { PublicModule } from './modules/public/public.module';
import { PrivateModule } from './modules/private/private.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkeelaCommonModule, I18nService as I18nServiceAkeela} from '@akeela/common';
import { I18nModule, I18nService, I18nPipe } from '@akeela/i18n';
// SERVICES...

// COMPONENTS
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardFooterComponent } from './components/dashboard/footer/footer.component';


// OTROS...
import { lang as lang_en_default } from './i18n/default/lang/en/en';
import { lang as lang_es_default } from './i18n/default/lang/es/es';

import { lang as lang_en } from './i18n/lang/en/en';
import { lang as lang_es } from './i18n/lang/es/es';

import { SubheaderTemplateComponent } from './components/subheader/subheader.component';
import { environment } from 'src/environments/environment';
import { AkeelaSecurityModule, HttpInterceptorService } from '@akeela/security';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import merge from 'lodash/merge';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ValidatePhoneComponent } from './components/validate-phone/validate-phone.component';
import { ValidateEmailComponent } from './components/validate-email/validate-email.component';
import { SuggestionComponent } from './components/suggestion/suggestion.component';




@NgModule({
  declarations: [
    
    AppComponent,
    DashboardComponent,
    DashboardFooterComponent,
    SubheaderTemplateComponent,
    ValidatePhoneComponent,
    ValidateEmailComponent,
    SuggestionComponent

   ],
  imports: [
    ProductsModule,
    PublicModule,
    PrivateModule,
    I18nModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AkeelaSecurityModule.config(environment.security),
    NgbModule
  ],
  exports: [NgbModule],
  providers: [
      I18nServiceAkeela,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private i18nService: I18nService, private i18nServiceAkeela: I18nServiceAkeela) {
    // this.i18nService.langDefault = 'en';

    // Mario:
    // Se hace el merge entre el archivo de i18n base y el del producto, en modo cascada, si el key está en el archivo default y en del producto se usa el del producto...
    const _lang_en = merge(lang_en_default, lang_en);
    const _lang_es = merge(lang_es_default, lang_es);

    this.i18nService.add(this.i18nServiceAkeela.getLangJson('en'), 'en');
    this.i18nService.add(this.i18nServiceAkeela.getLangJson('es'), 'es');
    this.i18nService.add(_lang_en, 'en');
    this.i18nService.add(_lang_es, 'es');
  }
}
