<app-template>
    <ng-template appContent componentName="mainContentApp">
        <div *ngIf="workflowStatusMenu && indicatorsProgres" class="bg-menu-ak d-none d-md-block">
        </div>
    </ng-template>
</app-template>

<akeela-template>


    <ng-template akeela-content componentName="SectionShareHoldersRelated">
        <app-section-shareholders-relateds></app-section-shareholders-relateds>
    </ng-template>
    <ng-template akeela-content componentName="SwornSectionComponents">
        <app-private-sworn></app-private-sworn>


    </ng-template>
    <ng-template akeela-content componentName="CommentsSectionComponent">
        <private-resumen [short]="resumen_requirements"></private-resumen>
    </ng-template>
    <ng-template akeela-content componentName="PaperworkSectionComponent">
        <app-paperwork></app-paperwork>
        <!-- <app-requirements-camera></app-requirements-camera> -->
    </ng-template>
    <ng-template akeela-content componentName="SectionLegalRepresentativeComponent">
        <app-section-legal-representative></app-section-legal-representative>
    </ng-template>
    <ng-template akeela-content componentName="SectionShareholdersComponent">
        <app-section-shareholders></app-section-shareholders>
    </ng-template>
    <ng-template akeela-content componentName="SectionBoardOfDirectorComponent">
        <app-section-board-of-directors></app-section-board-of-directors>
    </ng-template>
    <ng-template akeela-content componentName="SectionSuppliersComponent">
        <app-section-suppliers></app-section-suppliers>
    </ng-template>
    <ng-template akeela-content componentName="SectionCustomersComponent">
        <app-section-customers></app-section-customers>
    </ng-template>
    <ng-template akeela-content componentName="SectionRelatedCompanyComponent">
        <app-section-related-company></app-section-related-company>
    </ng-template>
    <ng-template akeela-content componentName="SectionBankAccountInstitutionComponent">
        <app-section-bank-account-institution></app-section-bank-account-institution>
    </ng-template>
    <ng-template akeela-content componentName="SectionBankAccountOtherInstitutionComponent">
        <app-section-bank-account-other-institution></app-section-bank-account-other-institution>
    </ng-template>
    <ng-template akeela-content componentName="SectionPepComponent">
        <app-section-pep></app-section-pep>
    </ng-template>
    <ng-template akeela-content componentName="SectionPepShareHoldersComponent">
        <app-section-pep-shareholders></app-section-pep-shareholders>
    </ng-template>
    <ng-template akeela-content componentName="SectionPepBoardOfDirectorsComponent">
        <app-section-pep-boardofdirectors></app-section-pep-boardofdirectors>
    </ng-template>
    <ng-template akeela-content componentName="ConstitutionDataComponent">
        <app-section-constitution-data></app-section-constitution-data>
    </ng-template>
    <ng-template akeela-content componentName="UploadSectionComponent">
        <app-private-requirements [isActiveResume]="false"></app-private-requirements>
    </ng-template>
    <ng-template akeela-content componentName="commercialReferencesSectionComponent">
        <app-section-commercial-references></app-section-commercial-references>
    </ng-template>

    <!-- PN -->

    <ng-template akeela-content componentName="legalRepresentativeSectionComponent">
        <app-section-legal-representative-pn></app-section-legal-representative-pn>
    </ng-template>

    <ng-template akeela-content componentName="SectionPNPepComponent">
        <app-section-pep-pn></app-section-pep-pn>
    </ng-template>

    <ng-template akeela-content componentName="SectionPNPepAssociationComponent">
        <app-section-pep-pn-association></app-section-pep-pn-association>
    </ng-template>



    <ng-template akeela-content componentName="customersPNSectionComponent">
        <app-section-customers-pn></app-section-customers-pn>
    </ng-template>

    <ng-template akeela-content componentName="supplierPNSectionComponent">
        <app-section-suppliers-pn> </app-section-suppliers-pn>
    </ng-template>

    <ng-template akeela-content componentName="personalReferencesPNSectionComponent">
        <app-section-personal-references-pn></app-section-personal-references-pn>
    </ng-template>

    <ng-template akeela-content componentName="SectionFiscalDirections">
        <div>
            <app-section-fiscal-directions></app-section-fiscal-directions>
        </div>
    </ng-template>

    <ng-template akeela-content componentName="imgLogo">
        <div class="mt-2">
            <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
        </div>
    </ng-template>

    <ng-template akeela-content componentName="identityVerificationComponent">

        <app-identity-verification-start></app-identity-verification-start>
    </ng-template>


    <ng-template akeela-content componentName="identityVerificationPhotosComponent">
        <!-- <app-identity-verification-photos></app-identity-verification-photos> -->
        <app-requirements-camera></app-requirements-camera>

    </ng-template>


    <ng-template akeela-content componentName="identityVerificationEndComponent">

        <app-identity-verification-end></app-identity-verification-end>
    </ng-template>


    <ng-template akeela-content componentName="SendClientSectionComponent">

        <app-send-client-section></app-send-client-section>

    </ng-template>

    <ng-template akeela-content componentName="INVESTOR_PROFILE_COMPONENT">
        <app-investor-profile></app-investor-profile>
    </ng-template>


</akeela-template>


<app-template>
    <ng-template appContent componentName="mainContentModalApp">

        <div class="d-md-none capa-menu-2" *ngIf="buttomModal" (click)="buttomModal =!buttomModal"> </div>
        <div style="overflow:auto;" [ngClass]="{'d-none':animate_init_bottom,'animate__slideInDown':buttomModal,animate__slideOutUp:!buttomModal } " class="d-md-none  animate__animated animate__faster ak-modal-top p-2 ">

            <div class="body pb-3 pt-3" style="overflow:auto;">

                <div class="container-fluid">

                    <div class="font-weight-bold mt-1  text-center w-100">
                        <i class="fas fas fa-user text-secondary mr-1 "></i> Información Personal

                        <hr>
                    </div>
                    <nav class=" ak-menu-tsct ">

                        <ul class="ak-menu-tsct pl-0 ">
                            <ng-container *ngIf="!workflowStatusMenu">
                                <div class=" col-12  p-t-1 p-b-1">
                                    <img src="./assets/public/images/loading.gif" alt="">
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let stage of workflowStatusMenu ; let first = first; let i = index">
                                <ng-container *ngFor="let step of stage?.howToSteps ; let first2 = first; let i2 = index">
                                    <li (click)="dropDownTransitionsWfs[step?.alternateName] = !dropDownTransitionsWfs[step?.alternateName] " [ngClass]="{'active':transitionsWfs[sectionName]?.active?.step == step?.alternateName &&  step?.sections?.length < 2, 'completed': (workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.state == 'COMPLETED'}"
                                        *ngIf="step?.showTitles && step?.sections?.length > 0">
                                        <ng-container *ngIf="step?.sections?.length > 1">
                                            <!-- 
                                            <i *ngIf="dropDownTransitionsWfs[step?.alternateName]" class="fa fa-chevron-down text-secondary pt-1 fz-12 "></i>
                                            <i *ngIf="!dropDownTransitionsWfs[step?.alternateName]" class="fa fa-chevron-down text-secondary pt-1 fz-12"></i>
                                            -->

                                            <div class="d-flex w-100 ">

                                                <div>
                                                    <a href="javascript:void(0) ">


                                                        <span> {{step?.name | i18n}} </span>

                                                    </a>

                                                </div>
                                                <div class=" ml-auto" style="color: #ccc;">
                                                    <i *ngIf="!dropDownTransitionsWfs[step?.alternateName]" class="fas fa-chevron-down"></i>
                                                    <i *ngIf="dropDownTransitionsWfs[step?.alternateName]" class="fas fa-chevron-up"></i>

                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="step?.sections?.length < 2">

                                            <ng-container *ngFor="let section of step?.sections ; let first3 = first; let i3 = index">

                                                <ng-container *ngIf="section?.additionalType != 'modal'">
                                                    <a (click)="redirectSectionView(workflowCodeActive,stage,step,section);;" href="javascript:void(0) ">
                                                        <span> {{step?.name | i18n}} </span>

                                                    </a>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>

                                        <div class="ak-menu-tsct2">
                                            <ul *ngIf="step?.sections?.length > 1 && dropDownTransitionsWfs[step?.alternateName]">
                                                <ng-container *ngFor="let section of step?.sections ; let first3 = first; let i3 = index">
                                                    <li [ngClass]="{'active': nodeActive == section.alternateName,'completed': (workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state == 'COMPLETED'}" *ngIf="section?.additionalType != 'modal'">
                                                        <a (click)="$event?.stopPropagation();redirectSectionView(workflowCodeActive,stage,step,section);;" href="javascript:void(0) ">
                                                            <span *ngIf="(workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state == 'COMPLETED'" class="fas fa-check" style="font-size: 10px;color: #999;"></span>

                                                            <span *ngIf="section?.sectionTitle"> {{section?.sectionTitle | i18n}} </span>
                                                            <span *ngIf="!section?.sectionTitle"> {{section?.alternateName}} </span>

                                                            <!--  <img *ngIf=" (workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state == 'COMPLETED'" style="height: 8px;" src="./assets/public/images/check2.png" alt="">
                                                          -->
                                                        </a>
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </li>
                                </ng-container>

                            </ng-container>

                        </ul>


                    </nav>
                </div>
            </div>
        </div>

    </ng-template>
</app-template>




<div>
    <div class="container-fluid ">
        <ng-container *ngTemplateOutlet="templates['topAlert'];"></ng-container>
    </div>
</div>
<ng-container *ngIf="isAuth && sectionWfActual">

    <ng-container *ngIf="showSubHeaderStep == true">
        <div class=" d-none " [ngClass]="{'d-block':showSubHeaderStep}">
            <app-breadcrumb [currentWorkflowDefault]="currentWorkflowDefault" [title]="title" [subtitle]="subtitle" [link]="link" [currentWorkflowChange]="currentWorkflowChange" (response)="get_response_breadcrub($event)"></app-breadcrumb>

        </div>
    </ng-container>

    <div [ngClass]="{'container-fluid': containerFluid,'container': !containerFluid}">
        <div class="w-100">

            <div *ngIf="indicatorsProgres " [ngClass]="{'d-md-block': indicatorsProgres}" class="w-100  d-none  " style="min-height: 45px;">
                <div class=" d-flex ak-fixed-proges">
                    <div style="min-height: 45px;" class=" w-100 d-flex">

                        <div (click)="redirectHistotyBak()" class="fz-14 active-cursor ak-text-color-7 mt-3" *ngIf="indicatorsProgres">
                            <ng-container *ngIf="transactionData">
                                <span [innerHTML]="transactionData?.plan?.shortDescription| i18n"></span>
                            </ng-container>

                            <ng-container *ngIf="!transactionData">
                                <span [innerHTML]="optionsDataWF?.name | i18n"></span>

                            </ng-container>
                        </div>
                        <div class="ml-auto" *ngIf="indicatorsProgres">
                            <div style="width: 40px;
                            margin-right: 17px;
                            padding-top: 5px;">
                                <akeela-graphics [percent]="indicatorsProgres?.percent" [type]="'circle'">
                                </akeela-graphics>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
            <div class="d-flex flex-wrap ">
                <div>

                    <div class=" ak-menu-t d-none d-md-block " *ngIf="wfMenuTransitionsActive">

                        <nav [ngClass]="{'ak-menu-fixed-progres': indicatorsProgres}" class="pb-3 pt-3 ak-menu-tsct ak-menu-fixed  ">
                            <ul class="ak-menu-tsct  ml-0 pl-0 font-weight-transitionsWfsbold ">
                                <ng-container *ngIf="!workflowStatusMenu">
                                    <div class=" col-12  p-t-1 p-b-1">
                                        <img src="./assets/public/images/loading.gif" alt="">
                                    </div>
                                </ng-container>


                                <ng-container *ngFor="let stage of workflowStatusMenu ; let first = first; let i = index">
                                    <ng-container *ngFor="let step of stage?.howToSteps ; let first2 = first; let i2 = index">
                                        <li (click)="dropDownTransitionsWfs[step?.alternateName] = !dropDownTransitionsWfs[step?.alternateName] " [ngClass]="{'active':transitionsWfs[sectionName]?.active?.step == step?.alternateName && step?.sections?.length < 2, 'completed': (workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.state == 'COMPLETED'}"
                                            *ngIf="step?.showTitles && step?.sections?.length > 0">
                                            <ng-container *ngIf="step?.sections?.length > 1">

                                                <div class="d-flex w-100 ">

                                                    <div>
                                                        <a href="javascript:void(0) ">


                                                            <span> {{step?.name | i18n}} </span>

                                                        </a>

                                                    </div>
                                                    <div class=" ml-auto" style="color: #ccc;">
                                                        <i *ngIf="!dropDownTransitionsWfs[step?.alternateName]" class="fas fa-chevron-down"></i>
                                                        <i *ngIf="dropDownTransitionsWfs[step?.alternateName]" class="fas fa-chevron-up"></i>

                                                    </div>
                                                </div>

                                            </ng-container>

                                            <ng-container *ngIf="step?.sections?.length < 2">

                                                <ng-container *ngFor="let section of step?.sections ; let first3 = first; let i3 = index">

                                                    <ng-container *ngIf="section?.additionalType != 'modal'">
                                                        <a (click)="redirectSectionView(workflowCodeActive,stage,step,section);;" href="javascript:void(0) ">
                                                            <span> {{step?.name | i18n}} </span>
                                                        </a>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>

                                            <div class="ak-menu-tsct2">
                                                <ul *ngIf="step?.sections?.length > 1 && dropDownTransitionsWfs[step?.alternateName]">
                                                    <ng-container *ngFor="let section of step?.sections ; let first3 = first; let i3 = index">
                                                        <li [ngClass]="{'active': nodeActive == section.alternateName,'completed': (workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state == 'COMPLETED'}" *ngIf="section?.additionalType != 'modal'">
                                                            <a (click)="$event?.stopPropagation();redirectSectionView(workflowCodeActive,stage,step,section);;" href="javascript:void(0) ">
                                                                <span *ngIf="(workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state == 'COMPLETED'" class="fas fa-check" style="font-size: 10px;color: #999;"></span>
                                                                <!--
                                                                <pre>
    {{ (workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName] | json }}
</pre>-->
                                                                <span *ngIf="section?.sectionTitle"> {{section?.sectionTitle | i18n}} </span>
                                                                <span *ngIf="!section?.sectionTitle"> {{section?.alternateName}} </span>

                                                            </a>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        </li>
                                    </ng-container>

                                </ng-container>
                            </ul>


                        </nav>
                    </div>
                </div>
                <div class="pl-0 pl-md-4" [ngClass]="{'content-menu':wfMenuTransitionsActive,'w-100': !(wfMenuTransitionsActive)  }">

                    <workflow-embed-components></workflow-embed-components>
                    <div class="row">
                        <div [ngClass]="{' col-md-12': containerFluid}" class="col-12">


                            <div class="d-md-none " #menuminfixed *ngIf="wfMenuTransitionsActive ">
                                <div #menufixed>
                                    <div *ngIf="indicatorsLoading">
                                        <img class="pt-1" style="width: 36px;" src="./assets/public/images/loading.gif" alt="">
                                    </div>
                                    <div *ngIf="indicatorsProgres" style="min-height: 44px;" class="w-100 d-flex mb-2 mb-md-0 d-block d-none-block">


                                        <div (click)="redirectHistotyBak()" class="fz-14 active-cursor   ak-text-color-7  mt-2" *ngIf="indicatorsProgres">


                                            <ng-container *ngIf="transactionData">
                                                <span [innerHTML]="transactionData?.plan?.shortDescription| i18n"></span>
                                            </ng-container>

                                            <ng-container *ngIf="!transactionData">
                                                <span [innerHTML]="optionsDataWF?.name | i18n"></span>

                                            </ng-container>

                                        </div>


                                        <div class="ml-auto" *ngIf="indicatorsProgres">
                                            <div style="width: 40px;
                                            margin-right: 0;
                                            padding-top: 5px;">
                                                <akeela-graphics [percent]="indicatorsProgres?.percent" [type]="'circle'">
                                                </akeela-graphics>
                                            </div>
                                        </div>
                                    </div>

                                    <div (click)="openModalMenu()" class="  pb-0 pt-1" *ngIf="wfMenuTransitionsActive">
                                        <hr class="mt-0 mb-2 pt-0 pb-0" style="    margin-left: -15px;
                                        margin-right: -15px;">
                                        <span class="text-secondary fz-14  fz-16">
                                            Saltar a
                                        </span>
                                        <i class="fas fa-chevron-down float-right text-primary pt-1"></i>
                                        <hr class="mt-2 mb-0 pt-0 pb-0" style="    margin-left: -15px;
                                        margin-right: -15px;">
                                    </div>

                                </div>
                            </div>

                            <main>
                                <router-outlet class="animate__animated animate__fadeInDown "></router-outlet>
                            </main>


                        </div>
                    </div>



                </div>
            </div>

        </div>

    </div>

</ng-container>

<div class="container mt-2" *ngIf="!sectionWfActual && ((!showWorkflow) || (!isAuth)) && !(workflowStatusMenu && wfMenuTransitionsActive)">
    <div class="col-12  p-t-1 p-b-1">
        <img src="./assets/public/images/loading.gif" alt="">
    </div>
</div>

<!--  <img *ngIf=" (workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state == 'COMPLETED'" style="height: 8px;" src="./assets/public/images/check2.png" alt="">
                                                            -->