import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@akeela/auth';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input() subtitle?= '@i18n-title-open-account';
  @Input() title?= '@i18n-title-procedure';
  @Input() link?;
  @Input() currentWorkflowChange?;
  @Input() currentWorkflowDefault?;


  @Output() response = new EventEmitter<any>();

  transactionData;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _auth: AuthService,
    private workflowService: AkeelaWorkflowService
  ) { }

  ngOnInit() {
    this.workflowService.transactionData$.subscribe((transactionData: any) => {
      if (!isNullOrUndefined(transactionData) && transactionData !== undefined) {
        this.transactionData = transactionData;
      }
    });
  }

  get_response($event) {
    this.response.emit($event);
  }


}
