<!--
<app-change-user-password></app-change-user-password>
 -->


<div class="container mb-4">
    <div class="row  mt-5 text-center">
        <div class="col-12 col-md-10 offset-md-1">

            <div style=" background: url({{filePreview}}) no-repeat center" class="animate__animated animate__fadeIn content-avata-img2 " *ngIf="dataAndSelfi?.description">
            </div>
            <div *ngIf="!dataAndSelfi?.description" class="ml-auto mr-auto mb-2  ak-abatar-big ak-dinamic-color-abatar  menu">{{ _auth.getInitials() }}</div>

            <div class="mt-2 text-center">
                <h5>
                    <div class="text-secondary " *ngIf="_auth.getSession()?.businessName">
                        <span>
                  {{_auth.getSession()?.businessName}}
                </span>
                    </div>
                    <div class="ml-auto mr-auto  text-secondary ">
                        Bienvenido,
                        <span *ngIf="_auth.getSession()?.firstName"> {{_auth.getSession()?.firstName}}</span>
                        <span *ngIf="_auth.getSession()?.lastName"> {{_auth.getSession()?.lastName}}</span>
                    </div>
                </h5>
            </div>

            <div class="w-100 mt-3 text-center">
                Gestiona tu información para hacer que trabaje mejor para tí
            </div>

            <div class="row  mt-3 ">
                <div class="col-12 col-md-6 mt-4">
                    <div class="w-100 section-box hover p-3 text-left">
                        <div class="d-flex w-100">

                            <div class="">
                                <h5 class="">Verificación de identidad</h5>
                            </div>

                            <div class="ml-auto text-right text-primary">
                                <h4><i class="fas fas fa-fingerprint mr-1"></i></h4>
                            </div>

                        </div>
                        <div class="d-flex w-100">

                            <div class="">
                                <div class="text-secondary mt-2 fz-14">
                                    Comienza con la simple tarea de comprobar la autenticidad y reforzar la seguridad de tus datos. Debajo de la descripción</div>


                            </div>
                        </div>


                    </div>
                </div>

                <div class="col-12 col-md-6 mt-4">
                    <div class="w-100 section-box hover p-3  text-left" (click)="redirecClientData()">
                        <div class="d-flex w-100">

                            <div class="">
                                <ng-container *ngIf="isNatural">

                                    <h5>Identificación Personal</h5>

                                </ng-container>

                                <ng-container *ngIf="!isNatural">

                                    <h5>Identificación de la Empresa</h5>

                                </ng-container>


                            </div>

                            <div class="ml-auto text-right text-primary">
                                <h4><i class="fas fas fa-user mr-1"></i></h4>
                            </div>
                        </div>

                        <div class="d-flex w-100">

                            <div class="fz-14">
                                <div class="text-secondary mt-2 ">Nombre, fecha de nacimiento, entre otros. </div>

                                <div class=" mt-3">
                                    <a href="javascript:void(0)" (click)="redirecClientData()" class="text-primary"> Gestiona tu información </a>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>

            </div>


            <div class="row">
                <div class="col-12 col-md-6 mt-4">
                    <div class="w-100 section-box hover p-3 text-left">
                        <div class="d-flex w-100">

                            <div class="">
                                <h5 class="">Documentación </h5>
                            </div>

                            <div class="ml-auto text-right text-primary">
                                <h4><i class="fas fa-folder mr-1"></i></h4>
                            </div>

                        </div>
                        <div class="d-flex w-100">

                            <div class="">
                                <div class="text-secondary mt-2 fz-14">Nombre, fecha de nacimiento, entre otros.</div>


                                <div class=" mt-3">
                                    <a href="#" class="text-primary"> Gestiona tus recaudos </a>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                <div class="col-12 col-md-6 mt-4">
                    <div class="w-100 section-box hover p-3  text-left">
                        <div class="d-flex w-100">

                            <div class="">
                                <h5 class="">Ejecutivo</h5>

                            </div>

                            <div class="ml-auto text-right text-primary">
                                <h4><i class="fas fa-user-tie"></i></h4>
                            </div>
                        </div>

                        <div class="d-flex w-100">

                            <div class="fz-14">
                                <div class="text-secondary mt-2 ">

                                    <b>{{agencyEjecutive?.person?.givenName}}  {{agencyEjecutive?.person?.familyName}}</b>

                                </div>

                                <div class=" mt-3">
                                    <a href="#" class="text-primary mr-2"> Chat</a>
                                    <a href="#" class="text-primary"> Reunión</a>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                <div class="col-12 col-md-6 mt-4">
                    <div class="w-100 section-box hover p-3  text-left">
                        <div class="d-flex w-100">

                            <div class="">
                                <h5 class="">Agencia</h5>

                            </div>

                            <div class="ml-auto text-right text-primary">
                                <h4><i class="fas fa-user-building"></i></h4>
                            </div>
                        </div>

                        <div class="d-flex w-100">

                            <div class="fz-14">
                                <div class="text-secondary mt-2 ">

                                    <b class="pb-2">{{agencyEjecutive?.localBusiness?.name}}</b> <br>{{agencyEjecutive?.localBusiness?.address?.description}}

                                </div>
                                <div class=" mt-3">
                                    <a href="#" class="text-primary"> Gestiona tu agencia </a>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                <div class="col-12 col-md-6 mt-4">
                    <div class="w-100 section-box hover p-3  text-left">
                        <div class="d-flex w-100">

                            <div class="">
                                <h5 class="">Cambiar contraseña</h5>

                            </div>

                            <div class="ml-auto text-right text-primary">
                                <h4><i class="fas fa-key"></i></h4>
                            </div>
                        </div>

                        <div class="d-flex w-100">

                            <div class="">
                                <div class="text-secondary mt-2 fz-14">Actualiza tu contraseña cuando desees. </div>


                                <div class=" mt-3">
                                    <a href="#" class="text-primary"> Cambiar</a>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>



            </div>

        </div>



    </div>
</div>