<auth-akeela-template>
    <ng-template auth-akeela-content componentName="loginLinks">
        <br>
        <div class=" text-center font-weight-light ls-o">
            <span>
                <span [innerHTML]="'@i18n-new-user-question' | i18n:lang"></span>
            </span>
            <span class="ls"></span>
        </div>
        <div class=" text-center " *ngIf="env?.theme?.showRegister">
            <a (click)="register()" [routerLink]="''" class="font-weight-normal link" [innerHTML]="'@i18n-store-front-login-user-account-create' | i18n:lang"></a>
        </div>
        <br>
        <div class=" text-center font-weight-light ls-o">
            <span class="ls">---------------</span> ó
            <span class="ls">---------------</span>
        </div>
        <br>

        <div class=" text-center ">
            <a (click)="recovery()" [routerLink]="''" class="font-weight-normal link" [innerHTML]="'@i18n-store-front-login-user-recovery-password' | i18n:lang"></a>
            ó
            <a (click)="unlock()" [routerLink]="''" class="font-weight-normal link" [innerHTML]="'@i18n-store-front-login-user-unlock-password' | i18n:lang"></a>
        </div>

    </ng-template>
</auth-akeela-template>

<akeela-login [params]="params" [endpoint]="endpoint" [sessionType]="sessionType" (response)="response($event)"></akeela-login>

<!--
<br>

<div class=" text-center font-weight-light ls-o">
    <span class="">
        <span [innerHTML]="'@i18n-user-exists-question' | i18n"></span>
    </span>
    <span class="ls"></span>
</div>

<div class=" text-center ">
    <a class="link-color font-weight-normal link" [innerHTML]="'@i18n-store-front-login-affiliate' | i18n"></a>
</div>

-->