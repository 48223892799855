<div class="container font-weight-light mt-4" >
<div class="container animate__animated animate__fadeIn">
       <div>
          <img src="./assets/public/images/loading.gif" alt="">
       </div>
    </div>
</div>


<plan-requirements-export [show]="false">
</plan-requirements-export>

<products-akeela-template>
            <ng-template products-akeela-content componentName="sectionRequirementsPDFImageHeader" let-persontype>
                <div>
                    <app-logo-img [theme]="env?.theme?.logoConf?.themeColorPdf" [type]="env?.theme?.logoConf?.typePdf"></app-logo-img>
                </div>
                <br>
                <div>
                    <b>RECAUDOS PARA APERTURA DE CUENTA</b>
                    <br>
                    <br> El solicitante debe consignar dos copias de los documentos y el original para la validación de las copias entregadas
                    <br>
                    <br>
                </div>
            </ng-template>

            <ng-template products-akeela-content componentName="imgLogo" let-plan>
                <div class="mt-2">
                        <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>

                </div>
            </ng-template>
        
            <ng-template products-akeela-content componentName="sectionForm" let-plan>

            </ng-template>
        </products-akeela-template>
        
<div *ngIf="planReq">
       <plans [notClose]="true" [showNoteCategory]="showNoteCategory" [showDescriptionReq]="showDescriptionReq" [product]="product" [plan]="planReq"></plans>
</div>
      
