<akeela-requirements-template>

    <ng-template akeelaRequirementsContent componentName="imgLogo">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsDetailHeaderContent">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsDetailFooterContent">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsHeaderContent">

    </ng-template>
</akeela-requirements-template>
<ng-container *ngIf="activeRequirements">
    <div class="row">

        <div class="col-12 mt-2">
            <ul class="nav nav-tabs">
                <li (click)="rq_menu = 1" class="nav-item">
                    <a [ngClass]="{'active': rq_menu == 1}" class="nav-link ">Recaudos</a>
                </li>
                <li (click)="rq_menu = 2" class="nav-item">
                    <a [ngClass]="{'active': rq_menu == 2}" class="nav-link">Otros Recaudos</a>
                </li>

            </ul>
        </div>

        <div class="col-12" *ngIf="rq_menu == 1">

            <akeela-requirements-briefcase [viewOptional]="viewOptional" [folder]="folder" [config]="config_requirements" (showFile)="show_file($event)" (openWindowRequirement)="openWindowRequirement($event)" (response)="response_requirements($event)" (finishupload)="finish_upload($event)"
                (responseTransactionChange)="response_transaction_change($event)" (responsetransaction)="response_transaction($event)" *ngIf="config_requirements && start_requirements"></akeela-requirements-briefcase>

        </div>

        <div class="col-12 " *ngIf="rq_menu == 2">

            <akeela-requirements-briefcase [viewOptional]="viewOptional" [folder]="folder" [config]="config_requirements_other" (showFile)="show_file($event)" (openWindowRequirement)="openWindowRequirement($event)" (response)="response_requirements($event)" (finishupload)="finish_upload($event)"
                (responseTransactionChange)="response_transaction_change($event)" (responsetransaction)="response_transaction($event)" *ngIf="config_requirements_other && start_requirements"></akeela-requirements-briefcase>

        </div>


    </div>
</ng-container>
<ng-container *ngIf="!config_requirements">
    <div class="container animate__animated animate__fadeIn ">
        <div>
            <img src="./assets/public/images/loading.gif" alt="">
        </div>
    </div>
</ng-container>