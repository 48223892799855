import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpService } from '@akeela/properties';
import { AuthService, ErrorService } from '@akeela/auth';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import swal from 'sweetalert2';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LocalService } from '@akeela/local-storage';


@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.css']
})
export class ClientInformationComponent implements OnInit {
  loading_form = false; 
  clientInformation: any = null;
  countryList$;
  civilStatusList$;
  stateList$;
  cityList$;

  occupationList$;


  form = this.formBuilder.group({
    givenName: ['', [Validators.required,Validators.pattern("^([a-zA-ZzäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙÑñ ])+$")]],
    name: ['', [Validators.pattern("^([a-zA-ZzäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙÑñ ])+$")]],
    familyName: ['', [Validators.required,Validators.pattern("^([a-zA-ZzäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙÑñ ])+$")]],
    lastName: ['', [Validators.pattern("^([a-zA-ZzäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙÑñ ])+$")]],
    type_identity: ['', [Validators.required]],
    identity: ['', [Validators.required,Validators.pattern("^[JGVEPjgvep][0-9]+$")]],
    taxID: ['', [Validators.pattern("^[JGVEPjgvep][0-9]+$")]],

    gender: ['', [Validators.required]],
    nationality: ['', [Validators.required]],
    otherNationality: [''],
    profession_job: ['', [Validators.required]],
    civil_status: ['', [Validators.required]],
    family_burden: ['', [ Validators.pattern("^([0-9])+$")]],

    birthdate: ['', [Validators.required]],
    country: ['', [Validators.required]],
    state: ['', [Validators.required]],
    city: ['', [Validators.required]]
  });


  constructor(
    private _error: ErrorService,
    private formBuilder: FormBuilder, 
    private _httpService: HttpService,
    private _auth: AuthService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    public localStorage: LocalService
  ) { }

  async ngOnInit() {
    this.countryList$ = this.countrys();
    this.occupationList$ = this.occupation();
    this.civilStatusList$ = this.civilStatus();
    try{
      this.clientInformation = await this.userInformation();
      this.setDefault();
      this.setInformation();
    } catch(error) {
      console.log(error);
      this.setDefault();
    }
    
  }

  update = (source, option) => {
    let form = this.form.getRawValue();
    const source_value = form[source];
    if(option =='states') this.stateList$ = this.state(source_value); this.cityList$ = this.citys(form['country'], form['state']);
    if(option =='city') this.cityList$ = this.citys(form['country'], source_value);
  }

  occupation = async () => {
    const params = {
      name: 'workflow_manager$attributes_options$get',
      params: {
        request: {},
        path: {
          code: 'occupation'
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  timestampToStringDate = (value, format) => {
    const date_timestamp = new Date(Number(value));
    return this.datePipe.transform(date_timestamp, format, '+0700');
  }

  stringDateToTimestamp = (value) => {
    return new Date(value).getTime();
  }

  civilStatus = async () => {
    const params = {
      name: 'workflow_manager$attributes_options$get',
      params: {
        request: {},
        path: {
          code: 'civilStatus'
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }


  countrys = async () => {
    const params = {
      name: 'app_properties$countrys$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  citys = async (country, state) => {
    const params = {
      name: 'app_properties$citys$get',
      params: {
        request: {
          countryCode: country,
          identifierState: state
        },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  state = async (country) => {
    const params = {
      name: 'app_properties$states$get',
      params: {
        request: {
          countryCode: country
        },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  setGender = (gender) => {
    this.form.patchValue({
      gender: gender
    });
  }

  setDefault = () => {
    this.form.patchValue({
      type_identity: '1'
    });
  }

  setInformation = () => {
    this.form.patchValue({
      givenName: this.clientInformation.givenName,
      name: this.clientInformation.name,
      familyName: this.clientInformation.familyName,
      type_identity: 1,
      identity: this.clientInformation.identity,
      lastName: this.clientInformation.alternateName,
      taxID: this.clientInformation.taxID,
      family_burden: this.clientInformation.familyBurden,
      gender: this.clientInformation.gender,
      otherNationality: this.clientInformation?.otherNationality?this.clientInformation.otherNationality.alternateName: null,

      nationality: this.clientInformation.nationality.alternateName,
      profession_job: this.clientInformation?.hasOccupation?.identifier,
      civil_status: this.clientInformation?.civilStatus,
      birthdate: this.timestampToStringDate(this.clientInformation?.birthDate, 'yyyy-MM-dd'),
      country: this.clientInformation?.homeAddress?.addressCountry?.identifier,
      state: this.clientInformation?.homeAddress?.state?.identifier,
      city: this.clientInformation?.homeAddress?.city?.identifier
    });
    this.update('country', 'states');
    this.update('state', 'city');
  }

  save = () => {
    if(this.form.valid) {
      this.loading_form = true;
      const params = {
        name: 'briefcase$personalinfo$post',
        params: {
          request: {
            role: 'CLIENT',
            audience : 'NATURAL',
            step: 'PERSONAL_INFORMATION'
          },
          path: {},
          body: this.getJsonNatural()
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).toPromise().then((response)=> {
        swal.fire({
          title: '',
          html: "Información personal actualizada.",
        });
        this.router.navigate(['/dashboard'], {
          queryParams: {
              role: this.localStorage.getItem('role'),
              profile: this.localStorage.getItem('profile'),
              audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL'
          }
        });
        this.loading_form = false;
      }, error => {
        this.loading_form = false;
        this._error.show(error);
      });
    }
  }

  getJsonNatural = () => {
    let form = this.form.getRawValue();
    return {
      "givenName": form.givenName,
      "name": form.name,
      "familyName": form.familyName,
      "alternateName": form.lastName,
      "additionalType": form.type_identity,
      "identity": form.identity,
      "taxID": form.taxID,
      "gender": form.gender,
      "nationality": {
        "identifier": form.nationality
      },
      "otherNationality": {
        "identifier": form.otherNationality
      },
      "hasOccupation": {
        "identifier": form.profession_job
      },
      "civilStatus": form.civil_status,
      "familyBurden": form.family_burden,
      "birthDate": this.stringDateToTimestamp(form.birthdate),
      "birthPlace": {
        "address": {
          "addressCountry": {
            "identifier": form.country
          },
          "state": {
            "identifier": form.state
          },
          "city": {
            "identifier": form.city
          }
        }
      },
      "identifier": this.clientInformation.identifier
    };
  }



  userInformation = () => {
    const params = {
      name: 'briefcase$person$get',
      params: {
        request: {
          id: this._auth.getPersonIdentifier()
        },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }
}
