import { AuthService } from '@akeela/auth';
import { UploadService } from '@akeela/common';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { PropertiesService } from 'src/app/services';
import { WorkflowService } from 'src/app/services/workflow.service';

@Component({
  selector: 'user-account-user',
  templateUrl: './user-account-user.component.html',
  styleUrls: ['./user-account-user.component.css']
})
export class UserAccountUserComponent implements OnInit {

  dataAndSelfi;
  filePreview;
  showBackDashboard ;
  verifiedIdentification;
  compleatVerifi;
  agencyEjecutive;
  isNatural
  constructor(
    private router: Router,
    public  config: NgbDropdownConfig,
    public _auth:AuthService,
    public _properties: PropertiesService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _wfService: WorkflowService,

    private _httpService: HttpService,
    private i18n: I18nPipe,
    private localStorage: LocalService,
    private akWorkflowService: AkeelaWorkflowService,


  ) { }


  ngOnInit(): void {

    this.isNatural = this.localStorage.getItem('clientaudience') == 'NATURAL';

  this.getUser();
  this.get_dataAndSelfi();
  this.getAgencyEjecutive() ;
  
  }



getUser() {
  const params = {
    name: 'person$enduser$get',
    params: {
      request: {
        'username': this._auth.getUsername()
      },
      path: {},
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    
    this.compleatVerifi = response ?.person ?.completeIdentification;
    this.verifiedIdentification = response ?.person ?.verifiedIdentification ;
    this._wfService.setGetIdentityVerification(this.verifiedIdentification);
    this._wfService.setGetIdentityCompleat( this.compleatVerifi);

  });

}



get_dataAndSelfi(){

    const params = {
      name: 'person$selfie$get',
      params: {
        request: {
          'username': this._auth.getUsername()
        },
        path: {},
        body: {}
      }
    };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    this.dataAndSelfi = response;
    this.filePreview = 'data:image/jpg' + ';base64,' + response?.description;

    
  });

}


redirecClient(){


  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    section:'PERSONAL_INFORMATION'

  };

  this.akWorkflowService.setCurrentWorkflowCodeActive('CLIENT_DATA');
  let url = ['private/' + 'CLIENT_DATA'+ '/stages/CLIENT_IDENTIFICATION/steps/PERSONAL_INFORMATION'];

  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
  });

}


redirecClientData(){
  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
  };

  let url = ['/client-data'];

  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
    
  });
}



getAgencyEjecutive() {
  const params = {
    name: 'person$user_executive_assigned$get',
    params: {
      request: {
        'username': this._auth.getUsername()
      },
      path: {},
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

   this.agencyEjecutive = response;

  });


}


}
