import { LocalService } from '@akeela/local-storage';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-identity-verification-photos',
  templateUrl: './identity-verification-photos.component.html',
  styleUrls: ['./identity-verification-photos.component.css']
})
export class IdentityVerificationPhotosComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalService
  ) { }

  ngOnInit(): void {
  }

  redirect(){


    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
    };

    const prefixed = 'private';
    const url = 'INTER_IDENTITY_VERIF/stages/INTER_IDENTITY_VERIF/steps/INTER_IDENTITY_VERIF_END';


    this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });



  }

}
