import { Component, OnInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { isNullOrUndefined } from 'util';
import { 
  AkeelaWorkflowService, 
  AkeelaStageService, 
  AkeelaStepService, 
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
import { Table, Row, Column } from '@akeela/ak-table';



@Component({
  selector: 'app-section-board-of-directors',
  templateUrl: './section-board-of-directors.component.html',
  styleUrls: ['./section-board-of-directors.component.css']
})
export class SectionBoardOfDirectorComponent implements OnInit {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  readonlyMode = false;
  showLoading = false;
  configuration_table: Table;


  private transactionDataSubscription: Subscription;
  private workflowEventsSubscription: Subscription;
  private requestSubscription: Subscription;
  paramsSubscription: Subscription;


  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private localStorage: LocalService
  ) { }

  ngOnInit() {
    //[dfutrille] Esta ejecucion de evento es para prevenir la suscripcion al ultimo evento ejecutado.
    //Esto se debe evaluar, ya que es algo que molesta en muchas ejecuciones.


 
    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
     });

    this.akeelaWorkflowService.worflowEvents$.next(null);

    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();

    this.akeelaWorkflowService.transactionNumber.subscribe(transactionNumber => {
      if (transactionNumber) {
        this.transactionNumber = transactionNumber;
      }
    });

    this.getItemList();
    // this.transactionDataSubscription = this.akeelaWorkflowService.transactionData.subscribe(transactionData => {
    //   this.transactionData = transactionData;
    //   if (!isNullOrUndefined(this.transactionData) && 
    //     (!isNullOrUndefined(this.transactionData.organization) || !isNullOrUndefined(this.transactionData.person))) {
        
    //   }
    // });

    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    this.workflowEventsSubscription = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (response && (response.action === 'account$related_person_bod$post' || response.action === 'account$related_person_pep_bod$post')) {
        this.getItemList();
      } else if (!isNullOrUndefined(response) && response.action === 'send_formValidationEvent_board_of_directors') {
        //Si marco SI, entonces valida sino o si tiene al menos un PEP, entonces continua

        const markYes = response.response.forms.controls.person.controls.isRelatedToPep ? response.response.forms.controls.person.controls.isRelatedToPep.value:null;
         
        if (markYes && !this.haveAtLeastOnePep()) {
          swal.fire({
            icon: "warning",
            text: this.i18n.transform('@i18n-validation-have-to-add-pep'),
          });
        } else {
          this.sendEventToContinue(response.response);
        }
      } else if (!isNullOrUndefined(response) && response.action === 'send_formValidationEvent_delete_all_board_of_directors_pep') {
        //Si marco SI, entonces valida sino o si tiene al menos un PEP, entonces continua
        const radioButtonNo =  response.response.forms.controls.person.controls.isRelatedToPep ? response.response.forms.controls.person.controls.isRelatedToPep.value:null;
        if (radioButtonNo && this.haveAtLeastOnePep()) {
          //Ha marcado NO y tiene PEP, Preguntar si quiere eliminar todos los PEP de REP LEG
          swal.fire({
            title: this.i18n.transform('@i18n-confirmation-are-you-shure'),
            text: this.i18n.transform('@i18n-confirmation-are-you-shure-delete-pep'),
            showCancelButton: true,
            confirmButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-true'),
            cancelButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-false'),
            reverseButtons: true
          }).then((result) => {
            if (!isNullOrUndefined(result.value) && result.value) {
              //Si el usuario responde que si a la pregunta... Elimina sus PEP
              this.akeelaWorkflowService.worflowEvents$.next({
                response: 'BOARD_OF_DIRECTORS',
                action: 'account_opening$related_person_all_pep_bod$delete'
              });
            } else {
              //Asigno nuevo valor cuando el usuario CANCELE la operacion
              response.response.forms.controls.person.controls.isRelatedToPep.patchValue(true);
            }
          });
        }
      }
    });

  }

  haveAtLeastOnePep() {
    const modalFather = this.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather) && !isNullOrUndefined(modalFather.person)) {
      //Esto es cuando la entidad Padre ya esta guardada en BD y se verifican sus PEP asociados en el JSON
      if (isNullOrUndefined(modalFather.person.relatedPeps) || (!isNullOrUndefined(modalFather.person.relatedPeps) && modalFather.person.relatedPeps.length === 0)) {
        return false;
      }
    } else if (isNullOrUndefined(modalFather)) {
      //Esto es cuando la entidad Padre no esta guardada en BD y se verifica si tiene PEP en memoria.
      if (this.akeelaSectionService.countModalChildList('new_pep_board_of_directors') === 0) {
        return false;
      }
    }
    return true;
  }

  sendEventToContinue(response) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {
        fn: response.fn,
        event: event,
        sectionIdentifier: response.sectionIdentifier,
        field: response.field
      },
      action: 'receive_formValidationEvent'
    });
  }

  ngOnDestroy() {
    if (this.transactionDataSubscription) {
        this.transactionDataSubscription.unsubscribe();
    }
    if (this.workflowEventsSubscription) {
        this.workflowEventsSubscription.unsubscribe();
    }
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  getItemList() {
    // const params = {
    //   name: 'account$related_persons$get',
    //   params: {
    //     request: {},
    //     path: {
    //       typeRelatedPerson: 'BOARD_OF_DIRECTORS',
    //       personId: this.localStorage.getItem('clientIdentifier'),
    //     },
    //     body: {}
    //   }
    // };

    const params = {
      name: 'briefcase$get-relateds$get',
      params: {
        request: {
          personId: this.localStorage.getItem('clientIdentifier'),
          relatedType:'BOARD_OF_DIRECTORS'
        },
        path: {
        },
        body: {}
      }
    };



    this.requestSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.itemList = response;
      this.setConfigurationTable();
      if(this.itemList.length === 0){
        this.akeelaWorkflowService.setResetEstatus(true);
      }
      this.updateFather();
      this.showLoading = false;
    });
  }

  setConfigurationTable() {
    const base: Column[] = this.setHeadTable();
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'class-color-title-table-section',
        rows: [{
          backgroundColor: '#fff',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };

  }

  setHeadTable() {

    const base: Column[] = [
      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '100',
        html: ' Nombre',
        cssCustom: '',
        enableRightBorder: true

      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Tipo',
        cssCustom: '',

      },

      {
        alignContent: 'left',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      },
      {
        alignContent: 'center',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      }
    ]
    return base;
  }

  setContentTable(configuration): Row[] {
    const content: Row[] = [];
    let i = 0;
    for (const row of this.itemList) {
      i++;

      let temp = '-';
      if (row.applicantType == 'NATURAL') {

        temp = '@i18n-quote-option-list-applicantType-option-natural';

      } else if (row.applicantType == 'LEGAL') {
        temp = '@i18n-quote-option-list-applicantType-option-legal';


      }
      content.push(
        {
          identifier: row.person ? row.person.identifier : row.organization.identifier,
          cssCustom: '',
          enabled: true,
          columns: [
            this.setRowContentTable({

              configuration,
              number: 0,
              html: (row.person) ? this.capitalize(row.person.givenName) + " " + this.capitalize(row.person.familyName) : this.capitalize(row.organization.legalName),
              cssCustom: ' text-capitalize'

            }),

            this.setRowContentTable({
              configuration,
              number: 1,
              html: this.i18n.transform(temp),
            }),

           

            this.setRowContentTable({

              configuration,
              number: 2,
              html: 'Ver',
              cssCustom: 'ak-tabled-row-active text-capitalize'

            }),


            this.setRowContentTable({
              configuration,
              number: 3,
              html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
              click: 'delete_item',
            })
          ]
        }
      );
    }

    return content;
  }

  table_response(response) {

    if (response.action === 'open') {
      this.openItem(response.object.identifier);

    }else if (response.action === 'action') {
       if (response.object.action === 'delete_item' && !this.readonlyMode) {

        this.confirmDelete(response.object.obj.identifier);
      }
    }
  }



  setRowContentTable(row) {

    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click ? row.click : null;
    let dropdown = row.dropdown ? row.dropdown : null;
    let cssCustom = row.cssCustom ? row.cssCustom : configuration[number].cssCustom;



    let result = {

      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click: click,
      dropdown: dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }

    return result;

  }

  capitalize(word) {
    if(word && word[0]){
      return word[0].toUpperCase() + word.slice(1);

    }
    return '';
  }


  updateFather(){
    const self = this;
    const modalFather = self.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather)){
      const fatherToUpdate = this.itemList.find(
        (_father) => (!isNullOrUndefined(_father.person)) && (_father.person.identifier === modalFather.person.identifier)
      );
      if (!isNullOrUndefined(fatherToUpdate)){
        self.akeelaWorkflowService.worflowEvents$.next({
            response: fatherToUpdate,
            action: 'update_board_of_directors_pep'
        });
      }
    }
  }

  openItem(row) {
    if(isNullOrUndefined(row)) {
      row = {};
    } else {
      row = this.itemList.filter((elem)=> elem.person.identifier ===row)[0];
    }

    this.akeelaSectionService.addModalSection({ name: 'BOARD_DIRECTORS_MODAL' });
    this.akeelaSectionService.showModalSection('BOARD_DIRECTORS_MODAL');
    this.akeelaSectionService.setModalFormValue('BOARD_DIRECTORS_MODAL', row);
  }

  confirmDelete(row: any){
    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }

  deleteItem(row: any) {
    // const relatedPersonId = !isNullOrUndefined(row.organization) ? row.organization.identifier : row.person.identifier;
    // const configuration = {
    //   name: 'account$related_persons$delete',
    //   params: {
    //     path: {
    //       relatedPersonId: relatedPersonId,
    //       transactionNumber: this.transactionNumber,
    //       workflow: this.workflow,
    //       stage: this.stage,
    //       step: this.step
    //     },
    //     request: {
    //       audience: this.queryParams.audience,
    //       role: this.queryParams.role
    //     }
    //   }
    // };
    const configuration = {
      name: 'briefcase$delete-related$delete',
      params: {
        path: {

        },
        request: {
          relatedId: row,
          stepCode: 'PERSONAL_INFORMATION',
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience')  
        },
        body: {}
      }
    };

    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
      swal.fire({
        title: this.i18n.transform('Éxito'),
        html: this.i18n.transform('@i18n-text-information-deleted')
      });
      this.updateList('account$related_person_bod$post');
      this.akeelaWorkflowService.setResetEstatus(true);

    }, error => {
      //dfutrille esto debe borrarse.
      this.updateList('account$related_person_bod$post');
    });
  }

  updateList(action: string){
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }


}
