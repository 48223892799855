<app-new-transaction [selected]="newTransaction" (change)="setNewTransaction($event)"></app-new-transaction>

<div class="row">
    <div class="col-12 col-md-10 offset-md-1 mt-3 pt-2">

        <h4 class="text-secondary mt-4 mb-4">Historial de solicitudes ({{formalities?.length}})</h4>


        <ng-container *ngIf="active && !loading_table">
            <ng-container *ngIf="formalities?.length == 0 ">

                <div class="animate__animated animate__fade mt-3 text-center col-12 border pl-2 pr-2  pt-3 pb-3 section-box">

                    <div>

                        <h5 class="mt-4 pt-1 mb-3">
                            Somos tu aliado de negocios y estamos listos para apoyarte
                            <br class="d-none d-md-block"> Abre tu primera cuenta con
                            <span [innerHTML]="'clientName' | i18n "> </span>
                        </h5>

                        <div (click)="new()" style=" width: fit-content;border-radius: 20px ;" class="btn-new active-cursor mt-5 p-4 border border-primary  section-box ml-auto mr-auto">

                            <div class="">
                                <i class="fas fa-money-check text-primary fa-2x"> </i>
                            </div>

                            <a href="javascript:void(0)" class=" mt-md-0  text-primary">
                    Crear Solicitud
            </a>

                        </div>

                    </div>

                    <div class="text-left lines" [innerHTML]="'@i18n-new-solicitud' | i18n ">
                    </div>


                </div>

            </ng-container>
            <ng-container *ngIf="formalities">
                <div class="d-flex align-items-start">
                    <div class="w-90">
                        <app-sub-header [subtitle]="formalitieI18n" [title]="'Tus solicitudes'"> </app-sub-header>
                    </div>
                    <div class="w-10 align-self-end" *ngIf="env.theme.images !== 'MC' ">
                        <button (click)="new()" type="button" class="btn btn-outline-primary float-right btn-normal">
                    
                    
                    <span   [innerHTML]="'@i18n-new' | i18n "></span>
                       </button>
                    </div>

                </div>
                <div class="animate__animated animate__fadeIn">

                    <div class="pt-4">

                        <ng-container *ngFor="let row of formalities; let first = first; let i_stf = index">

                            <div style="border-radius: 10px;" class="w-100 bg-white section-box mb-4 p-2 p-md-3 ">

                                <div class=" list d-flex bd-highlight list-flex flex-wrap pb-0 pt-0 pb-md-1 pt-md-1">

                                    <div class="mb-1 bd-highlight w-95 order-1  ">

                                        <span class="font-weight-bold ">
                                            {{row?.plan?.name}}
                                        </span>
                                        <span class="fz-14 text-secondary">
                                          / Sol# {{row?.transactionNumber}}
                                        </span>
                                    </div>


                                    <div class="mb-1 bd-highlight  w-5 order-2 pull-right text-right text-primary">

                                        <!-- comenntado por  juan /hay ue revisarlo row?.requestUser?.person?.identifier===usuario_session -->

                                        <i class=" fa fa-trash " aria-hidden="true" *ngIf=" env.theme.images !== 'FACEBANK' &&  row.accountStatus[0].additionalType==='PI_IN_PROCESS' " (click)="confirmDelete(row)"></i>

                                        <i *ngIf="!(cargando == row?.transactionNumber) && row?.accountStatus[0].additionalType == 'AC_INCOMPLETE'" class=" fas fa-eye"></i>
                                        <i *ngIf="!(cargando == row?.transactionNumber) && row?.accountStatus[0].additionalType == 'AC_RECEIVED'" class=" fas fa-check "></i>


                                    </div>

                                    <div class="mb-1 bd-highlight  w-60 w-sm-100 order-3 mb-3 ">
                                        <span class="fz-14  "><span  [innerHTML]="row?.plan?.shortDescription| i18n"></span></span>
                                    </div>


                                    <div class="mb-1 bd-highlight  w-40 w-sm-100 order-4  text-secondary  text-md-right">
                                        <span class="badge badge-secondary pt-1 pb-1">{{ row?.accountStatus? row?.accountStatus[0]?.clientName : '-'}}</span>
                                    </div>

                                    <div class="mb-1 bd-highlight  w-80 order-5 fz-14 text-secondary">
                                        {{ row?.accountStatus?row?.accountStatus[0]?.clientDescription: '-'}}
                                    </div>

                                    <div class="mb-1 bd-highlight  w-20 order-6  small text-right">
                                        {{row?.dateOfThing?.modifiedDate | date: 'MMM d h:mm a' }}
                                    </div>


                                </div>
                                <div class="row" *ngIf="getDataProgress(row?.transactionNumber)">
                                    <div class="col-12 col-md-8">
                                        <!--<div class="d-flex  bd-highlight" *ngIf="row?.accountStatus[0].additionalType =='PI_IN_PROCESS'">
                                            <div class="bd-highlight">
                                                <span class="small text-secondary"> 
                                              
                                                    {{(getDataProgress(row?.transactionNumber))?.name | i18n}}
                                                </span>

                                                <span class="pl-2 small text-dark"> 
                                                    {{(getDataProgress(row?.transactionNumber))?.minValue }}/{{(getDataProgress(row?.transactionNumber))?.maxValue }}
                                                    </span>
                                            </div>
                                        </div>-->

                                        <div class="d-flex  bd-highlight pb-2" *ngIf="row?.accountStatus[0].additionalType =='PI_IN_PROCESS'">
                                            <div class="bd-highlight w-100">
                                                <ng-container>
                                                    <div class=" d-flex bd-highlight w-70 w-sm-100" *ngIf="indicatorsProgres">
                                                        <div class=" w-5">
                                                            <i class="fas fa-align-left text-secondary"></i>
                                                        </div>
                                                        <!--{{ (getDataProgress(row?.transactionNumber))?.percent}}-->
                                                        <div class="line-2 bar w-80 pt-2" style="min-height: 20px">
                                                            <akeela-graphics [percent]="(getDataProgress(row?.transactionNumber))?.percent" [type]="'line'">
                                                            </akeela-graphics>
                                                        </div>
                                                        <div class=" w-15 text-right">
                                                            <span class="pl-2">{{(getDataProgress(row?.transactionNumber))?.percent}}%</span>
                                                        </div>

                                                    </div>
                                                </ng-container>

                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div class="row" *ngIf="row.accountStatus[0].additionalType==='PI_IN_PROCESS' || row.accountStatus[0].additionalType==='AC_INCOMPLETE'">
                                    <div class="col-12 mt-2 text-right">
                                        <button (click)="openFormalities(row?.transactionNumber,true)" type="button" class=" btn btn-primary">
                                            <span [innerHTML]="'Continuar' | i18n "></span>
                                            <span class="fas fa-arrow-right ml-1"></span>
                                        </button>
                                    </div>
                                </div>

                            </div>


                        </ng-container>
                    </div>
                    <div class="w-100 ">
                        <!-- PAGINATOR -->
                        <div class="table-container ">
                            <div class="externo " *ngIf="paginator">
                                <akeela-paginator [paginator]="paginator" (response)="response_paginator($event)">
                                </akeela-paginator>
                            </div>
                        </div>
                    </div>

                    <div class="w-100 text-center mb-3" *ngIf="formalities.length===0">
                        <div>

                            <span class="icon animate__animated animate__fadeIn" [innerHTML]="(formalitieI18n + '-icon') | i18n "></span>
                            <i class="animate__animated animate__fadeIn animate__delay-1s exclamation fas fa-exclamation"></i>
                        </div>
                        <div class=" text-dark mt-2 mb-5 ">

                            <span style="font-size: 1rem" [innerHTML]="'@i18n-register-text-standard-paperwork' | i18n "></span>
                        </div>
                    </div>
                </div>
            </ng-container>


        </ng-container>
        <ng-container *ngIf="loading_table ">
            <div>
                <img src="./assets/public/images/loading.gif" alt="">
            </div>
        </ng-container>

    </div>
</div>