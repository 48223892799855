import { AuthService, ErrorService, KeepAliveService } from '@akeela/auth';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { HttpService } from '@akeela/properties';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { EmailService } from 'src/app/services/email.service';
import { Utf8RegexService } from 'src/app/services/utf8-regex.service';
import swal from 'sweetalert2';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-orders-actions',
  templateUrl: './orders-actions.component.html',
  styleUrls: ['./orders-actions.component.css']
})
export class OrdersActionsComponent implements OnInit,OnDestroy {

    form: FormGroup;
    submit_disabled = false;
    email = "^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$";
    phone = "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{6,6}$";
    identity = "^[JGVEPjgvep][0-9]+$";
    env :any = environment;
    emailSubscription: Subscription;
    sendMailSubscription: Subscription;
    


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _httpService: HttpService,
    private _error: ErrorService,
    private _auth: AuthService,
    private _http: HttpClient,
    private _i18n: I18nPipe,
    private localStorage: LocalService,
    private keepaliveService: KeepAliveService,
    private _utf8: Utf8RegexService,
    private _email: EmailService,
  ) { 
    if ( !(this.env.theme.images === 'MC' || this.env.theme.images === 'AKEELA'  || this.env.theme.images === 'RENDIVALORES' || this.env.theme.images === 'KOI')) {

      const queryParams = {};
      this.router.navigate(['/'], { queryParams: queryParams });
  
    }



  }

  
  ngOnInit() {

    //Validators.pattern(this.email)

    this.form = this.formBuilder.group({

      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      businessName: [''],

      personType : ['NATURAL', [Validators.required]],
      identity: ['', [Validators.required,Validators.pattern(this.identity)]],
      email: ['', [Validators.required,Validators.pattern(this.email)]],
      phone: ['', [Validators.required,Validators.pattern(this.phone)]],
      countActions: ['', [Validators.required]]

     

  });

  

    document.body.classList.add('teme-gray');
  }


  destroySub = () =>{

    this.emailSubscription.unsubscribe();
    this.sendMailSubscription.unsubscribe();

  }
  
newEmailClient = async (data) =>{
  
      
let site_header = this.env.theme.site.name;
let img = '';
     if (!isNullOrUndefined(this.env.theme.image)) {
       if (!isNullOrUndefined(this.env.theme.image.active)) {
         if (this.env.theme.image.active) {
             img = `<img style="height: 40px;" src="${this.env?.theme?.image?.urlImageHeader}"  ></img>`;
         }
       }
     }
     const portal = this.env?.urlFront+'/'+this.env?.staticLinks?.register;

   let  text = `
   
      <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="http://www.w3.org/1999/xhtml">
      
      <head>
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </head>

      <body style="margin:0;font-size: 14px;padding:0;color: #555;">

         <br>
         ${img}
         <br> 
         <br>
         <h3>Hola, ${data?.name} ${data?.lastName} ${data?.personType == 'LEGAL'? '( <span style="font-size: 16px;" > '+data?.businessName+' </span> )' :''}</h3>
         
          Te informamos que tu solicitud de orden fue generada exitosamente <br><br>

          <b>Número de Identificación </b> ${data?.identity} <br>
          <b>Email  </b> ${data?.email} <br>
          <b>Teléfono </b>  ${data?.phone} <br>
          <b>Cantidad de acciones </b>  ${data?.countActions} <br><br>
          ----
          <br><br>
          Nos complace que hayas elegido formar parte de   ${site_header}
          Estás dando el primer paso hacia la transformación digital con la experiencia que te ofrece   ${site_header}<br>
          Para continuar con el proceso, debes crear tu cuenta, registrandote en nuestro portal en linea.

          
          Ingresa   <a href="${portal}" >Aquí</a>

    
      </body>
      
      </html>

   
   `;


   this.sendMail(text,data,'Orden generada exitosamente', data['email']).subscribe((event: any) => {

    this.destroySub();
    
    },error=>{
      this.destroySub();

    });
   

}


newEmailUser = async (data) =>{
        
  let site_header = this.env.theme.site.name;
  let img = '';
       if (!isNullOrUndefined(this.env.theme.image)) {
         if (!isNullOrUndefined(this.env.theme.image.active)) {
           if (this.env.theme.image.active) {
               img = `<img style="height: 40px;" src="${this.env?.theme?.image?.urlImageHeader}"  ></img>`;
           }
         }
       }
       const portal = this.env?.urlClient+'/'+this.env?.staticLinks?.register;
  
     let  text = `
     
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml">
        
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
  
        <body style="margin:0;font-size: 14px;padding:0;color: #555;">
  
           <br>
           ${img}
           <br> 
           <br>
           <h3>  Nueva orden genera </h3>
             
            <b>Nombre </b> ${data?.name} ${data?.lastName} ${data?.personType == 'LEGAL'? '( <span style="font-size: 16px;" > '+data?.businessName+' </span> )' :''} <br>
            <b>Número de Identificación </b> ${data?.identity} <br>
            <b>Email  </b> ${data?.email} <br>
            <b>Teléfono </b>  ${data?.phone} <br>
            <b>Cantidad de acciones </b>  ${data?.countActions} <br><br>
          
        </body>
        
        </html>
  
     
     `;

     
     let emailEjecutive;
     
     if (this.env.theme.images === 'MC' && this.env?.code) {
        
         emailEjecutive = 'ordenes@mercosur.com.ve';

       }else if (this.env.theme.images === 'RENDIVALORES'  && this.env?.code) {
        
        emailEjecutive = 'ordenes@rendivalores.com';
        
       }else if (this.env.theme.images === 'AKEELA') {
        
        emailEjecutive =  data['email'];
       
      }


       
       if(emailEjecutive){
        this.sendMail(text,data, `Orden generada - ${data?.name} ${data?.lastName}`,emailEjecutive).subscribe((event: any) => {
    
          this.destroySub();
          
          },error=>{
    
            this.destroySub();
      
          });


       }
      

     
  
  }

sendMail = (text,data,subject,email )  => {
  this._utf8.utf8Encode(text);

  let emailTo = email;

   return new Observable(resolve => {
     const portal = this.env.theme.site.name;
     const properties: object = {};

    
     const email_message = {
      identifier: 1,
      dateOfThing: {},
      status: null,
      sender: null,
      text: null,
      type: 'text/html',
      subjectOf: subject,
      toRecipient: emailTo
    };
    const template = this._email.getTemplate(properties);

      const response = this._email.sendMail(
        email_message,
        properties,
        text
      );


       this.sendMailSubscription = response.subscribe(mail_result => {
         resolve.next(mail_result);
         resolve.complete();
       }, error => {

         resolve.next(false);
         resolve.complete();

       });
    

   });

 }

  ngOnDestroy() {
    document.body.classList.remove('teme-gray');
  }

 
  submit= async () => {
    this.submit_disabled = true;
    if (this.form.dirty && this.form.valid) {

      swal.fire({
        title: '',
        html: '¿Estas seguro de crear esta orden?',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',      
        reverseButtons: true
      }).then( async (result)   => {
    
        if(result?.isConfirmed){

    

          let isGuest = true;

      try {


      let form =  this.form.getRawValue();
      
      let objResp = {
        
          personType: form?.personType,
          name:  form?.name ,
          lastName: form?.lastName ,
          businessName: form?.businessName ,
          email: form?.email ,
          phone: form?.phone ,
          executiveUsername: '', 
          application:this.env?.theme?.images,
          extraData:'order'

          
      }

 
      const params = {
    
        name: 'person$create_guest$post',
        params: {
          request: {
          },
          path: {},
          body: objResp
        }
      };
      

        let response2 = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();
       
      } catch (error) {

        if (!(error?.error?.code == "AKC-008")) {

          isGuest = false;
          this._error.show(error);
          this.submit_disabled = false;

        }
     
      }
    
      if (isGuest) {

      try {

  
        let response = await  this.saveOrder();

        if(response){
          let data =  this.form.getRawValue();
          this.newEmailUser(data);
          this.newEmailClient(data);
          
          this.form.reset();
          swal.fire({
            reverseButtons: true,
            confirmButtonText: 'Ok',
            html: 'Orden creada exitosamente',
          });
  
       
      this.submit_disabled = false;
                 
    }

      } catch (error) {
        
         this._error.show(error);
         this.submit_disabled = false;


      }
    }

    

    }});
    
    }
  }



  saveOrder = async () => {
    const pathResponse = {
      url: 'https://orders.akeela.co/client',
      httpMethod: 'POST'
    };

    let form =  this.form.getRawValue();
    const formData = new FormData();
      formData.append("params", JSON.stringify({
        "environment_code": this.env?.code || this.env?.theme?.images,
        "identity_number": form?.identity,
        "first_name": form?.name,
        "legal_name": form?.businessName,
        "family_name": form?.lastName,
        "person_type": form?.personType,
        "shares": form?.countActions,
        "phone": form?.phone,
        "email": form?.email,
        "order_type": "Purchase",
        "instrument_type": "bonds",
        "market": "primary",
        "company": "CANTV",
        "ticket": "",
        "stocks": "",
        "price": 0,
        "total": 0,
        "fees":  0,
        "source": "0",
        "status": "receved",
        "note": "",
        "user_id": ""
      })
    );

    return  this._httpService.executeExternalFrontRequestMultipart(pathResponse, formData, {}).toPromise();
  }

}
