import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined, isNull, log } from 'util';
import { AlertService } from '@akeela/alert';
import { Alert } from 'selenium-webdriver';
import { Subscription } from 'rxjs';
import { Table, Row, Column } from '@akeela/ak-table';
import { LocalService } from '@akeela/local-storage';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-section-suppliers',
  templateUrl: './section-suppliers.component.html',
  styleUrls: ['./section-suppliers.component.css']
})
export class SectionSuppliersComponent implements OnInit, OnDestroy {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  active_redirect = false;
  readonlyMode = false;

  checkDoNotHaveSuppliers = false;
  showLoading = false;


  loading_table = false;
  configuration_table: Table;
  paramsSubscription: Subscription;
  env = null;
  hideTop = false;


  private workflowEventsSubscription: Subscription;


  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private _alert: AlertService,
    private localStorage: LocalService

  ) { }

  ngOnInit() {
    this.env = environment;
    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();
    if(this.env) {
      if(this.env?.theme?.images==="MC") {
        this.hideTop = true;
      }
    }

    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
    });


    this.akeelaWorkflowService.transactionNumber.subscribe(transactionNumber => {
      if (transactionNumber) {
        this.transactionNumber = transactionNumber;
      }
    });
    this.getItemList();

    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }

    this.workflowEventsSubscription =  this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {

      if (!isNullOrUndefined(response) && (response.action === 'account_contacts$customer-contacts$post')) {
        this.getItemList();

      } else if (!isNullOrUndefined(response) && (response.action === 'account_contacts$delete-contact$delete')) {
        this.getItemList();
        
      } else if (!isNullOrUndefined(response) && response.action === 'receive_formValidationEvent') {
        this.akeelaWorkflowService.worflowEvents$.next(null);
      }
    });

  }

  setShowLoading(value: boolean) {
    this.showLoading = value;
  }
  setCheckDoNotHaveSuppliers(value: boolean) {
    if (!isNullOrUndefined(value)) {
      this.checkDoNotHaveSuppliers = value;
    } else {
      this.checkDoNotHaveSuppliers = false;
    }

  }

  getItemList() {

    const params = {
      name: 'account_contacts$contacts$get',
      params: {
        request: {
          idPerson: this.localStorage.getItem('clientIdentifier'),
          contactType:'SUPPLIER'
        },
        path: {
        },
        body: {}
      }
    };
    this.setShowLoading(true);


    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.itemList = response;


      // row['disableWhenUpdate'] = "applicantType";

      this.setConfigurationTable();
      this.akeelaWorkflowService.setResetEstatus(true);

      this.setShowLoading(false);
    }, error => {
      this.setShowLoading(false);
    });
  }

  openItem(row: any) {
    console.log(row);
    this.akeelaSectionService.addModalSection({ name: 'SUPPLIERS_MODAL' });
    this.akeelaSectionService.showModalSection('SUPPLIERS_MODAL', true);

    let auxItems = [];
    auxItems['applicantType'] = row.alternateName;
    auxItems = Object.assign({}, auxItems);
    row = Object.assign(auxItems, row);
    

    // Mario: hay un caso en que si se agregan varios tipos de personas relacionadas, y se edita una persona natural y luego una persona legal, se pasa tanto el identifier de la persona legal, pero tambien se pasa el identifier de la persona natural, esto
    // genera un bug, ya que se borran los datos de la persona natural
    // entonces para solventar esto, se agrega una condición, para que si se va a guardar una persona legal, se vacie el identificador de la natural.
    // personContact.identifier;
    // if (row.alternateName === 'LEGAL') {
    //   console.log('ES LEGAL');
    //   delete row['personContact'];
    //   row['personContact'] = null;
    // } else {
    //   console.log('ES NATURAL');
    //   delete row['organizationContact'];
    //   row['organizationContact'] = null;
    // }
    
    delete row['alternateName'];
    this.akeelaSectionService.setModalFormValue('SUPPLIERS_MODAL', row);
  }

  confirmDelete(row: any) {
    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }

  deleteItem(row: any) {


    const contactId = !isNullOrUndefined(row.organizationContact) && !isNullOrUndefined(row.organizationContact.identifier) ? row.organizationContact.identifier : row.personContact.identifier;
    const configuration = {
      name: 'account_contacts$delete-contact$delete',
      params: {
        path: {
        },
        request: {
          contactId: contactId,
          idPerson: this.localStorage.getItem('clientIdentifier'),
          contactType:'SUPPLIER',
          currentStepCode:'RELATED_PERSONS',
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience')
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
      swal.fire({
        title: this.i18n.transform('Éxito'),
        html: this.i18n.transform('@i18n-text-information-deleted')
      });
      this.updateList('account_contacts$delete-contact$delete');
      this.akeelaWorkflowService.setResetEstatus(true);

    }, error => {
      //dfutrille esto debe borrarse.
      this.updateList('account_contacts$delete-contact$delete');
    });
  }

  updateList(action: string) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }

  ngOnDestroy() {
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }



  /**tabla */

  setConfigurationTable() {
    const base: Column[] = this.setHeadTable();
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'class-color-title-table-section',
        rows: [{
          backgroundColor: '#fff',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };
    console.log("configuration_table", this.configuration_table);


  }

  setHeadTable() {

    const base: Column[] = [
      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '100',
        html: 'Identificación',
        cssCustom: '',
        enableRightBorder: true

      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '400',
        minWidth: '300',
        html: 'Tipo',
        cssCustom: '',

      }
      ,
      {
        alignContent: 'right',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      },
      {
        alignContent: 'right',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      }
    ]
    return base;
  }

  setContentTable(configuration): Row[] {
    const content: Row[] = [];
    let i = 0;
    console.log("setContentTable setContentTable : ");

    for (const row of this.itemList) {
      i++;

      let temp = '-';
      if (row.alternateName == 'NATURAL') {

        temp = '@i18n-quote-option-list-applicantType-option-natural';

      } else if (row.alternateName == 'LEGAL') {
        temp = '@i18n-quote-option-list-applicantType-option-legal';


      }


      content.push(
        {
          identifier: row.identifier,
          cssCustom: '',
          enabled: true,
          columns: [
            this.setRowContentTable({

              configuration,
              number: 0,
              html: row.personContact ? this.capitalize(row.personContact.givenName) + " " + this.capitalize(row.personContact.familyName) : this.capitalize(row.organizationContact.legalName),
              cssCustom: 'text-capitalize'

            }),

            this.setRowContentTable({
              configuration,
              number: 1,
              html: this.i18n.transform(temp),
            }),

            this.setRowContentTable({

              configuration,
              number: 2,
              html: 'Ver',
              cssCustom: 'ak-tabled-row-active text-capitalize'
  
            }),

            this.setRowContentTable({
              configuration,
              number: 3,
              html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
              click: 'delete_item',
            })
          ]
        }
      );
    }
    return content;
  }


  capitalize(word) {
    if(word && word[0]){
      return word[0].toUpperCase() + word.slice(1);
   
    }
    return '';
  }


  setRowContentTable(row) {

    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click ? row.click : null;
    let dropdown = row.dropdown ? row.dropdown : null;
    let cssCustom = row.cssCustom ? row.cssCustom : configuration[number].cssCustom;


    let result = {

      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click: click,
      dropdown: dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }

    return result;

  }

  table_response(response) {
    if (response.action === 'open') {

      let row = this.itemList.find((item: any) => {
        return item.identifier === response.object.identifier;
      });
      this.openItem(row);

    } else if (response.action === 'action') {
      if (response.object.action === 'delete_item' && !this.readonlyMode) {

        let row = this.itemList.find((item: any) => {
          return item.identifier === response.object.obj.identifier;
        });
        this.confirmDelete(row);
      }
    }

  }

}



