<div class="row pt-4">
    <div class="ak-section bg-white col-12">
  
  
      <div class="row">
  
        <div class="ak-section col-12 col-md-8 ">
          <!-- <div class="row p-2">
            <div class="col-12">
              <div class="pl-0 pr-0">
                <div class="w-100  mb-3 sub-section animate__animated animate__fadeIn">
                  <h4 class="w-100 flex-fill title-section mb-3">Recaudos</h4>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row pl-2 pr-2 pb-2">
            <div class="col-12">
              <div class="pl-2 pr-0">
                <ak-requirements-briefcase *ngIf="env?.requirements?.briefcase===true && dataInformation" [dataInformation]="dataInformation" ></ak-requirements-briefcase>

              </div>
            </div>
          </div> 

  
         
        </div>
  
        <div class="d-none d-md-block col-md-4 ak-border-left-box-right">
          <div class="p-1 pl-2  mt-2 mb-2 ">
              <div>
                  <div class="base-description-section-top mb-2 font-weight-bold">
                  Recaudos
                  </div>
          
                  <div class="text-form-description ">
                  Podrá adjuntar sus recaudos para que sean vinculados a sus próximas solicitudes.
                  </div>
               </div>
          </div>
        </div>


      </div>
    </div>
  </div>