<div class="row pt-4">
  <div class="ak-section bg-white col-12">


    <div class="row">

      <div class="ak-section col-12 col-md-8 ">
        <div class="row p-2">
          <div class="col-12">
            <div class="pl-0 pr-0">
              <div class="w-100  mb-3 sub-section animate__animated animate__fadeIn">
                <h4 class="w-100 flex-fill title-section mb-3">Actualización de Datos Personales</h4>
              </div>
            </div>
          </div>
        </div>

        <form [formGroup]="form" (ngSubmit)="save()">

          <div class="row pt-2 pl-2 pr-2">
            <div class="col-6">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Documento de Identidad<span class="text-danger"> *</span>
              </label>
              <select
                class="form-control"
                id="type_identity"
                formControlName="type_identity"
                readonly="readonly" disabled="disabled"
                [ngClass]="{
                  error:
                    form.controls.type_identity.invalid &&
                    form.controls.type_identity.dirty,
                  valid: form.controls.type_identity.valid
                }"
              >
                <option value="1">Cédula de Identidad</option>
                <option value="2">Pasaporte</option>
              </select>
              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('type_identity').errors"
              >
                <div
                  *ngIf="
                    form.get('type_identity').errors['required'] &&
                    !form.get('type_identity').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
            <div class="col-6">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Número de Documento <span class="text-danger"> *</span>
              </label>
              <input
                id="identity"
                class="form-control"
                type="text"
                formControlName="identity"
                readonly="readonly" disabled="disabled"

                [ngClass]="{
                  error:
                    form.controls.identity.invalid &&
                    form.controls.identity.dirty,
                  valid: form.controls.identity.valid
                }"
              />
              <div class="w-100 mb-2 mt-1 pl-1 pr-1" style="line-height: 1rem;">
                <span class="form-control-feedback small text-secondary">Cédula V11777888, Pasaporte P555555, Extranjero E11777888</span>
              </div>

              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('identity').errors"
              >
                <div
                  *ngIf="
                    form.get('identity').errors['required'] &&
                    !form.get('identity').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
          </div>

          
          <div class="row pl-2 pr-2">
            <div class="col-6">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Primer Nombre<span class="text-danger"> *</span>
              </label>
              <input
                id="givenName"
                class="form-control"
                type="text"
                formControlName="givenName"
                [ngClass]="{
                  error:
                    form.controls.givenName.invalid &&
                    form.controls.givenName.dirty,
                  valid: form.controls.givenName.valid
                }"
              />

              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('givenName').errors"
              >
                <div
                  *ngIf="
                    form.get('givenName').errors['required'] &&
                    !form.get('givenName').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
            <div class="col-6">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Segundo Nombre
              </label>
              <input
                id="name"
                class="form-control"
                type="text"
                formControlName="name"
                [ngClass]="{
                  error: form.controls.name.invalid && form.controls.name.dirty,
                  valid: form.controls.name.valid
                }"
              />

              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('name').errors"
              >
                <div
                  *ngIf="
                    form.get('name').errors['required'] &&
                    !form.get('name').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
          </div>

          

          <div class="row pl-2 pr-2">
            <div class="col-6">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Primer Apellido<span class="text-danger"> *</span>
              </label>
              <input
                id="familyName"
                class="form-control"
                type="text"
                formControlName="familyName"
                [ngClass]="{
                  error:
                    form.controls.familyName.invalid &&
                    form.controls.familyName.dirty,
                  valid: form.controls.familyName.valid
                }"
              />

              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('familyName').errors"
              >
                <div
                  *ngIf="
                    form.get('familyName').errors['required'] &&
                    !form.get('familyName').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
            <div class="col-6">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Segundo Apellido
              </label>
              <input
                id="lastName"
                class="form-control"
                type="text"
                formControlName="lastName"
                [ngClass]="{
                  error:
                    form.controls.lastName.invalid &&
                    form.controls.lastName.dirty,
                  valid: form.controls.lastName.valid
                }"
              />

              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('lastName').errors"
              >
                <div
                  *ngIf="
                    form.get('lastName').errors['required'] &&
                    !form.get('lastName').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
          </div>

          

          

          

          <div class="row pl-2 pr-2">
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Registro de Identificación Fiscal (RIF) Personal
              </label>
              <input
                id="taxID"
                class="form-control"
                type="text"
                formControlName="taxID"
                [ngClass]="{
                  error:
                    form.controls.taxID.invalid && form.controls.taxID.dirty,
                  valid: form.controls.taxID.valid
                }"
              />

              <div class="w-100 mb-2 mt-1 pl-1 pr-1" style="line-height: 1rem;">
                <span class="form-control-feedback small text-secondary">Ej. J191635850</span>
              </div>


              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('taxID').errors"
              >
                <div
                  *ngIf="
                    form.get('taxID').errors['required'] &&
                    !form.get('taxID').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
          </div>


          <div class="row pl-2 pr-2 pt-5">
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Seleccione su género <span class="text-danger"> *</span>
              </label>
              <input
                id="gender"
                class="form-control"
                type="hidden"
                formControlName="gender"
              />
              <div class="row ">
                <div class="col-6 col-md-6 mb-3">
                  <!-- (click)="legalForm = false; updateRequiredValidations('NATURAL');resetClientFormExcludeAttributes(['type_person'])"-->
                  <button type="button" (click)="setGender('F')"
                    class="btn btn-block base-style-option-button"
                    [class.active]="form.get('gender').value=='F'">
                    <i class="fa-female fas pr-2 text-secondary"></i> Femenino
                  </button>
                </div>
                <div class="col-6 col-md-6 mb-3">
                  <button type="button" (click)="setGender('M')"
                    class="btn btn-block base-style-option-button"
                    [class.active]="form.get('gender').value=='M'">
                  <i class="fa-male fas pr-2 text-secondary"></i> Masculino
                  </button>
                </div>
              </div>




              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('gender').errors"
              >
                <div
                  *ngIf="
                    form.get('gender').errors['required'] &&
                    !form.get('gender').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
          </div>



          <div class="row pt-2 pl-2 pr-2">
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Nacionalidad<span class="text-danger"> *</span>
              </label>
              <div *ngIf="countryList$ | async as countryList; else loading">
                <select
                  class="form-control"
                  id="nationality"
                  formControlName="nationality"
                  [ngClass]="{'error': form.controls.nationality.invalid && form.controls.nationality.dirty,
                  'valid': form.controls.nationality.valid
                }" 
                >
                  <option value="">Seleccione</option>
                  <option
                    *ngFor="let country of countryList"
                    [ngValue]="country.alternateName"
                  >
                    {{ country.nationality }}
                  </option>
                </select>
                <div class="form-control-feedback help-block" *ngIf="form.get('nationality').errors">
                  <div *ngIf="form.get('nationality').errors['required'] && !form.get('nationality').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                </div>
              </div>
              </div>
              <ng-template #loading>
                <div>
                  <i
                    class="fas fa-circle-notch fa-spin load-select text-primary"
                  ></i>
                </div>
              </ng-template>
            </div>
  
            
          </div>

          <div class="row pt-2 pl-2 pr-2">
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Otra nacionalidad
              </label>
              <div *ngIf="countryList$ | async as countryList; else loading">
                <select
                  class="form-control"
                  id="otherNationality"
                  formControlName="otherNationality"
                  [ngClass]="{'error': form.controls.otherNationality.invalid && form.controls.otherNationality.dirty,
                  'valid': form.controls.otherNationality.valid
                }" 
                >
                  <option value="">Seleccione</option>
                  <option
                    *ngFor="let country of countryList"
                    [ngValue]="country.alternateName"
                  >
                    {{ country.nationality }}
                  </option>
                </select>
                <div class="form-control-feedback help-block" *ngIf="form.get('otherNationality').errors">
                  <div *ngIf="form.get('otherNationality').errors['required'] && !form.get('otherNationality').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                </div>
              </div>
              </div>
              <ng-template #loading>
                <div>
                  <i
                    class="fas fa-circle-notch fa-spin load-select text-primary"
                  ></i>
                </div>
              </ng-template>
            </div>
  
            
          </div>

          <div class="row pt-2 pl-2 pr-2">
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Profesión u oficio <span class="text-danger"> *</span>
              </label>
              <div *ngIf="occupationList$ | async as occupationList; else loading">
                <select
                  class="form-control"
                  id="profession_job"
                  formControlName="profession_job"
                  [ngClass]="{'error': form.controls.profession_job.invalid && form.controls.profession_job.dirty,
                  'valid': form.controls.profession_job.valid
                }" 
                >
                  <option value="">Seleccione</option>
                  <option
                    *ngFor="let occupation of occupationList"
                    [ngValue]="occupation.item.alternateName"
                  >
                    {{ occupation.name | i18n }}
                  </option>
                </select>
                <div class="form-control-feedback help-block" *ngIf="form.get('profession_job').errors">
                  <div *ngIf="form.get('profession_job').errors['required'] && !form.get('profession_job').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                </div>
              </div>
              </div>
              <ng-template #loading>
                <div>
                  <i
                    class="fas fa-circle-notch fa-spin load-select text-primary"
                  ></i>
                </div>
              </ng-template>
            </div>
  
            
          </div>

          <div class="row pt-2 pl-2 pr-2">
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Seleccione su Estado Civil <span class="text-danger"> *</span>
              </label>
              <div *ngIf="civilStatusList$ | async as civilStatusList; else loading">
                <select
                  class="form-control"
                  id="civil_status"
                  formControlName="civil_status"
                  [ngClass]="{'error': form.controls.civil_status.invalid && form.controls.civil_status.dirty,
                  'valid': form.controls.civil_status.valid
                }" 
                >
                  <option value="">Seleccione</option>
                  <option
                    *ngFor="let civilStatus of civilStatusList"
                    [ngValue]="civilStatus.item.alternateName"
                  >
                    {{ civilStatus.name | i18n }}
                  </option>
                </select>
                <div class="form-control-feedback help-block" *ngIf="form.get('civil_status').errors">
                  <div *ngIf="form.get('civil_status').errors['required'] && !form.get('civil_status').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                </div>
              </div>
              </div>
              <ng-template #loading>
                <div>
                  <i
                    class="fas fa-circle-notch fa-spin load-select text-primary"
                  ></i>
                </div>
              </ng-template>
            </div>
  
            
          </div>


          <div class="row pl-2 pr-2">
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Carga familiar
              </label>
              <input
                id="family_burden"
                class="form-control"
                type="number"
                formControlName="family_burden"
                [ngClass]="{
                  error:
                    form.controls.family_burden.invalid &&
                    form.controls.family_burden.dirty,
                  valid: form.controls.family_burden.valid
                }"
              />

              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('family_burden').errors"
              >
                <div
                  *ngIf="
                    form.get('family_burden').errors['required'] &&
                    !form.get('family_burden').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
          </div>


          <div class="w-100 pt-5 mb-3 sub-section animate__animated animate__fadeIn">
            <span class="pr-2 pl-2">Información de Nacimiento</span>
          </div>

          <div class="row pl-2 pr-2">
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Fecha
              </label>
              <input
                id="birthdate"
                class="form-control"
                type="date"
                formControlName="birthdate"
                [ngClass]="{
                  error:
                    form.controls.birthdate.invalid &&
                    form.controls.birthdate.dirty,
                  valid: form.controls.birthdate.valid
                }"
              />

              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('birthdate').errors"
              >
                <div
                  *ngIf="
                    form.get('birthdate').errors['required'] &&
                    !form.get('birthdate').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
          </div>



          <div class="row pt-2 pl-2 pr-2">
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                País<span class="text-danger"> *</span>
              </label>
              <div *ngIf="countryList$ | async as countryList; else loading">
                <select
                  class="form-control"
                  id="country"
                  formControlName="country"
                  [ngClass]="{'error': form.controls.country.invalid && form.controls.country.dirty,
                  'valid': form.controls.country.valid
                }" 
                  (change)="update('country', 'states')"
                >
                  <option value="">Seleccione</option>
                  <option
                    *ngFor="let country of countryList"
                    [ngValue]="country.alternateName"
                  >
                    {{ country.description }}
                  </option>
                </select>
                <div class="form-control-feedback help-block" *ngIf="form.get('country').errors">
                  <div *ngIf="form.get('country').errors['required'] && !form.get('country').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                </div>
              </div>
              </div>
              <ng-template #loading>
                <div>
                  <i
                    class="fas fa-circle-notch fa-spin load-select text-primary"
                  ></i>
                </div>
              </ng-template>
            </div>
  
            
          </div>

          <div class="row pt-2 pl-2 pr-2">
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Estado<span class="text-danger"> *</span>
              </label>
              
                <select
                  class="form-control"
                  id="state"
                  formControlName="state"
                  [ngClass]="{'error': form.controls.state.invalid && form.controls.state.dirty,
                  'valid': form.controls.state.valid
                }" 
                  (change)="update('state', 'city')"
                >
                  <option value="">Seleccione</option>
                  <ng-container *ngIf="stateList$ | async as stateList; else loading">
                    <option
                    *ngFor="let state of stateList"
                    [ngValue]="state.identifier"
                  >
                    {{ state.name }}
                  </option>
                  </ng-container>
                  
                </select>
                 <div class="form-control-feedback help-block" *ngIf="form.get('state').errors">
                    <div *ngIf="form.get('state').errors['required'] && !form.get('state').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                    </div>
                  </div>
              
              <ng-template #loading>
                <div>
                  <i
                    class="fas fa-circle-notch fa-spin load-select text-primary"
                  ></i>
                </div>
              </ng-template>
            </div>
  
            
          </div>

          <div class="row pt-2 pl-2 pr-2">
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                Ciudad<span class="text-danger"> *</span>
              </label>
              
                <select
                  class="form-control"
                  id="city"
                  formControlName="city"
                  [ngClass]="{'error': form.controls.city.invalid && form.controls.city.dirty,
                  'valid': form.controls.city.valid
                }" 
                >
                  <option value="">Seleccione</option>
                  <ng-container *ngIf="cityList$ | async as cityList; else loading">
                    <option
                    *ngFor="let city of cityList"
                    [ngValue]="city.identifier"
                  >
                    {{ city.name }}
                  </option>
                  </ng-container>
                  
                </select>
                <div class="form-control-feedback help-block" *ngIf="form.get('city').errors">
                  <div *ngIf="form.get('city').errors['required'] && !form.get('city').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                </div>
              </div>
              
              <ng-template #loading>
                <div>
                  <i
                    class="fas fa-circle-notch fa-spin load-select text-primary"
                  ></i>
                </div>
              </ng-template>
            </div>
  
            
          </div>













          <div class="row pl-2 pr-2">
            <div class="col-12">
              <div class="text-left w-100 m-t-1 btn-section-save">
                <!-- <pre>{{ form.value | json }}</pre> -->
                <button
                  [disabled]="!form.valid || loading_form"
                  type="submit"
                  class="btn btn-primary"
                >
                  <span>Guardar</span
                  ><span class="fas fa-arrow-right ml-1" *ngIf="form.valid && !loading_form"> </span><span class="fas fa-circle-notch fa-spin ml-1" *ngIf="loading_form"> </span>
                </button>
              </div>
            </div>
          </div>



        </form>
      </div>

      <div class="d-none d-md-block col-md-4 ak-border-left-box-right">
        <div class="p-1 pl-2  mt-2 mb-2 ">
            <div>
                <div class="base-description-section-top mb-2 font-weight-bold">
                Datos Básicos
                </div>
        
                <div class="text-form-description ">
                Se refiere a su información personal, como: nombres, apellidos, estado civil, edad, género, profesión y ocupación.<br><br>Esta información estará visible a su ejecutivo asignado. De este modo, le resultará más fácil ponerse en contacto con usted.
                </div>
             </div>
        </div>
      </div>
    </div>
  </div>
</div>
