import { Injectable } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class TransitionService {

  showWorkflow$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  compleatTransaction$: BehaviorSubject<boolean> = new BehaviorSubject(null);


  constructor(
    private _httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) { }


  setCompleatTransaction(compleatTransaction: boolean) {
    this.compleatTransaction$.next(compleatTransaction);
  }

  next(prefix, params, queryParams?) {
    if (!params) params = {};
    if (!prefix) prefix = '';
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      let query;
      if (queryParams) {
        query = queryParams;
      } else {
        query = {  identifierProduct: params.o.identifierProduct }
      }

      this.router.navigate([prefix + '/ACCOUNT_OPENING/stages/' + response.nextStep.stageParentCode + '/steps/' + response.nextStep.alternateName], {queryParams: query});
      // this.router.navigateByUrl(this.router.createUrlTree([prefix + '/ACCOUNT_OPENING/stages/' + response.nextStep.stageParentCode + '/steps/' + response.nextStep.alternateName],
      //   {
      //     queryParams: query
      //   }
      // )
      // );

      // const navigationExtras: NavigationExtras = {
      //   queryParamsHandling: 'preserve',
      //   preserveFragment: true
      // };
      // this.router.navigate([prefix + '/ACCOUNT_OPENING/stages/' + response.nextStep.stageParentCode + '/steps/' + response.nextStep.alternateName], navigationExtras);

    });
  }

  nextStep(prefix, nextStep, params?, queryParams?) {
    this.router.navigate([prefix + '/' + environment.workflow + '/stages/' + nextStep.stageParentCode + '/steps/' + nextStep.alternateName], { queryParams: queryParams });
  }

}
