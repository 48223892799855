<div class="row" *ngIf="balancerequirements">
  <div class="col-4 col-md-2 text-center p-3 ">
    <div class="w-100 rectagle-container">
      <span class="rectangle-container-label" [innerHTML]="'@i18n-requirements-progress-attach' | i18n"></span>
      <div [innerHTML]="balancerequirements.total_requirements_completed+' / '+balancerequirements.total_requirements"></div>
    </div>
  </div>
  <div class="col-4 col-md-2 text-center p-3">
    <div class="w-100 rectagle-container">
      <span class="rectangle-container-label" [innerHTML]="'@i18n-requirements-progress-approved' | i18n"></span>
      <div [innerHTML]="balancerequirements.total_required_responses_user_requirements_completed_in_status_verified !== 0 ?balancerequirements.total_required_responses_user_requirements_completed_in_status_verified:'-'"></div>
    </div>
  </div>
  <div class="col-4 col-md-2 text-center p-3">
    <div class="w-100 rectagle-container">
      <span class="rectangle-container-label" [innerHTML]="'@i18n-requirements-progress-incompleted' | i18n"></span>
      <div [innerHTML]="balancerequirements.total_required_responses_user_requirements_completed_in_status_incompleted !== 0 ?balancerequirements.total_required_responses_user_requirements_completed_in_status_incompleted: '-'"></div>
    </div>
  </div>
  <div class="col-12 d-md-none">

  </div>
  <div class="col-4 col-md-2 text-center p-3">
    <div class="w-100 rectagle-container">
      <span class="rectangle-container-label" [innerHTML]="'@i18n-requirements-progress-rejected' | i18n"></span>
      <div [innerHTML]="balancerequirements.total_required_responses_user_requirements_completed_in_status_rejected !== 0 ?balancerequirements.total_required_responses_user_requirements_completed_in_status_rejected:'-'"></div>
    </div>
  </div>

  <!-- <div class="col-4 col-md-2 text-center p-3">
    <div class="w-100 rectagle-container">
      <span class="rectangle-container-label" [innerHTML]="'@i18n-requirements-progress-inconsistent' | i18n"></span>
      <div [innerHTML]="balancerequirements.total_required_responses_user_requirements_completed_in_status_inconsistent !== 0 ? balancerequirements.total_required_responses_user_requirements_completed_in_status_inconsistent:
          '-'"></div>
    </div>
  </div> -->
</div>