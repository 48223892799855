<div class=" mb-4">
    <div class="row  mt-3 text-left">

        <div class="col-12 col-md-8 ">

            <h4 class="w-100 title-section mb-3">Enviar solicitud</h4>

            <div>

                <div>
                    Por favor, asegúrese de que la siguiente información sea correcta. Lo usaremos para generar sus documentos legales.<br> <br> Al enviar nuestra institución revisará y responderá a la brevedad posible. Las solicitudes se atienden por
                    orden de llegada.

                </div>

                <div class="mt-4 font-weight-bold">
                    Solicitante
                </div>

                <div class="text-ligth w-sm-100 w-60 section-box  p-3 mt-3 ">

                    <div class="text-secondary fz-14">Persona Natural</div>
                    <div class="text-dark text-capitalize">Juan Soto</div>

                    <div class="text-secondary fz-14 mt-3">Documento de Identidad</div>
                    <div class="text-dark text-capitalize">CI, 11.3123.323</div>

                    <div class="text-secondary fz-14 mt-3">Dirección</div>
                    <div class="text-dark text-capitalize">Lorem ipsum</div>

                </div>expression


                <div class="mt-4 ">

                    <button class="btn btn-primary ">Enviar
                        <span class="fas fa-paper-plane ml-1"></span>
                    </button>

                </div>

                <div class=" fz-14 pt-2">
                    <!-- 
                    <div style="max-height: 400px;overflow: auto" [innerHTML]="'@i18n-quote-label-termsAndConditionsDescriptions' | i18n"></div>
-->
                    <div [innerHTML]="'@i18n-quote-termsAndConditions' | i18n"> </div>

                </div>


            </div>
        </div>

    </div>
</div>