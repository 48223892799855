<app-template>
    <ng-template appContent componentName="mainContentModalCenter">

        <div *ngIf="centerModal" class="ak-full-modal-3 capa-menu-2 " style="height: 100vh!important;" (click)="setClose()">
            <div class="container-fluid">


                <div class="row">
                    <div class="col-12 col-md-6 offset-md-3">

                        <div (click)="$event.stopPropagation();" class="w-100 bg-white mt-3 body p-3" style="overflow:auto">

                            <div style="min-height: 206px;">

                                <h6 class="font-weight-bold  p-2">Ingresa el Código de Seguridad</h6>
                                <hr>
                                <ng-container *ngIf="loadingSentValidateEmail">
                                    <div class="container animate__animated animate__fadeIn text-center">
                                        Por favor espere Enviando mensaje...
                                        <div class="mt-2">
                                            <img src="./assets/public/images/loading.gif" alt="">
                                        </div>
                                    </div>
                                </ng-container>

                                <div *ngIf="!loadingSentValidateEmail && sentValidateEmail && timeValid">
                                    <div class="mt-4 text-dark">Por favor revisa tu Correo electrónico, se te ha enviado un código de seguridad.</div>
                                    <div class="row mt-4">
                                        <div class="col-12 col-md-5">
                                            <div class="form-group">
                                                <input type="text" min="0" max="6" minlength="0" maxlength="6" class="form-control" [(ngModel)]="code" placeholder="código">
                                                <div class="text-secondary fz-14 mt-1">Tiempo <span class="font-weight-bold">  {{minutes>0 ? minutes +":" : '' }}{{seconds}}</span></div>
                                                <label *ngIf="errorMsgValidatEmail" class="form-check-label text-danger">{{errorMsgValidatEmail}}</label>
                                            </div>

                                        </div>
                                        <div class="col-12 col-md-7">

                                            <div class="font-weight-bold fz-14">Te enviamos un código al correo electrónico: </div>
                                            <div class="text-secondary fz-14">{{data}}</div>
                                        </div>


                                    </div>


                                </div>

                                <div *ngIf="!loadingSentValidateEmail && !timeValid" class="text-center">

                                    <div class="mt-4 text-dark">Tiempo expirado, reenvia el código al correo electrónico </div>

                                    <div class=" w-100">
                                        <div class="mt-3">
                                            <i class=" text-warning pl-2 pr-2 fas fa-info-circle fa-3x" aria-hidden="true"></i>

                                        </div>

                                        <div class="mt-2">
                                            <b>( {{data}} )</b><br>
                                            <span class="text-primary active-cursor" (click)="sendMsgValidateEmail(data)">Reenviar</span>
                                        </div>
                                    </div>
                                </div>


                                <div *ngIf="!loadingSentValidateEmail && sentValidateErrorEmail " class="text-center">
                                    <div class="mt-4 text-dark">Ocurrio un error al enviar el mensaje. Por favor revisa tu Correo electrónico, y vuelve a validar </div>

                                    <div class=" w-100">
                                        <div class="mt-3">
                                            <i class=" text-warning pl-2 pr-2 fas fa-info-circle fa-3x" aria-hidden="true"></i>

                                        </div>

                                        <div class="mt-2">
                                            <b>( {{data}} )</b><br>
                                            <span class="text-primary active-cursor" (click)="sendMsgValidateEmail(data)">Validar</span>
                                        </div>



                                    </div>


                                </div>


                            </div>
                            <div class="mt-4 text-right" *ngIf="sentValidateEmail || sentValidateErrorEmail">
                                <hr>
                                <span class="float-left text-primary active-cursor mt-2" (click)="sendMsgValidateEmail(data)">Reenviar</span>

                                <button class="btn btn-secondary mr-1" (click)="setClose()"> 
                                Cancelar 
                             </button>
                                <button *ngIf="sentValidateEmail" [disabled]="code?.length < 6 || loadingValidateCode " class="btn btn-primary" (click)="getCodeValidateEmail(data,code)"> 
                       
                                    <span *ngIf="!loadingValidateCode" >Continuar</span>
                                   <span *ngIf="loadingValidateCode" >Enviando...</span>
                           
                           <span *ngIf="!loadingValidateCode" class="fas fa-arrow-right ml-1"></span>

                        </button>

                            </div>


                        </div>

                    </div>
                </div>
            </div>

        </div>
    </ng-template>
</app-template>