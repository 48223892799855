import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investor-profile-briefcase',
  templateUrl: './investor-profile-briefcase.component.html',
  styleUrls: ['./investor-profile-briefcase.component.css']
})
export class InvestorProfileBriefcaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
