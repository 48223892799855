import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router,Params } from '@angular/router';
import { HttpService } from '@akeela/properties';
import { AlertService } from '@akeela/alert';
import { ErrorService } from '@akeela/common';
import { I18nPipe } from '@akeela/i18n';
import { environment } from '../../../../../environments/environment';
import { mergeMap, map } from 'rxjs/operators';
import { AuthService } from '@akeela/auth';
import { LocalService } from '@akeela/local-storage';
import { PlansService } from '@akeela/products-plans';
import { AkeelaWorkflowService } from '@akeela/workflow';

@Component({
  selector: 'app-produt-type',
  templateUrl: './produt-type.component.html',
  styleUrls: ['./produt-type.component.css']
})
export class ProdutTypeComponent implements OnInit {
  public balancerequirements;
  typeId;
  plan;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _httpService: HttpService,
    private _alert: AlertService,
    private _error: ErrorService,
    private i18n: I18nPipe,
    private _auth: AuthService,
    private _planService: PlansService,
    private localStorage: LocalService,
    private akWorkflowService: AkeelaWorkflowService,


  ) { }

  ngOnInit() {


    this._activatedRoute.params
    .pipe(
        mergeMap(params => this._activatedRoute.queryParams
            .pipe(
                map(queryParams => {
                    return { ...params, ...queryParams };
                })
            )
        )
    )
    .subscribe(queryParams => {


      if(queryParams['typeId']){

    this.typeId =  queryParams['typeId'];
   
        const params_plans: any = {
          endpoint: {
            plan: {
              name: 'product_plan$plans$get',
              params: {
                path: {},
                request: {
                },
                body: {}
              }
            }
          }
        };

        this._httpService.executeRequest(params_plans.endpoint.plan.name, params_plans.endpoint.plan.params, true).subscribe((response: any) => {
          if (response) {
            const result = response.filter(p => p.identifier == this.typeId);


            if(result.length > 0){
              this.plan = result[0];

              let client = queryParams['c'] == 'true';
              let audience = queryParams['p'] == 'n'?'NATURAL': queryParams['p'] == 'l'?'LEGAL': null; 

                this.generated(this.typeId,client,audience);

              queryParams['typeId']

            }
          }
        }, error => {

          this._router.navigate([environment.staticLinks.login], { queryParams: {} });

        });
      }

    });
  
  }


  generated(identifierPlan,client,audience){


    const params_submit = {
      name: 'account$accountopening$post',
      params: {
        request: {
          role: 'CLIENT',
          audience: 'ALL'
        },
        path: {
          workflow: 'ACCOUNT_OPENING',
          stage: 'ACCOUNT_SELECTION',
          step: 'ACCOUNT_PLAN_SELECTION',
          event: 'ACCOUNT_SELECTED_AND_USER_NOT_LOGGED'
        },
        body: {}
      }
    };


    if (this._auth.isAuthenticated()) {
      params_submit.params.request.audience = this._auth.getUserAudience();
      params_submit.params.body = {
        plan: {
          identifier: identifierPlan
        },
        requestUser: {
          identifier: this._auth.getUsername(),
          adminOrganization: {
            identifier: this.localStorage.getItem('clientIdentifier')
          }
        }
      };


    } else {
      params_submit.params.body = {
        plan: {
          identifier: identifierPlan
        }
      };
    }

    this._httpService.executeRequest(params_submit.name, params_submit.params).subscribe((response: any) => {
      if (!this._auth.isAuthenticated()) {
        const obj = {
          token: response.token,
          transactionNumber: response.transactionNumber,
        
        };
        this.localStorage.setItem('objToken', JSON.stringify(obj));
        this.localStorage.setItem('objTokenPlan', this.plan.shortDescription);
        if(!client){

          if(audience){

            this.akWorkflowService.addWfFormField(
              {"name":"applicantType",
               "value":audience,
               "disabled":true,
               "temp":false
              });

            }

          this._router.navigate([environment.staticLinks.register], { queryParams: {} });

        }else{

          this._router.navigate([environment.staticLinks.login], { queryParams: {} });

        }
      }else{

        this._router.navigate([environment.staticLinks.login], { queryParams: {} });

      }


    }, (error) => {

      this._router.navigate([environment.staticLinks.login], { queryParams: {} });


    });
  }

}
