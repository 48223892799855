import { Route } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";
import { WorkflowRoutes } from '@akeela/workflow';
import { WorkflowComponent } from "../components/workflow/workflow.component";
import { PublicDashboardComponent } from "../components/dashboard/dashboard.component";
import { RecoveryPasswordComponent } from "../components/recovery-password/recovery-password.component";

export const PUBLIC_ROUTES: Route = {
    path: "dashboard",
    component: PublicDashboardComponent,
    children: WorkflowRoutes
}