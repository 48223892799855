import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
// import { promise } from 'protractor';
import { PathService } from '@akeela/properties';
import { Utf8RegexService } from './utf8-regex.service';



@Injectable()
export class EmailService {
  private base_url: string;

  constructor(private utf8: Utf8RegexService, private http: HttpClient, private _path: PathService) {
    this.base_url = this._path.getServicesIp() + '/email_sender';
  }

  getTemplate(properties: object): Observable<any> {
    const template = this.http.get(properties['template'], {
      responseType: 'text'
    });
    return template;
  }

  sendMail(
    email_message: any,
    properties: object,
    template: string
  ): Observable<any> {
    const path = 'email_sender$sendMail$post';

    return new Observable(resolve => {
      this._path.getPathService(path).subscribe((resp: any) => {
        const url = resp.url;
        const headers = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
          })
        };

        // SE HIDRATA EL TEMPLATE, Y SE ASIGNA AL OBJETO EmailMessage
        const data = this.configureTemplate(template, properties['attr']);
        // email_message.text = this.utf8.utf8Encode(data);
        email_message.text = data;

        /**ENVIO DEL CORREO MEDIANTE EL SERVICIO REST*/
        return this.http.post(url, email_message).subscribe(resultado => {
          resolve.next(resultado);
          resolve.complete();
        }, error => {
          resolve.error(error);
          resolve.complete();
        });
        /**Fin Internal function */
      });
    });
  }

  configureTemplate(data, properties) {
    if (properties) {
      properties.forEach(function (value, key) {
        data = data.replace(
          new RegExp(properties[key].key, 'g'),
          properties[key].value
        );
      });
    }
    return data;
  }
}
