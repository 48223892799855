<!-- <app-template>
    <ng-template appContent componentName="mainContentModalCenter"> -->
        <div *ngIf="centerModal" class="ak-full-modal-4 capa-menu-2 " style="height: 100vh!important;" (click)="setClose()">
            <div class="container-fluid">


                <div class="row">
                    <div class="col-12 col-md-6 offset-md-3">

                        <div (click)="$event.stopPropagation();" class="w-100 bg-white mt-3 body p-3" style="overflow:auto">

                            <div style="min-height: 206px;">

                                <h6 class="font-weight-bold  p-2">Códigos postales sugeridos</h6>
                                <hr>
                                <ng-container *ngIf="loading">
                                    <div class="row pt-5 pb-3" >
                                        <div class="col-12 text-center font-weight-bold">
                                            <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                                        </div>
                                    </div>
                                </ng-container>
                                
                                <ng-container *ngIf="!suggestion_obj && !loading && !not_selected">
                                    <div class="row pt-5 pb-3" >
                                        <div class="col-12 text-center font-weight-bold">
                                            No se ubicaron códigos postales para sugerir.
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="not_selected && !loading">
                                    <div class="row pt-5 pb-3" >
                                        <div class="col-12 text-center font-weight-bold">
                                            Debe seleccionar la parroquia
                                        </div>
                                    </div>
                                </ng-container>


                                <ng-container *ngIf="suggestion_obj && !loading && !not_selected">
                                    
                                    <div class="row pt-5 pb-3" >
                                        <div class="col-12 text-center font-weight-bold">
                                            <ng-container *ngIf="suggestion_obj">
                                                <ng-container *ngFor="let row of suggestion_obj">
                                                    
                                                    <span class="ak-btn-form btn btn-outline-dark mb-3 mr-2 " style="font-size: 1.8rem;min-width: 150px;">{{ row.description }}</span>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>

                                <!--
                                <span class="badge badge-light mr-2 fs-18">1040</span><span class="badge badge-secondary mr-2">1040</span><span class="badge badge-light mr-2">1040</span>
                                -->
                            </div>

                            <div class="row">
                                <div class="col-12 text-right">
                                        <hr>
                                        <button class="btn btn-secondary mr-1" (click)="setClose()"> 
                                        Cancelar 
                                        </button>
                                </div>
                            </div>


                        </div>

                        

                    </div>
                </div>

                


               


            </div>

        </div>
    <!-- </ng-template>
</app-template> -->