import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '@akeela/properties';
import { AlertService } from '@akeela/alert';
import { AuthService } from '@akeela/auth';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-public-change-user-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordPublicComponent implements OnInit {
  username;
  token;
  config;
  environment = environment;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _httpService: HttpService,
    private akAlert: AlertService,
    public _auth: AuthService
  ) { }

  ngOnInit() {

    this.route.params.subscribe(queryParams => {
      this.username = queryParams['username'];
      this.token = queryParams['token'];
      this.config = {
        save: {
          url: 'person$create_password$put',
          params: {
            path: {},
            request: {},
            body: {}
          }
        },
        reset: {
          url: 'person$reset_password_client$put',
          params: {
            path: {},
            request: {},
            body: {}
          }
        }
      };



    });
  }

  getResponse(response) {
    if (response.success) {

      this.router.navigateByUrl(this.router.createUrlTree
        ([this.environment.staticLinks.login],
        {
          queryParams: {}
        }
        )
      ).then((response: any) => {
        if (response) {
          this.akAlert.show({
            title: '',
            html: 'Contraseña actualizada, por favor ingrese su usuario y contraseña para continuar.'
          });
        }
      });



    }
  }
}
