<div class="container  px-0">
    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2">

            <div class=" w-100 ak-table-0-comment">

                <div class="text-dark  mb-4 text-left">
                    <div class="text-form-description mb-2">
                        <div *ngIf="!(itemList && itemList.length > 0) && !showLoading">
                            <div class="text-secondary alert alert-warning p-3 mt-4 mb-4 animate__animated animate__fadeIn ">
                                En caso de no agregar un registro en este apartado y continuar con la gestión de su cuenta, se tomará como que no posee Referencias Bancarias con la Institución, completándose la sección. De todas maneras la sección estará disponible para agregar los
                                registros necesarios en caso de poseerlas.
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <ng-container>
                <div class=" mb-4">
                    <button (click)="openItem({})" class="btn btn-outline-dark">
                    <span class="fas fa-plus mr-1" ></span>

                    Agregar
                    <span [innerHTML]="'@i18n-quote-bankAccountsWithInstitution-section.title' | i18n "></span>
                </button>
                </div>
            </ng-container>



            <div *ngIf="(!(itemList && itemList?.length==0) && !showLoading && configuration_table)">

                <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">

                </akeela-table>
            </div>


            <ng-container *ngIf="showLoading">
                <div>
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>




</div>