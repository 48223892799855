import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
// import { RequestOptions, ResponseContentType } from "@angular/http";
import { AuthService} from "@akeela/auth";
import { BehaviorSubject, Observable } from "rxjs";
import { PathService } from "@akeela/properties";

@Injectable()



export class PropertiesService {

  showTermAndoConditions$: BehaviorSubject<any> = new BehaviorSubject(null);



  setShowTermAndoConditions(showTermAndoConditions: any) {

    this.showTermAndoConditions$.next(showTermAndoConditions);

  }

  base_url;
  public productIdentifier;
  public productIdentifierBoolean: boolean = false;
  public showMenu=false;



  SetShowMenu(param: boolean) {
    this.showMenu = param;
  }

  constructor(private _http: HttpClient, private _path: PathService, private _auth: AuthService, ) {
    this.base_url = this._path.getServicesIp();
    if (this._auth.isAuthenticated() && screen.width > 767) {
      this.SetShowMenu(true);
    } else {
      this.SetShowMenu(false);
    }
    this.SetShowMenu(false);
  }

  getCurrency() {
    let path = "app_properties$getCurrency$get";
    return new Observable(resolve => {
      this._path.getPathService(path).subscribe((resp: any) => {
        let url = resp.url;
        return this._http
          .get(url, { responseType: "text" })
          .subscribe(resultado => {
            resolve.next(resultado);
            resolve.complete();
          }, (error) => {
            resolve.error(error);
          });
      });
    });
  }

  getDecimaleScale() {
    let path = "app_properties$getDecimalScale$get";
    return new Observable(resolve => {
      this._path.getPathService(path).subscribe((resp: any) => {
        let url = resp.url;
        return this._http
          .get(url, { responseType: "text" })
          .subscribe(resultado => {
            resolve.next(resultado);
            resolve.complete();
          }, (error) => {
            resolve.error(error);
          });
      });
    });
  }

  getProperty(options: any) {
    return new Observable(resolve => {
      this._path.getPathService(options.endpointKey).subscribe((resp: any) => {
        let url = resp.url;
        const params = {
          request: {
            key: options.propertyKey
          }
        };
        return this._http
          .get(url, {
            headers : null,
            params: {
              key: options.propertyKey
            },
            responseType: "text"
          })
          .subscribe(resultado => {
            resolve.next(resultado);
            resolve.complete();
          }, (error) => {
            resolve.error(error);
          });
      });
    });
  }



}
