import { Component, OnInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined, isUndefined } from 'util';
import { Subscription } from 'rxjs';
import { Table, Row, Column } from '@akeela/ak-table';
import { ShareholdersService } from '../../services/shareholders.service';
import { LocalService } from '@akeela/local-storage';
import { AlertService } from '@akeela/alert';


@Component({
  selector: 'app-section-shareholders',
  templateUrl: './section-shareholders.component.html',
  styleUrls: ['./section-shareholders.component.css']
})
export class SectionShareholdersComponent implements OnInit {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any = [];
  queryParams;
  sumSharePercentage;


  showLoading = false;
  configuration_table: Table;

  readonlyMode = false;

  count_main_shareholders = 0;

  rules = {
    maxShareholders: 20,
    maxMainShareholders: 10
  };

  private transactionDataSubscription: Subscription;
  private workflowEventsSubscription: Subscription;
  private requestSubscription: Subscription;
  paramsSubscription: Subscription;


  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private shareholderService: ShareholdersService,
    private localStorage: LocalService,
    private alertService: AlertService
  ) { }

  ngOnInit() {

    // let hideSections = {};
    // hideSections['SHAREHOLDERS_BUTTONS'] = true;
    // this.akeelaWorkflowService.setHideSections(hideSections);

    //[dfutrille] Esta ejecucion de evento es para prevenir la suscripcion al ultimo evento ejecutado.
    //Esto se debe evaluar, ya que es algo que molesta en muchas ejecuciones.
    this.akeelaWorkflowService.worflowEvents$.next(null);

    // seteo valor de identificación de tipo de shareholder:
    // SHAREHOLDER para accionista Padre y RELATED_SHAREHOLDER para relacionado, por defecto seteo SHAREHOLDER
    this.localStorage.setItem('typeShareholder', 'SHAREHOLDER');


    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
    });

    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();

    this.akeelaWorkflowService.transactionNumber.subscribe(transactionNumber => {
      if (transactionNumber) {
        this.transactionNumber = transactionNumber;
      }
    });
    this.getItemList();

    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    this.workflowEventsSubscription = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (response && (response.action === 'briefcase$save-related$post' || response.action === 'briefcase$delete-related$delete')) {
        // Mario: Importante eliminar esta variable de sesión, para que no se guarde como un accionista hijo, sino como un padre.
        this.localStorage.removeItem('idShareholderFather');
        this.localStorage.setItem('typeShareholder', 'SHAREHOLDER');

        this.getItemList();
      } else if (!isNullOrUndefined(response) && response.action === 'send_formValidationEvent_shareholders') {
        const isEdit = response.response.forms.controls.applicantType.value === '2' ? response.response.forms.controls.person.controls.identifier.value : response.response.forms.controls.organization.controls.identifier.value
        if (isEdit === 'newIdentifier') {
          if ((this.count_main_shareholders < this.rules.maxMainShareholders && this.localStorage.getItem('typeShareholder') === 'SHAREHOLDER') || this.localStorage.getItem('typeShareholder') === 'RELATED_SHAREHOLDER') {

            // Si marco SI, entonces valida sino o si tiene al menos un PEP, entonces continua
            const markYes = response.response.forms.controls.person.controls.isRelatedToPep ? response.response.forms.controls.person.controls.isRelatedToPep.value : null;
            if (markYes && !this.haveAtLeastOnePep()) {
              swal.fire({
                icon: 'warning',
                text: this.i18n.transform('@i18n-validation-have-to-add-pep'),
              });
            } else {
              this.sendEventToContinue(response.response);
            }
          } else {
            this.alertService.show({
              title: 'Mensaje!',
              html: this.i18n.transform('@i18n-text-msj-exceed-main-shareholders') + ' ' + this.rules.maxMainShareholders + ' ' + this.i18n.transform('@i18n-text-msj-max')
            });
          }
        } else {

          


          //Si marco SI, entonces valida sino o si tiene al menos un PEP, entonces continua
          const markYes = response.response.forms.controls.person.controls.isRelatedToPep ? response.response.forms.controls.person.controls.isRelatedToPep.value : null;
          if (markYes && !this.haveAtLeastOnePep()) {
            swal.fire({
              icon: "warning",
              text: this.i18n.transform('@i18n-validation-have-to-add-pep'),
            });
          } else {
            console.log(response.response);
            this.sendEventToContinue(response.response);
          }
        }
      } else if (!isNullOrUndefined(response) && response.action === 'send_formValidationEvent_delete_all_shareholders_pep') {
        //Si marco SI, entonces valida sino o si tiene al menos un PEP, entonces continua
        const radioButtonNo = response.response.forms.controls.person.controls.isRelatedToPep ? response.response.forms.controls.person.controls.isRelatedToPep.value : null;
        if (radioButtonNo && this.haveAtLeastOnePep()) {
          //Ha marcado NO y tiene PEP, Preguntar si quiere eliminar todos los PEP de REP LEG
          swal.fire({
            title: this.i18n.transform('@i18n-confirmation-are-you-shure'),
            text: this.i18n.transform('@i18n-confirmation-are-you-shure-delete-pep'),
            showCancelButton: true,
            confirmButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-true'),
            cancelButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-false'),
            reverseButtons: true
          }).then((result) => {
            if (!isNullOrUndefined(result.value) && result.value) {
              //Si el usuario responde que si a la pregunta... Elimina sus PEP
              this.akeelaWorkflowService.worflowEvents$.next({
                response: 'SHAREHOLDERS',
                action: 'account_opening$related_person_all_pep_shareholders$delete'
              });
            } else {
              //Asigno nuevo valor cuando el usuario CANCELE la operacion
              response.response.forms.controls.person.controls.isRelatedToPep.patchValue(true);
            }
          });
        }
      } else if (!isNullOrUndefined(response) && response.action === 'openModal') {
        if (response.value === 'SHAREHOLDERS_MODAL') {
          this.localStorage.setItem('typeShareholder', 'SHAREHOLDER');
          this.updateCounter();
        }
      }
    });

  }

  haveAtLeastOnePep() {
    const modalFather = this.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather) && !isNullOrUndefined(modalFather.person)) {
      //Esto es cuando la entidad Padre ya esta guardada en BD y se verifican sus PEP asociados en el JSON
      if (isNullOrUndefined(modalFather.person.relatedPeps) || (!isNullOrUndefined(modalFather.person.relatedPeps) && modalFather.person.relatedPeps.length === 0)) {
        return false;
      }
    } else if (isNullOrUndefined(modalFather)) {
      //Esto es cuando la entidad Padre no esta guardada en BD y se verifica si tiene PEP en memoria.
      if (this.akeelaSectionService.countModalChildList('new_pep_shareholders') === 0) {
        return false;
      }
    }
    return true;
  }

  sendEventToContinue(response) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {
        fn: response.fn,
        event: event,
        sectionIdentifier: response.sectionIdentifier,
        field: response.field
      },
      action: 'receive_formValidationEvent'
    });
  }

  ngOnDestroy() {
    if (this.transactionDataSubscription) {
      this.transactionDataSubscription.unsubscribe();
    }
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
    this.localStorage.removeItem('typeShareholder');

  }

  getItemList() {
    this.sumSharePercentage = 0;
  
    const params = {
      name: 'briefcase$get-relateds$get',
      params: {
        request: {
          personId: this.localStorage.getItem('clientIdentifier'),
          relatedType:'SHAREHOLDER'
        },
        path: {
        },
        body: {}
      }
    };

    this.showLoading = true;

    this.requestSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.itemList = response;
      this.shareholderService.setShareholders(response);
      this.updateCounter();
   
      this.setConfigurationTable();
      // this.akeelaSectionService.setSumSharePercentage(this.sumSharePercentage);

      this.akeelaWorkflowService.setResetEstatus(true);
      this.updateFather();
      this.showLoading = false;


    }, error => {
      this.showLoading = false;

      this.itemList = [];
    });
  }

    updateCounter() {
      let count_main_shareholders = 0;
      this.sumSharePercentage = 0;
  
      if(!isNullOrUndefined(this.itemList) && this.itemList.length > -1){
    for (let i of this.itemList) {
      if (i.person) {
        this.sumSharePercentage = this.sumSharePercentage + i.person.sharePercentage;
      } else if (i.organization) {
        this.sumSharePercentage = this.sumSharePercentage + i.organization.sharePercentage;
      }
      count_main_shareholders++;
    }
  }
    this.count_main_shareholders = count_main_shareholders;

    // se decide si se muestra o no el botón de guardar
    //Juan: comentado, el boton se llama continuar
    /*
    if (this.count_main_shareholders >= this.rules.maxMainShareholders || this.sumSharePercentage >= 100) {
      const hideSections = {};
      hideSections['SHAREHOLDERS_BUTTONS'] = true;
      this.akeelaWorkflowService.setHideSections(hideSections);
    } else {
      const hideSections = {};
      hideSections['SHAREHOLDERS_BUTTONS'] = false;
      this.akeelaWorkflowService.setHideSections(hideSections);
    }
*/
    this.akeelaSectionService.setSumSharePercentage(this.sumSharePercentage);
  }

  updateFather() {
    const self = this;
    const modalFather = self.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather) && !isNullOrUndefined(modalFather.person)) {
      const fatherToUpdate = this.itemList.find(
        (_father) => (!isNullOrUndefined(_father.person)) && (_father.person.identifier === modalFather.person.identifier)
      );
      if (!isNullOrUndefined(fatherToUpdate)) {
        self.akeelaWorkflowService.worflowEvents$.next({
          response: fatherToUpdate,
          action: 'update_shareholders_pep'
        });
      }
    }
  }

  openItem(row) {
    this.localStorage.setItem('typeShareholder', 'SHAREHOLDER')
    //this.akeelaSectionService.sharePercentageField(nuevo);

    this.akeelaSectionService.addModalSection({ name: 'SHAREHOLDERS_MODAL' });
    this.akeelaSectionService.showModalSection('SHAREHOLDERS_MODAL', true);
    row.disableWhenUpdate = 'applicantType';

    this.akeelaSectionService.setModalFormValue('SHAREHOLDERS_MODAL', row);
    let tmp = 0;

    if (row.person) {
      tmp = row.person.sharePercentage;
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_LEGAL_FIELDS', 'hidden': true });
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_NATURAL_FIELDS', 'hidden': false });
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_PEP', 'hidden': false });

    } else if (row.organization) {
      tmp = row.organization.sharePercentage;
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_LEGAL_FIELDS', 'hidden': false });
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_NATURAL_FIELDS', 'hidden': true });
      this.akeelaWorkflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_PEP', 'hidden': true });

    }

    if (isNullOrUndefined(tmp)) {
      tmp = 0;
    }




    this.akeelaSectionService.setSharePercentageField(tmp);



  }

  confirmDelete(row: any) {
    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }

  deleteItem(row: any) {
    
    const relatedPersonId = row?.organization ? row?.organization?.identifier : row?.person?.identifier;
    const configuration = {
      name: 'briefcase$delete-related$delete',
      params: {
        path: {
        },
        request: {
          relatedId: relatedPersonId,
          stepCode: 'SHAREHOLDER',
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience')  
        }
      }
    };



    
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
      this.akeelaWorkflowService.setResetEstatus(true);


      swal.fire({
        title: this.i18n.transform('Éxito'),
        html: this.i18n.transform('@i18n-text-information-deleted')
      });
      this.updateList('briefcase$delete-related$delete');
      this.akeelaWorkflowService.setResetEstatus(true);

    }, error => {
      //dfutrille esto debe borrarse.
      this.updateList('briefcase$delete-related$delete');
    });
  }

  updateList(action: string) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }

  setConfigurationTable() {
    const base: Column[] = this.setHeadTable();
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'class-color-title-table-section',
        rows: [{
          backgroundColor: '#fff',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };

  }

  setHeadTable() {

    const base: Column[] = [
      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '100',
        html: ' Nombre',
        cssCustom: '',
        enableRightBorder: true

      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Tipo',
        cssCustom: '',

      }
      ,
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Participación',
        cssCustom: ''
      },

      {
        alignContent: 'left',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      },
      {
        alignContent: 'center',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      }
    ]
    return base;
  }

  setContentTable(configuration): Row[] {
    const content: Row[] = [];
    let i = 0;
    for (const row of this.itemList) {
      i++;

      let temp = '-';
      if (row.applicantType == 'NATURAL') {

        temp = '@i18n-quote-option-list-applicantType-option-natural';

      } else if (row.applicantType == 'LEGAL') {
        temp = '@i18n-quote-option-list-applicantType-option-legal';


      }
      content.push(
        {
          identifier: row.person ? row.person.identifier : row.organization.identifier,
          cssCustom: '',
          enabled: true,
          columns: [
            this.setRowContentTable({

              configuration,
              number: 0,
              html: (row.person) ? this.capitalize(row.person.givenName) + " " + this.capitalize(row.person.familyName) : this.capitalize(row.organization.legalName),
              cssCustom: ' text-capitalize'

            }),

            this.setRowContentTable({
              configuration,
              number: 1,
              html: this.i18n.transform(temp),
            }),

            this.setRowContentTable({
              configuration,
              number: 2,
              html: row.person ? (row.person.sharePercentage + "%") : (row.organization.sharePercentage + "%"),
            }),

            this.setRowContentTable({

              configuration,
              number: 3,
              html: 'Ver',
              cssCustom: 'ak-tabled-row-active text-capitalize'

            }),


            this.setRowContentTable({
              configuration,
              number: 4,
              html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
              click: 'delete_item',
            })
          ]
        }
      );
    }

    return content;
  }

  capitalize(word) {
    if(word && word[0]){
      return word[0].toUpperCase() + word.slice(1);

    }
    return '';
  }



  setRowContentTable(row) {

    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click ? row.click : null;
    let dropdown = row.dropdown ? row.dropdown : null;
    let cssCustom = row.cssCustom ? row.cssCustom : configuration[number].cssCustom;



    let result = {

      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click: click,
      dropdown: dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }

    return result;

  }

  table_response(response) {
    if (response.action === 'open') {
      let row = null;

      row = this.itemList.find((item: any) => {
        if (item.person) {
          return item.person.identifier === response.object.identifier;
        } else {
          return item.organization.identifier === response.object.identifier;
        }
      });


      this.openItem(row);

    } else if (response.action === 'action') {
      if (response.object.action === 'delete_item' && !this.readonlyMode) {

        let row = null;

        row = this.itemList.find((item: any) => {
          if (item.person) {
            return item.person.identifier === response.object.obj.identifier;
          } else {
            return item.organization.identifier === response.object.obj.identifier;
          }
        });


        this.confirmDelete(row);
      }
    }

  }

}
