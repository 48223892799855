<div class="container  px-0">

    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2">

            <div class="  w-100 ak-table-0-comment">

                <div class="text-dark  mb-4 text-left">
                    <div class="text-form-description mb-2">
                        <div>
                            <div class="text-secondary alert alert-warning p-3 mt-4 mb-4 animate__animated animate__fadeIn ">

                                Por favor identifique el Representante Legal de <b>{{businessName}}</b>. En caso de poseer más de uno (1) Representante Legal proceda a identificarlos.<br> También puede identificar aquí mismo las apersonas autorizadas
                                a firmar y/o cuentadante.

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <ng-container>
                <button (click)="openItem({})" class="ak-btn-form  btn btn-outline-dark">                                   
                    <span class="fas fa-plus mr-1" ></span>

                    Agregar <span [innerHTML]="'@i18n-quote-legalRepresentative-section.title' | i18n "></span></button>
            </ng-container>
            <ng-container *ngIf="itemList && !showLoading ">

                <div *ngIf="!((itemList && itemList.length===0))" class="mt-4">
                    <akeela-table [configuration]="configuration_table" [loading]="false" (response)="table_response($event)">
                    </akeela-table>
                </div>
            </ng-container>


            <ng-container *ngIf="showLoading ">
                <div class="mt-3">
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>
</div>