<footer (window:resize)="onResize($event)" class="footer animate__animated animate__fadeIn " #footer>
    <nav class="navbar navbar-expand-lg  navbar-dark bg-black ">

        <div class="container">


            <div class="navbar-nav footer-text">
                <a class="nav-item nav-link disabled" [innerHTML]="'@i18n-footer-copyright' | i18n:lang "></a>
            </div>

            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">

                <!--<div class="navbar-nav footer-text">
                    <a class="nav-item nav-link" href="#" [innerHTML]="'@i18n-footer-privacity' | i18n:lang "></a>
                    <a class="nav-item nav-link" href="#" [innerHTML]="'@i18n-footer-legalagreements' | i18n:lang "></a>
                    <a class="nav-item nav-link " href="#" [innerHTML]="'@i18n-footer-contact' | i18n:lang "></a>
                    <a class="nav-item nav-link " href="#" [innerHTML]="'@i18n-footer-opinion' | i18n:lang "></a>
                </div>-->
            </div>
        </div>
    </nav>
</footer>