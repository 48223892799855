import { AuthService } from '@akeela/auth';
import { LocalService } from '@akeela/local-storage';
import { HttpService } from '@akeela/properties';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowService } from 'src/app/services/workflow.service';

@Component({
  selector: 'app-identity-verification-end',
  templateUrl: './identity-verification-end.component.html',
  styleUrls: ['./identity-verification-end.component.css']
})
export class IdentityVerificationEndComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalService,
    private _wfService: WorkflowService,
    private _auth: AuthService,
    public _httpService: HttpService,


  ) { }

  ngOnInit(): void {
    this.setUserVerification();
  }

  redirect(){
  //console.log();
    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
    };

    const prefixed = 'private';
    const url = 'dashboard';
    this._wfService.setGetIdentityCompleat(true);
    this.router.navigate([ url ], { queryParams: queryParams });



  }



  setUserVerification(){
   // ;
  const params = {
    name: 'person$complete_identification$post',
    params: {
      request: {
        username: this._auth.getUsername()
      },
      path: {
     
      },
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

  
    //this._wfService.setGetIdentityCompleat(true);

  });


    
  };


}
