import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { AuthService } from '@akeela/auth';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  params_products;
  params_plans;
  plans;
  product;
  states;
  forms: any = {};
  submit_disabled;
  loading_form: any = true;
  environment :any = environment;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private akeelaBreadcrumbService: AkeelaBreadcrumbService,
    private _auth: AuthService,
  ) {

    if (this._auth.isAuthenticated()) {

    
      this.router.navigateByUrl(

        this.router.createUrlTree([(environment.defaultInitRouteAuthenticated)],
          {
            queryParams: {
              role: 'CLIENT',
              profile: 'CLIENT',
              audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL'
            }
          }
        )
      );

    } else {

   //   window.location.href = `${environment.defaultInitRoute}`;

   
      this.router.navigateByUrl(this.router.createUrlTree([environment.defaultInitRoute],
        {
          queryParams: {}

        }));



    }

  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
