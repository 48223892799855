import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.css']
})
export class GuideComponent implements OnInit {

  env = environment;
  @Input() params? =  [];
  @Input() loadingGuide? =  false;

  

  

  constructor() { }

  ngOnInit() {
  }

}
