
<div class="row ">
  <div class="ak-section bg-white col-12">
  
        <form [formGroup]="form" (ngSubmit)="save()">
          <fieldset [disabled]="readonly">
        
            <div class="row " *ngIf="this.form.contains('profile')">
              <div class="col-12">
                <div
                  style="word-wrap: break-word"
                  class="label-form-btn mb-2 mb-3 mt-3 font-weight-bold"
                >
                  <span
                    [innerHTML]="
                      '@i18n-quote-investorProfile-form-label-profile' | i18n
                    "
                  ></span>
                </div>

                <div class="row">
                  <div class="col-12">
                    <ng-container
                      *ngFor="let lst of profileInvestorList$ | async"
                    >
                      <button
                      type="button" 
                        class="btn btn-block base-style-option-button mb-3 mr-2"
                        [class.active]="
                          form.get('profile').value == lst?.item?.alternateName
                        "
                        (click)="setProfile(lst?.item?.alternateName)"
                      >
                        <span [innerHTML]="lst?.item?.name | i18n"></span>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row pt-2 "
              *ngIf="this.form.contains('experience')"
            >
              <div class="col-12">
                <label style="word-wrap: break-word" class="font-weight-bold">
                  {{ "@i18n-quote-investorProfile-form-label-experience" | i18n
                  }}<span class="text-danger"> *</span>
                </label>

                <div
                  *ngIf="investorExperienceList$ | async as list; else loading"
                >
                  <select
                    class="form-control"
                    id="experience"
                    formControlName="experience"
                    [ngClass]="{
                      error:
                        form.controls.experience.invalid &&
                        form.controls.experience.dirty,
                      valid: form.controls.experience.valid
                    }"
                  >
                    <option value="">Seleccione</option>
                    <option
                      *ngFor="let row of list"
                      [ngValue]="row?.item?.alternateName"
                    >
                      {{ row?.item?.name | i18n }}
                    </option>
                  </select>
                  <div
                    class="form-control-feedback help-block"
                    *ngIf="form.get('experience').errors"
                  >
                    <div
                      *ngIf="
                        form.get('experience').errors['required'] &&
                        !form.get('experience').pristine
                      "
                      [innerHTML]="
                        '@i18n-store-front-login-error-field-required' | i18n
                      "
                    ></div>
                  </div>
                </div>
                <ng-template #loading>
                  <div>
                    <i
                      class="fas fa-circle-notch fa-spin load-select text-primary"
                    ></i>
                  </div>
                </ng-template>

                
              </div>
            </div>

            <div
            class="row pt-2 "
            *ngIf="this.form.contains('objective')"
          >
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                {{ "@i18n-quote-investorProfile-form-label-objective" | i18n
                }}<span class="text-danger"> *</span>
              </label>
              <input
              id="objective"
              class="form-control"
              type="text"
              formControlName="objective"
              [ngClass]="{
                error:
                  form.controls.objective.invalid &&
                  form.controls.objective.dirty,
                valid: form.controls.objective.valid
              }"
            />
              

              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('objective').errors"
              >
                <div
                  *ngIf="
                    form.get('objective').errors['required'] &&
                    !form.get('objective').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
            </div>

            <div
              class="row pt-2 "
              *ngIf="this.form.contains('objetiveSelect')"
            >
              <div class="col-12">
                <label style="word-wrap: break-word" class="font-weight-bold">
                  {{ "@i18n-quote-investorProfile-form-label-objective" | i18n
                  }}<span class="text-danger"> *</span>
                </label>

                <div *ngIf="investorObjetiveList$ | async as list; else loading">
                  <select
                    class="form-control"
                    id="objetiveSelect"
                    formControlName="objetiveSelect"
                    [ngClass]="{
                      error:
                        form.controls.objetiveSelect.invalid &&
                        form.controls.objetiveSelect.dirty,
                      valid: form.controls.objetiveSelect.valid
                    }"
                  >
                    <option value="">Seleccione</option>
                    <option
                      *ngFor="let row of list"
                      [ngValue]="row?.item?.alternateName"
                    >
                      {{ row?.item?.name | i18n }}
                    </option>
                  </select>
                  <span class="form-control-feedback small text-secondary"
                    >Indique si su objetivo es Seguridad, Rentabilidad o
                    Apreciación de Capital</span
                  >

                  <div
                    class="form-control-feedback help-block"
                    *ngIf="form.get('objetiveSelect').errors"
                  >
                    <div
                      *ngIf="
                        form.get('objetiveSelect').errors['required'] &&
                        !form.get('objetiveSelect').pristine
                      "
                      [innerHTML]="
                        '@i18n-store-front-login-error-field-required' | i18n
                      "
                    ></div>
                  </div>
                </div>
                <ng-template #loading>
                  <div>
                    <i
                      class="fas fa-circle-notch fa-spin load-select text-primary"
                    ></i>
                  </div>
                </ng-template>

               
              </div>
            </div>

            <div
              class="row pt-2 "
              *ngIf="this.form.contains('month_average')"
            >
              <div class="col-12">
                <label style="word-wrap: break-word" class="font-weight-bold">
                  {{
                    "@i18n-quote-productInformation-form-label-averageMonthlyAmount"
                      | i18n
                  }}<span class="text-danger"> *</span>
                </label>
                <div class="w-100 input-group">
                  <input
                    id="month_average"
                    inputmode="numeric"
                    class="form-control"
                    type="text"
                    currencyMask
                    [options]="currency"
                    formControlName="month_average"
                    [ngClass]="{
                      error:
                        form.controls.month_average.invalid &&
                        form.controls.month_average.dirty,
                      valid: form.controls.month_average.valid
                    }"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      [innerHTML]="'Bs ' | i18n"
                    ></span>
                  </div>
                </div>

                <span class="form-control-feedback small text-secondary"
                  >Monto promedio mensual que estima movilizar en la cuenta</span
                >

                <div
                  class="form-control-feedback help-block"
                  *ngIf="form.get('month_average').errors"
                >
                  <div
                    *ngIf="
                      form.get('month_average').errors['required'] &&
                      !form.get('month_average').pristine
                    "
                    [innerHTML]="
                      '@i18n-store-front-login-error-field-required' | i18n
                    "
                  ></div>
                </div>
              </div>
            </div>

            <div class="row pt-2 " *ngIf="this.form.contains('credits')">
              <div class="col-12">
                <label style="word-wrap: break-word" class="font-weight-bold">
                  {{
                    "@i18n-quote-productInformation-form-label-numberOfCreditTransactions"
                      | i18n
                  }}<span class="text-danger"> *</span>
                </label>
                <input
                  id="credits"
                  class="form-control"
                  type="text"
                  inputmode="numeric"
                  formControlName="credits"
                  placeholder="Ej. 20"
                  [ngClass]="{
                    error:
                      form.controls.credits.invalid &&
                      form.controls.credits.dirty,
                    valid: form.controls.credits.valid
                  }"
                />
                <span class="form-control-feedback small text-secondary"
                  >Indique la cantidad de depósitos estimados a recibir
                  mensualmente</span
                >

                <div
                  class="form-control-feedback help-block"
                  *ngIf="form.get('credits').errors"
                >
                  <div
                    *ngIf="
                      form.get('credits').errors['required'] &&
                      !form.get('credits').pristine
                    "
                    [innerHTML]="
                      '@i18n-store-front-login-error-field-required' | i18n
                    "
                  ></div>
                </div>
              </div>
            </div>


            <div
            class="row pt-2 "
            *ngIf="this.form.contains('investments')"
          >
            <div class="col-12">
              <label style="word-wrap: break-word" class="font-weight-bold">
                {{ "@i18n-quote-investorProfile-form-label-investments" | i18n
                }}<span class="text-danger"> *</span>
              </label>

              <div class="w-100 input-group">
                <input
                  id="investments"
                  inputmode="numeric"
                  class="form-control"
                  type="text"
                  currencyMask
                  [options]="currency"
                  formControlName="investments"
                  [ngClass]="{
                    error:
                      form.controls.investments.invalid &&
                      form.controls.investments.dirty,
                    valid: form.controls.investments.valid
                  }"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text"
                    [innerHTML]="'Bs ' | i18n"
                  ></span>
                </div>

                
              </div>
              <div class="form-control-feedback small text-secondary"
                  >En caso de poseer inversiones, indique el monto aproximado del valor de sus inversiones. En caso de no poseer puede dejarlo en cero (0,00)</div>

              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('investments').errors"
              >
                <div
                  *ngIf="
                    form.get('investments').errors['required'] &&
                    !form.get('investments').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
              </div>
            </div>
            </div>

            <div class="row pt-2 " *ngIf="this.form.contains('savings')">
              <div class="col-12">
                <label style="word-wrap: break-word" class="font-weight-bold">
                  {{ "@i18n-quote-investorProfile-form-label-savings" | i18n
                  }}<span class="text-danger"> *</span>
                </label>
                <div class="w-100 input-group">
                  <input
                    id="savings"
                    inputmode="numeric"
                    class="form-control"
                    type="text"
                    currencyMask
                    [options]="currency"
                    formControlName="savings"
                    [ngClass]="{
                      error:
                        form.controls.savings.invalid &&
                        form.controls.savings.dirty,
                      valid: form.controls.savings.valid
                    }"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      [innerHTML]="'Bs ' | i18n"
                    ></span>
                  </div>

                  
                </div>
                <span class="form-control-feedback small text-secondary"
                    >Indique el monto aproximado en ahorros. En caso de no poseer puede dejarlo en cero (0,00)</span>
                <div
                  class="form-control-feedback help-block"
                  *ngIf="form.get('savings').errors"
                >
                  <div
                    *ngIf="
                      form.get('savings').errors['required'] &&
                      !form.get('savings').pristine
                    "
                    [innerHTML]="
                      '@i18n-store-front-login-error-field-required' | i18n
                    "
                  ></div>
                </div>
              </div>
            </div>

            <div
              class="row pt-2 "
              *ngIf="this.form.contains('passives')"
            >
              <div class="col-12">
                <label style="word-wrap: break-word" class="font-weight-bold">
                  {{ "@i18n-quote-investorProfile-form-label-passives" | i18n
                  }}<span class="text-danger"> *</span>
                </label>
                <div class="w-100 input-group">
                  <input
                    id="passives"
                    inputmode="numeric"
                    class="form-control"
                    type="text"
                    currencyMask
                    [options]="currency"
                    formControlName="passives"
                    [ngClass]="{
                      error:
                        form.controls.passives.invalid &&
                        form.controls.passives.dirty,
                      valid: form.controls.passives.valid
                    }"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      [innerHTML]="'Bs ' | i18n"
                    ></span>
                  </div>

                
                </div>
                <span class="form-control-feedback small text-secondary"
                >Indique el monto aproximado en deudas tales como hipotecas, créditos personales, y otras obligaciones. Esto incluye deudas con tarjetas de crédito. En caso de no poseer puede dejarlo en cero (0,00)</span>
                <div
                  class="form-control-feedback help-block"
                  *ngIf="form.get('passives').errors"
                >
                  <div
                    *ngIf="
                      form.get('passives').errors['required'] &&
                      !form.get('passives').pristine
                    "
                    [innerHTML]="
                      '@i18n-store-front-login-error-field-required' | i18n
                    "
                  ></div>
                </div>
              </div>
            </div>

            <div
              class="row pt-2 "
              *ngIf="this.form.contains('totalAssets')"
            >
              <div class="col-12">
                <label style="word-wrap: break-word" class="font-weight-bold">
                  {{ "@i18n-quote-investorProfile-form-label-totalAssets" | i18n
                  }}<span class="text-danger"> *</span>
                </label>
                <div class="w-100 input-group">
                  <input
                    id="totalAssets"
                    inputmode="numeric"
                    class="form-control"
                    type="text"
                    currencyMask
                    [options]="currency"
                    formControlName="totalAssets"
                    [ngClass]="{
                      error:
                        form.controls.totalAssets.invalid &&
                        form.controls.totalAssets.dirty,
                      valid: form.controls.totalAssets.valid
                    }"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      [innerHTML]="'Bs ' | i18n"
                    ></span>
                  </div>

                  
                </div>
                <span class="form-control-feedback small text-secondary"
                    >Representa el monto aproximado del valor total de sus activos restando el monto aproximado de sus pasivos. En caso de no poseer puede dejarlo en cero (0,00)
                  </span>
                <div
                  class="form-control-feedback help-block"
                  *ngIf="form.get('totalAssets').errors"
                >
                  <div
                    *ngIf="
                      form.get('totalAssets').errors['required'] &&
                      !form.get('totalAssets').pristine
                    "
                    [innerHTML]="
                      '@i18n-store-front-login-error-field-required' | i18n
                    "
                  ></div>
                </div>
              </div>
            </div>
          </fieldset>
          
          <div class="row " >
                <div class="col-12">
                  <div class="text-left w-100 m-t-1 btn-section-save">
                    <!-- <pre> {{ form.pristine }}</pre> -->
                    <!-- <pre>{{ form.value | json }}</pre> -->

                    <!-- Button -->
                    <div class="text-left w-100 m-t-1 btn-section-save" *ngIf="!briefcase">

                      <button class="ak-btn-form btn btn-outline-dark mr-2" (click)="back()">
                        <span class="fas fa-arrow-left mr-1" ></span>	Atrás
                      </button>

                      <button class="ak-btn-form" type="button" class="btn btn-primary"  [disabled]="!compleat"  (click)="continue()" *ngIf="form.untouched">
                        <span  [innerHTML]="'@i18n-text-button-continue' | i18n "> </span>
                        <span  class="fas fa-arrow-right ml-1" *ngIf="compleat" ></span>
                      </button>

                      <button class="ak-btn-form" class="btn btn-primary" [disabled]="!form.valid  || loading_form"  *ngIf="!form.untouched">
                        <span  [innerHTML]="'@i18n-text-button-continue' | i18n "  > </span>
                        <span
                        class="fas fa-arrow-right ml-1"
                        *ngIf="form.valid && !loading_form"
                      >
                      </span
                      ><span
                        class="fas fa-circle-notch fa-spin ml-1"
                        *ngIf="loading_form"
                      >
                      </span>
                      </button>


                    </div>



                    <ng-container *ngIf="briefcase">
                    <button
                      [disabled]="!form.valid  || loading_form"
                      type="submit"
                      class="btn btn-primary"
                    >
                      <span>Guardar</span
                      ><span
                        class="fas fa-arrow-right ml-1"
                        *ngIf="form.valid && !loading_form"
                      >
                      </span
                      ><span
                        class="fas fa-circle-notch fa-spin ml-1"
                        *ngIf="loading_form"
                      >
                      </span>
                    </button>
                    </ng-container>


                  </div>
                </div>
          </div>
        </form>
      </div>

      <div class="d-none d-md-block col-md-4 ak-border-left-box-right">
        <div class="p-1 pl-2 mt-2 mb-2">
          <!-- <div>
                  <div class="base-description-section-top mb-2 font-weight-bold">
                  Datos Básicos
                  </div>
          
                  <div class="text-form-description ">
                  Se refiere a su información personal, como: nombres, apellidos, estado civil, edad, género, profesión y ocupación.<br><br>Esta información estará visible a su ejecutivo asignado. De este modo, le resultará más fácil ponerse en contacto con usted.
                  </div>
               </div> -->
        </div>
      </div>
    <!-- </div>
  </div> -->
</div>

