<div class="header">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let step of steps; let i_step = index">
                <button class="btn step-title " [ngClass]="{'active-link': currentStepCode === step.alternateName}" (click)="gotoStep(step.alternateName)">
                    <span class="ak-circle {{ stepStatus[step.alternateName] === 'COMPLETED' ? 'btn-primary' : 'btn-secondary' }}">
                        {{ i_step + 1 }}
                        <i *ngIf="step.status === 'COMPLETED'" class="fa fa-check-circle alert-success ak-badge"></i>
                    </span>
                    {{ step.name | i18n }}
                </button>
            </ng-container>
        </div>
    </div>
</div>