<div class=" px-0">
    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2 mb-3" *ngIf="checkDoNotHaveRelatedPEP">
            <div class="w-100 ak-table-0-comment" *ngIf="!showLoading">
                <div class="text-dark mt-2 mb-4 text-left">

                    <div class="label-form-btn fz-16"> <span [innerHTML]="'@i18n-quote-legalRepresentative-asociados-pep-modal-section-title' | i18n:lang "></span>
                    </div>
                    <div class="text-form-description mb-2">Por favor, es necesario que identifique su nombre completo, nacionalidad, documento de identidad, ente de adscripción, cargo y tipo de relación. </div>
                </div>
            </div>

            <ng-container *ngIf=" !showLoading ">
                <div>
                    <button (click)="openItem({})" class="btn btn-outline-dark">
                    <span class="fas fa-plus mr-1" ></span>
                    Agregar
                    <span [innerHTML]="'@i18n-quote-legalRepresentative-asociados-pep-modal-section-title' | i18n:lang "></span>
                </button>
                </div>
            </ng-container>


            <ng-container *ngIf="!showLoading  && configuration_table">
                <div *ngIf="( !(itemList && itemList.length===0))" class="mt-4">
                    <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">
                    </akeela-table>
                </div>

            </ng-container>

            <ng-container *ngIf="showLoading">
                <div>
                    <img src="./assets/public/images/loading.gif " alt=" ">
                </div>
            </ng-container>

        </div>
    </div>
</div>