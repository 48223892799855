import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[appContent]'
})
export class ContentDirective {
    @Input() componentName: string;

    constructor() {
    }
}
