import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

if (environment.production) {
  enableProdMode();

  console.log("%c ¡Aviso!", `
box-sizing: content-box;
border: none;
font: normal 60px/normal "Anton", Helvetica, sans-serif;
color: rgb(200, 0, 0);
line-height:40px;
 text-overflow: clip;
 padding: 10px;
text-shadow: 2px 0 0 #000, -2px 0 0 #fff, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #fff, 1px -1px 0 #000, -1px 1px 0 #000;

`);

  console.log('%c Esta función del navegador está destinada para desarrolladores. Si alguien te indicó que copiaras y pegaras algo aquí para habilitar una función de ' + environment.theme.site.name + ' o para "hackear" la cuenta de alguien, se trata del el fraude tipo "Self-XSS", y tendra consecuencias.', `
box-sizing: content-box;
border: none;
font: normal 18px/normal "Anton", Helvetica, sans-serif;
color: #444;
text-overflow: clip;
background: rgb(248, 248, 255);
border: 1px solid rgb(105, 105, 105);
padding: 10px;
margin: 15px 0;

`);

  console.log('%c ¿Qué es un fraude Self-XSS?', `
border: none;
font: normal 18px/normal "Anton", Helvetica, sans-serif;
color: #111;
font-weight: bold;
margin: 5px 0;
`);



  console.log('%c Self-XSS se define como un ataque de ingeniería social usado para perder control de las cuentas web de las víctimas. Lo que diferencia a este tipo de ataques del resto es que es el propio usuario ejecuta el código que les permite obtener los datos de acceso a la cuenta. El método en cuestión se vale de la consola del navegador (Google Chrome, Mozilla Firefox, Microsoft Edge…) para codificar los comandos que envían las credenciales a los atacantes. De hecho, su nombre procede del tipo de comando que tendremos que ejecutar en la consola.', `
border: none;
font: normal 18px/normal "Anton", Helvetica, sans-serif;
color: #666;
font-weight: 100;
margin: 5px 0 30px 0;
`);


  for (let clave in window.console) {
    window.console[clave] = () => { };
  }

  window.console.log = () => { };

}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
