<div class="container animated fadeIn px-0">
    <div class="mb-0 row box  box-section skin-white  animated fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2" *ngIf="tableShow">


            <div class="w-100 ak-table-0-comment" *ngIf="!loading_table ">
                <div class="text-dark mt-2 mb-4 text-left">

                    <div class="label-form-btn fz-16"> <span [innerHTML]="'@i18n-quote-customers-modal-section-title' | i18n:lang "></span>
                    </div>
                    <div class="text-form-description mb-2">Por favor, es necesario que identifique Su nombre completo, nacionalidad, documento de identidad, ente de adscripción, cargo y tipo de relación. </div>

                </div>
            </div>

            <ng-container *ngIf=" !loading_table">
                <div>
                    <button (click)="openItem({})" class="btn btn-outline-dark">
                    <span class="fas fa-plus mr-1" ></span>

                    Agregar
                    <span [innerHTML]="'@i18n-quote-customers-modal-section-title' | i18n:lang "></span>
                </button>
                </div>
            </ng-container>

            <ng-container *ngIf="((itemList && itemList.length> 0)) && !loading_table && tableShow">
                <div class="mt-4">
                    <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">
                    </akeela-table>
                </div>
            </ng-container>


            <ng-container *ngIf="loading_table">
                <div>
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>
</div>