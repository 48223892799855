

<div class="container">
  <div class="col-6 offset-3">
    <div class="card">
      <div class="card-header">
        Analisis Fácial con Inteligencia Artificial
      </div>


      
      <div class="card-body">

          <div class="row pb-4">
              <div class="col-12">
    
                <div class="w-100">
                  <span class="text-left" style="display: block;float: left">
                    23 años
                  </span>
                  <span class="text-right" style="display: block;float: right">
                    90%
                  </span>
                </div>
                <div class="w-100 pt-4">
                    <akeela-graphics [percent]="90" [type]="'line'">
                    </akeela-graphics>
                  </div>
    
              </div>
            </div> 
            <div class="row pb-4">
                <div class="col-12">
      
                  <div class="w-100">
                    <span class="text-left" style="display: block;float: left">
                      Féliz
                    </span>
                    <span class="text-right" style="display: block;float: right">
                      90%
                    </span>
                  </div>
                  <div class="w-100 pt-4">
                      <akeela-graphics [percent]="90" [type]="'line'">
                      </akeela-graphics>
                    </div>
      
                </div>
              </div> 


        <div class="row pb-4">
          <div class="col-12">

            <div class="w-100">
              <span class="text-left" style="display: block;float: left">
                Femenino
              </span>
              <span class="text-right" style="display: block;float: right">
                90%
              </span>
            </div>
            <div class="w-100 pt-4">
              <akeela-graphics [percent]="90" [type]="'line'">
              </akeela-graphics>
            </div>

          </div>
        </div>

        <div class="row pb-4">
            <div class="col-12">
              <div class="w-100">
                <span class="text-left" style="display: block;float: left">
                  Confianza
                </span>
                <span class="text-right" style="display: block;float: right">
                  90%
                </span>
              </div>
              <div class="w-100 pt-4">
                <akeela-graphics [percent]="90" [type]="'line'">
                </akeela-graphics>
              </div>
            </div>
  
          </div>
        

        
      </div>
    </div>
  </div>
</div>


<!-- <div>
  <img allFaces [src]="imageSrc" width="300px" />
</div> -->

<!-- <div>
  <img
    allFaces
    [with]="['expressions', 'landmarks', 'ageAndGender']"
    [src]="imageSrc"
    width="300px"
  *ngIf="imageSrc!==''" />
</div>

{{ response | json }}

<h2>Select File</h2>
<div style="background-color: red">
  <input class="form-control" type="file" value="" (change)="selectFile($event)" accept=".jpg, .jpeg, .png" />
</div> -->