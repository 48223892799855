import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router,Params } from '@angular/router';
import { HttpService } from '@akeela/properties';
import { AlertService } from '@akeela/alert';
import { ErrorService } from '@akeela/common';
import { I18nPipe } from '@akeela/i18n';
import { environment } from '../../../../../environments/environment';
import { mergeMap, map } from 'rxjs/operators';
import { AuthService } from '@akeela/auth';
import { LocalService } from '@akeela/local-storage';
import { PlansService, ProductsService } from '@akeela/products-plans';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubheaderService } from '../../../../services/subheader.service';
import { TransitionService } from '../../../../services/transition.service';
const BASE_URL = './';

@Component({
  selector: 'app-product-type-rq',
  templateUrl: './product-type-rq.component.html',
  styleUrls: ['./product-type-rq.component.css']
})
export class ProdutTypeRqComponent implements OnInit {
  public balancerequirements;

  planReq;

  typeId;
  plan;

  config;

  params_plans;
  plans;
  productform: FormGroup;
  product;
  states;
  forms: any = {};
  submit_disabled;
  loading_form: Boolean = true;
  identifierProduct = '';
  docsupport: Array<any> = [];
  optionsProducts = []
  workflow;
  stage;
  step;
  event = 'ACCOUNT_SELECTED_AND_USER_NOT_LOGGED';
  url_transition = 'workflow$transitionssteps$get';
  url_desktop: string;
  env = environment;
  options = {
    orientation: 'landscape',
    margins: {
      top: '100'
    }
  };
  planHidden = false;


showDescriptionReq;
showNoteCategory;
audience = 'NATURAL';
showContainerPlan = true;


  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,

    private i18n: I18nPipe,
    private formBuilder: FormBuilder,
    public _httpService: HttpService,
    private _productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private sh: SubheaderService,
    private transitionService: TransitionService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService,
    private _auth: AuthService,
    private _alert: AlertService,
    private _error: ErrorService,
    private _planService: PlansService,
    private localStorage: LocalService,
  ) { }

  ngOnInit() {

    this.showDescriptionReq = this.env.configDefauld.showDescriptionReq;
    this.showNoteCategory = this.env.configDefauld.showNoteCategory;

    this.getImages();
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    // this.sh.subHeader$.next(true);
    document.body.classList.add('teme-gray');
  
    this._activatedRoute.params
    .pipe(
        mergeMap(params => this._activatedRoute.queryParams
            .pipe(
                map(queryParams => {
                    return { ...params, ...queryParams };
                })
            )
        )
    )
    .subscribe(queryParams => {

      if(queryParams['typeId']){

    this.typeId =  queryParams['typeId'];
   
        const params_plans: any = {
          endpoint: {
            plan: {
              name: 'product_plan$plans$get',
              params: {
                path: {},
                request: {},
                body: {}
              }
            }
          }
        };

        this._httpService.executeRequest(params_plans.endpoint.plan.name, params_plans.endpoint.plan.params, true).subscribe((response: any) => {
          if (response) {
            const result = response.filter(p => p.identifier == this.typeId);

            this.planReq = result[0];
            this.product = (result[0])?.financialProduct;

            this.setDocsupport(this.typeId,true);
            

          }
        }, error => {

          this._router.navigate([environment.staticLinks.login], { queryParams: {} });

        });
      }

    });
  
  }

  setDocsupport(id,param){

    console.log("id",id);
    console.log("id",param);

    
    this.planHidden = true;
    this._planService.setDocsupport(id,param);

  }

  getImages() {
    if (environment.theme.images === 'MIBANCO') {
      this.url_desktop = BASE_URL + 'assets/mibanco/public/images/mibanco_color.png';
    } else if (environment.theme.images === 'FACEBANK') {
      this.url_desktop = BASE_URL + 'assets/facebank/public/images/logo_claro.jpeg';
    } else {
      this.url_desktop = BASE_URL + 'assets/public/images/akeela_logo_claro.svg';
    }
  }



  ngOnDestroy() {
    this.sh.subHeader$.next(false);
    document.body.classList.remove('teme-gray');
  }

  export() {

    let tmp = {
      type: "pdf",
      plan: 3,
      audience: "LEGAL"
    };

    this._planService.setRequirementsCategory(tmp);

  }


}
