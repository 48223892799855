import { AuthService } from '@akeela/auth';
import { LocalService } from '@akeela/local-storage';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeepAliveService } from '@akeela/auth';
import { ModelLoaderService, ModelToken, FeatureToken } from '@akeela/ngx-face-api-js';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';



@Component({
  selector: 'app-identity-verification-start',
  templateUrl: './identity-verification-start.component.html',
  styleUrls: ['./identity-verification-start.component.css']
})
export class IdentityVerificationStartComponent implements OnInit {
  usuario;
  env = environment;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalService,
    private _keepAliveService: KeepAliveService,
    private _auth: AuthService,
    private _modelLoadFaceApiService: ModelLoaderService
  ) { }




  ngOnInit(): void {
    this.loadModel();

    setTimeout(() => {
      this._keepAliveService.setReset();
    }, 3000);

    this.usuario = this._auth.getSession();
  }

  loadModel = async () => {
    if (this.env?.facialRecognition?.active) {
      if(!isNullOrUndefined(this.env?.facialRecognition?.landmarks)){
        const tokens = this._modelLoadFaceApiService.loadModels((this.env?.facialRecognition?.landmarks)?this.env?.facialRecognition?.landmarks:[]);
        await this._modelLoadFaceApiService.loadForFeature(tokens);
      }
    }
  }

  redirect() {
    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
    };

    const prefixed = 'private';
    let url = 'INTER_IDENTITY_VERIF/stages/INTER_IDENTITY_VERIF/steps/INTER_IDENTITY_VERIF_INFO';
    // const url = 'INTER_IDENTITY_VERIF/stages/INTER_IDENTITY_VERIF/steps/INTER_IDENTITY_VERIF_PHOTOS';

    if(this.localStorage.getItem('audience') === 'LEGAL' ){

       url = 'INTER_IDENTITY_VERIF/stages/INTER_IDENTITY_VERIF/steps/INTER_ORGANIZATION_VERIF_INFO';

    }
   
      this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });

  }

  
  redirectDashboard(): void {
  
    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience')
    };

    this.router.navigate(['/dashboard'],{ queryParams: queryParams });


  }


}
