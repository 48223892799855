import { Compiler, Component, OnInit, OnDestroy } from '@angular/core';
import { ProductsService } from '@akeela/products-plans';
import { HttpService } from '@akeela/properties';
import { I18nService, I18nPipe } from '@akeela/i18n';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { TransitionService } from '../../../../services/transition.service';
import { EmailService } from '../../../../services/email.service';
import { AuthService } from '@akeela/auth';
import { isNullOrUndefined } from 'util';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { Utf8RegexService } from '../../../../services/utf8-regex.service';
import { LocalService } from '@akeela/local-storage';



@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class PublicDashboardComponent implements OnInit, OnDestroy {
  params_products;
  params_plans;
  plans;
  product;
  states;
  forms: any = {};
  submit_disabled;
  loading_form = true;
  currentStep;
  workflow;
  stage;
  step;
  event = 'USER_CREATED_ACCOUNT_OPENING';
  url_transition = 'workflow$transitionssteps$get';
  showSubHeaderStep = false;
  showSubHeaderStepSubscription;

  constructor(
    private _compiler: Compiler,
    private formBuilder: FormBuilder,
    private _httpService: HttpService,
    private _productsService: ProductsService,
    private _akeelaStepService: AkeelaStepService,
    private _akeelaWorkflowService: AkeelaWorkflowService,
    private _i18n: I18nService,
    private i18nPipe: I18nPipe,
    private route: ActivatedRoute,
    private router: Router,
    private transitionService: TransitionService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService,
    private _email: EmailService,
    private _auth: AuthService,
    private _breadcrumbService: AkeelaBreadcrumbService,
    private _utf8: Utf8RegexService,
    private localStorage: LocalService

  ) { }

  ngOnInit() {
    this._breadcrumbService.setbreadcrumbOptions(null);

    this.showSubHeaderStepSubscription = this.akStepService.showSubHeaderStep$.subscribe(data => {

      this.showSubHeaderStep = data;

    });



    this._akeelaWorkflowService.worflowEvents$.next(null);
    this.localStorage.setItem('created', 'false');
    this.workflow = 'ACCOUNT_OPENING';
    this.stage = 'CREATE_USER_ACCOUNT_OPENING';
    this.step = 'CREATE_USER_ACCOUNT_OPENING';



    //document.body.classList.add('teme-gray');
    this.params_products = {
      endpoint: {
        products: {
          name: 'product_plan$father_products$get',
          params: {
            path: {},
            request: {}
          }
        }
      }
    };


    this._akeelaStepService.currentStep$.subscribe((current) => {
      if (!isNullOrUndefined(current) && current !== 'do nothing') {

        this.currentStep = current;

      }
    });

    // this._akeelaWorkflowService.worflowEvents$.subscribe((event: any) => {
    //   console.log('AQUIIIIII');
    //   console.log(event);
    //   if (event) {
    //     if (event.action === 'setNewClient') {
    //       if (localStorage.getItem('created') === 'false') {
    //         this._compiler.clearCache();
    //         localStorage.setItem('created', 'true');
    //         this.sendMail(event.response.person.email, event.response);
    //         swal.fire({
    //           title: this.i18nPipe.transform('@i18n-text-user-registred'),
    //           html: this.i18nPipe.transform('@i18n-text-user-registred-detail'),
    //         });
    //       }

    //       this.login();
    //     }
    //   }
    // });
  }

  sendMail(emailTo, data) {
    //debugger;
    let url = '';
    if (window.location.origin === 'http://190.216.242.10:1495') {
      url = window.location.origin + '/arn/activate-account/' + data.identifier + '/' + data.name;

    } else {
      url = window.location.origin + '/activate-account/' + data.identifier + '/' + data.name;

    }
    const portal = 'Facebank';
    const properties: object = {};
    properties['template'] = 'templates/' + this._i18n.lang + '/userconfirmation.html';
    properties['attr'] = [{
      'key': '%%USERNAME%%',
      'value': this._utf8.utf8Encode(data.identifier)
    }, {
      'key': '%%URLDESCRIPTION%%',
      'value': this.i18nPipe.transform('@i18n-mail-verify-account')
    },
    {
      'key': '%%NOMBRE%%',
      'value': this._utf8.utf8Encode(data.person.givenName)
    },
    {
      'key': '%%PORTAL%%',
      'value': portal
    },
    {
      'key': '%%URL%%',
      'value': url
    }];
    const email_message = {
      identifier: 1,
      dateOfThing: {},
      status: null,
      sender: null,
      text: null,
      type: 'text/html',
      subjectOf: this._utf8.utf8Encode('Registro de usuario'),
      toRecipient: emailTo
    };
    const template = this._email.getTemplate(properties);
    template.subscribe(template_mail => {
      const response = this._email.sendMail(
        email_message,
        properties,
        template_mail
      );
      response.subscribe(mail_result => {
        console.log(mail_result);
      }, error => {
        console.log(error);

      });
    }, error => {
      console.log(error);
    });


  }







  login() {
    this.router.navigate(['security/login'], {});
  }


  register(audience) {
    this.router.navigateByUrl(this.router.createUrlTree
      (['dashboard/ACCOUNT_OPEN/stages/CREATE_USER_ACCOUNT_OPENING/steps/CREATE_USER_ACCOUNT_OPENING'],
      {
        queryParams: {
          role: 'CLIENT',
          audience: audience
        }
      }
      )
    );
  }



  deleteSubscription() {
    if (this.showSubHeaderStepSubscription) {
      this.showSubHeaderStepSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.showSubHeaderStep = false;

    this.deleteSubscription();
    document.body.classList.remove('teme-gray');

  }


}
