import { Component, OnInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
import { Table, Row, Column } from '@akeela/ak-table';
import { AuthService } from '@akeela/auth';


@Component({
  selector: 'app-section-legal-representative-pn',
  templateUrl: './section-legal-representative-pn.component.html',
  styleUrls: ['./section-legal-representative-pn.component.css']
})
export class SectionLegalRepresentativePNComponent implements OnInit {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  active_redirect = false;
  checkDoNotHaveLegalRep = false;
  showLoading = false;
  readonlyMode = false;

  loading_table = false;
  configuration_table: Table;

  subscriptor_transaction: Subscription;
  workflow_event_suscriptor: Subscription;

  role;
  lang;

  private transactionDataSubscription: Subscription;
  private workflowEventsSubscription: Subscription;
  private requestSubscription: Subscription;
  paramsSubscription: Subscription;


  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private localStorage: LocalService,
    public _auth:AuthService

  ) { }

  ngOnInit() {

    this.getItemList();

    if(this.localStorage.getItem('tempOpenLegalRepresentative')){
      this.openItem({});
      this.localStorage.removeItem('tempOpenLegalRepresentative');
    }


    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();
    this.role = this.localStorage.getItem('role');
    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
    })

    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });


    if (this.workflow_event_suscriptor) {
      this.workflow_event_suscriptor.unsubscribe();
    }
    this.workflow_event_suscriptor = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
;
      
      if (response && response.action === 'briefcase$delete-related$delete') {
        
          this.getItemList();
        
      }else if (response && response.action === 'briefcase$save-related$post') {
        
      
        this.getItemList();
      
    } else if (response && response.action === 'account_opening$legal_representative_check$put') {
        this.setShowLoading(false);

          this.getItemList();
        
      } else if (response && response.action === 'showLoadingLegalRepresentative') {
       
        let values = !response.value? true:false;

        this.setCheckDoNotHaveLegalRep(!values);

        if (values) {
          this.getItemList();
        }

        this.setShowLoading(false);


      } else if (response && response.action === 'send_formValidationEvent') {
        this.sendEventToContinue(response.response);
      }
    });
  }

  

  setShowLoading(value: boolean) {
    this.showLoading = value;
  }


  getTransactionData() {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role
        },
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }



  setCheckDoNotHaveLegalRep(value: boolean) {
    this.checkDoNotHaveLegalRep = value;

  }


  sendEventToContinue(response) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {
        fn: response.fn,
        event: event,
        sectionIdentifier: response.sectionIdentifier,
        field: response.field
      },
      action: 'receive_formValidationEvent'
    });
  }

  ngOnDestroy() {
    if (this.transactionDataSubscription) {
      this.transactionDataSubscription.unsubscribe();
    }
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }


  getItemList() {

const params = {
  name: 'briefcase$get-relateds$get',
  params: {
    request: {
      personId: this.localStorage.getItem('clientIdentifier'),
      relatedType:'LEGAL_REPRESENTATIVE'
    },
    path: {
    },
    body: {}
  }
};
   


    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }

    this.setShowLoading(true);
    this.setCheckDoNotHaveLegalRep(false);
    this.requestSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.setShowLoading(false);

      this.itemList = response;
      this.setConfigurationTable();
    //  this.akeelaWorkflowService.setResetEstatus(true);
    }, error => {
      this.setShowLoading(false);

    });

    
  }


  openItem(row) {
    this.akeelaSectionService.addModalSection({ name: 'LEGAL_REPRESENTATIVE_PN_MODAL' });
    this.akeelaSectionService.showModalSection('LEGAL_REPRESENTATIVE_PN_MODAL');
    this.akeelaSectionService.setModalFormValue('LEGAL_REPRESENTATIVE_PN_MODAL', row);
  }

  confirmDelete(row: any) {

 //   console.log("deleted ", row);

    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }

  deleteItem(row: any) {

    const relatedPersonId = row.person.identifier;
    const configuration = {
      name: 'briefcase$delete-related$delete',
      params: {
        path: {
        },
        request: {
          relatedId: relatedPersonId,
          stepCode: 'PERSONAL_INFORMATION',
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience')  
        }
      }
    };

    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
      swal.fire({
        title: this.i18n.transform('Éxito'),
        html: this.i18n.transform('@i18n-text-information-deleted')
      });
      this.updateList('briefcase$delete-related$delete');
      this.akeelaWorkflowService.setResetEstatus(true);

    }, error => {
    });
  }

  updateList(action: string) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }


  destroyTransactionDataSubscription() {
    if (this.subscriptor_transaction) {
      this.subscriptor_transaction.unsubscribe();
    }
  }



  /**tabla */

  setConfigurationTable() {
    const base: Column[] = this.setHeadTable();
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'class-color-title-table-section',
        rows: [{
          backgroundColor: '#fff',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };

  }

  setHeadTable() {

    const base: Column[] = [
      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '100',
        html: 'Nombre y Apellido',
        cssCustom: '',
        enableRightBorder: true

      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Rol',
        cssCustom: ''
      },
      {
        alignContent: 'right',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      },
      {
        alignContent: 'right',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      }
    ]
    return base;
  }

  setContentTable(configuration): Row[] {
    let content: Row[] = [];
    let i = 0;
    for (const row of this.itemList) {
      i++;
      content = [...content,
        {
          identifier: row.person.identifier,
          cssCustom: '',
          enabled: true,
          columns: [
            this.setRowContentTable({

              configuration,
              number: 0,
              html:"<span class='text-capitalize' >"+  row.person.givenName + " " + row.person.familyName +"</span>",
              cssCustom: 'ak-tabled-row-active text-capitalize'

            }),

            this.setRowContentTable({
              configuration,
              number: 1,
              html: this.i18n.transform(row.person.condition.name),
            }),
            this.setRowContentTable({

              configuration,
              number: 1,
              html: 'Ver',
              cssCustom: 'ak-tabled-row-active text-capitalize'
  
            }),
            this.setRowContentTable({
              configuration,
              number: 2,
              html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
              click: 'delete_item',
            })
          ]
        }
      ];
    }
    return content;
  }






  setRowContentTable(row) {

    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click ? row.click : null;
    let dropdown = row.dropdown ? row.dropdown : null;
    let cssCustom = row.cssCustom ? row.cssCustom : configuration[number].cssCustom;


    let result = {

      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click: click,
      dropdown: dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }

    return result;

  }


  table_response(response) {
    if (response.action === 'open') {
      let row = this.itemList.find((item: any) => {
        return item.person.identifier === response.object.identifier;
      });
      this.openItem(row);

    } else if (response.action === 'action') {
      if (response.object.action === 'delete_item' && !this.readonlyMode) {
        let row = this.itemList.find((item: any) => {
          return item.person.identifier === response.object.obj.identifier;
        });
        this.confirmDelete(row);
      }
    }
  }


}


