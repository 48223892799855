<app-template>
    <ng-template appContent componentName="mainContentModalCenter">

        <div *ngIf="showTermAndoConditions" class=" capa-menu-2 w-100 h-100" (click)="showTermAndoConditions = false">

            <div class="p-2 d-flex justify-content-center flex-column align-items-center w-100 h-100" style="border-radius: 8px;">
                <div class="container bg-white p-2" (click)="$event.stopPropagation();" style="height: 70px;">
                    <h5 class="font-weight-bold text-secondary pt-2"> Términos y Condiciones del servicio</h5>
                    <hr>
                </div>
                <div style="max-height:calc(90%  - 70px) ;overflow: auto;" class="container bg-white p-2" (click)="$event.stopPropagation();">
                    <div class="  p-2">
                        <div class="mt-2 mb-2">

                            <span [innerHtml]="'@i18n-temConditions' | i18n"></span>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </ng-template>
</app-template>

<akeela-template>
    <ng-template akeela-content componentName="ComponentProducts">
        <select-product></select-product>
    </ng-template>
    <ng-template akeela-content componentName="ComponentPlans">
        <public-plans></public-plans>
    </ng-template>
    <ng-template akeela-content componentName="ComponentLogin">
        <public-login></public-login>
    </ng-template>
</akeela-template>

<div class="container main-content  mt-4">
    <div class="box mb-2  pt-3  pl-2 pr-2" style="border-radius: 8px;">
        <div class="col-12">
            <div class="row">
                <div class="w-100">

                    <body class="">
                        <router-outlet></router-outlet>
                    </body>
                </div>
            </div>
        </div>
    </div>
</div>