<!-- <div class="container  px-0">
    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2">
            <ng-container *ngIf="itemList">
                <div class="w-100 fz-body" *ngFor="let row of itemList; let first = first; let i_stf = index">
                    <hr *ngIf="!first" class="m-0 ">
                    <div class="d-flex bd-highlight list-flex flex-wrap pb-3 pt-3 pb-md-2 pt-md-2">
                        <div *ngIf="row?.person" (click)="openItem(row)" class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-80  order-1  ml-4 ml-md-0 ">
                            <div class="font-weight-bold text-capitalize">
                                {{row?.person?.givenName}} {{row?.person?.familyName}}
                            </div>
                        </div>

                        <div *ngIf="!row?.person" (click)="openItem(row)" class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-80  order-1  ml-4 ml-md-0 ">
                            <div class="font-weight-bold text-capitalize">
                                {{row?.organization?.legalName}}
                            </div>
                        </div>

                        <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 w-10 pl-md-2 pr-md-2  order-2">
                            <a *ngIf="!readonlyMode" href="javascript:void(0)" (click)="confirmDelete(row)" class="font-weight-bold">
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>

                        <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 w-10 pl-md-2 pr-md-2  order-3 d-none d-md-block ">
                            <a href="javascript:void(0)" (click)="openItem(row)" class="font-weight-bold">
                                <i class="fas fa-angle-right text-primary"></i>
                            </a>
                        </div>

                    </div>
                </div>
            </ng-container>

            <div class="w-100 ak-table-0-comment" *ngIf="(itemList && itemList.length===0) || !transactionData?.organization">
                <div class="text-dark mt-2 mb-4">
                    <i class="text-secondary far fa-comment mr-3"></i>
                    <span [innerHTML]="'@i18n-register-text-standard' | i18n:lang "></span>
                    <span [innerHTML]="'@i18n-quote-boardOfDirectors-section.title' | i18n:lang "></span>
                </div>
            </div>

            <ng-container *ngIf="!itemList && transactionData?.organization">
                <div>
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>
</div> -->


<div class="container  px-0">

    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2">

            <div class="  w-100 ak-table-0-comment">

                <div class="text-dark  mb-4 text-left">
                    <div class="text-form-description mb-2">
                        <div *ngIf="!showLoading" class="text-secondary alert alert-warning p-3 mt-4 mb-4 mb-2 animate__animated animate__fadeIn">
                            <div class=" font-weight-bold ">

                                Identifique la composición de la Junta Directiva de la empresa


                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!--v-->
            <ng-container *ngIf="!showLoading">
                <button (click)="openItem(null)" class="ak-btn-form  btn btn-outline-dark">                                   
                    <span class="fas fa-plus mr-1" ></span>

                    Agregar <span [innerHTML]="'@i18n-quote-boardOfDirectors-section.title' | i18n "></span></button>
            </ng-container>
            <ng-container *ngIf="itemList && !showLoading ">

                <div *ngIf="!((itemList && itemList.length===0))" class="mt-4">
                    <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">
                    </akeela-table>
                </div>


                <!-- <div class="text-secondary mb-2 mt-3 " *ngIf="itemList  && itemList.length>0">Total participación accionaria identificada <span class="font-weight-bold"> {{this.sumSharePercentage}}% de 100%</span>
                </div> -->
            </ng-container>


            <ng-container *ngIf="showLoading ">
                <div class="mt-3">
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>
</div>