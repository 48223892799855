import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpService } from '@akeela/properties';
import { AuthService, ErrorService } from '@akeela/auth';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import swal from 'sweetalert2';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LocalService } from '@akeela/local-storage';


@Component({
  selector: 'app-organization-information',
  templateUrl: './organization-information.component.html',
  styleUrls: ['./organization-information.component.css']
})
export class OrganizationInformationComponent implements OnInit {
  loading = false;
  loading_form = false; 
  clientInformation: any = null;
  legalFormList$;
  

  form = this.formBuilder.group({
    name: ['', [Validators.required,Validators.pattern("^([a-zA-ZzäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙÑñ ])+$")]],
    legal_form: ['', [Validators.required]],
    taxID: ['', [Validators.pattern("^[JGVEPjgvep][0-9]+$")]],
    legal_name: ['', [Validators.required]],
  });

  constructor(
    private _error: ErrorService,
    private formBuilder: FormBuilder, 
    private _httpService: HttpService,
    private _auth: AuthService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    public localStorage: LocalService
  ) { }

  async ngOnInit() {
    this.legalFormList$ = this.legalForms();
    try{
      this.clientInformation = await this.userInformation();
      //this.setDefault();
      this.setInformation();
    } catch(error) {
      console.log(error);
      //this.setDefault();
    }
  }

  setInformation = () => {
    this.form.patchValue({
      name: this.clientInformation?.legalName,
      legal_form: this.clientInformation?.legalForm?.identifier,
      taxID: this.clientInformation?.taxID,
      legal_name: this.clientInformation?.alternateName
    });
  }

  setDefault = () => {
    this.form.patchValue({
      type_identity: '1'
    });
  }

  userInformation = () => {
    const params = {
      name: 'briefcase$business_person$get',
      params: {
        request: {
          id: this._auth.getAdminOrganizationIdentifier()
        },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }


  legalForms = async () => {
    const params = {
      name: 'product_plan$legal_forms$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  save = () => {
    if(this.form.valid) {
      this.loading_form = true;
      const params = {
        name: 'briefcase_pe$organization_info$post',
        params: {
          request: {
            role: 'CLIENT',
            audience : 'LEGAL',
            stepCode: 'BUSINESS_IDENTITY'
          },
          path: {},
          body: this.getJson()
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).toPromise().then((response)=> {
        swal.fire({
          title: '',
          html: "Información actualizada.",
        });
        this.router.navigate(['/dashboard'], {
          queryParams: {
              role: this.localStorage.getItem('role'),
              profile: this.localStorage.getItem('profile'),
              audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL'
          }
        });
        this.loading_form = false;
      }, error => {
        this.loading_form = false;
        this._error.show(error);
      });
    }
  }

  getJson = () => {
    let form = this.form.getRawValue();
    return {
      "legalName": form.name,
      "legalForm": {
        "identifier": form.legal_form
      },
      "alternateName": form.legal_name,
      "taxID": form.taxID,
      "identifier": this.clientInformation.identifier
    };
  }

}
