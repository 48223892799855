import { Component, OnInit } from '@angular/core';
import { AlertService } from '@akeela/alert';
import { AuthService } from '@akeela/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { HttpService, PathService } from '@akeela/properties';
import { environment } from 'src/environments/environment';
import { LocalService } from '@akeela/local-storage';

@Component({
  selector: 'app-unlock-password',
  templateUrl: './unlock-password.component.html',
  styleUrls: ['./unlock-password.component.css']
})
export class UnlockPasswordComponent implements OnInit {
  params_unlock;
  params_check;
  params_question;
  params_change_password;
  email = null;
  username = null;
  step = 1;
  time = 10;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _auth: AuthService,
    private akAlert: AlertService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _pathService: PathService,
    private localStorage: LocalService

  ) { }

  ngOnInit() {

    this.params_change_password = {
      save: {
        url: 'person$changeandlogin$post',
        params: {
          path: {},
          request: {},
          body: {},
          headers: {
           // 'Accept': 'application/akeela.change_previos.app+json'
          }
        }
      }
    };

    this.params_unlock = {
      endpoint: {
        name: 'person$numericcode$get',
        params: {
          responseType: 'text',
          path: {},
          request: {},
          body: {}
        }
      }
    };

    this.params_check = {
      submit: {
        name: 'person$numericcode$put',
        params: {
          request: {},
          path: {},
          body: {}
        }
      },
      resend: {
        name: 'person$resendnumericcode$get',
        params: {
          request: {},
          path: {},
          body: {}
        }
      }
    };

    this.params_question = {
      question: {
        name: 'person$systemquestion$get',
        params: {
          request: {},
          path: {},
          body: {}
        }
      },
      submit: {
        name: 'person$systemquestion$put',
        params: {
          request: {},
          path: {},
          body: {}
        }
      }
    };
  }

  response_check_question(response) {
    if (response.response === 'success') {
      if (response.data) {
        this.step = 4;
      }
    } else if (response.response === 'error') {
      this.step = 1;
    }
  }

  response_check_code(response) {
    if (response.response === 'expired') {
      this.username = null;
      this.email = null;
      this.step = 1;
    } else if (response.response === 'success') {
      if (response.data) {
        this.step = 3;
      } else {
        this.akAlert.show({
          title: 'Código inválido',
          text: 'El código ingresado no es válido, por favor verifique.'
        });
      }
    } else if (response.response === 'locked') {
      this.username = null;
      this.email = null;
      this.step = 1;
    }
  }

  response_check(response) {
    if (response.response === 'success') {
      this.email = null;
      this.username = response.data;
      this.step = 2;
    }
  }

  response_change_password(response) {
    if (response.success) {

      // this.router.navigateByUrl(this.router.createUrlTree
      //   (['security/login'],
      //   {
      //     queryParams: {}
      //   }
      //   )
      // );

      if (this._auth.isAuthenticated()) {
        const opt = {
          name: 'LEFT_MENU_CLIENT'
        };
        this._akeelaSidebarMenu.setsidebarLeftOptions(opt);
        this._pathService.getPathService(false, environment.appEndPontDefaultConfig.menu).subscribe(pathResponse => {
          this._akeelaSidebarMenu.setsidebarLeftKey(pathResponse);
        });
        if (this.localStorage.getItem('showSidebarLeft') !== 'true') {
          if (screen.width > 768) {
            this._akeelaSidebarMenu.setshowSidebarLeft(true);
          } else {
            this._akeelaSidebarMenu.setshowSidebarLeft(false);
          }
        } else {
          this._akeelaSidebarMenu.setshowSidebarLeft(true);
        }
        this.localStorage.setItem('role', 'CLIENT');
        this.localStorage.setItem('profile', 'CLIENT');
        this.localStorage.setItem('audience', this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL');

        this.akAlert.show({
          title: '',
          html: 'El proceso de desbloqueo de su usuario se ha realizado con éxito'
        });


        this.router.navigateByUrl(
          this.router.createUrlTree([(environment.defaultInitRouteAuthenticated)],
            {
              queryParams: {
                role: 'CLIENT',
                profile: 'CLIENT',
                audience: this._auth.getUserAudience()
              }
            }
          )
        );
      } else {
        /*
        this.akAlert.show({
          title: '',
          html: 'Favor, ingrese su usuario y contraseña para continuar.'
        })*/
        this.router.navigateByUrl(this.router.createUrlTree
          (['/login'],
          {
            queryParams: {}
          }
          )
        );
      }

    }
  }

}
