<swal [customClass]="'col-12 col-md-8 col-lg-6'" [showCloseButton]="true" [showConfirmButton]="false" #swaldates>
    <ng-container *swalPortal="swalTargets.content">
        <p>&nbsp;</p>
            <table style="border: 1px solid black;font-size: 12px;">
                <tbody>
                    <tr style="background-color: #d1d1d1;">
                        <td>
                            <br>
                            <ul>
                                <li style="font-size: 12px;">
                                    <strong>PASOS PARA INICIAR EL PROCESO DE PRE- APERTURA DE CUENTA&nbsp;&nbsp;</strong>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <br>
                            <ol>
    
                                <li style="font-weight: 400;">
                                    <span style="font-weight: 400;"> Reg&iacute;strese en la pantalla de inicio y cree su usuario.</span>
                                </li>
                                <li style="font-weight: 400;">
                                    <span style="font-weight: 400;">En la siguiente pesta&ntilde;a complete la informaci&oacute;n necesaria para la afiliaci&oacute;n</span>
                                </li>
                                <li style="font-weight: 400;">
                                    <span style="font-weight: 400;">Ingrese a su correo electr&oacute;nico cargado en la herramienta y recibir&aacute; un mensaje
                                        del banco para verificar su afiliaci&oacute;n a la herramienta.</span>
                                </li>
                                <li style="font-weight: 400;">
                                    <span style="font-weight: 400;">Presione el bot&oacute;n </span>
                                    <strong>Verificar</strong>
                                    <span style="font-weight: 400;"> que lo llevar&aacute; nuevamente a la herramienta</span>
                                </li>
                                <li style="font-weight: 400;">
                                    <span style="font-weight: 400;">Ingrese con su usuario y genere la contrase&ntilde;a para completar los datos de acceso a
                                        la herramienta</span>
                                </li>
                                <li style="font-weight: 400;">
                                    <span style="font-weight: 400;">Si no desea generar la contrase&ntilde;a en ese momento, pero le dio al bot&oacute;n verificar,
                                        puede hacerlo posteriormente ingresando a la herramienta y presionando el bot&oacute;n
                                    </span>
                                    <strong>LOGIN</strong>
                                </li>
                                <li style="font-weight: 400;">
                                    <span style="font-weight: 400;">Al ingresar con su usuario y su contraseña a la herramienta, en la parte superior derecha aparece
                                        el bot&oacute;n&nbsp; &ldquo;Nuevo&rdquo;, haga clic para continuar</span>
                                </li>
                                <li style="font-weight: 400;">
                                    <span style="font-weight: 400;">En la siguiente pesta&ntilde;a seleccione el tipo de producto que desea.</span>
                                </li>
                                <li style="font-weight: 400;">
                                    <span style="font-weight: 400;">Siga los pasos&nbsp; y complete la informaci&oacute;n solicitada para realizar
                                        la pre apertura de la cuenta y presione el bot&oacute;n </span>
                                    <strong>Enviar</strong>
                                </li>
                                <li style="font-weight: 400;">
                                    <span style="font-weight: 400;">Para realizar consultas relacionadas con el estatus de su solicitud, utilice el n&uacute;mero
                                        de tr&aacute;mite generado por la herramienta a lo largo del proceso.</span>
                                </li>
                            </ol>
                            <p>
                                <strong>
                                    <em>Notas:&nbsp;&nbsp;</em>
                                </strong>
                            </p>
                            <ul>
                                <li>
                                    <strong>
                                        <em>Una vez enviado el tr&aacute;mite a Mi Banco, es revisado por personal autorizado de
                                            la Instituci&oacute;n</em>
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        <em>Al completar la revisi&oacute;n,&nbsp; la herramienta emitir&aacute; una respuesta a
                                            la solicitud con el resultado, al correo electr&oacute;nico ingresado por el solicitante&nbsp;</em>
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        <em>Todos los campos resaltados en negrita, son obligatorios</em>
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        <em>Si el sistema observa inactividad en la herramienta (3 minutos) autom&aacute;ticamente
                                            se cierra la sesi&oacute;n y debe ingresar nuevamente.</em>
                                    </strong>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <br />
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>&nbsp;</p>
        </ng-container>
    </swal>
    
    <div style="display: none">
        <a [swal]="swaldates" id="idshowdates" #myLink></a>
    </div>
    
    
    <div class="container-fluid pr-0 pl-0 mb-4">

        
            <a  *ngIf="env?.theme?.images==='MIBANCO'" class="nav-item  d-block d-md-none" [href]="env.theme.site.url"><i class="fas fa-arrow-left"></i>&nbsp;Regresar</a>
       
            <div [innerHTML]="'@i18n-text-products-1' | i18n"></div>
        <div class="text-center" [innerHTML]="'@i18n-text-products-2' | i18n"></div>
    
    

    <div class="w-100 box-prod pr-0 pl-0  mb-3">

<div class="container  pr-0 pl-0   mb-2 ">



        <div class=" w-100 ml-auto mr-auto text-center">
                <div class="font-weight-bold text-center title-products mt-4" [innerHTML]="'@i18n-products-and-services' | i18n"> </div>
        </div>

        <products [params]="params_products" [iconProduct]="env?.configDefauld?.iconProduct" [styleBox]="env?.configDefauld?.styleBox">
        </products>

 </div>

 </div>
 <div class="container  pr-0 pl-0   mb-2 ">

        <div class="col-12 text-center" style="margin-top: 0.4rem" *ngIf="env?.theme?.images==='MIBANCO'">
            Conoce más sobre los pasos para inicar el proceso de pre-apertura
                        <br> Instructivo
            <a href="javascript:void()" [swal]="swaldates" id="idshowdates">Aquí</a>
        </div>

        <div class="col-12 text-center mt-3 " *ngIf="env?.theme?.images==='AKEELA'  || env?.theme?.images==='FACEBANK'">
            Conoce más sobre los pasos para inicar el proceso de pre-apertura
            <div class="mt-4">
                <button href="javascript:void()" class=" btn btn-outline-primary disabled">
                    Ver pasos
            </button>
            </div>
            
            <div  class="mt-4">
                <button class=" btn btn-outline-primary">
                    Compartir esta experiencia con tus amigos
                 </button>
            </div>
            
            <div class=" w-100  mt-4 product-sub">
                Horario de atención Lunes a Viernes 8:30 AM - 3:30
            </div>
            <div class=" font-weight-bold  w-100  mt-4 product-sub">
              
                Una vez inicie su solicitud le asignaremos un número de solicitud y contará con un <br>ejecutivo de negocios que lo podrá asistir y acompañar en este proceso.
                        </div>

        </div>

       

        <div style="background: white;text-align: center;padding-bottom: 1rem " class="w-100 mt-4" *ngIf="env?.theme?.images==='AKEELA'">

          <img  class="ak-img-url-logo animate__animated animate__fadeIn" style="height: 50px;" src="./assets/public/images/Akeela_Powered_by.png">
       
 <div class="text-secondary container text-left" style="color: #aaa">     
    <div class="mt-5 mb-3"> Sobre nuestras imágenes</div>  
        <ul>
            <li>Online shop business partners working in small office together by Jacob Lund from Noun Project
                    <a target="_blank" href="https://thenounproject.com/photo/online-shop-business-partners-working-in-small-office-together-5r3Dp4/" class="card-link ">
                        view
                    </a> </li>
            <li> Woman photo created by Cookie Studio  <a target="_blank"  href="https://www.freepik.com/free-photo/sassy-african-american-businesswoman-showing-way-pointing-upper-right-corner_10348845.htm" class="card-link ">
                view
            </a></li>
        </ul>
 </div>

</div>

    </div>

