import { Component, EventEmitter, Input, OnDestroy, OnInit, Output  } from '@angular/core';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { HttpService } from '@akeela/properties';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.css']
})
export class SuggestionComponent implements OnInit {
  centerModal;
  data;
  suggestion_obj;
  loading = false;
  not_selected = false;

  @Output() close = new EventEmitter();

  @Input() set selected(data) {
    if (data) {
      
      this.centerModal = true;

    }
  }

  @Input()  set config(data) {
    if (data) {     
      this.data = data;
      this.suggestion_obj = null;
      this.getSuggestion().then((response:any)=>{
        // if(response!==null){
          this.suggestion_obj = response; 
        // }
      });
    }
   
  }

  constructor(
    private akWorkflowService: AkeelaWorkflowService,
    private _httpService: HttpService
  ) { }

  ngOnInit(): void {

    // this.akWorkflowService.showSuggestion$.subscribe((response) => {
    //   if (response) {
    //       //console.clear();
    //       console.log(response); 
    //   }
    // });


  }

  getSuggestion = () => {
      this.loading = true;
      this.not_selected = false;
      if(this.data.id) {
        return new Promise((resolve, reject) => {
          const params = {
            name: this.data.endpoint,
            params: {
              request: {
                parishId: this.data.id
              },
              path: {},
              body: {}
            }
          };
          this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
            this.loading = false;
            resolve(response);
          }, error => {
            reject(false);
          });
        });
      } else {
        this.not_selected = true;
        this.loading = false;
      }
  }

  setClose(){
    this.close.emit(null);
    this.centerModal = false;
  }

}
