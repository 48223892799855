import { Component, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpService } from '@akeela/properties';
import { AkeelaSectionService, AkeelaStepService, AkeelaWorkflowService, AkeelaStageService, RouteUtilsService } from '@akeela/workflow';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadService, ErrorService } from '@akeela/common';
import { MiddlewareService } from '../../../../services/middleware.service';
import { Subscription } from 'rxjs';
import { RequirementsService } from '@akeela/requirements';
import { environment } from '../../../../../environments/environment';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { isNullOrUndefined } from 'util';
import { LocalService } from '@akeela/local-storage';
import { PropertiesService } from '../../../../services/properties/properties.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sent-sworn',
  templateUrl: './sent-sworn.component.html',
  styleUrls: ['./sent-sworn.component.css']
})
export class SentSwornComponent implements OnInit {
  form;
  workflow;
  stage;
  step;
  audience;
  role;
  download_file = false;
  transactionNumber;
  loading = true;
  loadingCheck = false;


  activeSend = false;
  showContract = false;
  sectionsIncomplete: any = false;
  showWindowError; false;
  sections;

  formSheet;
  _back: any = null;
  _next: any = null;

  subscriptor_transaction: Subscription;
  subscriptor_verify_active: Subscription;

  transactionData;
  isRequirementModuleActive = null;

  requireLoadRequirements: any = false;
  requireReloadRequirements: any = true;
  requirementsIsStatusIncompleted: any = false;

  loadView = false;
  environment;

  queryParams;
  requirementsModuleActive = false;


  preview: any = null;
  tmpAcept = true;
  documents: any = [];

  autorizado = [];
  legalRepresentative = ''

  readonly_2 = false;

  paramsSubscription: Subscription;
  jsonData:any = {};


  constructor(
    private formBuilder: FormBuilder,
    private _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private _upload: UploadService,
    private _error: ErrorService,
    private _middleware: MiddlewareService,
    private _akeelaRequirementsService: RequirementsService,
    private i18n: I18nPipe,
    private localStorage: LocalService,
    private _properties: PropertiesService,
    private router: Router,
    private datePipe: DatePipe,
    private routerUtilService: RouteUtilsService

  ) { }

  ngOnInit(): void {
  }


  send() {

    swal.fire({
      title: this.i18n.transform('@i18n-confirmation-are-you-shure'),
      text: this.i18n.transform('@i18n-confirmation-are-you-shure-send-analisys'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-true'),
      cancelButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-false'),
      reverseButtons: true
    }).then((result) => {


      if ( result.value) {
        if (this.isRequirementModuleActive) {
          this.getRequirements().subscribe((response: any) => {
            const validation_dates_requirements = [];

            for (const i_req in response) {
              if (i_req) {
                for (const req of response[i_req]) {
                  if (req.requirementArchives) {
                    for (const file of req.requirementArchives) {
                      let mandatoryIssueDateError = false;
                      let mandatoryExpirationDateError = false;
                      if (req.requirement.mandatoryExpirationDate === true) {
                        if (file.expires) {
                          if (file.expires.orderDate) {
                            mandatoryExpirationDateError = true;
                          }
                        }
                      }

                      if (req.requirement.mandatoryIssueDate === true) {
                        if (file.dateCreated) {
                          if (isNullOrUndefined(file.dateCreated.startDate)) {
                            mandatoryIssueDateError = true;
                          }
                        }
                      }

                      if (mandatoryIssueDateError === true || mandatoryExpirationDateError === true) {
                        validation_dates_requirements.push({
                          name: req.requirement.name,
                          mandatoryIssueDateError: mandatoryIssueDateError,
                          mandatoryExpirationDateError: mandatoryExpirationDateError
                        });
                      }

                    }
                  }
                }
              } 

            }

            if (validation_dates_requirements.length === 0) {
              this.sendForm();
            } else {
              let html_err = '<br>';
              let html_err_type = '';
              for (const err of validation_dates_requirements) {
                console.log(err);
                if (err.mandatoryIssueDateError === true) {
                  html_err_type = html_err_type + this.i18n.transform('@collections-text-emission');
                  if (err.mandatoryIssueDateError) {
                    html_err_type = html_err_type + ',';

                  }
                }
                if (err.mandatoryExpirationDateError === true) {
                  html_err_type = html_err_type + this.i18n.transform('@collections-text-expiration');
                }
                html_err_type =
                  html_err = html_err + '<br>' + err.name + ' (' + (html_err_type) + ')';
              }
              swal.fire({
                title: '',
                html: '<div style="text-align: center"><b>' + this.i18n.transform('@i18n-text-error-requirements-files-need-dates-information') + '</b>' + html_err + '</div>'
              });
            }
          });
        } else {
          this.sendForm();
        }

      } else {

      }


    });

    //  }
  }


  getRequirements() {
    const params = {
      name: 'requirements$transaction_requirements$get',
      params: {
        request: {
          audience: this.transactionData.holder && this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
        },
        path: {
          idPlan: this.transactionData.plan.identifier,
          transactionNumber: this.transactionData.transactionNumber,
          idPerson: this.transactionData.holder ? this.transactionData.holder.identifier : null
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }


  sendForm() {
    this.loadingCheck = true;
    const params = {
      name: 'account_opening$send_analisys$put',
      params: {
        request: {
          role: this.role,
          audience: this.audience
        },
        path: {
          transactionNumber: this.transactionNumber,
          workflow: this.workflow,
          stage: this.stage,
          step: this.step,
          event: 'SEND_ANALYSIS_ACCOUNT_OPENING_CONTRACT'
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.formSheet = response;
      this.download_file = true;
      this.loadingCheck = false;
        this.akeelaWorkflowService.transactionData$.next(this.transactionData);

      // ACTUALIZAR EL ESTATUS SUPERIOR
      this.akeelaWorkflowService.worflowEvents$.next({
        response: 'UPDATE_REQUIREMENT_FINISH',
        action: null,
        forms: null
      });

    }, (error: any) => {
      this._error.show(error);
      this.loadingCheck = false;
    });
  }

}
