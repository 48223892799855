import { AfterViewInit, Component, ContentChildren, OnInit, QueryList, TemplateRef } from '@angular/core';
import { ContentDirective } from '../../directives/content.directive';
import { TemplateService } from '../../services/template.service';


@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})

export class TemplateComponent implements OnInit, AfterViewInit {

  @ContentChildren(ContentDirective) contentsRef: QueryList<ContentDirective>;
  @ContentChildren(ContentDirective, { read: TemplateRef }) templatesRef: QueryList<TemplateRef<any>>;

  constructor(private templateService: TemplateService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.contentsRef.forEach((item: ContentDirective, index) => {
        const template = this.templatesRef.find((_template, templateIndex) => templateIndex === index);
        this.templateService.addTemplate(item.componentName, template);
      });
    }, 1);
  }


}
