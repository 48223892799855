import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-resumen-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.css']
})
export class BoxComponent implements OnInit {
;
@Output() redirect = new EventEmitter<any>();

  @Input() title?;
  @Input()  item? = null;
  constructor() { }

  ngOnInit() {
  }


  event_redirect(data){
    this.redirect.emit(data);

  }



}
