<akeela-requirements-template>
    <ng-template akeelaRequirementsContent componentName="requirementsWindowContent">
        <div class="full-modal-2 compleat-modal" style="height: 100vh!important;" *ngIf="showModalResume">
            <div class="header active-cursor" style="text-align:right">
                <div class="float-right" (click)="showModalResume=!showModalResume">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                </div>
                <div class="pr-2 pl-2 pb-2 float-left">
                    <div class=" pt-1">
                        <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
                    </div>
                </div>
            </div>
            <div class="body " style="overflow:auto">
                <div style="overflow-y: scroll;max-height:95vh!important;">
                    <div class="container box_scroll">

                        <div class="    offset-md-1 col-12 col-md-10">
                            <br>
                            <br>
                            <br>
                            <br>


                            <!--******************-->

                            <div *ngIf="transactionData" class="mt-2 animate__animated animate__fadeIn" style="font-size: 0.84rem">

                                <div class="animate__animated animate__fadeInDown  mt-2 p-2 alert ak-status alert-secondary  w-100 text-left text-dark p-1 pt-2 pb-2 mb-2" [class.alert-warning]="transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE' " [class.alert-primary]="transactiotransactionDatanData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS'"
                                    [class.alert-primary]="transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || transactionData?.accountStatus[0]?.additionalType == 'AC_PENDING' ">
                                    <i class=" text-secondary pr-2 fas fa-info-circle " aria-hidden="true"></i>

                                    <span class="font-weight-bold ">
                               {{transactionData?.accountStatus[0]?.userName}}
                           </span>
                                    <br>
                                    <span class="text-dark">{{transactionData?.accountStatus[0]?.description}}</span>
                                </div>


                                <div class="row mt-4">
                                    <div style="font-size: 1rem;" class="col-12 font-weight-bold ak-text-color-1">
                                        Progreso de la solicitud
                                    </div>
                                </div>


                                <div *ngIf="diffDates(transactionData?.dateOfThing?.startDate)" class="row mb-3" style="padding-top: 12px">
                                    <div class=" col-6 mb-1">
                                        <div class=" mt-2 p-1">
                                            <span class="p-2 rectangle green">{{ diffDates(transactionData?.dateOfThing?.startDate)?diffDates(transactionData?.dateOfThing?.startDate):'-' }}</span>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div>
                                            <div>Total</div>
                                            <div class="rectangle gray">{{ duration(transactionData?.dateOfThing?.startDate) }}</div>

                                        </div>
                                    </div>
                                </div>

                                <div class="row  mb-3 mb-1 ">
                                    <div class="  col-6">
                                        <div class="mt-2 p-1">
                                            <span class="p-2 rectangle gray">{{ diffDates(transactionData.accountStatus[0].dateOfThing?.startDate) }}</span>
                                        </div>
                                    </div>
                                    <div class=" col-6">
                                        <div>
                                            <div>{{ transactionData.accountStatus[0].userName }}</div>
                                            <div class="text-secondary">{{duration(transactionData.accountStatus[0].dateOfThing?.startDate)}}</div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-12 mt-2" *ngIf="items">

                                        <div class="mb-2" *ngFor="let item of items">
                                            <span class="label" style="line-height : 40px;">{{ item.approvalUser | i18n }} (
                                                    <span [innerHTML]="item.progress.minValue+'/'+item.progress.maxValue" *ngIf="item.additionalType!=='EXISTENCE'"></span>
                                            <span [innerHTML]="item.progress.minValue===0?'Pendiente':'Completado'" *ngIf="item.additionalType==='EXISTENCE'"></span>)
                                            </span>
                                            <akeela-graphics [percent]="round(item.progress.minValue*100/item.progress.maxValue)" [type]="'line'">
                                            </akeela-graphics>
                                        </div>
                                    </div>

                                    <div *ngIf="loadItems">
                                        <img src="./assets/public/images/loading.gif" alt="">
                                    </div>

                                </div>


                                <div class="row mt-4">
                                    <div style="font-size: 1rem;" class="col-12 font-weight-bold ak-text-color-1">
                                        Datos de la solicitud
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Solicitud N°</div>
                                            <div class="col-6">{{ transactionData?.transactionNumber }}</div>
                                        </div>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Creación</div>
                                            <div class="col-6">{{ transactionData?.dateOfThing?.startDate | date: 'MMM dd, y - hh:mm aa' }}</div>
                                        </div>

                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Producto</div>
                                            <div class="col-6">{{ transactionData?.plan?.financialProduct.name }}</div>
                                        </div>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Plan</div>
                                            <div class="col-6">{{ transactionData?.plan?.name | i18n }}</div>
                                        </div>

                                    </div>
                                </div>



                                <div class="row">
                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold"> No. versión</div>
                                            <div class="col-6">{{ transactionData?.numberOfVersions?transactionData?.numberOfVersions:'-' }}</div>
                                        </div>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Estado</div>
                                            <div class="col-6">{{ transactionData.accountStatus[0].userName | i18n }}</div>
                                        </div>

                                    </div>
                                </div>



                                <div class="row">
                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Fecha del estatus</div>
                                            <div class="col-6">{{ transactionData?.accountStatus[0].dateOfThing?.startDate | date: 'MMM dd, y - hh:mm aa' }}</div>
                                        </div>

                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-12 col-md-12" style="padding-top: 12px">

                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Última actualización</div>
                                            <div class="col-6"> {{ transactionData?.dateOfThing?.modifiedDate | date: 'MMM dd, y - hh:mm aa' }}
                                            </div>
                                        </div>
                                        <!-- <span >Última actualización </span>
                                                <span >
                                                    {{ transactionData?.dateOfThing?.modifiedDate | date: 'MMM dd, y - hh:mm aa'  }}
                                                   
                                                </span>                         -->
                                    </div>
                                </div>


                                <div class="row" style="padding-top:12px">
                                    <div class="col-12 col-md-12">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Cliente</div>
                                            <div class="col-6">{{ transactionData?.holder?.name?transactionData?.holder?.name:'-' }}</div>
                                        </div>

                                    </div>

                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Tipo de solicitante</div>

                                            <div class="col-6">{{ transactionData?.holder?.additionalType == 'NATURAL'?('@i18n-quote-option-list-applicantType-option-natural' | i18n): ('@i18n-quote-option-list-applicantType-option-legal' | i18n) }}</div>
                                        </div>

                                    </div>


                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Aplicante</div>
                                            <div class="col-6 ">{{ transactionData?.holder?.person?.name?transactionData?.holder?.person?.name:'-' }}
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">{{ '@i18n-text-shared-service-center' | i18n}}</div>
                                            <div class="col-6">{{ transactionData?.availableChannel?.name?transactionData?.availableChannel?.name:'-' }}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-12" style="padding-top: 12px">

                                        <div class="row">
                                            <div class="col-6 font-weight-bold">{{ '@i18n-text-label-agency' | i18n }}</div>
                                            <div class="col-6">{{ transactionData?.channelAgency?.name?transactionData?.channelAgency?.name:'-' }}
                                            </div>
                                        </div>

                                    </div>
                                    <!-- <div class="col-12 col-md-12" style="padding-top: 12px">
                                            <div class="row">
                                                <div class="col-6 font-weight-bold">{{ '@i18n-text-shared-service-center' | i18n }}</div>
                                                <div class="col-6">{{ transactionData?.availableChannel?.name?transactionData?.availableChannel?.name:'-'}}
                                                </div>
                                         </div>
                        
                                        </div> -->

                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Alianza</div>
                                            <div class="col-6">-</div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Ejecutivo</div>
                                            <div class="col-6" [innerHTML]="transactionData?.salesManagerUser?transactionData?.salesManagerUser?.name:'<i class=\'fas fa-question-circle font-color-green\'></i> <span class=\'font-color-gray\'>Sin asignar</span>'"></div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-12" style="padding-top: 12px">
                                        <div class="row">
                                            <div class="col-6 font-weight-bold">Agencia Comercial</div>
                                            <div class="col-6">{{ transactionData?.channelAgency?.name?transactionData?.channelAgency?.name:'-'}} </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <!--******************-->


                        </div>














                    </div>
                </div>
            </div>
        </div>


        <div class="full-modal-2 compleat-modal" style="height: 100vh!important;" *ngIf="showModalResponses">
            <div class="header active-cursor" style="text-align:right">
                <div class="float-right" (click)="showModalResponses=!showModalResponses">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i>

                </div>
                <div class="pr-2 pl-2 pb-2 float-left">
                    <div class=" pt-1">
                        <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
                    </div>
                </div>
            </div>
            <div class="body " style="overflow:auto">
                <ak-responses (close)="showModalResponses = false" [showCommentsBoolean]="showCommentsBoolean"></ak-responses>

            </div>
        </div>
    </ng-template>
</akeela-requirements-template>


<div [class.section-box]="transactionData" class="col-12  pt-3 mb-3">
    <ng-container *ngIf="transactionData">
        <div style="font-size: 14px">

            <div class="row">

                <div class="col-md-4 col-12  mt-3 mt-md-0  d-none d-md-block">

                    <div class="col-12 animate__animated animate__fadeInRight">
                        <div class="row">
                            <div class="col-12" style="background-color: #F5F4F4">
                                <div style="font-size: 16px;" class="mt-2 mb-4 font-weight-bold text-dark "> Ruta de tu solicitud</div>
                                <!--  <div  style="font-size: 16px;line-height: 18px; "  class="mb-4 mt-3 text-secondary ak-font-roboto">Aquí está el resumen de apertura de su cuenta </div> -->

                                <app-guide [params]='guide' [loadingGuide]="loadingGuide"></app-guide>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-8 col-12">

                    <div class=" ">

                        <!-- IMG MSG-->

                        <div class="" *ngIf="transactionData.accountStatus[0].additionalType == 'PI_IN_PROCESS'">
                            <div class="text-center  p-2  background-image-w-exit animate__animated animate__fadeInUp">
                                <div class=" mb-4">

                                    <div>
                                        <span class="icon text-primary animate__animated animate__zoomIn fas fa-tools"></span>
                                    </div>
                                    <div class="ak-title-msg  ">En proceso de registro</div>

                                </div>


                                <div class=" text-dark  mb-3 ">

                                    <span style="font-size: 1rem">

                                                
                                                   Por favor complete y envíe  su solicitud  <span class="font-weight-bold">{{transactionData?.plan?.name}}</span>


                                    </span>
                                </div>
                                <button class=" btn  btn-step btn-outline-primary primary" (click)="firstStep() ">
                    
                                        <span  [innerHTML]="'@i18n-ak-tep-next' | i18n " >
                                    
                                            </span>
                
                                </button>
                            </div>
                        </div>

                        <div class="" *ngIf="transactionData.accountStatus[0].additionalType == 'AC_PENDING'">
                            <div class="text-center  p-2  background-image-w-exit animate__animated animate__fadeInUp">
                                <div class=" mb-4">

                                    <div>
                                        <span class="icon text-primary animate__animated animate__zoomIn fas fa-users"></span>
                                    </div>
                                    <div class="ak-title-msg  ">Espera de análisis</div>

                                </div>
                                <div class=" text-dark   pt-3">

                                    <span style="font-size: 1rem">
            
                                                
                                                    Tu solicitud  <span class="font-weight-bold">{{transactionData?.plan?.name}}</span> , está siendo revisada por nuestros analistas

                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="" *ngIf="transactionData.accountStatus[0].additionalType == 'AC_INCOMPLETE'">
                            <div class="text-center  p-2  background-image-w-exit animate__animated animate__fadeInUp">
                                <div class=" mb-4">

                                    <div>
                                        <span class="icon  text-primary animate__animated animate__zoomIn fas fa-reply-all"></span>
                                    </div>
                                    <div class="ak-title-msg ">Revisada con observaciones</div>
                                    <button *ngIf="(incomplete)" class="mt-2 nav-item   btn btn-outline-primary " (click)="response()">
                                            <i class=" mr-1 far fa-comments" ></i>
        
                                               Respuesta y comentarios
        
                                            <i class=" ml-2 fas fa-circle text-danger blink_me" style="font-size: 10px" ></i>
        
                                    </button>

                                </div>
                                <div class=" text-dark  pt-2 ">

                                    <span style="font-size: 1rem">
        
                                                Complete información adicional de  tu solicitud  <span class="font-weight-bold">{{transactionData?.plan?.name}}</span>

                                    </span>


                                </div>
                            </div>
                        </div>

                        <div class="" *ngIf="transactionData.accountStatus[0].additionalType == 'AC_REJECTED'">
                            <div class="text-center  p-2  background-image-w-exit animate__animated animate__fadeInUp">
                                <div class=" mb-4">

                                    <div>
                                        <span class="icon text-secondary animate__animated animate__zoomIn far fa-flag"></span>
                                    </div>
                                    <div class="ak-title-msg  ">No cumple con la política</div>

                                </div>
                                <div class=" text-dark   pt-3">

                                    <span style="font-size: 1rem">
    
                                          
                                        Tu solicitud  <span class="font-weight-bold">{{transactionData?.plan?.name}}</span> , no cumple con la política


                                    </span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="transactionData.accountStatus[0].additionalType == 'AC_RECEIVED'">
                            <div class="text-center  p-2  background-image-w-exit animate__animated animate__fadeInUp">
                                <div class=" mb-4">

                                    <div>
                                        <span class="icon text-primary animate__animated animate__zoomIn fas fa-star"></span>
                                    </div>
                                    <div class="ak-title-msg">Felicitaciones</div>

                                </div>
                                <div class=" text-dark  pt-3">

                                    <span style="font-size: 1rem">

                                    
                                        Tu solicitud  <span class="font-weight-bold">{{transactionData?.plan?.name}}</span>, ha sido <span class="font-weight-bold"> preaprobada</span>. Por favor, dirígete a la agencia/sucursal,
                                    consigna los recaudos y retira tu producto.<br><br>

                                    <div *ngIf="env?.theme?.images != 'MIBANCO'">

                                        Tu ejecutivo
                                        <span class="font-weight-light" [innerHTML]="(transactionData?.salesManagerUser)?(transactionData?.salesManagerUser?.name) : '' "></span> para mayor información.

                                    </div>


                                    </span>
                                </div>
                            </div>
                        </div>


                        <!--FIN IMG MSG-->
                    </div>

                    <div class="row mb-3">

                        <div class="col-6  order-1 order-md-1" style="border-right: 1px solid #ccc">


                            <div class="row " style="padding-top: 12px" *ngIf="transactionData?.salesManagerUser">
                                <div class="col-12 ">
                                    <div>
                                        <div ngbDropdown display="dynamic" placement="bottom-left">

                                            <div ngbDropdownToggle id="navbarDropdown3" class=" btn-link text-primary font-color-gray ">
                                                <span [innerHTML]=" '¿Necesitas ayuda?' | i18n:lang "> </span>

                                            </div>

                                            <div class="text active-cursor">
                                                <span>Tu ejecutivo </span>
                                                <div class="font-weight-light" [innerHTML]="(transactionData?.salesManagerUser)?(transactionData?.salesManagerUser?.name) : '-' "></div>
                                            </div>

                                            <div style="width: 270px;" ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">


                                                <div class="p-2">
                                                    <div class="text-secondary">Ejecutivo</div>
                                                    <hr class="m-0 mt-2">

                                                    <div style="font-size:.82rem" class="w-100">

                                                        <div class="d-flex bd-highligh mt-1">
                                                            <div class="bd-highligh w-30  font-weight-bold ">Nombre</div>
                                                            <div class="bd-highligh text-right w-70 ">{{dataEjecutive?.person?.givenName }} {{dataEjecutive?.person?.familyName }}</div>

                                                        </div>

                                                        <div class="d-flex bd-highligh mt-1 ">

                                                            <div class="bd-highligh w-20  font-weight-bold ">Correo</div>
                                                            <div class="bd-highligh text-right w-80">{{ dataEjecutive?.person?.email }} </div>

                                                        </div>


                                                        <div class="d-flex bd-highligh mt-1" *ngIf="dataEjecutive?.person?.telephone">

                                                            <div class="bd-highligh w-30  font-weight-bold ">Teléfono</div>
                                                            <div class="bd-highligh text-right w-70">{{dataEjecutive?.person?.telephone }}</div>

                                                        </div>

                                                        <div class="d-flex bd-highligh mt-1" *ngIf="dataEjecutive?.person?.telephoneExtension">

                                                            <div class="bd-highligh w-30 font-weight-bold ">Extensión</div>
                                                            <div class="bd-highligh text-right w-70 ">{{ dataEjecutive?.person?.telephoneExtension }}</div>

                                                        </div>

                                                        <div class="d-flex bd-highligh mt-1 " *ngIf="dataEjecutive?.person?.contactPoint?.telephone">

                                                            <div class="bd-highligh w-50  font-weight-bold ">Teléfono Celular</div>
                                                            <div class="bd-highligh text-right w-50">{{ dataEjecutive?.person?.contactPoint?.telephone }} </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="row " style="padding-top: 12px">
                                <div class="col-12 " style="z-index: 0;">
                                    <div>
                                        <div [innerHTML]=" 'Agencia' | i18n:lang " class="font-color-gray "></div>
                                        <div [innerHTML]="transactionData?.channelAgency?.name?transactionData?.channelAgency?.name:'-' " class="text "></div>
                                    </div>
                                </div>
                            </div>

                            <div class="row " style="padding-top: 12px">
                                <div class="col-12 " style="z-index: 0;">
                                    <div>
                                        <div placement="bottom" ngbPopover="{{(transactionData?.channelAgency?.address)?(transactionData?.channelAgency?.address?.description) : ''}}" class="font-color-gray active-cursor  ">

                                            <span [innerHTML]=" 'Dirección de la Agencia' | i18n:lang "></span>
                                            <i class="pl-2 far fa-question-circle"></i>

                                        </div>
                                        <div [innerHTML]="(transactionData?.channelAgency?.address)?(transactionData?.channelAgency?.address?.description) : '' " class="text "></div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="env.theme.images !== 'MIBANCO'" class="row " style="padding-top: 12px">
                                <div class="col-12 " style="z-index: 0;">
                                    <div>
                                        <div [innerHTML]=" 'Entrevista' | i18n:lang " class="font-color-gray "></div>
                                        <div class="text ">

                                            <div class="mt-1 mb-1" *ngIf="interview?.interviewDate">
                                                <i style="font-size: 16px" class="animate__animated animate__swing {{interviewTypeIcon[interview?.type]}} pr-1 ak-text-color-1"> </i> {{interviewType[interview?.type] | i18n }}

                                                <br> {{ interview?.interviewDate | date: 'd MMM, y': 'UTC' }}
                                            </div>

                                            <div *ngIf="!interview?.interviewDate">
                                                Pendiente por asignar
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>



                        <!-- mitad -->


                        <div class="col-6  order-1 order-md-1">
                            <div class="row">
                                <div class=" col-12 order-1 order-md-1">



                                    <div class="row " style="padding-top: 12px">
                                        <div class="col-12 " style="z-index: 0;">
                                            <div>
                                                <div [innerHTML]=" '@i18n-text-shared-service-center' | i18n:lang " class="font-color-gray "></div>
                                                <div [innerHTML]="transactionData?.availableChannel?.name?transactionData?.availableChannel?.name:'-' " class="text "></div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>


                            <div class="row ">
                                <div class="col-12 " style="padding-top: 12px">
                                    <div>
                                        <div [innerHTML]=" 'Solicitante' | i18n:lang " class="font-color-gray "></div>
                                        <div [innerHTML]="transactionData?.holder?.name " class="text"></div>

                                    </div>
                                </div>

                            </div>

                            <div class="row ">

                                <div class=" col-12">

                                    <div *ngIf="transactionData?.holder?.adminOrganization" style="padding-top: 12px">
                                        <div [innerHTML]=" 'Contacto' | i18n:lang " class="font-color-gray "></div>
                                        <div class="text ">{{transactionData?.holder?.person?.givenName }} {{transactionData?.holder?.person?.familyName }}</div>

                                    </div>
                                </div>


                            </div>

                            <div class="row " style="padding-top: 12px">

                                <div class=" col-12">

                                    <div>
                                        <div class="font-color-gray ">
                                            <span [innerHTML]=" 'Tiempo estimado de llenado ' | i18n:lang "></span>


                                        </div>


                                        <div class="text "> 15 Minutos <i class="pl-1 far fa-clock"></i></div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="row ">

                        <div class="col-12 ">

                            <span class="font-color-gray pr-2 " [innerHTML]=" 'Última Actualización' | i18n:lang "> </span>
                            <span class="font-weight-bold" [innerHTML]="transactionData?.dateOfThing?.modifiedDate?(transactionData?.dateOfThing?.modifiedDate | date: 'd MMM, y h:mm a'):(transactionData?.dateOfThing?.startDate | date: 'd MMM, y h:mm a') "> </span>

                            <button (click)="open_modal()" class="nav-item  btn btn-link text-primary pl-1" style="margin-top: -3px;">

                                                Ver mas
                
                                        </button>

                        </div>

                    </div>






                    <div class="row mb-2">
                        <div class="col-12 col-md-6 d-block d-md-none mt-2 ">

                            <button (click)="showGuia = !showGuia" class="nav-item  btn btn-outline-secondary  btn-block">
    
                                        Ruta de tu solicitud</button>
                        </div>


                    </div>
                    <!--BOX DE LOS REPRESENTANTES-->

                    <div class="row d-block d-md-none " *ngIf="showGuia == true">

                        <div class=" col-md-4 col-12  mt-3 mt-md-0 animate__animated animate__fadeInRight">
                            <div class="col-12">
                                <div class=" row">
                                    <div class="col-12" style="background-color: #F5F4F4">
                                        <div style="font-size: 16px;" class="mt-2 mb-4 font-weight-bold text-dark "> Ruta de tu solicitud</div>
                                        <!--<div  style="font-size: 16px;line-height: 18px; " class="mb-4 text-secondary mb-3 ak-font-roboto">Aquí está el resumen de apertura de su cuenta </div> -->

                                        <app-guide [params]='guide' [loadingGuide]="loadingGuide"></app-guide>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ng-container *ngIf="env.showTransactionsRelatedResumen && legal_representative.length > 0">

                        <div class="rd-section-box mb-5 p-3 pt-4 pb-4 mt-5 ">
                            <div class="row ">
                                <div class="col-12 font-weight-bold">
                                    <h5 class="w-100 flex-fill font-weight-light  title-section">

                                        Solicitudes de personas relacionadas</h5>

                                </div>
                            </div>


                            <div class="row ">

                                <ng-container *ngFor="let data of legal_representative">
                                    <div class="col-12 col-md-6 pt-2">

                                        <app-resumen-box (redirect)="gotoBoxUrl($event)" [title]="'Representante Legal'" [item]="data"></app-resumen-box>

                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let data of autorizado">
                                    <div class="col-12 col-md-6 pt-2">

                                        <app-resumen-box (redirect)="gotoBoxUrl($event)" [title]="'Autorizados'" [item]="data"></app-resumen-box>

                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let data of cuentadante">
                                    <div class="col-12 col-md-6 pt-2">

                                        <app-resumen-box (redirect)="gotoBoxUrl($event)" [title]="'Cuentadante'" [item]="data"></app-resumen-box>

                                    </div>
                                </ng-container>

                                <div class="col-12 col-md-6 pt-2">


                                    <app-resumen-box (redirect)="gotoBoxUrl($event)"></app-resumen-box>

                                </div>

                            </div>

                        </div>


                    </ng-container>



                </div>
            </div>


        </div>





    </ng-container>

    <div *ngIf="transactionData" class="col-12 pr-md-4 d-none " style="position: absolute;margin-top: 32px;    margin-left: -15px;">

        <div class="row">

            <button class=" btn col-md-3 offset-md-9 btn-step btn-primary" (click)="firstStep() ">
                    
                        <span  [innerHTML]="'@i18n-ak-tep-next' | i18n " >
                    
                        </span>

                    </button>
        </div>

    </div>

    <ng-container *ngIf="!transactionData">
        <div>
            <img src="./assets/public/images/loading.gif" alt="">
        </div>
    </ng-container>