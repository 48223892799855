import { Route } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { WorkflowRoutes } from '@akeela/workflow';
import { WorkflowComponent } from '../components/workflow/workflow.component';
import { PublicDashboardComponent } from '../components/dashboard/dashboard.component';
import { RecoveryPasswordComponent } from '../components/recovery-password/recovery-password.component';
import { CreateUserPasswordComponent } from '../components/create-user-password/create-user-password.component';
import { UnlockPasswordComponent } from '../components/unlock-password/unlock-password.component';
import { ActivateAccountComponent } from '../components/activate-account/activate-account.component';
import { ValidateSession } from '../../../guards/validatesession.guard';
import { LogoutComponent } from '../components/logout/logout.component';
import { ChangePasswordPublicComponent } from '../components/change-password/change-password.component';

import { ProdutTypeComponent } from '../components/product-type/produt-type.component';
import { ProdutTypeRqComponent } from '../components/product-type-rq/product-type-rq.component';
import { OtpComponent } from '../components/otp/otp.component';
import { FaceApiComponent } from '../components/face-api/face-api.component';
import { AuthGuard } from '../../../guards/auth.guard';
import { AkeelaDashboardComponent } from '../../../modules/private/components/akeela-dashboard/akeela-dashboard.component';
import { UserAccountUserComponent } from '../../../modules/private/components/user-account-user/user-account-user.component';
import { ClientDashboardComponent } from '../../../modules/private/components/client-dashboard/client-dashboard.component';
import { TransactionDashboardComponent } from '../../../modules/private/components/transaction-dashboard/transaction-dashboard.component';
import { NewTransactionComponent } from '../../../modules/private/components/new-transaction/new-transaction.component';
import { LoginComponent } from '../../../modules/public/components/login/login.component';
import { OrdersActionsComponent } from '../../../modules/public/components/orders-actions/orders-actions.component';

export const PUBLIC_EXTERNAL_ROUTES: Route = {
    path: '',
    children: [
        {
            path: 'login',
            component: LoginComponent
        },
        
        {
            path: 'register',
            redirectTo: '/public/ACCOUNT_OPENING/stages/CREATE_USER_ACCOUNT_OPENING/steps/CREATE_USER_ACCOUNT_OPENING',
             pathMatch: 'full'
        },
        {
            path: 'unlock',
            component: UnlockPasswordComponent
        },
        {
            path: 'recovery-password',
            component: RecoveryPasswordComponent
        },
        {
            path: 'create-new-password/:username/:token',
            component: CreateUserPasswordComponent
        },
        {
            path: 'change-password/:username/:token',
            component: ChangePasswordPublicComponent
        },
        {
            path: 'activate-account/:username/:token',
            component: ActivateAccountComponent
        },
        {
            path: 'logout',
            component: LogoutComponent
        },
        {
            path: 'products-type/:typeId',
            component: ProdutTypeComponent
        },
        {
            path: 'products-type-rq/:typeId',
            component: ProdutTypeRqComponent
        },
        {
            path: 'otp',
            component: OtpComponent
        },
        {
            path: 'faceapi',
            component: FaceApiComponent
        },
        {
            path: 'dashboard',
            component: AkeelaDashboardComponent,
            canActivate: [AuthGuard]
    
        },
        {
            path: 'user-account',
            component: UserAccountUserComponent,
            canActivate: [AuthGuard]
    
        },
        {
            path: 'client-data',
            component: ClientDashboardComponent,
            canActivate: [AuthGuard]
    
        },

        {
            path: 'transaction-dashboard',
            component: TransactionDashboardComponent,
            canActivate: [AuthGuard]
    
        },
        {
            path: 'new-tansaction',
            component: NewTransactionComponent,
            canActivate: [AuthGuard]
    
        },
        // {
        //     path: 'orders',
        //     redirectTo: '/',
        // } ,  
        {
            path: 'orders',
            component: OrdersActionsComponent
        }  
    ]
};

export const PUBLIC_ROUTES: Route = {
    path: 'public',
    component: WorkflowComponent,
    children: WorkflowRoutes,
    canActivate: [ValidateSession]
};
