<div class="row pt-4">
    <div class="ak-section bg-white col-12">
  
  
      <div class="row">
  
        <div class="ak-section col-12 col-md-8 ">
          <div class="row p-2">
            <div class="col-12">
              <div class="pl-0 pr-0">
                <div class="w-100  mb-3 sub-section animate__animated animate__fadeIn">
                  <h4 class="w-100 flex-fill title-section mb-3">Actualización de Identificación de la empresa</h4>
                </div>
              </div>
            </div>
          </div>
  
          <form [formGroup]="form" (ngSubmit)="save()">


            <div class="row pt-2 pl-2 pr-2">
                <div class="col-12">
                  <label style="word-wrap: break-word" class="font-weight-bold">
                    Nombre de la Razón Social<span class="text-danger"> *</span>
                  </label>
                  <input
                id="name"
                class="form-control"
                type="text"
                formControlName="name"
                [ngClass]="{
                  error:
                    form.controls.name.invalid &&
                    form.controls.name.dirty,
                  valid: form.controls.name.valid
                }"
              />

              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('name').errors"
              >
                <div
                  *ngIf="
                    form.get('name').errors['required'] &&
                    !form.get('name').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
                  </div>
                </div>
               
            </div>

            <div class="row pt-2 pl-2 pr-2">
                <div class="col-12">

                <label style="word-wrap: break-word" class="font-weight-bold">
                    Forma Jurídica<span class="text-danger"> *</span>
                  </label>
                  <div
                    *ngIf="legalFormList$ | async as legalFormList; else loading"
                  >
                    <select
                      class="form-control"
                      id="legal_form"
                      formControlName="legal_form"
                      [ngClass]="{'error': form.controls.legal_form.invalid && form.controls.legal_form.dirty,
                      'valid': form.controls.legal_form.valid
                    }" 
  
                    >
                      <option value="" selected="true">Seleccione</option>
                      <option
                        *ngFor="let row of legalFormList"
                        [ngValue]="row.identifier"
                      >
                        {{ row.name }}
                      </option>
                    </select>
  
                    <div class="form-control-feedback help-block" *ngIf="form.get('legal_form').errors">
                      <div *ngIf="form.get('legal_form').errors['required'] && !form.get('legal_form').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                    </div>
                  </div>
  
  
                  </div>
                  <ng-template #loading>
                    <div>
                      <i
                        class="fas fa-circle-notch fa-spin load-select text-primary"
                      ></i>
                    </div>
                  </ng-template>
                </div>
               
            </div>

            <div class="row pt-2 pl-2 pr-2">
                <div class="col-12">
                    <label style="word-wrap: break-word" class="font-weight-bold">
                        Número de Identificación Fiscal
                    </label>
                    <input
                      id="taxID"
                      class="form-control"
                      type="text"
                      formControlName="taxID"
                      [ngClass]="{
                        error:
                          form.controls.taxID.invalid && form.controls.taxID.dirty,
                        valid: form.controls.taxID.valid
                      }"
                    />
      
                    <div class="w-100 mb-2 mt-1 pl-1 pr-1" style="line-height: 1rem;">
                      <span class="form-control-feedback small text-secondary">Ej. J191635850</span>
                    </div>
      
      
                    <div
                      class="form-control-feedback help-block"
                      *ngIf="form.get('taxID').errors"
                    >
                      <div
                        *ngIf="
                          form.get('taxID').errors['required'] &&
                          !form.get('taxID').pristine
                        "
                        [innerHTML]="
                          '@i18n-store-front-login-error-field-required' | i18n
                        "
                      ></div>
                    </div>
                  </div>
            </div>

            <div class="row pt-2 pl-2 pr-2">
                <div class="col-12">
                  <label style="word-wrap: break-word" class="font-weight-bold">
                    Nombre Comercial<span class="text-danger"> *</span>
                  </label>
                  <input
                id="legal_name"
                class="form-control"
                type="text"
                formControlName="legal_name"
                [ngClass]="{
                  error:
                    form.controls.legal_name.invalid &&
                    form.controls.legal_name.dirty,
                  valid: form.controls.legal_name.valid
                }"
              />

              <div class="w-100 mb-2 mt-1 pl-1 pr-1" style="line-height: 1rem;">
                <span class="form-control-feedback small text-secondary">El nombre comercial es la forma en que una empresa elige darse a conocer ante el público. Puede ser la misma razón social, o uno diferente.
                </span>
              </div>

              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('legal_name').errors"
              >
                <div
                  *ngIf="
                    form.get('legal_name').errors['required'] &&
                    !form.get('legal_name').pristine
                  "
                  [innerHTML]="
                    '@i18n-store-front-login-error-field-required' | i18n
                  "
                ></div>
                  </div>
                </div>
               
            </div>


            <div class="row pl-2 pr-2">
                <div class="col-12">
                  <div class="text-left w-100 m-t-1 btn-section-save">
                    <!-- <pre>{{ form.value | json }}</pre> -->
                    <button
                      [disabled]="!form.valid || loading_form"
                      type="submit"
                      class="btn btn-primary"
                    >
                      <span>Guardar</span
                      ><span class="fas fa-arrow-right ml-1" *ngIf="form.valid && !loading_form"> </span><span class="fas fa-circle-notch fa-spin ml-1" *ngIf="loading_form"> </span>
                    </button>
                  </div>
                </div>
              </div>

            

          </form>
        </div>

        <div class="d-none d-md-block col-md-4 ak-border-left-box-right">
            <div class="p-1 pl-2  mt-2 mb-2 ">
                <div>
                    <div class="base-description-section-top mb-2 font-weight-bold">
                    Datos Básicos
                    </div>
            
                    <div class="text-form-description ">
                        Se refiere a la información de la empresa, tales como: Razón Social, Forma Jurídica, Número de RIF, entre otros.<br><br>Esta información estará visible a su ejecutivo asignado. De este modo, le resultará más fácil validar la información y ponerse en contacto con los representantes de la empresa.
                    </div>
                 </div>
            </div>
          </div>


    </div>
</div>


