import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SubheaderService {
  
  subHeader$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }
}
