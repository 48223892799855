<div class="text-center row">

    <div class="col-12 col-md-10 offset-md-1">
        <!--
        <div class="text-left">
            <span class="pt-2 pb-2 font-weight-light">Verifícate</span>
            <div class="line">
                <div class="line5 bg-primary font-weight-light"></div>
            </div>
        </div>
        -->
        <div class="mt-4 mb-4 ">

            <h5 class="font-weight-light text-dark mb-4">Felicitaciones</h5>

            <i class="fas fa-check-double text-primary " style=" font-size: 75px;"></i>



            <div class=" font-weight-bold text-dark mt-4 ">
                <div class="">Ahora puedes continuar con tu proceso</div>

            </div>

        </div>


        <div class="mt-4 mb-5 row">
            <div class="col-12 col-md-4 offset-md-4">
                <button (click)="redirect()" class="btn btn-block btn-outline-primary ">Ok</button>
                <br>
            </div>
        </div>

    </div>
</div>