import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { AkeelaStageService, AkeelaWorkflowService, RouteUtilsService, Stage, Workflow } from '@akeela/workflow';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'app-stage',
    templateUrl: './stage.component.html',
    styleUrls: ['./stage.component.css']
})
export class StageComponent implements OnInit, OnDestroy {

    @Input() value: string;

    private workflowSubscription: Subscription;
    private currentStageSubscription: Subscription;
    workflow: Workflow;
    stages: Stage[];
    currentStageCode: string;

    @Input() workflowCode: string;
    @Input() inputPoint: string;
    @Input() profile: string;
    @Input() audience: string;
    @Input() role: string;
    @Input() loading: boolean;


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private stageService: AkeelaStageService,
        private workflowService: AkeelaWorkflowService,
        private routeService: RouteUtilsService,
    ) {
    }

    ngOnInit(): void {
        this.route.params
            .pipe(
                mergeMap(params => this.route.queryParams
                    .pipe(
                        map(queryParams => {
                            return { ...params, ...queryParams };
                        })
                    )
                )
            )
            .subscribe(params => {
                this.role = this.routeService.getRole(params);
                this.audience = this.routeService.getAudience(params);
                this.profile = this.routeService.getProfile(params);
                this.inputPoint = this.routeService.getInputPoint(params);
                this.workflowService.currentWorkflow$.subscribe(workflowCode => {
                    this.workflowCode = workflowCode;
                    this.createStagesSubscription();
                });
            });

    }

    createStagesSubscription() {

        this.deleteWorkflowSubscription();

        this.workflowSubscription = this.stageService.getStages(this.workflowCode, this.profile, this.role, this.audience)
            .subscribe(stages => {
                this.stages = stages;
                this.createCurrentStageSubscription();
            });
    }

    createCurrentStageSubscription() {
        this.deleteCurrentStageSubscription();
        this.currentStageSubscription = this.stageService.currentStage$.subscribe(currentStageCode => {
            setTimeout(() => this.currentStageCode = currentStageCode, 100);
        });
    }

    deleteWorkflowSubscription() {
        if (this.workflowSubscription) {
            this.workflowSubscription.unsubscribe();
        }
    }

    deleteCurrentStageSubscription() {
        if (this.currentStageSubscription) {
            this.currentStageSubscription.unsubscribe();
        }
    }


    gotoStage(stageName: string, doNothing?: boolean) {
        const prefixed = this.router.config[0].path;
        if (doNothing) {
            return;
        }
        const queryParams: any = {
            role: this.role,
            audience: this.audience,
            profile: this.profile
        };

        if (!isNullOrUndefined(this.inputPoint)) {
            queryParams.input_point = this.inputPoint;
        }

        if (!isNullOrUndefined(stageName)) {
            this.currentStageCode = stageName;
        }

        this.router.navigate([prefixed, this.workflowCode, 'stages', this.currentStageCode], { queryParams });
    }


    ngOnDestroy() {
        this.deleteCurrentStageSubscription();
        this.deleteWorkflowSubscription();
    }

}

