import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Utf8RegexService {

  constructor() { }

  /**
 * Encodes multi-byte Unicode string into utf-8 multiple single-byte characters
 * (BMP / basic multilingual plane only).
 *
 * Chars in range U+0080 - U+07FF are encoded in 2 chars, U+0800 - U+FFFF in 3 chars.
 *
 * Can be achieved in JavaScript by unescape(encodeURIComponent(str)),
 * but this approach may be useful in other languages.
 *
 * @param   {string} unicodeString - Unicode string to be encoded as UTF-8.
 * @returns {string} UTF8-encoded string.
 */
  utf8Encode(unicodeString) {
    let utf8String = '';
    utf8String = this.replaceCharacters(unicodeString);

    // console.log(typeof unicodeString);
    // console.log('-------------------');
    // if (typeof unicodeString !== 'string') {
    //   utf8String = unicodeString.replace(
    //     /[\u0080-\u07ff]/g,  // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
    //     function (c) {
    //       const cc = c.charCodeAt(0);
    //       return String.fromCharCode(0xc0 | cc >> 6, 0x80 | cc & 0x3f);
    //     }
    //   ).replace(
    //     /[\u0800-\uffff]/g,  // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
    //     function (c) {
    //       const cc = c.charCodeAt(0);
    //       return String.fromCharCode(0xe0 | cc >> 12, 0x80 | cc >> 6 & 0x3F, 0x80 | cc & 0x3f);
    //     }
    //   );
    // } else {
    //   throw new TypeError('parameter ‘unicodeString’ is not a string');
    // }
    return utf8String;
  }

  replaceCharacters(text) {
    text = text.replace(new RegExp('á', 'g'), '&aacute;');
    text = text.replace(new RegExp('é', 'g'), '&eacute;');
    text = text.replace(new RegExp('í', 'g'), '&iacute;');
    text = text.replace(new RegExp('ó', 'g'), '&oacute;');
    text = text.replace(new RegExp('ú', 'g'), '&uacute;');
    text = text.replace(new RegExp('Á', 'g'), '&Aacute;');
    text = text.replace(new RegExp('É', 'g'), '&Eacute;');
    text = text.replace(new RegExp('Í', 'g'), '&Iacute;');
    text = text.replace(new RegExp('Ó', 'g'), '&Oacute;');
    text = text.replace(new RegExp('Ú', 'g'), '&Uacute;');
    text = text.replace(new RegExp('ñ', 'g'), '&ntilde;');
    text = text.replace(new RegExp('Ñ', 'g'), '&Ntilde;');
    text = text.replace(new RegExp('€', 'g'), '&euro;');
    return text;
  }

  toRegexUnicode(theString) {
    theString = theString.replace(/\\/g, '\\\\');
    return new RegExp(theString);
  }

  /**
  * Decodes utf-8 encoded string back into multi-byte Unicode characters.
  *
  * Can be achieved JavaScript by decodeURIComponent(escape(str)),
  * but this approach may be useful in other languages.
  *
  * @param   {string} utf8String - UTF-8 string to be decoded back to Unicode.
  * @returns {string} Decoded Unicode string.
  */
  utf8Decode(utf8String) {
    let unicodeString = '';
    if (typeof utf8String !== 'string') {
      // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
      unicodeString = utf8String.replace(
        /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g,  // 3-byte chars
        function (c) {  // (note parentheses for precedence)
          const cc = ((c.charCodeAt(0) & 0x0f) << 12) | ((c.charCodeAt(1) & 0x3f) << 6) | (c.charCodeAt(2) & 0x3f);
          return String.fromCharCode(cc);
        }
      ).replace(
        /[\u00c0-\u00df][\u0080-\u00bf]/g,                 // 2-byte chars
        function (c) {  // (note parentheses for precedence)
          const cc = (c.charCodeAt(0) & 0x1f) << 6 | c.charCodeAt(1) & 0x3f;
          return String.fromCharCode(cc);
        }
      );
    } else {
      throw new TypeError('parameter ‘utf8String’ is not a string');
    }
    return unicodeString;
  }
}
