import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { 
  AkeelaWorkflowService, 
  AkeelaStageService, 
  AkeelaStepService, 
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AlertService } from '@akeela/alert';
import { Alert } from 'selenium-webdriver';
import { Subscription } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
import { Table, Row, Column } from '@akeela/ak-table';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-section-suppliers-pn',
  templateUrl: './section-suppliers-pn.component.html',
  styleUrls: ['./section-suppliers-pn.component.css']
})
export class SectionSuppliersPNComponent implements OnInit, OnDestroy {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  active_redirect = false;
  readonlyMode = false;
  private workflowEventsSubscription: Subscription;
  subscriptions: Subscription[] = [];
  tableShow = false;

  loading_table = false;
  configuration_table: Table;
  paramsSubscription: Subscription;

  env = null;
  hideTop = false;


  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private _alert: AlertService,
    private localStorage: LocalService

  ) { }


  ngOnInit() {
    this.env = environment;

    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();

    if(this.env) {
      if(this.env?.theme?.images==="CARONI") {
        this.hideTop = true;
      }
    }

    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
     });


    this.akeelaWorkflowService.transactionNumber.subscribe(transactionNumber => {
      if (transactionNumber) {
        this.transactionNumber = transactionNumber;
      }
    });


    this.akeelaWorkflowService.transactionData.subscribe(transactionData => {
      this.transactionData = transactionData;
      if (!isNullOrUndefined(this.transactionData) && 
        (!isNullOrUndefined(this.transactionData.organization) || !isNullOrUndefined(this.transactionData.holder.person))) {
          this.transactionData = transactionData;
           this.getHolder(true);
        }
    });

    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    this.createEventsSubscription();
    this.getItemList(); 

  }


  createEventsSubscription() {
    this.workflowEventsSubscription = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
     console.log(response);
      if (response && response.action === 'showLoadingSuppliers') {
      if (!response.value) {
        this.loading_table = true ;
        this.getItemList();
        this.tableShow = true
      }else{
        this.loading_table = true ;
        this.tableShow = false;
      }
      this.loading_table = false ;
    } else if ( response &&  response.action == "account_contacts$customer-contacts$post"){
        this.loading_table = true ;
        this.getItemList();
        this.tableShow = true
        this.loading_table = false ;
    } 
  });
  }

  



  getHolder(tableShow = true) {
    const params = {
      name:   "accounts$accounts_holder$get",
      params: {
        request: {
          role: this.localStorage.getItem('role')

        },
        path: {
          transactionNumber:this.transactionNumber
        },
        body: {}
      }
    };


    this.subscriptions.push( this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      
      this.getItemList();
    
      if (!isNullOrUndefined(response.person.ownBusiness) && tableShow){
        if (response.person.ownBusiness.itHasNoSuppliers === false) {
          this.tableShow =true;
        }
      }

    }));
  }


  getItemList() {
    const params = {
      name: 'account_contacts$contacts$get',
      params: {
        request: {
          contactType: 'SUPPLIER',
          idPerson: this.localStorage.getItem('clientIdentifier')
        },
        path: {},
        body: {}
      }
    };

    this.subscriptions.push(  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.itemList = response;
      this.setConfigurationTable();
        this.akeelaWorkflowService.setResetEstatus(true);
    }));
  }

  // openItem(row) {
    
  //   this.akeelaSectionService.addModalSection({ name: 'SUPPLIERS_PN_MODAL' });
  //   this.akeelaSectionService.showModalSection('SUPPLIERS_PN_MODAL');
  //   row.disableWhenUpdate = 'applicantType';

  //   // Mario: hay un caso en que si se agregan varios tipos de personas relacionadas, y se edita una persona natural y luego una persona legal, se pasa tanto el identifier de la persona legal, pero tambien se pasa el identifier de la persona natural, esto
  //   // genera un bug, ya que se borran los datos de la persona natural
  //   // entonces para solventar esto, se agrega una condición, para que si se va a guardar una persona legal, se vacie el identificador de la natural.
  //   // personContact.identifier;
  //   if (row.applicantType === 'LEGAL') {
  //     row['personContact'] = {
  //       identifier: null
  //     };
  //   }
    
  //   this.akeelaSectionService.setModalFormValue('SUPPLIERS_PN_MODAL', row);
  // }

  openItem(row: any) {

    this.akeelaSectionService.addModalSection({ name: 'SUPPLIERS_PN_MODAL' });
    this.akeelaSectionService.showModalSection('SUPPLIERS_PN_MODAL', true);
    // row.disableWhenUpdate = 'applicantType';


    // let auxItemsDisable = [];
    // auxItemsDisable = Object.assign({}, auxItemsDisable);
    // row = Object.assign( row, auxItemsDisable);

    let auxItems = [];
    auxItems['applicantType'] = row.alternateName;
    auxItems = Object.assign({}, auxItems);
    row = Object.assign(auxItems, row);
    

    // Mario: hay un caso en que si se agregan varios tipos de personas relacionadas, y se edita una persona natural y luego una persona legal, se pasa tanto el identifier de la persona legal, pero tambien se pasa el identifier de la persona natural, esto
    // genera un bug, ya que se borran los datos de la persona natural
    // entonces para solventar esto, se agrega una condición, para que si se va a guardar una persona legal, se vacie el identificador de la natural.
    // personContact.identifier;
    // if (row.alternateName === 'LEGAL') {
    //   delete row['personContact'];
    //   row['personContact'] = null;
    // } else {
    //   delete row['organizationContact'];
    //   row['organizationContact'] = null;
    // }
    
    delete row['alternateName'];
    this.akeelaSectionService.setModalFormValue('SUPPLIERS_PN_MODAL', row);
  }

  confirmDelete(row: any){
    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }

  deleteItem(row: any) {
    const personId = this.localStorage.getItem('clientIdentifier');
    const configuration = {
      name: 'account_contacts$delete-contact$delete',
      // account_contacts/contacts/{personId}/accounts/{transactionNumber}/workflows/{workflow}/stages/{stage}/steps/{step}
      params: {
        path: {},
        request: {
          idPerson: personId,
          contactType: 'SUPPLIER',
          currentStepCode: this.step,
          audience: this.queryParams.audience,
          role: this.queryParams.role,
          contactId: row.identifier
        }
      }
    };

    this.subscriptions.push( this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
      swal.fire({
        title: this.i18n.transform('Éxito'),
        html: this.i18n.transform('@i18n-text-information-deleted')
      });
      this.updateList('account_contacts$customer-contacts$post');
      this.akeelaWorkflowService.setResetEstatus(true);

    }, error => {
    }));
  }

  updateList(action: string){
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }

  ngOnDestroy() {
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach(s => s.unsubscribe());
  }

    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }

  }





  /**tabla */
  
  setConfigurationTable() {
    const base: Column[] = this.setHeadTable();
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'class-color-title-table-section',
        rows: [{
          backgroundColor: '#fff',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };
    
  }

  setHeadTable(){
    
    const base: Column[] = [
      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '100',
        html: 'Identificación',
        cssCustom: '',
        enableRightBorder: true

      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Tipo',
        cssCustom: '',

      }
      , 
      {
        alignContent: 'center',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html:'',
        cssCustom: ''
      }
  ]
    return base;
  }
  
  setContentTable(configuration): Row[] {
    let content: Row[] = [];
    let i = 0;
    console.log("setContentTable setContentTable : ");

    for (const row of  this.itemList) {
      i++;

      let temp = '-';
      if(row.alternateName == 'NATURAL'){

        temp = '@i18n-quote-option-list-applicantType-option-natural';

      }else if(row.alternateName == 'LEGAL'){
        temp = '@i18n-quote-option-list-applicantType-option-legal';


      }

      
      content = [...content,
        {
          identifier: row,
          cssCustom: '',
          enabled: true,
          columns: [
              this.setRowContentTable({

                configuration,
                number:0,
                html: row.personContact? this.capitalize(row.personContact.givenName) + " " +this.capitalize(row.personContact.familyName):this.capitalize(row.organizationContact.legalName),
                cssCustom:'ak-tabled-row-active text-capitalize'
            
              }), 

              this.setRowContentTable({
                configuration,
                number:1,
                html:this.i18n.transform(temp),
              }),  

              this.setRowContentTable({
                configuration,
                number:2,
                html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
                click: 'delete_item',
              })
          ]
        }
      ];
    }
    return content;
  }


  capitalize(word) {
    if(word && word[0]){
      return word[0].toUpperCase() + word.slice(1);
   
    }
    return '';
  }


  setRowContentTable(row){
    
    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click?row.click:null ;
    let dropdown = row.dropdown?row.dropdown:null ;
    let cssCustom = row.cssCustom?row.cssCustom:configuration[number].cssCustom;


let result = {
      
      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click:click,
      dropdown:dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }
  
return result;

  }
  table_response(response) {

    if (response.action === 'open') {
      this.openItem(response.object.identifier);

    }else if (response.action === 'action') {
       if (response.object.action === 'delete_item' && !this.readonlyMode) {

        this.confirmDelete(response.object.obj.identifier);
      }
    }
  }


}



