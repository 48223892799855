import { AuthService } from '@akeela/auth';
import { UploadService } from '@akeela/common';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { log } from 'console';
import { Subscription } from 'rxjs';
import { PropertiesService } from 'src/app/services';
import { WorkflowService } from 'src/app/services/workflow.service';


@Component({
  selector: 'client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.css']
})
export class ClientDashboardComponent implements OnInit {

  dataAndSelfi;
  filePreview;
  showBackDashboard ;
  verifiedIdentification;
  compleatVerifi;
  indicatorsLoading;
  indicatorsProgres :any = {};
  transitionsWfs;
  workflowStatus;
  workflowStatusMenu;
  workflowStatusSubscription: Subscription;
  dropDownTransitionsWfs= {};
  workflowCodeActive;
  transactionData;
  primer = true;
dataInformation;
clientActive = 'CLIENT_DATA';
itemList = {};
accountType;
economicActivity;
civilStatus;
housingCondition;
incomeGeneratingActivity;
isNatural;
  constructor(
    private router: Router,
    public  config: NgbDropdownConfig,
    public _auth:AuthService,
    public _properties: PropertiesService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _wfService: WorkflowService,
    private workflowService: AkeelaWorkflowService,
    private _httpService: HttpService,
    private i18n: I18nPipe,
    private localStorage: LocalService,
    private akWorkflowService: AkeelaWorkflowService,


  ) { }


  ngOnInit(): void {
    this.isNatural = this.localStorage.getItem('clientaudience') == 'NATURAL';


    if( this.localStorage.getItem('redirectTransation')){

    this.clientActive =   this.localStorage.getItem('clientActive');
    
    }


  this.getDataInformations();
  //this.get_dataAndSelfi();


    this.workflowCodeActive = this.clientActive;

this.getIndicatorsProgres();

    this.getOptionsMenu(this.workflowCodeActive);

          this.resetStatus();
          this.workflowService.transactionData$.next(null);
          this.workflowService.setTransactionNumber(null);
          this.workflowService.setWfTransitionsActive(false);

  }



getDataInformations() {
  const params = {
    name:  this._auth.getAdminOrganizationIdentifier()? 'briefcase$business_person$get' : 'briefcase$person$get',
    params: {
      request: {
        'id': this.localStorage.getItem('clientIdentifier')
      },
      path: {},
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe(async (response: any) => {

    
   this.dataInformation = response;


if(this.isNatural){

  this.getItemListLR();
  this.getItemListPepR();
  this.getItemListPepRA();
  this.getItemListRefPer();
  this.getEconomicActivity();
  this.getCivilStatus();
  this.getHousingCondition();
  this.getIncomeGeneratingActivity();

}else{

  this.getItemListLR_legal();
  this.getItemListShareHolder();
  this.getItemListSupliers();
  this.getItemListCustomers();  
  this.getItemListComRef();  
  this.getItemListRelComp();  
  this.getItemListFiscalAdress();

}
this.getItemListOtherBank();
this.getItemListBank();

this.getAccountType();

   
  });

}



getIncomeGeneratingActivity() {
  const params = {
    name: 'workflow_manager$attributes_options$get',
    params: {
      request: {},
      path: {
        code: 'incomeGeneratingActivity'
      },
      body: {}
    }
  };
  
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    let nameItem = '';
    if(response.length){
      this.incomeGeneratingActivity = [];
      for (const value of response) {
        nameItem = value.item.alternateName;
        this.incomeGeneratingActivity[nameItem] = value.name;

      }}});
  
}

getHousingCondition() {
  const params = {
    name: 'workflow_manager$attributes_options$get',
    params: {
      request: {},
      path: {
        code: 'housingCondition'
      },
      body: {}
    }
  };
  
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    let nameItem = '';
    if(response.length){
      this.housingCondition = [];
      for (const value of response) {
        nameItem = value.item.alternateName;
        this.housingCondition[nameItem] = value.name;

      }}});
  
}

getCivilStatus() {
  const params = {
    name: 'workflow_manager$attributes_options$get',
    params: {
      request: {},
      path: {
        code: 'civilStatus'
      },
      body: {}
    }
  };
  
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    let nameItem = '';
    if(response.length){
      this.civilStatus = [];
      for (const value of response) {
        nameItem = value.item.alternateName;
        this.civilStatus[nameItem] = value.name;

      }}});
  
}

getEconomicActivity() {
  const params = {
    name: 'product_plan$economic_activities$get',
    params: {
      request: {},
      path: {},
      body: {}
    }
  };
  

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
  
  
    let nameItem = '';
    if(response.length){
      this.economicActivity = [];
      for (const value of response) {
        nameItem = value.identifier;
        this.economicActivity[nameItem] = value.name;

      }}});
  
}

getAccountType() {
  const params = {
    name: 'workflow_manager$attributes_options$get',
    params: {
      request: {},
      path: {
        code: 'accountType'
      },
      body: {}
    }
  };
  
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    let nameItem = '';
    if(response.length){
      this.accountType = [];
      for (const value of response) {
        nameItem = value.item.alternateName;
        this.accountType[nameItem] = value.name;

      }}});
  
}


redirectContinue(urlWf = this.clientActive ) {

  this.localStorage.removeItem('redirectTransation');


  if(!this.dataInformation?.completeBriefcase){

 let url = [urlWf];
 const prefixed = 'private';

  const queryParams = {
    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience')
  };

  this.localStorage.setItem('sectionRedirect',true);

  this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });

}
}



get_dataAndSelfi(){

    const params = {
      name: 'person$selfie$get',
      params: {
        request: {
          'username': this._auth.getUsername()
        },
        path: {},
        body: {}
      }
    };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    this.dataAndSelfi = response;
    this.filePreview = 'data:image/jpg' + ';base64,' + response?.description;

    
  });

}


redirecClient(section = false){


  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    section:section?section:'PERSONAL_INFORMATION'

  };

  this.akWorkflowService.setCurrentWorkflowCodeActive(this.clientActive);
  let url = ['private/' + this.clientActive+ '/stages/CLIENT_IDENTIFICATION/steps/PERSONAL_INFORMATION'];
  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
  });

}



getOptionsMenu(wf) {
  const params = {
    name: 'workflows$stagesMenu$get',
    params: {
      request: {
        role: this.localStorage.getItem('role'),
        audience: this.localStorage.getItem('audience'),
        profile: this.localStorage.getItem('profile'),
        idPerson:  this.localStorage.getItem('clientIdentifier')


      },
      path: {
         // workflowCode: this.transactionData?.plan?.
           workflowCode: wf


      },
      body: {}
    }
  };

  let sectionBack = null;

 this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
   let endSection    = null;

      if (result) {
        
        let transitionsWfs = [];

         for (const dataStage of result) {
          if (dataStage) {
              if (dataStage.howToSteps) {
                  for (const dataStep of dataStage.howToSteps) {

                    if(this.primer &&  dataStep?.sections?.length > 0){
                      this.dropDownTransitionsWfs[dataStep?.alternateName] = true;
                      this.primer = false;
                    }else{

                      this.dropDownTransitionsWfs[dataStep?.alternateName] = true;

                    }

                      if (dataStep?.sections?.length > 0) {

                        for (const dataSection of dataStep.sections) {

                          endSection = dataSection.alternateName;


                        transitionsWfs[dataSection.alternateName]={};
                        transitionsWfs[dataSection.alternateName]['active'] = {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf} ;
                        

                        if(sectionBack){
                          
                          transitionsWfs[sectionBack?.section]['new'] = {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf } ;
                          transitionsWfs[dataSection.alternateName]['back'] = sectionBack;
                          sectionBack =  {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf};

                        }else{
                          sectionBack =  {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf};
                        }
                      }

                      }

                  }
              }
          }

      }

      transitionsWfs[endSection]['end'] = '/dashboard';
      

      this.workflowService.setWfTransitions(transitionsWfs);


      this.transitionsWfs =  transitionsWfs;
      this.workflowStatusMenu =  result;
      }else{
        this.transitionsWfs = null;
         this.workflowStatusMenu = null;

      }


  });
 
}



redirectSection(urlWf, stage,step,section ) {



  if(!this.dataInformation?.completeBriefcase){
    let temp =(this. workflowStatus[stage])?.steps[step]?.sections[section]?.state;
  
    
    if( temp == 'COMPLETED'){
  

  this.dropDownTransitionsWfs[step] = true;
  const prefixed = 'private';
 let url = [urlWf + '/stages/' + stage + '/steps/' + step];

  const queryParams = {
    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    section: section,

  };


  this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });
  }else{

    this.redirectContinue();

  }
}
}


resetStatus() {    

let params;



if( this.workflowCodeActive == this.clientActive){
   params = {

    name: 'briefcase$stages_status$get',
    params: {
      request: {
        role: this.localStorage.getItem('role'),
        audience: this.localStorage.getItem('audience'),
        id_person:  this.localStorage.getItem('clientIdentifier'),
        wfp_code: this.workflowCodeActive

      },
      path: {
     
      },
      body: {}
    }
  };

}


  if(this.workflowStatusSubscription){
    this.workflowStatusSubscription.unsubscribe();
  }



  this.workflowStatusSubscription =  this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
    let sectionBack = null;
    let sectionNext = null;

      if (result) {

       //   this._akeelaSidebarMenu.setshowSidebarLeft(true);
          let WorkflowStatus = [];
          let stepsStates;
          let sectionsStates;

          for (const dataStage of result) {
              if (dataStage) {

                  stepsStates = [];
                  if (dataStage.howToSteps) {
                      for (const dataStep of dataStage.howToSteps) {
                          sectionsStates = [];

                          if (dataStep.sections) {

                              for (const dataSection of dataStep.sections) {                                 
                                  sectionsStates[dataSection.alternateName] = {
                                      code: dataSection.alternateName,
                                      state: dataSection.status,
                                  };
                              }
                          }

                          stepsStates[dataStep.alternateName] = {
                              code: dataStep.alternateName,
                              state: dataStep.status,
                              sections: sectionsStates
                          };

                      }
                  }
              }


              WorkflowStatus[dataStage.alternateName] = {
                  code: dataStage.alternateName,
                  state: dataStage.status,
                  steps: stepsStates
              };

           

          }

              this.workflowStatus = WorkflowStatus;
              

      }

  });
 
}


getIfSection(section,data){


  if(this.transitionsWfs && this.transitionsWfs[section]){

    let resp =   this.transitionsWfs[section] ?   this.transitionsWfs[section][data] != null  : false;

    return true;
  }else{

    this.transitionsWfs;
    return false;
  }
}



getIndicatorsProgres() {
  let data :any;


    data = {
      "workflow":this.workflowCodeActive,
      "personId": this.localStorage.getItem('clientIdentifier')
  }
 
    
 
  const params = {

    name: 'resume$workflow_progress$post',
    params: {
      request: {
        role: this.localStorage.getItem('role'),
        audience: this.localStorage.getItem('audience'),

      },
      path: {},
      body: data

    }
  };

  this.indicatorsLoading = true;
  let indicatorsProgres = {};
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if (response) {
      let progress;
      let preData =response[0]?.modules;

       progress = response[0]?.modules[0]?.progress;
      
      


       let  maxValue = progress?.maxValue;
       let  minValue = progress?.minValue;

       indicatorsProgres['maxValue'] = maxValue;
       indicatorsProgres['minValue'] = minValue;
       indicatorsProgres['percent'] = minValue > 0 ?   this.round(minValue*100/maxValue):0;
        
       this.indicatorsProgres = indicatorsProgres;
        this.indicatorsLoading = false;
      }


  }, error => {

    this.indicatorsLoading = false;

  });

}

round(value) {
  
return Math.round(value);
}



sendCompleat() {
  

  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    section:'SEND_CUSTOMER_DATA'

  };

  this.akWorkflowService.setCurrentWorkflowCodeActive(this.clientActive);
  let url = ['private/' + this.clientActive+ '/stages/CLIENT_IDENTIFICATION/steps/SEND_CUSTOMER_DATA'];
  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
  });

}

backCompleat() {
  let data :any;
 
  const params = {

    name: 'briefcase$reset_complete-data$post',
    params: {
      request: {
        'username': this._auth.getUsername()
      },
      path: {},
      body: {}
    }
  };


  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if (response) {


        this.getDataInformations();

      }
  }, error => {


  });

}



getItemListLR() {

  const params = {
    name: 'briefcase$get-relateds$get',
    params: {
      request: {
        personId: this.localStorage.getItem('clientIdentifier'),
        relatedType:'LEGAL_REPRESENTATIVE'
      },
      path: {
      },
      body: {}
    }
  };

     this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        this.itemList['LEGAL_REPRESENTATIVE_PN'] = response;
      }, error => {
  
      });

    }


    getItemListPepR() {



      const params = {
        name: 'briefcase$get-relateds$get',
        params: {
          request: {
            personId: this.localStorage.getItem('clientIdentifier'),
            relatedType:'PEP',
            pepType:'RELATIONSHIP'
          },
          path: {
          },
          body: {}
        }
      };
         
    
         this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
            this.itemList['RELATED_PEP_PN'] = response;
          }, error => {
      
          });
    
        }
    

    getItemListPepRA() {



      const params = {
        name: 'briefcase$get-relateds$get',
        params: {
          request: {
            personId: this.localStorage.getItem('clientIdentifier'),
            relatedType:'PEP',
            pepType:'ASSOCIATION'
          },
          path: {
          },
          body: {}
        }
      };
         
    
         this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
            this.itemList['RELATED_PEP_ASSOCIATION_PN'] = response;
          }, error => {
      
          });
    
        }
    



    getItemListRefPer() {


      const params = {
        name: 'account_contacts$contacts$get',
        params: {
          request: {
            idPerson: this.localStorage.getItem('clientIdentifier'),
            contactType:'PERSONAL_REFERENCE'
          },
          path: {
          },
          body: {}
        }
      };
         
    
         this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
            this.itemList['PERSONAL_REFERENCES'] = response;
          }, error => {
      
          });
    
        }

        getItemListBank() {

          const params = {
            name: 'briefcase$person_bank_accounts$get',
            params: {
              request: {
                otherInstitution: false,
                idPerson: this.localStorage.getItem('clientIdentifier'),
      
              },
              path: {
              },
              body: {}
            }
          };
          
             
        
             this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
                this.itemList['BANK_ACCOUNT_WITH_INST'] = response;
              }, error => {
          
              });
        
            }
    
    
    getItemListOtherBank() {

      const params = {
        name: 'briefcase$person_bank_accounts$get',
        params: {
          request: {
            otherInstitution: true,
            idPerson: this.localStorage.getItem('clientIdentifier'),
  
          },
          path: {
          },
          body: {}
        }
      };
      
         
    
         this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
            this.itemList['BANK_ACCOUNT_WITH_OTHER_INST'] = response;
          }, error => {
      
          });
    
        }





getItemListLR_legal= async () => {

  const params = {
    name: 'briefcase$get-relateds$get',
    params: {
      request: {
        personId: this.localStorage.getItem('clientIdentifier'),
        relatedType:'LEGAL_REPRESENTATIVE'
      },
      path: {
      },
      body: {}
    }
  };

    try {

      let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();

      this.itemList['LEGAL_REPRESENTATIVE'] = response;

    } catch (error) {

      this.itemList['LEGAL_REPRESENTATIVE'] = null;
      
    }

    }



getItemListShareHolder= async () => {
  const params = {
    name: 'briefcase$get-relateds$get',
    params: {
      request: {
        personId: this.localStorage.getItem('clientIdentifier'),
        relatedType:'SHAREHOLDER'
      },
      path: {
      },
      body: {}
    }
  };

    try {

      let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();
      this.itemList['SHAREHOLDERS'] = response;

    } catch (error) {

      this.itemList['SHAREHOLDERS'] = null;
      
    }

    }



getItemListCustomers= async () => {

  const params = {
    name: 'account_contacts$contacts$get',
    params: {
      request: {
        idPerson: this.localStorage.getItem('clientIdentifier'),
        contactType:'CUSTOMER'
      },
      path: {
      },
      body: {}
    }
  };
    try {
      let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();

      this.itemList['CUSTOMERS'] = response;

    } catch (error) {

      this.itemList['CUSTOMERS'] = null;
      
    }

    }




getItemListSupliers= async () => {

  const params = {
    name: 'account_contacts$contacts$get',
    params: {
      request: {
        idPerson: this.localStorage.getItem('clientIdentifier'),
        contactType:'SUPPLIER'
      },
      path: {
      },
      body: {}
    }
  };
    try {
   

      let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();

      this.itemList['SUPPLIERS'] = response;

    } catch (error) {

      this.itemList['SUPPLIERS'] = null;
      
    }

    }



    getItemListComRef = async () => {

  const params = {
    name: 'account_contacts$contacts$get',
    params: {
      request: {
        idPerson: this.localStorage.getItem('clientIdentifier'),
        contactType:'RELATED_COMPANY'
      },
      path: {
      },
      body: {}
    }
  };
    try {

      let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();
      this.itemList['RELATED_COMPANIES'] = response;

    } catch (error) {

      this.itemList['RELATED_COMPANIES'] = null;
      
    }

    }



    getItemListRelComp= async () => {

  const params = {
    name: 'account_contacts$contacts$get',
    params: {
      request: {
        idPerson: this.localStorage.getItem('clientIdentifier'),
        contactType:'COMMERCIAL_REFERENCE'
      },
      path: {
      },
      body: {}
    }
  };
    try {

      let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();

      this.itemList['COMMERCIAL_REFERENCES'] = response;

    } catch (error) {

      this.itemList['COMMERCIAL_REFERENCES'] = null;
      
    }

    }




    getItemListFiscalAdress= async () => {

      const params = {
        name: 'briefcase_pe$fiscal_addresses$get',
        params: {
          request: {
            idPerson: this.localStorage.getItem('clientIdentifier')
          },
          path: {
          },
          body: {}
        }
      };
        try {
    
          let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();
    
          this.itemList['FISCAL_ADDRESS'] = response;
    
        } catch (error) {
    
          this.itemList['FISCAL_ADDRESS'] = null;
          
        }
    
        }
    
    
    

  

}
