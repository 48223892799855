<ng-template #sectionInformation let-data_step="data_step" let-data_section="data_section">

    <!-- 

 <br>  {{data_section?.alternateName}}<br>

 -->

    <ng-container *ngIf="data_section?.alternateName == 'PERSONAL_INFORMATION'">
        <div class=" fz-14">

            <div class="mb-2  mt-3">
                <div class="font-weight-bold">{{dataInformation?.givenName}} {{dataInformation?.name}} {{dataInformation?.alternateName}} {{dataInformation?.familyName}}</div>
                <div>{{dataInformation?.identity}}</div>
            </div>

            <div>
                <div class="font-weight-bold  mt-3">Fecha de Nacimiento</div>
                <div>{{dataInformation?.birthDate | date: 'dd/MM/yyyy':'UTC' }} </div>
            </div>

            <div>
                <div *ngIf="false" class="font-weight-bold  mt-3">Lugar de Nacimiento</div>
                <div *ngIf="false"> - </div>
            </div>


            <div>
                <div class="font-weight-bold  mt-3">Estado Civil</div>
                <div>{{civilStatus[dataInformation?.civilStatus] | i18n }} </div>
            </div>

            <div *ngIf="dataInformation?.civilStatus == 'M'">
                <div>{{dataInformation?.spouse?.givenName }} {{dataInformation?.spouse?.familyName }}, {{dataInformation?.spouse?.identity }}</div>
            </div>


        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'BUSINESS_DATA'">
        <div class=" fz-14">

            <div class="mb-2  mt-2">
                <div class="font-weight-bold">Nombre de la Razón Social</div>
                <div>{{dataInformation?.legalName}} {{dataInformation?.familyName}}</div>
            </div>

            <div class="mb-3">
                <div class="font-weight-bold">Número de Identificación Fiscal</div>
                <div>CI. {{dataInformation?.taxID}}</div>
            </div>

            <div>
                <div class="font-weight-bold">Nombre Comercial</div>
                <div>{{dataInformation?.alternateName }} </div>
            </div>

        </div>
    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'HOME_ADDRESS'">

        <div class=" fz-14">

            <div class="mb-2  mt-2">
                <div> {{dataInformation?.homeAddress?.state?.name}}, {{dataInformation?.homeAddress?.city?.name}}, {{dataInformation?.homeAddress?.addressCountry?.name}} </div>
            </div>

            <div class="mb-2  mt-2">
                <div> {{dataInformation?.homeAddress?.urbanization}},{{dataInformation?.homeAddress?.streetAddress}}, {{dataInformation?.homeAddress?.name}}, {{dataInformation?.homeAddress?.floor}}, {{dataInformation?.homeAddress?.postalCode}}
                </div>
            </div>


            <div>
                <div class="font-weight-bold  mt-3">Condición de la Vivienda</div>
                <div>{{housingCondition[dataInformation?.homeAddress?.housingCondition] | i18n}} </div>
            </div>

        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'CONTACT_MEANS_PN' || data_section?.alternateName == 'CONTACT_MEANS' ">
        <div class=" fz-14">
            <div class="mb-2  mt-2">
                <div class="font-weight-bold">Teléfonos</div>
                <div>{{dataInformation?.otherPhone}}. {{dataInformation?.telephone}}</div>
            </div>

            <div>
                <div class="font-weight-bold">Email</div>
                <div>{{dataInformation?.email}}</div>
            </div>


        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'LEGAL_REPRESENTATIVE_PN'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['LEGAL_REPRESENTATIVE_PN']">

                <ng-container *ngFor="let row of itemList['LEGAL_REPRESENTATIVE_PN'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row.person.givenName}}  {{row.person.familyName}} </span>
                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['LEGAL_REPRESENTATIVE_PN'] &&  itemList['LEGAL_REPRESENTATIVE_PN']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'PEP_PN'">

        <div class="mt-2">

            <div class="mb-2  mt-3">
                <div class="font-weight-bold" *ngIf="!dataInformation?.itsAPep">No es PEP </div>
                <div class="font-weight-bold" *ngIf="dataInformation?.itsAPep">Es PEP </div>
                <div *ngIf="dataInformation?.itsAPep" class="mt-2">{{dataInformation?.institutionNamePep}}, {{dataInformation?.jobTitlePep}}, {{dataInformation?.countryPep?.identifier }}
                </div>


            </div>


        </div>
    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'RELATED_PEP_PN'">

        <div class="mt-2">

            <div class="font-weight-bold" *ngIf="!dataInformation?.isRelatedToPep">No tiene parentesco con PEP </div>
            <div class="font-weight-bold" *ngIf="dataInformation?.isRelatedToPep">Tiene parentesco con PEP </div>

            <div *ngIf="dataInformation?.isRelatedToPep && itemList['RELATED_PEP_PN']" class="mt-2">

                <ng-container *ngFor="let row of itemList['RELATED_PEP_PN'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 '>{{row.person.givenName}} {{row.person.secondName}} {{row.person.familyName}}   {{row.person.secondName}}, {{row.person.institutionNamePep}}  </span>
                    <hr class="mt-2 p-1">


                </ng-container>
            </div>

            <div class="mt-2" *ngIf=" !isRelatedToPep && (!itemList['RELATED_PEP_PN'] || itemList['RELATED_PEP_PN']?.length < 1) ">

                <span class="fz-14 ">
                No no se ha cargado información.</span>
            </div>

        </div>

    </ng-container>




    <ng-container *ngIf="data_section?.alternateName == 'RELATED_PEP_ASSOCIATION_PN'">

        <div class="mt-2">

            <div class="font-weight-bold" *ngIf="!dataInformation?.isRelatedToPepAssociation">No tiene relación con PEP</div>
            <div class="font-weight-bold" *ngIf="dataInformation?.isRelatedToPepAssociation">Tiene relación con PEP</div>

            <div *ngIf="dataInformation?.isRelatedToPepAssociation && itemList['RELATED_PEP_ASSOCIATION_PN']" class="mt-2">

                <ng-container *ngFor="let row of itemList['RELATED_PEP_ASSOCIATION_PN'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 '>{{row.person.givenName}} {{row.person.secondName}} {{row.person.familyName}}   {{row.person.secondName}}, {{row.person.institutionNamePep}}  </span>
                    <hr class="mt-2 p-1">


                </ng-container>
            </div>

            <div class="mt-2" *ngIf=" dataInformation?.isRelatedToPepAssociation && (!itemList['RELATED_PEP_ASSOCIATION_PN'] || itemList['RELATED_PEP_ASSOCIATION_PN']?.length < 1) ">

                <span class="fz-14 ">
                No no se ha cargado información.</span>
            </div>

        </div>

    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'ECONOMIC_ACTIVITY_PN'">

        <div class="mt-2">

            <div>
                <div>{{economicActivity[dataInformation?.isicV4] }} </div>
            </div>

            <div class="mt-3">
                <div>{{dataInformation?.specificActivity }} </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'RELATION_OF_LABOR_DEPENDENCY_CHECK'">

        <div class="mt-2">


            <div class="font-weight-bold" *ngIf="!dataInformation?.dependent">No trabaja para una empresa </div>
            <div class="font-weight-bold" *ngIf="dataInformation?.dependent">Trabaja para una empresa</div>

        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'RELATION_OF_LABOR_DEPENDENCY'">

        <div class="mt-2">

            <div class="font-weight-bold  mt-3">{{dataInformation?.worksFor.legalName}}</div>
            <div class="  mt-2">{{dataInformation?.worksFor?.taxID}}</div>
            <div class="  mt-2">{{dataInformation?.remuneration}} Bs.</div>

        </div>
    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'OWN_BUSINESS_CHECK'">

        <div class="mt-2">


            <div class="font-weight-bold" *ngIf="!dataInformation?.haveOwnBusiness">No tiene negocio propio </div>
            <div class="font-weight-bold" *ngIf="dataInformation?.haveOwnBusiness">Tiene negocio propio</div>

        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'OWN_BUSINESS'">

        <div class="mt-2">

            <div class="font-weight-bold  mt-3">{{ dataInformation?.ownBusiness.legalName}}</div>
            <div class="  mt-2">{{dataInformation?.ownBusiness?.taxID}}</div>
            <div class="  mt-2">{{dataInformation?.ownBusiness?.totalMonthlyIncome}} Bs.</div>

        </div>
    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'ANOTHER_SOURCE_INCOME'">

        <div class="mt-2">


            <div class="font-weight-bold" *ngIf="!dataInformation?.anotherSourceIncome">No posee otras fuentes de ingresos </div>
            <div class="font-weight-bold" *ngIf="dataInformation?.anotherSourceIncome">Tiene otras fuentes de ingresos</div>
            <div *ngIf="dataInformation?.anotherSourceIncome" class="  mt-2">{{incomeGeneratingActivity[dataInformation?.incomeGeneratingActivity] | i18n}}</div>
            <div *ngIf="dataInformation?.anotherSourceIncome" class="  mt-2">{{dataInformation?.monthlyIncomeAnotherSource}} Bs.</div>

        </div>
    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'PERSONAL_REFERENCES'">

        <div class="mt-2">



            <ng-container *ngIf="itemList['PERSONAL_REFERENCES']">

                <ng-container *ngFor="let row of itemList['PERSONAL_REFERENCES'] ; let first = first; let i = index">

                    <div class='text-capitalize font-weight-bold  mt-1 mb-1 fz-14 '>{{row?.personContact?.givenName}} {{row?.personContact?.familyName}} </div>
                    <div class=' mt-1 fz-14 '>{{row?.personContact?.identity}} </div>
                    <div class=' mt-1 fz-14 '>{{row?.personContact?.contactPoint?.telephone }} </div>



                    <hr class="mt-2 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!itemList['PERSONAL_REFERENCES'] || itemList['PERSONAL_REFERENCES']?.length < 1">

                <span class="fz-14 ">
                No no se ha cargado información.

            </span>
            </ng-container>

        </div>

    </ng-container>




    <ng-container *ngIf="data_section?.alternateName == 'BANK_ACCOUNT_WITH_INST'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['BANK_ACCOUNT_WITH_INST']">

                <ng-container *ngFor="let row of itemList['BANK_ACCOUNT_WITH_INST'] ; let first = first; let i = index">
                    <div class='text-capitalize font-weight-bold  mt-1 mb-1 fz-14 '>{{row?.provider?.name}}</div>
                    <div class=' mt-1 fz-14 '>{{accountType[row?.bankAccountType] | i18n}}</div>
                    <div class=' mt-1 fz-14 '>{{row?.accountNumber}} </div>



                    <hr class="mt-2 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!itemList['BANK_ACCOUNT_WITH_INST'] || itemList['BANK_ACCOUNT_WITH_INST']?.length < 1">

                <span class="fz-14 ">
                No no se ha cargado información.

            </span>
            </ng-container>

        </div>

    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'BANK_ACCOUNT_WITH_OTHER_INST'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['BANK_ACCOUNT_WITH_OTHER_INST']">

                <ng-container *ngFor="let row of itemList['BANK_ACCOUNT_WITH_OTHER_INST'] ; let first = first; let i = index">
                    <div class='text-capitalize font-weight-bold  mt-1 mb-1 fz-14 '>{{row?.provider?.name}}</div>
                    <div class=' mt-1 fz-14 '>{{accountType[row?.bankAccountType] | i18n}}</div>
                    <div class=' mt-1 fz-14 '>{{row?.accountNumber}} </div>



                    <hr class="mt-2 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!itemList['BANK_ACCOUNT_WITH_OTHER_INST'] || itemList['BANK_ACCOUNT_WITH_OTHER_INST']?.length < 1">

                <span class="fz-14 ">
                No no se ha cargado información.

            </span>
            </ng-container>

        </div>

    </ng-container>


    <!-- LEGAL-->


    <ng-container *ngIf="data_section?.alternateName == 'LEGAL_REPRESENTATIVE'">
        <div class="mt-2">


            <ng-container *ngIf="itemList['LEGAL_REPRESENTATIVE']">

                <ng-container *ngFor="let row of itemList['LEGAL_REPRESENTATIVE'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row.person.givenName}}  {{row.person.familyName}} </span>
                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['LEGAL_REPRESENTATIVE'] &&  itemList['LEGAL_REPRESENTATIVE']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'SHAREHOLDERS'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['SHAREHOLDERS']">

                <ng-container *ngFor="let row of itemList['SHAREHOLDERS'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row.person.givenName}}  {{row.person.familyName}} </span>
                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['SHAREHOLDERS'] &&  itemList['SHAREHOLDERS']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'CUSTOMERS'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['CUSTOMERS']">

                <ng-container *ngFor="let row of itemList['CUSTOMERS'] ; let first = first; let i = index">

                    <span *ngIf="row?.alternateName == 'NATURAL'" class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.personContact?.givenName}}  {{row?.personContact?.familyName}} </span>
                    <span *ngIf="row?.alternateName == 'LEGAL'" class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.organizationContact?.legalName}}</span>

                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['CUSTOMERS'] &&  itemList['CUSTOMERS']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'SUPPLIERS'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['SUPPLIERS']">

                <ng-container *ngFor="let row of itemList['SUPPLIERS'] ; let first = first; let i = index">

                    <span *ngIf="row?.alternateName == 'NATURAL'" class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.personContact?.givenName}}  {{row?.personContact?.familyName}} </span>
                    <span *ngIf="row?.alternateName == 'LEGAL'" class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.organizationContact?.legalName}}</span>

                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['SUPPLIERS'] &&  itemList['SUPPLIERS']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'COMMERCIAL_REFERENCES'">

        <div class="mt-2">

            <ng-container *ngIf="itemList['COMMERCIAL_REFERENCES']">

                <ng-container *ngFor="let row of itemList['COMMERCIAL_REFERENCES'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.organizationContact?.legalName}}</span>
                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['COMMERCIAL_REFERENCES'] &&  itemList['COMMERCIAL_REFERENCES']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'RELATED_COMPANIES'">

        <div class="mt-2">

            <ng-container *ngIf="itemList['RELATED_COMPANIES']">

                <ng-container *ngFor="let row of itemList['RELATED_COMPANIES'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.organizationContact?.legalName}}</span>
                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['RELATED_COMPANIES'] &&  itemList['RELATED_COMPANIES']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'FISCAL_ADDRESS'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['FISCAL_ADDRESS']">

                <ng-container *ngFor="let row of itemList['FISCAL_ADDRESS'] ; let first = first; let i = index">

                    <div *ngIf="row?.description" class="mb-2  mt-2">
                        <div> {{row?.description}}</div>
                    </div>

                    <div *ngIf="!row?.description" class="mb-2  mt-2">
                        <div> {{row?.addressLocality?.name}}, {{row?.state?.name}}, {{row?.city?.name}}, {{row?.addressCountry?.description}} </div>
                    </div>




                    <div>
                        <div class="font-weight-bold  mt-3 fz-14">Tipo</div>
                        <div>{{row?.addressType?.alternateName | i18n}} </div>
                    </div>


                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['FISCAL_ADDRESS'] &&  itemList['FISCAL_ADDRESS']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>






    <!--

        {{data_section?.alternateName }}

 -->

</ng-template>

<div class="container mb-4">
    <div class="row  mt-3 text-left">

        <div class="col-12 col-md-10 offset-md-1">

            <div *ngIf="!dataInformation?.completeBriefcase && indicatorsProgres?.percent && indicatorsProgres?.percent == 100" (click)="sendCompleat()" class="alert alert-secondary p-3  mt-4 mb-4 animate__animated animate__fadeIn">

                <div class="d-flex  fz-14">

                    <div class="pr-1 ">
                        <i class="text-secondary fas fa-credit-card pt-2 pr-3 fa-3x"></i>
                    </div>

                    <div class="text-left w-100">

                        <div class="text-secondary">
                            <div class="font-weight-bold mb-2"> Ahora necesitamos aprobar tu información</div>
                            <div>Felicitaciones, haz completado toda la información ahora procederemos a aprobarla y crear tu perfil en nuestros subsistemas.</div>
                        </div>
                        <div class="ml-auto text-right ">

                            <a (click)="true" class="font-weight-bold" href="javascript:void(0)">Enviar</a>
                        </div>
                    </div>

                </div>
            </div>

            <div class="w-100 d-flex mb-1 mb-md-0" *ngIf="indicatorsProgres">
                <div *ngIf=" indicatorsProgres?.percent < 100" class="text-secondary  mt-2">Completa tu Perfil</div>
                <div *ngIf="indicatorsProgres?.percent == 100" class="text-secondary  mt-2">Tu Perfil</div>

                <div class="ml-auto">
                    <div style="width: 40px;">
                        <akeela-graphics [percent]="indicatorsProgres?.percent" [type]="'circle'">
                        </akeela-graphics>
                    </div>
                </div>
            </div>
            <hr>



            <div *ngIf=" indicatorsProgres?.percent && indicatorsProgres?.percent < 100 && workflowStatusMenu" class="alert alert-secondary  mb-4 animate__animated animate__fadeIn mt-3">

                <div class="d-flex  fz-14">
                    <div class="pr-1">
                        <i class=" fas fa-info-circle text-primary  "></i>
                    </div>

                    <div class="text-left">
                        <div class="text-secondary"> Completar su información personal</div>
                    </div>
                    <div class="ml-auto text-right ">
                        <a class="font-weight-bold" (click)="redirectContinue()" href="javascript:void(0)">Reanudar</a>
                    </div>
                </div>

            </div>
            <ng-container *ngIf="!workflowStatusMenu">
                <div class=" col-12  p-t-1 p-b-1">
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
            <ng-container *ngFor="let stage of workflowStatusMenu ; let first = first; let i = index">
                <ng-container *ngFor="let step of stage?.howToSteps ; let first2 = first; let i2 = index">
                    <ng-container *ngIf="step?.alternateName != 'SEND_CUSTOMER_DATA'">
                        <div (click)="dropDownTransitionsWfs[step?.alternateName] = !dropDownTransitionsWfs[step?.alternateName]" class="pt-2 w-100 text-left" *ngIf="step?.sections?.length > 0 && section?.additionalType != 'modal' && step?.canBeCompleted">

                            <div class="d-flex w-100 active-cursor mt-4">

                                <div style="cursor: pointer;  color: #666 !important">
                                    <div class="d-flex w-100">
                                        <div class="mr-2">
                                            <i *ngIf="(workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.state != 'COMPLETED'" title="Información incompleta" class="fas fa-info-circle text-warning fz-18 pr-1"></i>

                                        </div>

                                        <div class="mb-2">
                                            <h5 class="text-left text-dark">
                                                <span> {{step?.name | i18n}} </span>
                                            </h5>
                                            <!--
                                            <div class="fz-14">
                                                {{'@i18n-htmlSubTitle-'+step?.alternateName | i18n }}


                                            </div>
                                             -->
                                        </div>

                                    </div>

                                </div>

                                <div class="ml-auto text-right text-primary ">
                                    <i *ngIf="!dropDownTransitionsWfs[step?.alternateName]" class="fas fa-chevron-down"></i>
                                    <i *ngIf="dropDownTransitionsWfs[step?.alternateName]" class="fas fa-chevron-up"></i> </div>
                            </div>

                            <hr *ngIf="!dropDownTransitionsWfs[step?.alternateName]">

                        </div>
                        <div class="section-box mt-2 mb-2 pt-2 " *ngIf="dropDownTransitionsWfs[step?.alternateName] ">
                            <ng-container *ngFor="let section of step?.sections ; let first3 = first; let i3 = index">
                                <div class=" mb-2">
                                    <div class="w-100  p-3 pb-2  text-left" (click)="redirectSection(workflowCodeActive,stage?.alternateName,step?.alternateName,section?.alternateName);">
                                        <div class="d-flex w-100">
                                            <div>
                                                <h6 class="text-secondary"> <span> {{section?.sectionTitle | i18n}} </span>
                                                </h6>
                                            </div>
                                            <div class="ml-auto text-right text-primary">
                                                <h6>
                                                    <div class="circle-check" *ngIf="(workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state != 'COMPLETED'"><i title="Información incompleta" class="fas fa-info-circle text-warning"></i> </div>

                                                    <ng-container *ngIf="section?.icono">

                                                        <i class="{{section?.icono}} mr-1 "></i>

                                                    </ng-container>

                                                    <ng-container *ngIf="!section?.icono">

                                                        <i class="fas fa-circle mr-1 "></i>

                                                    </ng-container>




                                                </h6>
                                            </div>
                                        </div>

                                        <div class="w-100" *ngIf="(workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state == 'COMPLETED'">

                                            <ng-container *ngTemplateOutlet="sectionInformation; context:{data_step: step,data_section:section}">
                                            </ng-container>
                                        </div>

                                        <div class="w-100 mt-2 mb-2 " style=" color: #333 !important" *ngIf="(workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state != 'COMPLETED'">
                                            No no se ha cargado información.
                                        </div>



                                    </div>
                                </div>

                            </ng-container>


                        </div>


                    </ng-container>
                </ng-container>
            </ng-container>



        </div>
    </div>
</div>