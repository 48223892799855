<div class="text-center row">

    <div class="col-12 col-md-10 offset-md-1">
        <div class="text-left pt-4">
            <span class="pt-2 pb-2 font-weight-light">Verifícate</span>
            <div class="line">
                <div class="line1 bg-primary font-weight-light"></div>
            </div>
        </div>

        <div class="mt-4 mb-4 ">

            <h5 class="font-weight-light mb-3">Hola <span class="text-capitalize">{{usuario?.firstName | titlecase}}</span> , </h5>
            <h5 class="font-weight-bold mb-4">Ayúdanos a verificar tu identidad</h5>



            <div class="pr-1 ">
                <img src="./assets/public/images/selfie.svg" style="width: 70px" class="pt-2 pr-2" alt="">
            </div>

            <div class="font-weight-light text-dark mt-4 mb-4">
                Te solicitaremos:
            </div>


            <div class=" font-weight-bold text-dark">
                <div class="mt-2">Información Personal</div>
                <div class="mt-2"> Selfie </div>
                <div class="mt-2">Documento de Identidad
                    <div class="font-weight-normal ">(Cédula de identidad ó Pasaporte)</div>
                </div>
                <div class="mt-2">Selfie + Documento de Identidad</div>
            </div>


            <div class="mt-4 mb-5 row">
                <div class="col-12 col-md-4 offset-md-4">
                    <button (click)="redirect()" class="btn btn-outline-primary btn-block">Ok</button>
                </div>
                <a class="col-12 text-primary mt-3 fz-14 " href="javascript:void(0)" (click)="redirectDashboard()">Omitir</a>

            </div>
        </div>

    </div>
</div>