


import { AuthService } from '@akeela/auth';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { async, Subscription } from 'rxjs';
import { WorkflowService } from 'src/app/services/workflow.service';
import swal from 'sweetalert2';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.css']
})
export class ValidateEmailComponent implements OnInit,OnDestroy {

  errorMsgValidatEmail;
  sentValidateEmail = false;
  activeModalValidateEmail= false;
  centerModal;
  code;
  data;
  loadingSentValidateEmail;
  loadingValidateCode;
  sentValidateErrorEmail;

  timeValid = true;
  timeLimit = 10; //tiempo -  x * 30
  conteo:any;
  intervaloRegresivo:any;
  minutes:any;
  seconds:any;
  showValidateEmail : Subscription;
  @Output() change = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() set selected(data) {
    if (data) {
      
      this.centerModal = true;

    }
   
  }


  @Input() set config(data) {
    if (data) {      
      this.data = data;
      this.sendMsgValidateEmail(data);
    }
   
  }
  constructor(
 
    private _httpService: HttpService,
    public _auth: AuthService,
    private localStorage: LocalService,
    private akWorkflowService: AkeelaWorkflowService,
    private i18n: I18nPipe,

  ) { 

    this.inicializar();

  }


 inicializar(){

   this.conteo = new Date(this.timeLimit * 30000);
  this.minutes = this.conteo.getMinutes();
  this.seconds = this.conteo.getSeconds();

    }

cuenta(){
    this.intervaloRegresivo = setInterval(() => {this.regresiva() }, 1000);
 }


regresiva(){
    if(this.conteo.getTime() > 0){
      this.conteo.setTime(this.conteo.getTime() - 1000);
    }else{

       clearInterval(this.intervaloRegresivo);
       this.timeValid = false;
       this.inicializar();
    }

    this.minutes = this.conteo.getMinutes();
    this.seconds = this.conteo.getSeconds();
  
 }




      ngOnInit(): void {

        this.akWorkflowService.showValidateEmail$.subscribe((response) => {
        if (response) {
            console.clear();
            console.log(response);
            
           
        }
         });
      }

      sendMsgValidateEmail = async  (email:any) =>{

        this.timeValid = true;
        this.cuenta();
  
        this.loadingSentValidateEmail = true;
      
      this.sentValidateErrorEmail = false;
  
        this.loadingSentValidateEmail = true;
  
        const params = {
          name: 'person$numericCodeConfirmEmail$get',
          params: {
            request: {
              email
            },
            path: {
            },
            body: {}
          }
        };
          try {
            let result:any = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();
  
            
            if (result) {
      
              this.sentValidateEmail = true;
  
  
            }else{
  
              this.sentValidateErrorEmail = true;
  
              this.loadingSentValidateEmail = false;
  
            }
      
            this.loadingSentValidateEmail = false;
  
      
          }catch  (error) {
            console.log(error);
            
            this.loadingSentValidateEmail = false;
  
            this.sentValidateEmail = false;
  
          }
          
  
          
  
      }


    getCodeValidateEmail = async (email,code)  =>{
      this.loadingSentValidateEmail = true;
      this.loadingValidateCode = true;

    const params = {
      name: 'person$validateNumericCodeConfirmEmail$get',
      params: {
        request: {
          email,
          code
        },
        path: {
        },
        body: {}
      }
    };

      try {

        let result:any = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();
    

        debugger
        if(result){
                 
          swal.fire({
            html: this.i18n.transform('Validación exitosa')
          });

          this.setChange(this.data);

        }else{
          
            this.errorMsgValidatEmail = 'Codigo Invalido'
            this.code = null;
        }

        this.loadingSentValidateEmail = false;
        this.loadingValidateCode = false;
        
      }catch  (error) {

        this.errorMsgValidatEmail = 'Intente de nuevo'
        this.code = null;

        
        this.loadingSentValidateEmail = false;
        this.loadingValidateCode = false;

      }

    }

      

      ngOnDestroy = ():void => {

      }


      setClose(){
        this.close.emit(null);
        this.centerModal = false;
      }


      setChange(e){
        this.change.emit(e);
        this.centerModal = false;
      }


}
