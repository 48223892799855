import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, KeepAliveService } from '@akeela/auth';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { HttpService, PathService } from '@akeela/properties';
import { ErrorService } from '@akeela/common';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { TransitionService } from '../../../../services/transition.service';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import { isNullOrUndefined } from 'util';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { PropertiesService } from '../../../../services/properties/properties.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { log } from 'console';


@Component({
  selector: 'public-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  params;
  endpoint;
  workflow;
  stage;
  step;
  event = '';
  url_transition = 'workflow$transitionssteps$get';
  env = environment;
  sessionType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _auth: AuthService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService,
    public _httpService: HttpService,
    private _breadcrumbService: AkeelaBreadcrumbService,
    private _transition: TransitionService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _pathService: PathService,
    private _error: ErrorService,
    private http: HttpClient,
    private i18n: I18nPipe,
    public localStorage: LocalService,
    private _properties: PropertiesService,
    private _wfService: WorkflowService,
    private _keepAliveService: KeepAliveService

  ) { }

  ngOnInit() {
   // control de cache por versión
  //  const config = require('../../../../../../package.json'); 
  //  console.log("************************************************");
  //  console.log("Akeela System Version: "+config.version);      
  //  console.log("************************************************");
  //  if(isNullOrUndefined(this.localStorage.getItem('version'))) {
  //   this.localStorage.setItem('version', config.version);
  //  }

    this._wfService.setLogin(true);

    this._breadcrumbService.setbreadcrumbOptions([]);
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    document.body.classList.remove('teme-gray');
    this.params = {
      role: 'CLIENT',
      clientCode: environment.security.clientCode,
      formsValidations: environment.formsValidations.login,
      encrypt: environment.auth.activeEncrypt
    }

    this._properties.getProperty({
      endpointKey: 'app_properties$getProperty$get',
      propertyKey: 'app.session.active.type'
    }).subscribe(resp => {
      this.sessionType = resp;
    }, error => {
      this.sessionType = null;
    });

    this.endpoint = 'person$login$post';
  }

  response(event) {
    
    if (this._auth.isAuthenticated()) {


      if (this.localStorage.getItem('objToken')) {
        this.event = 'LOGIN_AFTER_PLAN_ACCOUNT_SELECTED';
        const transaction = JSON.parse(this.localStorage.getItem('objToken'));
        this.localStorage.setItem('transactionNumber', transaction.transactionNumber);
        //             responseType: 'text',

        const params = {
          name: 'account$assignaccountuser$put',
          params: {
            request: {
              role: 'CLIENT',
              audience: this._auth.getUserAudience()
            },
            path: {
              workflow: this.workflow,
              stage: this.stage,
              step: this.step,
              event: this.event,
              transactionNumber: transaction.transactionNumber
            },
            body: {
              token: transaction.token,
              requestUser: {
                identifier: this._auth.getUsername(),
                adminOrganization: {
                  identifier: this._auth.getAdminOrganizationIdentifier()
                },
                person: {
                  identifier: this.localStorage.getItem('clientIdentifier')
                }
              }
            }
          }
        };
        this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
          this.setSessionValues();
          this.localStorage.removeItem('objToken');
          const parameters = {
            name: this.url_transition,
            params: {
              request: {
                role: 'CLIENT',
                profile: 'CLIENT',
                audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL',
                event_code: this.event
              },
              path: {
                prefixWorkflow: 'workflow_manager',
                workflow: this.workflow,
                stage: this.stage,
                step: this.step
              }
            },
            o: {}
          };
          this._transition.next('private', parameters);
        }, error => {
          this.confirm();
          // this.logout(this._auth.getUsername()).subscribe(() => {
          //   setTimeout(() => {
          //     localStorage.clear();
          //   },300);
          //   this._error.show(error);
          // });
          // setTimeout(() => {
          //   this._error.show(error);
          // });
        });


      } else {
        this.setSessionValues();

        //Juan se inhabilito la opcion que muestra de pimero la vista de verificación de identidad
     
          this.akWorkflowService.worflowEvents$.next({
            response: true,
            action: 'verificationIdentity'
          });
      

          this.router.navigate([(environment.defaultInitRouteAuthenticated)], {
            queryParams: {
              role: 'CLIENT',
              profile: 'CLIENT',
              audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL'
            }
          }).then(() => {
            this._keepAliveService.setReset();
          });
        }
      
    }
  }

  applyVerification = () => (this.localStorage.getItem('audience') === 'NATURAL' ? true : false);


  confirm() {
    const text = '<div style="text-align: left">' + this.i18n.transform('@i18n-popup-text-alert-confirmation-token-invalid-type-person-1') + ' ' + (this._auth.getUserAudience() === 'NATURAL' ? 'Persona Natural' : 'Persona Jurídica') + '<br><br>' +
      this.i18n.transform('@i18n-popup-text-alert-confirmation-token-invalid-type-person-2') + ' ' + (this._auth.getUserAudience() === 'NATURAL' ? 'Persona Jurídica' : 'Persona Natural') + this.i18n.transform('@i18n-popup-text-alert-confirmation-token-invalid-type-person-3') + '<br><br>' +
      this.i18n.transform('@i18n-popup-text-alert-confirmation-token-invalid-type-person-6') + ' ' + (this._auth.getUserAudience() === 'NATURAL' ? 'Persona Jurídica' : 'Persona Natural') + this.i18n.transform('@i18n-popup-text-alert-confirmation-token-invalid-type-person-7') + '<br><br>' +
      this.i18n.transform('@i18n-popup-text-alert-confirmation-token-invalid-type-person-4') + ' ' + (this._auth.getUserAudience() === 'NATURAL' ? 'Persona Jurídica' : 'Persona Natural') + this.i18n.transform('@i18n-popup-text-alert-confirmation-token-invalid-type-person-5') + '<br>' + '<div>';
    swal.fire({
      title: this.i18n.transform('@i18n-error-server-internal-alert'),
      html: text,
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-continue'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-logout'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.localStorage.removeItem('objToken');
        this.setSessionValues();
        this.router.navigate([(environment.defaultInitRouteAuthenticated)], {
          queryParams: {
            role: 'CLIENT',
            profile: 'CLIENT',
            audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL'
          }
        });
      } else {
        this.logout(this._auth.getUsername()).subscribe(() => {
          const aux = this.localStorage.getItem('objToken')
          this.localStorage.clear();
          this.localStorage.setItem('objToken', aux);
        });
      }
    });
  }

  logout(username) {
    return new Observable((resolve) => {
      const logoutName = 'person$logout$delete';
      this._pathService.getPathService(logoutName).subscribe((response) => {
        const params = { username };
        const url = response.url;
        this.http.delete(url, { params }).subscribe(
          responseLogout => {
            resolve.next(responseLogout);
            resolve.complete();
          },
          error => {
            resolve.next(error);
            resolve.complete();
          }
        );
      });
    });
  }

  setSessionValues() {

    /*
    const opt = {
      name: 'LEFT_MENU_CLIENT'
    };
    this._akeelaSidebarMenu.setsidebarLeftOptions(opt);
    this._pathService.getPathService(false, environment.appEndPontDefaultConfig.menu).subscribe(pathResponse => {
      this._akeelaSidebarMenu.setsidebarLeftKey(pathResponse);
    });
    if (this.localStorage.getItem('showSidebarLeft') !== 'true') {
      if (screen.width > 768) {
        this._akeelaSidebarMenu.setshowSidebarLeft(true);
      } else {
        this._akeelaSidebarMenu.setshowSidebarLeft(false);
      }
    } else {
      this._akeelaSidebarMenu.setshowSidebarLeft(true);
    }

    */

    this.localStorage.setItem('role', 'CLIENT');
    this.localStorage.setItem('profile', 'CLIENT');
    this.localStorage.setItem('audience', this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL');

    this.localStorage.setItem('clientName',this._auth.getSession()?.firstName+' '+this._auth.getSession()?.lastName);
    this.localStorage.setItem('clientUsername', this._auth.getUsername());
    this.localStorage.setItem('clientIdentifier',this._auth.getAdminOrganizationIdentifier()?this._auth.getAdminOrganizationIdentifier():this._auth.getPersonIdentifier());
    this.localStorage.setItem('clientaudience', this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL');
    this.localStorage.setItem('businessName',this._auth.getSession()?.businessName);


  }


  ngOnDestroy() {
    this._wfService.setLogin(null);

  }

  register() {
    this.localStorage.setItem('role', 'CLIENT');
    this.router.navigateByUrl(this.router.createUrlTree
      (['public/ACCOUNT_OPEN/stages/CREATE_USER_ACCOUNT_OPENING/steps/CREATE_USER_ACCOUNT_OPENING'],
      {
        queryParams: {
          role: 'CLIENT'
        }
      }
      )
    );
  }


  recovery() {
    this.router.navigateByUrl(this.router.createUrlTree
      (['recovery-password'],
      {
        queryParams: {}
      }
      )
    );
  }

  unlock() {
    this.router.navigateByUrl(this.router.createUrlTree
      (['unlock'],
      {
        queryParams: {}
      }
      )
    );
  }



}


